import { ReportFilter } from 'interfaces/reports.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { Select } from 'shared-components/Select';

export const CUSTOMERS_REPORT_FILTERS: ReportFilter[] = [
  {
    id: 1,
    name: 'country_report',
    query: 'countries',
  },
  {
    id: 2,
    name: 'countries_dynamic',
    query: 'countries_dynamic',
  },
  {
    id: 3,
    name: 'status_report',
    query: 'statuses',
  },
  {
    id: 4,
    name: 'statuses_dynamic',
    query: 'statuses_dynamic',
  },
];

export const CUSTOMERS_REQUESTS_REPORT_FILTERS: ReportFilter[] = [
  {
    id: 1,
    name: 'country_report',
    query: 'countries',
  },
  {
    id: 2,
    name: 'countries_dynamic',
    query: 'countries_dynamic',
  },
  {
    id: 3,
    name: 'status_report',
    query: 'statuses',
  },
  {
    id: 4,
    name: 'statuses_dynamic',
    query: 'statuses_dynamic',
  },
  {
    id: 5,
    name: 'source_report',
    query: 'sources',
  },
  {
    id: 6,
    name: 'sources_dynamic',
    query: 'sources_dynamic',
  },
];

export const INCONSISTENCIES_REPORT_FILTERS: ReportFilter[] = [
  {
    id: 1,
    name: 'inconsistency_type_report',
    query: 'types',
  },
  {
    id: 2,
    name: 'inconsistency_types_dynamic',
    query: 'types_dynamic',
  },
  {
    id: 3,
    name: 'department_report',
    query: 'departments',
  },
  {
    id: 4,
    name: 'departments_dynamic',
    query: 'departments_dynamic',
  },
];

export const getCustomerReportsFilters = (
  options: SelectOption[],
  defaultSelectData?: SelectOption,
): Record<string, Field[]> => ({
  countries: [
    {
      name: 'country',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'country',
      placeholder: 'country',
    },
  ],
  statuses: [
    {
      name: 'status',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'status',
      placeholder: 'status',
    },
  ],
});

export const getCustomerRequestsReportsFilters = (
  options: SelectOption[],
  defaultSelectData?: SelectOption,
): Record<string, Field[]> => ({
  countries: [
    {
      name: 'country',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'country',
      placeholder: 'country',
    },
  ],
  statuses: [
    {
      name: 'status',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'status',
      placeholder: 'status',
    },
  ],
  sources: [
    {
      name: 'source',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'source',
      placeholder: 'source',
    },
  ],
});

export const getInconsistenciesReportsFilters = (
  options: SelectOption[],
  defaultSelectData?: SelectOption,
): Record<string, Field[]> => ({
  types: [
    {
      name: 'inconsistencyType',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'inconsistency_type',
      placeholder: 'inconsistency_type',
    },
  ],
  departments: [
    {
      name: 'department',
      options: {},
      defaultSelectData,
      component: Select,
      selectData: options,
      label: 'department',
      placeholder: 'department',
    },
  ],
});
