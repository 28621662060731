import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { rolesApi } from 'apis/roles.apis';
import { QueryParams } from 'interfaces/query.interfaces';
import { RoleData, UpdateRoleData } from 'interfaces/roles.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const ROLES_SLICE_NAME = 'roles';

export const asyncGetRoles = createAsyncThunk(
  `${ROLES_SLICE_NAME}/getRoles`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await rolesApi.getRoles(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetRole = createAsyncThunk(
  `${ROLES_SLICE_NAME}/getRole`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await rolesApi.getRoleById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateRole = createAsyncThunk(
  `${ROLES_SLICE_NAME}/updateRole`,
  async (payload: UpdateRoleData, { rejectWithValue }) => {
    try {
      await rolesApi.updateRole(payload);
      toast.success(intl.formatMessage({ id: 'role_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateRole = createAsyncThunk(
  `${ROLES_SLICE_NAME}/createRole`,
  async (payload: RoleData, { rejectWithValue }) => {
    try {
      await rolesApi.createRole(payload);
      toast.success(intl.formatMessage({ id: 'role_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteRole = createAsyncThunk(
  `${ROLES_SLICE_NAME}/deleteRole`,
  async (id: number, { rejectWithValue }) => {
    try {
      await rolesApi.deleteRole(id);
      toast.success(intl.formatMessage({ id: 'role_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetRole = createAction('UNSET_ROLE');
