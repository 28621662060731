import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { ManagerActionCustomer, ManagerActionInfo } from 'interfaces/manager-actions.interfaces';
import { asyncGetCustomerRequest, unsetCustomerRequest } from 'modules/customer-requests/action';
import { selectCustomerRequest } from 'modules/customer-requests/selectors';
import { asyncGetCustomer, unsetCustomer } from 'modules/customers/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CardInfoItem } from 'shared-components/CardInfoItem';

export const ManagerActionRequestInfo = () => {
  const { search } = useLocation();

  const { customerId } = qs.parse(search);

  const [requestContent, setRequestContent] = useState<ManagerActionInfo>({});
  const [customerContent, setCustomerContent] = useState<ManagerActionCustomer>({});

  const dispatch = useAppDispatch();

  const request = useAppSelector(selectCustomerRequest);

  useEffect(() => {
    if (customerId) {
      dispatch(asyncGetCustomerRequest(customerId as string))
        .unwrap()
        .then((response) => {
          setRequestContent({
            responsible: response.responsible.name,
            status: response.requestStatus.name,
            products: response.productGroups.map((group) => group.name).join(', '),
            additional_info: response?.description,
            source: response.requestSource.name,
          });
        });
    }
  }, [customerId, dispatch]);

  useEffect(() => {
    if (request) {
      dispatch(asyncGetCustomer(`${request.customer.id}`))
        .unwrap()
        .then((response) => {
          setCustomerContent({
            name: response.name,
            country: response.country.name,
            city: response.city.name,
            company: response.company,
            contact: response.contactPerson,
            email: response.email,
            phone: response.phone,
            additional_contacts: response?.additionalContacts,
            language: response.language,
            status: response.customerStatus.name,
          });
        });
    }
  }, [dispatch, request]);

  useBeforeRouteChange(() => {
    dispatch(unsetCustomerRequest());
    dispatch(unsetCustomer());
  });

  if (!request) return null;

  return (
    <div className='flex flex-col md:flex-row gap-6'>
      <CardInfoItem title='customer_info' content={customerContent} />
      <CardInfoItem title='customer_request_details' content={requestContent} />
    </div>
  );
};
