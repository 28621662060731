import { FC, useEffect, useState } from 'react';

import cn from 'classnames';
import { motion } from 'framer-motion';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as VectorSvg } from 'assets/svg/right-vector.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { RoutesItem } from 'page-components/page-layout/PagesCommonLayout';
import { Button } from 'shared-components/Button';
import { hasPermission } from 'utils/users.utils';

interface Props {
  navigationItem: RoutesItem | undefined;
  isSidebarOpen: boolean;
  handleCloseSidebar: VoidFunction;
}

export const Navbar: FC<Props> = ({ navigationItem, handleCloseSidebar, isSidebarOpen }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const user = useAppSelector(selectCurrentUser);

  const [resetKey, setResetKey] = useState(0);

  const { navigation: navigationList } = navigationItem || {};

  const handleNavigate = (path: string) => {
    navigate({ pathname: path, search: '' }, { replace: true });

    if (window.innerWidth < 1024) {
      handleCloseSidebar();
    }
  };

  useEffect(() => {
    setResetKey((prevKey) => prevKey + 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem('sidebar')]);

  if (user === null) return null;

  return (
    <nav
      className={twMerge(
        cn(
          'fixed bg-white z-20 lg:z-0 -left-[320px] top-0 min-w-[250px] h-full pt-20 transition-all duration-300 lg:mt-6 lg:ml-16 lg:bg-transparent overflow-y-auto remove-scrollbar',
          { 'left-16 lg:left-0': navigationItem },
          { '-left-[320px]': !isSidebarOpen },
        ),
      )}
    >
      <motion.div
        key={resetKey}
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100, transition: { duration: 0.4 } }}
        transition={{ delay: 0, duration: 0.4 }}
      >
        <div>
          {Object.keys(navigationList || {})?.length
            ? Object.keys(navigationList || {}).map((key) => {
                return (
                  <div className='mb-9 px-8' key={key}>
                    {key !== 'headless' && (
                      <h6 className='text-xs text-gray uppercase font-medium select-none mb-5 ml-10 px-2'>
                        <FormattedMessage id={key} />
                      </h6>
                    )}
                    {/* MOVE TO SEPARATE COMPONENT*/}
                    <ul className='flex flex-col gap-2'>
                      {navigationList?.[key]?.map(({ title, path, icon: Icon, permissions }) => {
                        const isUserAllowed = hasPermission(user?.permissions, permissions);
                        return (
                          <li
                            key={path}
                            className={twMerge(
                              cn(
                                'text-mediumBlue relative overflow-hidden pl-4 py-1.5 transition-all rounded hover:rounded hover:bg-gray1/10',
                                {
                                  'bg-gray1/10 font-bold text-black': pathname.includes(path),
                                },
                                {
                                  'hover:bg-transparent': !isUserAllowed,
                                },
                              ),
                            )}
                          >
                            <Button
                              variant={ButtonVariants.TRANSPARENT}
                              disabled={!isUserAllowed}
                              onClick={() => handleNavigate(path)}
                              className='flex items-center text-mediumBlue py-1 disabled:opacity-50 select-none pr-0'
                            >
                              {Icon && <Icon className='mr-3 w-5 h-5 stroke-mediumBlue' />}
                              <FormattedMessage id={title} />
                              <VectorSvg className={cn({ 'opacity-0': pathname !== path })} />
                            </Button>
                            {path === AppRoutes.notifications && (
                              <div className='bg-red w-full text-center absolute top-3.5 -right-16 rotate-45 text-white p-[0.1px] font-normal text-xs'>
                                new
                              </div>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                );
              })
            : null}
        </div>
      </motion.div>
    </nav>
  );
};
