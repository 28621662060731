import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useDocumentOptions } from 'hooks/useDocumentOptions';
import { DocumentInput, Document } from 'interfaces/documents.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  document?: Document | null;
}

export const DocumentTypeFields: FC<Props> = ({ document }) => {
  const { departmentsOptions, documentTypesOptions, defaultDepartmentOption, defaultDocumentTypeOption } =
    useDocumentOptions(document);

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<DocumentInput>();

  return (
    <Card title='document' wrapperClassName='overflow-visible flex-1' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='departmentSelect'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultDepartmentOption}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['departmentSelect']?.message}
            defaultValue={defaultDepartmentOption}
            isRequired
            isMulti
            label='department'
            options={departmentsOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_department' })}
          />
        )}
      />
      <Controller
        control={control}
        name='documentTypeSelect'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultDocumentTypeOption}
        render={({ field: { onChange, value } }) => (
          <Select
            isRequired
            placeholder={formatMessage({ id: 'search_document_type' })}
            label='document_type'
            error={errors['documentTypeSelect']?.message}
            defaultValue={defaultDocumentTypeOption}
            options={documentTypesOptions}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Card>
  );
};
