import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CUSTOMERS_REQUESTS_REPORT_FILTERS, getCustomerRequestsReportsFilters } from 'constants/reports.constants';
import { MetaData } from 'interfaces/corrections.interfaces';
import { CustomerRequestReportTypes } from 'interfaces/reports.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { asyncGetCountries } from 'modules/countries/action';
import { asyncGetCustomerRequestSources } from 'modules/customer-request-sources/action';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { asyncGetCustomerRequestsDynamicReports, asyncGetCustomerRequestsReports } from 'modules/reports/action';
import { useAppDispatch } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { BarReportChart } from 'page-components/reports/BarReportChart';
import { LineReportChart } from 'page-components/reports/LineReportChart';
import { ReportFilters } from 'page-components/reports/ReportFilters';
import { ReportTypes } from 'page-components/reports/ReportTypes';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const CustomerRequestsReports = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const filterKey = searchParams.get('type');
  const { country = '', status = '', source = '', startDate, endDate } = qs.parse(search);

  const [fields, setFields] = useState<Record<string, Field[]>>({});

  const handleFieldsUpdate = (data: MetaData[], queryValue: number) => {
    const formattedOptions = convertObjectsToSelectOption(data);

    setFields(
      getCustomerRequestsReportsFilters(
        formattedOptions,
        formattedOptions.find(({ value }) => value === queryValue),
      ),
    );
  };

  useEffect(() => {
    if (filterKey) {
      const commonData = {
        reportType: filterKey.split('_')[0],
        dates: startDate && endDate ? `${startDate ?? ''} - ${endDate ?? ''}` : '',
      };

      dispatch(
        !filterKey.includes('dynamic')
          ? asyncGetCustomerRequestsReports({
              ...commonData,
              country: `${country}`,
              status: `${status}`,
              source: `${source}`,
            })
          : asyncGetCustomerRequestsDynamicReports(commonData),
      );
    }
  }, [country, dispatch, endDate, filterKey, startDate, status, source]);

  useEffect(() => {
    if (filterKey?.includes('dynamic')) return;

    switch (filterKey) {
      case CustomerRequestReportTypes.countries:
        dispatch(asyncGetCountries({}))
          .unwrap()
          .then(({ data }) => {
            handleFieldsUpdate(data, +(country ?? 0));
          });
        break;
      case CustomerRequestReportTypes.statuses:
        dispatch(asyncGetCustomerStatuses({}))
          .unwrap()
          .then(({ data }) => {
            handleFieldsUpdate(data, +(status ?? 0));
          });
        break;
      case CustomerRequestReportTypes.sources:
        dispatch(asyncGetCustomerRequestSources({}))
          .unwrap()
          .then(({ data }) => {
            handleFieldsUpdate(data, +(source ?? 0));
          });
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterKey]);

  return (
    <PageLayout title='customer_requests_reports' wrapperClassName='flex flex-col gap-4'>
      <ReportTypes filters={CUSTOMERS_REQUESTS_REPORT_FILTERS} />
      <ReportFilters fields={filterKey && fields[filterKey] ? fields[filterKey] : []} />
      {filterKey?.includes('dynamic') ? <LineReportChart isDates /> : <BarReportChart />}
    </PageLayout>
  );
};
