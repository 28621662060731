import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { RESET_PASSWORD_FIELDS } from 'constants/auth.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { ResetPasswordInput } from 'interfaces/reset-password.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncSendResetPassword } from 'modules/user/action';
import { selectIsLoading } from 'modules/user/selectors';
import { Button } from 'shared-components/Button';
import { FormFields } from 'shared-components/FormFields';

export const ResetPasswordForm = () => {
  const { token } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(selectIsLoading);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ResetPasswordInput>();

  const onSubmit: SubmitHandler<ResetPasswordInput> = (data) => {
    if (token) {
      dispatch(asyncSendResetPassword({ token, ...data })).then(() => navigate(AppRoutes.signIn));
    }
  };

  return (
    <form className='p-5 space-y-4' onSubmit={handleSubmit(onSubmit)} data-test='reset-form'>
      <FormFields fields={RESET_PASSWORD_FIELDS} register={register} errors={errors} />
      <Button type='submit' className='w-full' isLoading={isLoading} data-test='reset-password-apply'>
        <FormattedMessage id='apply' />
      </Button>
    </form>
  );
};
