import { TableColumn } from './shared/table.constants';

export const NOTIFICATIONS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'name', title: 'name' },
  { id: 'description', title: 'description' },
  { id: 'creation_date', title: 'creation_date', className: 'last:text-left' },
];

export const NOTIFICATION_COLUMNS: TableColumn[] = [
  { id: 'id', title: 'id' },
  { id: 'receiver', title: 'receiver' },
  { id: 'confirmed_at', title: 'confirmed_at', className: 'last:text-left' },
];

export const NOTIFICATIONS_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  departmentSelect: {
    label: 'department',
    placeholder: 'search_departments',
    name: 'departmentSelect',
  },
};
