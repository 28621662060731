import { AnyAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { CorrectionInput } from 'interfaces/corrections.interfaces';
import { useAppDispatch } from 'modules/store';
import { asyncCreateTask, asyncUpdateTask, unsetTask } from 'modules/tasks/action';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { formatToDefaultDate } from 'utils/date.utils';
import { getEditRoute } from 'utils/routes.utils';

import { CorrectionAnalysisFields } from './CorrectionAnalysisFields';
import { CorrectionDatesFields } from './CorrectionDatesFields';
import { CorrectionGeneralFields } from './CorrectionGeneralFields';
import { CorrectionPerformersFields } from './CorrectionPerformersFields';

export const CorrectionForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();

  const { inconsistency } = qs.parse(search);

  const isView = pathname.includes('/view/');

  const dispatch = useAppDispatch();

  const methods = useForm<CorrectionInput>();
  const { handleSubmit } = methods;

  const onSubmit: SubmitHandler<CorrectionInput> = ({
    responsible: responsibleOption,
    performer: performerOption,
    actualDate,
    description,
    ...data
  }) => {
    const responsible = responsibleOption.value as number;
    const performer = performerOption.value as number;

    const formattedData = {
      inconsistency: inconsistency ? +inconsistency : undefined,
      responsible,
      performer,
      deadline: formatToDefaultDate(actualDate as unknown as Date),
      content: description,
      priority: 'low',
      ...data,
    };

    dispatch(id ? (asyncUpdateTask({ id, ...formattedData }) as unknown as AnyAction) : asyncCreateTask(formattedData))
      .unwrap()
      .then(() => {
        navigate(-1);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetTask()));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col gap-6'>
          <div className='flex-1 flex flex-col md:flex-row gap-6'>
            <CorrectionGeneralFields />
            <CorrectionAnalysisFields />
          </div>
          <div className='flex-1 flex flex-col md:flex-row gap-6'>
            <CorrectionPerformersFields />
            <CorrectionDatesFields defaultStart={new Date()} defaultRealStart={new Date()} isEditable={!isView} />
          </div>
        </div>
        <ItemNavigation navigateBack={getEditRoute(AppRoutes.corrections, inconsistency ? +inconsistency : -1)} />
      </FormProvider>
    </form>
  );
};
