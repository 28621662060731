import { ActionReducerMapBuilder, AsyncThunk } from '@reduxjs/toolkit';

export const withLoadingReducer =
  <Returned, ThunkArg>(asyncAction: AsyncThunk<Returned, ThunkArg, NonNullable<unknown>>) =>
  <T extends { isLoading: boolean }>(builder: ActionReducerMapBuilder<T>) => {
    builder
      .addCase(asyncAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(asyncAction.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(asyncAction.rejected, (state) => {
        state.isLoading = false;
      });
  };
