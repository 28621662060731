import { FC, useEffect, useState } from 'react';

import { flattenTree } from 'react-accessible-treeview';
import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetProductGroups } from 'modules/product-groups/action';
import { useAppDispatch } from 'modules/store';
import { Select } from 'shared-components/Select';
import { convertDataForTreeView } from 'utils/product-groups.utils';

interface Props {
  id?: string;
  control: Control<CustomerRequestInput>;
  defaultProducts?: SelectOption[];
  error?: string;
}

export const CustomerRequestProducts: FC<Props> = ({ defaultProducts, control, error, id }) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [productOptions, setProductOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    dispatch(asyncGetProductGroups({}))
      .unwrap()
      .then(({ data }) => {
        const formattedGroups = flattenTree({
          children: convertDataForTreeView(data),
          id: 0,
          name: '',
        })?.filter((_, index) => index !== 0);

        const formattedProducts = formattedGroups.map(({ id, name }) => ({ value: id, label: name }));

        setProductOptions(formattedProducts);
      });
  }, [dispatch]);

  if (!productOptions?.length || (!defaultProducts?.length && id)) return null;

  return (
    <Controller
      control={control}
      name='productsSelect'
      rules={REQUIRED_VALIDATION}
      defaultValue={defaultProducts}
      render={({ field }) => (
        <Select
          {...field}
          isMulti
          isRequired
          label='product_groups'
          defaultValue={defaultProducts}
          options={productOptions}
          className='flex-1 h-full'
          error={error}
          placeholder={formatMessage({ id: 'search_product' })}
        />
      )}
    />
  );
};
