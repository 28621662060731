import { FieldValues } from 'react-hook-form';

import { Field } from 'interfaces/shared/field.interfaces';

import { EMAIL_VALIDATION, PASSWORD_VALIDATION, REQUIRED_VALIDATION } from './validation-rules.constants';

export const PROFILE_INFO_FIELDS: Field[] = [
  {
    name: 'name',
    options: REQUIRED_VALIDATION,
    'data-test': 'profile-name',
    label: 'name',
    placeholder: 'name',
  },
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'profile-email',
    label: 'email',
    placeholder: 'email',
  },
];

export const PROFILE_CHANGE_PASSWORD_FIELDS: Field[] = [
  {
    name: 'currentPassword',
    options: PASSWORD_VALIDATION,
    'data-test': 'change-current-password',
    label: 'current_password',
    placeholder: 'current_password',
    type: 'password',
  },
  {
    name: 'newPassword',
    options: PASSWORD_VALIDATION,
    'data-test': 'change-new-password',
    label: 'new_password',
    placeholder: 'new_password',
    type: 'password',
  },
  {
    name: 'repeatNewPassword',
    options: {
      ...PASSWORD_VALIDATION,
      validate: (value: string, formValues: FieldValues) => {
        if (value !== formValues.newPassword) {
          return 'passwords_not_matches';
        }
        return '';
      },
    },
    'data-test': 'change-repeat-new-password',
    label: 'confirm_new_password',
    placeholder: 'confirm_new_password',
    type: 'password',
  },
];
