import { selectRisk } from 'modules/risks/selectors';
import { useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { TitleWithValue } from 'shared-components/TitleWithValue';

export const RiskProceedInfo = () => {
  const risk = useAppSelector(selectRisk);

  if (!risk) return null;
  return (
    <Card title='general_info'>
      <TitleWithValue textClassName='gap-2' title='title' value={risk.name} />
      <TitleWithValue textClassName='gap-2' title='department' value={risk.department.name} />
      <TitleWithValue textClassName='gap-2' title='responsible' value={risk.responsible.name} />
    </Card>
  );
};
