import { FC, useEffect, useState } from 'react';

import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomerRequestSources } from 'modules/customer-request-sources/action';
import { useAppDispatch } from 'modules/store';
import { Select } from 'shared-components/Select';

interface Props {
  control: Control<CustomerRequestInput>;
  defaultSource?: SelectOption;
  error?: string;
  isView?: boolean;
  id?: string;
}

export const CustomerRequestSource: FC<Props> = ({ defaultSource, control, error, isView, id }) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [requestSourcesOptions, setRequestSourcesOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    dispatch(asyncGetCustomerRequestSources({}))
      .unwrap()
      .then(({ data }) => {
        const formattedProducts = data.map(({ id, name }) => ({ value: id, label: name }));

        setRequestSourcesOptions(formattedProducts);
      });
  }, [dispatch]);

  if (!requestSourcesOptions || (!defaultSource && id)) return null;

  return (
    <Controller
      control={control}
      name='requestSourceSelect'
      rules={REQUIRED_VALIDATION}
      defaultValue={defaultSource}
      render={({ field }) => (
        <Select
          {...field}
          isDisabled={isView}
          isRequired
          label='customer_request_source'
          defaultValue={defaultSource}
          options={requestSourcesOptions}
          className='flex-1 h-full'
          error={error}
          placeholder={formatMessage({ id: 'search_customer_request_source' })}
        />
      )}
    />
  );
};
