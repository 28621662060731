import { FC, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { ComplaintInput } from 'interfaces/complaints.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomers } from 'modules/customers/action';
import { useAppDispatch } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { Textarea } from 'shared-components/Textarea';
import { convertObjectsToSelectOption } from 'utils/select.utils';

interface Props {
  defaultCustomer?: number;
}

export const ComplaintGeneralFields: FC<Props> = ({ defaultCustomer }) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [customersOptions, setCustomerOptions] = useState<SelectOption[]>();
  const [defaultCustomerOption, setDefaultCustomerOption] = useState<SelectOption>();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<ComplaintInput>();

  useEffect(() => {
    dispatch(asyncGetCustomers({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setCustomerOptions(options);
        setDefaultCustomerOption(options.find(({ value }) => value === defaultCustomer));
      });
  }, [dispatch, defaultCustomer]);

  if (id && !defaultCustomerOption) return null;

  return (
    <Card title='general_info' wrapperClassName='flex-1' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='customer'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultCustomerOption}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder={formatMessage({ id: 'search_customer' })}
            isRequired
            label='customer'
            error={errors['customer']?.message}
            defaultValue={defaultCustomerOption}
            options={customersOptions ?? []}
            value={value}
            onChange={onChange}
          />
        )}
      />
      <Textarea
        {...register('description', REQUIRED_VALIDATION)}
        isRequired
        label='notes'
        error={errors['description']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='notes-description'
        rows={4}
      />
    </Card>
  );
};
