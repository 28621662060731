import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { CUSTOMERS_COLUMNS } from 'constants/customers.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteCustomer, asyncGetCustomers } from 'modules/customers/action';
import { selectCustomers, selectCustomersCount, selectIsLoading } from 'modules/customers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CustomersFilters } from 'page-components/customers/CustomersFilters';
import { CustomersTableRow } from 'page-components/customers/CustomersTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Customers = () => {
  const [customersId, setCustomersId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const customers = useAppSelector(selectCustomers);
  const customersCount = useAppSelector(selectCustomersCount);

  const { search } = useLocation();
  const { status, country, city, startDate, endDate, search: searchQuery = '' } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setCustomersId(id);
    handleOpenModal();
  };

  const onCustomersDelete = async () => {
    if (customersId) {
      await dispatch(asyncDeleteCustomer(+customersId));
      await dispatch(asyncGetCustomers({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const dates = startDate && endDate ? `${startDate ?? ''}-${endDate ?? ''}` : '';
    const customersQueryParams = {
      search: searchQuery,
      country,
      city,
      dates,
      status,
    } as Record<string, string>;

    dispatch(
      asyncGetCustomers({
        page,
        perPage: 10,
        ...customersQueryParams,
      }),
    );
  }, [dispatch, page, status, country, city, startDate, endDate, searchQuery]);

  useQueryParams({ page });

  return (
    <PageLayout title='customers' wrapperClassName='flex flex-col gap-4'>
      <CustomersFilters />
      <CommonLayoutTable
        title='customers_management'
        columns={CUSTOMERS_COLUMNS}
        creationPath={AppRoutes.customerCreate}
        isLoading={isLoading}
      >
        {customers?.length ? (
          customers.map((customer) => (
            <CustomersTableRow key={customer.id} data={customer} handleDelete={onModalOpen(customer.id)} />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMERS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={customersCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCustomersDelete}
        title='delete_customer'
      />
    </PageLayout>
  );
};
