import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { DocumentInput } from 'interfaces/documents.interfaces';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { Textarea } from 'shared-components/Textarea';

export const DocumentGeneralFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<DocumentInput>();

  return (
    <Card title='general_info' wrapperClassName='flex-1' className='flex flex-col gap-8'>
      <Input
        {...register('name', REQUIRED_VALIDATION)}
        isRequired
        error={errors['name']?.message as string}
        label='name'
        placeholder={formatMessage({ id: 'name' })}
        data-test='document-name'
      />
      <Textarea
        {...register('description', REQUIRED_VALIDATION)}
        isRequired
        label='description'
        error={errors['description']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='document-description'
        rows={4}
      />
    </Card>
  );
};
