import { FC } from 'react';

import { Self } from 'interfaces/self.interfaces';
import { UserCard } from 'page-components/users/users-list/UserCard';

interface Props {
  handleOpenDeleteModal: (id: string) => VoidFunction;
  users: Self[];
}

export const UsersList: FC<Props> = ({ handleOpenDeleteModal, users }) => {
  return (
    <div data-test='users-list' className='grid gap-6 grid-cols-1 md:grid-cols-2 2xl:grid-cols-4 -mx-4 p-4'>
      {users.map((user) => (
        <UserCard user={user} handleOpenDeleteModal={handleOpenDeleteModal(`${user.id}`)} key={user.id} />
      ))}
    </div>
  );
};
