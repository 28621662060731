import { FC, LabelHTMLAttributes, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props extends LabelHTMLAttributes<HTMLLabelElement> {
  children?: ReactNode;
  htmlFor?: string | undefined;
  className?: string;
}

export const FormLabel: FC<Props> = ({ children, htmlFor, className }) => {
  if (!children) return null;

  return (
    <label className={twMerge('text-mediumBlue', className)} htmlFor={htmlFor}>
      {children}
    </label>
  );
};
