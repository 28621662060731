import { FC } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { TableColumn } from 'constants/shared/table.constants';

import { TitleWithTooltip } from '../TitleWithTooltip';

interface Props {
  columns: TableColumn[];
}

export const TableHead: FC<Props> = ({ columns }) => {
  return (
    <thead>
      <tr>
        {columns.map(({ id, title, className, icon, tooltipChildren: TooltipItem }) => (
          <th
            key={id}
            className={twMerge('text-mediumBlue font-normal text-left p-2 select-none last:text-right', className)}
          >
            <span className={cn({ 'flex items-center gap-1': TooltipItem })}>
              {typeof title === 'string' && title ? <FormattedMessage id={title} defaultMessage={title} /> : title}
              {TooltipItem && (
                <TitleWithTooltip className='justify-center' id={id} icon={icon} tooltipChildren={<TooltipItem />} />
              )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
  );
};
