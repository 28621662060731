import { FC } from 'react';

import { useFormContext } from 'react-hook-form';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Permission } from 'interfaces/permissions.interfaces';
import { RoleInput } from 'interfaces/roles.interfaces';
import { Card } from 'shared-components/Card';
import { Checkbox } from 'shared-components/Checkbox';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { getFormattedName } from 'utils/formatters.utils';
import { getSubGroups } from 'utils/role.utils';

import { RolePermissionLayout } from './RolePermissionLayout';
import { RoleSubGroupLayout } from './RoleSubGroupLayout';

interface Props {
  permissions: Record<string, Permission[]>;
  isFullAccess?: boolean;
}

export const RoleAllPermissions: FC<Props> = ({ permissions, isFullAccess }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext<RoleInput>();

  return (
    <Card title='available_permissions' className='flex flex-col gap-10'>
      <div className='flex flex-col gap-4'>
        {Object.keys(permissions).map((permissionGroup) => {
          const subGroups = getSubGroups(permissions, permissionGroup);

          if (isFullAccess && permissionGroup !== 'full-access') return null;
          return (
            <RolePermissionLayout key={permissionGroup} title={permissionGroup}>
              {subGroups.map((subGroup) => (
                <RoleSubGroupLayout key={subGroup} subGroup={subGroup}>
                  {permissions[permissionGroup].map(
                    ({ id: permissionsId, name }) =>
                      subGroup === name.split('.')[0] && (
                        <Checkbox
                          labelClassName='flex-1'
                          disabled={isFullAccess && permissionGroup !== 'full-access'}
                          data-test={`permission-${permissionsId}`}
                          key={permissionsId}
                          {...register(name)}
                          error={errors[permissionGroup]?.message}
                          value={permissionsId}
                          label={getFormattedName(name)}
                        />
                      ),
                  )}
                </RoleSubGroupLayout>
              ))}
            </RolePermissionLayout>
          );
        })}
      </div>
      <ItemNavigation navigateBack={AppRoutes.roles} />
    </Card>
  );
};
