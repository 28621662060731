import { parse, format } from 'date-fns';

import { DateFormates } from 'constants/date.constants';

export const parseDateWithHours = (date: string) => parse(date, DateFormates.dateWithHours, new Date());

export const parseInputDate = (date: string) => parse(date, DateFormates.inputDate, new Date());

export const parseDefaultDate = (date: string) => parse(date, DateFormates.defaultDate, new Date());

export const formatToDefaultDate = (date: Date) => format(date, DateFormates.defaultDate);

export const formatToInputDate = (date: Date) => format(date, DateFormates.inputDate);
