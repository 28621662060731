import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { CorrectionInfo } from 'interfaces/corrections.interfaces';
import { asyncGetInconsistency, unsetInconsistency } from 'modules/inconsistencies/action';
import { selectInconsistency } from 'modules/inconsistencies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CardInfoItem } from 'shared-components/CardInfoItem';

export const CorrectionInconsistencyInfo = () => {
  const { search } = useLocation();

  const { inconsistency: inconsistencyId } = qs.parse(search);

  const [inconsistencyContent, setInconsistencyContent] = useState<CorrectionInfo>({});

  const dispatch = useAppDispatch();

  const inconsistency = useAppSelector(selectInconsistency);

  useEffect(() => {
    if (inconsistencyId) {
      dispatch(asyncGetInconsistency(inconsistencyId as string))
        .unwrap()
        .then((response) => {
          setInconsistencyContent({
            description: response.description,
            department: response.department.name,
            notes: response.notes,
            reasons: response.reasons,
            consequences: response.consequences,
          });
        });
    }
  }, [inconsistencyId, dispatch]);

  useBeforeRouteChange(() => dispatch(unsetInconsistency()));

  if (!inconsistency) return null;

  return (
    <div className='flex flex-col md:flex-row gap-6'>
      <CardInfoItem title='inconsistency' content={inconsistencyContent} />
    </div>
  );
};
