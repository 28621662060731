import { FC } from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useTaskDefaultOptions } from 'hooks/useTaskDefaultOptions';
import { TaskInput, TaskInputPayload, UpdateTask } from 'interfaces/tasks.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncCreateTask, asyncUpdateTask, unsetTask } from 'modules/tasks/action';
import { selectTask } from 'modules/tasks/selectors';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Textarea } from 'shared-components/Textarea';
import { formatToDefaultDate } from 'utils/date.utils';

import { TaskDates } from './TaskDates';
import { TaskEndDate } from './TaskEndDate';
import { TaskInfo } from './TaskInfo';
import { TaskPerformer } from './TaskPerformer';
import { TaskPriority } from './TaskPriority';
import { TaskResponsibleInfo } from './TaskResponsibleInfo';

import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  isAuthor: boolean;
}

export const TaskForm: FC<Props> = ({ isAuthor }) => {
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const task = useAppSelector(selectTask);

  const { formatMessage } = useIntl();

  const isEditable = !id || isAuthor;
  const isViewMode = !!searchParams.get('mode');

  const methods = useForm<TaskInput>();

  const {
    handleSubmit,
    reset,
    register,
    formState: { errors },
  } = methods;

  const defaultOptions = useTaskDefaultOptions(reset, id);

  const onSubmit: SubmitHandler<TaskInput> = async ({ prioritySelect, deadline, responsible, ...data }) => {
    const priority = `${prioritySelect.value}`;

    const formattedDeadline = formatToDefaultDate(deadline as unknown as Date);

    const formattedData = id
      ? {
          id: id ?? '',
          deadline: formattedDeadline,
          priority,
          ...data,
        }
      : {
          responsible: responsible.value as number,
          priority,
          deadline: formattedDeadline,
          ...data,
        };

    dispatch(
      id
        ? (asyncUpdateTask(formattedData as UpdateTask) as unknown as AnyAction)
        : asyncCreateTask(formattedData as TaskInputPayload),
    )
      .unwrap()
      .then(() => {
        dispatch(unsetTask());
        navigate(AppRoutes.tasks);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetTask()));

  return (
    <FormProvider {...methods}>
      <form className='flex gap-6 flex-col md:flex-row' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-col gap-6 flex-1'>
          <Card title='general_info' className='flex flex-col gap-8'>
            {isEditable && !isViewMode ? (
              <>
                <TaskPriority defaultOption={defaultOptions.priority} />
                <Textarea
                  {...register('content', REQUIRED_VALIDATION)}
                  isRequired
                  error={errors['content']?.message}
                  label='description'
                  placeholder={formatMessage({ id: 'description' })}
                  data-test='task-description'
                  rows={4}
                />
              </>
            ) : (
              <TaskInfo task={task} defaultPriorityOption={defaultOptions.priority} />
            )}
          </Card>
          {!isViewMode && isEditable && (
            <Card>
              <ItemNavigation navigateBack={AppRoutes.tasks} />
            </Card>
          )}
        </div>
        <div className='flex flex-col gap-6 flex-1'>
          <Card wrapperClassName='overflow-visible' title={id ? 'users_connected_to_task' : 'responsible_and_executor'}>
            {id ? (
              <TaskResponsibleInfo task={task} />
            ) : (
              <div className='flex flex-col gap-4'>
                <TaskPerformer performer={task?.performer} />
              </div>
            )}
          </Card>
          <Card title='deadlines'>
            {id && <TaskDates task={task} />}
            <TaskEndDate defaultDate={defaultOptions.deadline} isEditable={!isViewMode && isEditable} />
          </Card>
        </div>
      </form>
    </FormProvider>
  );
};
