import { createSlice } from '@reduxjs/toolkit';

import { LOCALE, Locales } from 'i18n/locales';
import { Self } from 'interfaces/self.interfaces';
import {
  changeLocaleReducer,
  getSelfReducer,
  logOutReducer,
  sendResetPasswordEmailReducer,
  sendResetPasswordReducer,
  setUserReducer,
  signInReducer,
  unsetIsAuthorizedReducer,
  unsetUserReducer,
} from 'modules/user/reducers';

import { USER_SLICE_NAME } from './action';

export interface UserSliceState {
  currentUser: Self | null;
  isAuth: boolean | null;
  isLoading: boolean;
  locale?: Locales;
}

const STORED_LOCALE = localStorage.getItem(LOCALE) || Locales.UKRAINIAN;

export const initialState: UserSliceState = {
  currentUser: null,
  isAuth: null,
  isLoading: false,
  locale: STORED_LOCALE as Locales,
};

const userSlice = createSlice({
  name: USER_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    setUserReducer(builder);
    signInReducer(builder);
    getSelfReducer(builder);
    logOutReducer(builder);
    unsetIsAuthorizedReducer(builder);
    unsetUserReducer(builder);
    sendResetPasswordEmailReducer(builder);
    sendResetPasswordReducer(builder);
    changeLocaleReducer(builder);
  },
});

export const userReducer = userSlice.reducer;
