import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { AUTH_FIELDS } from 'constants/auth.constants';
import { SignInInput } from 'interfaces/auth/sign-in.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncSignIn } from 'modules/user/action';
import { selectIsLoading } from 'modules/user/selectors';
import { AuthLayout } from 'page-components/auth/AuthLayout';
import { Button } from 'shared-components/Button';
import { Checkbox } from 'shared-components/Checkbox';
import { FormFields } from 'shared-components/FormFields';

export const SignIn = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);

  const { formatMessage } = useIntl();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit: SubmitHandler<SignInInput> = (values) => {
    dispatch(asyncSignIn(values));
  };

  return (
    <AuthLayout title='sign_in_header' linkText='restoration_password' additionalLink='/reset-password'>
      <form className='p-5 flex flex-col gap-8' onSubmit={handleSubmit(onSubmit)}>
        <FormFields fields={AUTH_FIELDS} register={register} errors={errors} />
        <div className='flex justify-between'>
          <Checkbox data-test='login-rememberMe' name='rememberMe' label={formatMessage({ id: 'remember_me' })} />
          <Button data-test='login-submit' isLoading={isLoading} type='submit' className='w-28 ml-2'>
            <FormattedMessage id='sign_in' />
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
};
