import { FormattedMessage } from 'react-intl';

import { RiskProbability } from 'interfaces/risks.interfaces';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';

interface Props {
  data: RiskProbability;
}

export const RiskProbabilityTableRow = ({ data: { mark, probability, value } }: Props) => {
  return (
    <TableRow className='text-center'>
      <TableData>{mark}</TableData>
      <TableData>
        <FormattedMessage id={probability} />
      </TableData>
      <TableData className='last:text-left'>{value}</TableData>
    </TableRow>
  );
};
