import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ComplaintInput } from 'interfaces/complaints.interfaces';
import { Card } from 'shared-components/Card';
import { Textarea } from 'shared-components/Textarea';

export const ComplaintAdditionalFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<ComplaintInput>();

  return (
    <Card title='actions' wrapperClassName='flex-1' className='flex flex-col md:flex-row gap-6'>
      <Textarea
        {...register('operations')}
        label='actions'
        error={errors['operations']?.message}
        placeholder={formatMessage({ id: 'actions' })}
        data-test='operations'
        rows={4}
      />
      <Textarea
        {...register('resources')}
        label='resources'
        error={errors['resources']?.message}
        placeholder={formatMessage({ id: 'resources' })}
        data-test='resources'
        rows={4}
      />
    </Card>
  );
};
