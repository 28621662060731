import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { DocumentForm } from 'page-components/documents/DocumentForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Document = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.documentsCreate ? 'create_document' : 'edit_document';
  const titleText = pathname.includes('view') ? 'view_document' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <DocumentForm />
    </PageLayout>
  );
};
