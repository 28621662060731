import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import { permissionsReducer } from 'modules/permissions/slice';
import { rolesReducer } from 'modules/roles/slice';
import { userReducer } from 'modules/user/slice';

import { auditReducer } from './audits/slice';
import { citiesReducer } from './cities/slice';
import { complaintsReducer } from './complaints/slice';
import { componentsReducer } from './complects/slice';
import { countriesReducer } from './countries/slice';
import { customerRequestSourceReducer } from './customer-request-sources/slice';
import { customerRequestStatusesReducer } from './customer-request-statuses/slice';
import { customerRequestReducer } from './customer-requests/slice';
import { customerStatusesReducer } from './customer-statuses/slice';
import { customersReducer } from './customers/slice';
import { departmentsReducer } from './departments/slice';
import { documentTypesReducer } from './document-types/slice';
import { documentsReducer } from './documents/slice';
import { educationsReducer } from './educations/slice';
import { inconsistenciesReducer } from './inconsistencies/slice';
import { inconsistencyTypeReducer } from './inconsistency-types/slice';
import { managerActionsReducer } from './manager-actions/slice';
import { notificationsReducer } from './notifications/slice';
import { productGroupsReducer } from './product-groups/slice';
import { providersReducer } from './providers/slice';
import { reportsReducer } from './reports/slice';
import { risksReducer } from './risks/slice';
import { tasksReducer } from './tasks/slice';
import { usersReducer } from './users/slice';

export const rootReducer = combineReducers({
  userReducer,
  usersReducer,
  rolesReducer,
  permissionsReducer,
  countriesReducer,
  citiesReducer,
  departmentsReducer,
  tasksReducer,
  customerRequestSourceReducer,
  customerRequestStatusesReducer,
  customerStatusesReducer,
  customersReducer,
  productGroupsReducer,
  providersReducer,
  componentsReducer,
  customerRequestReducer,
  inconsistencyTypeReducer,
  inconsistenciesReducer,
  complaintsReducer,
  educationsReducer,
  managerActionsReducer,
  documentsReducer,
  notificationsReducer,
  auditReducer,
  documentTypesReducer,
  risksReducer,
  reportsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
