import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { DocumentType, DocumentTypesResponse } from 'interfaces/document-types.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateDocumentType,
  asyncDeleteDocumentType,
  asyncGetDocumentType,
  asyncGetDocumentTypes,
  asyncUpdateDocumentType,
  unsetDocumentType,
} from './action';
import { DocumentTypesSliceState } from './slice';

export function getDocumentTypesReducer(builder: ActionReducerMapBuilder<DocumentTypesSliceState>) {
  builder.addCase(asyncGetDocumentTypes.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetDocumentTypes.fulfilled, (state, { payload }: PayloadAction<DocumentTypesResponse>) => {
    state.documentTypes = payload.data;
    state.documentTypesCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDocumentTypes.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getDocumentTypeReducer(builder: ActionReducerMapBuilder<DocumentTypesSliceState>) {
  builder.addCase(asyncGetDocumentType.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetDocumentType.fulfilled, (state, { payload }: PayloadAction<DocumentType>) => {
    state.documentType = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDocumentType.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateDocumentTypeReducer = withLoadingReducer(asyncUpdateDocumentType);
export const createDocumentTypeReducer = withLoadingReducer(asyncCreateDocumentType);

export function deleteDocumentTypeReducer(builder: ActionReducerMapBuilder<DocumentTypesSliceState>) {
  builder.addCase(asyncDeleteDocumentType.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteDocumentType.fulfilled, (state) => {
    state.documentType = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteDocumentType.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetDocumentTypeReducer(builder: ActionReducerMapBuilder<DocumentTypesSliceState>) {
  builder.addCase(unsetDocumentType, (state) => {
    state.documentType = null;
  });
}
