import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CUSTOMER_CONTACTS_FIELDS } from 'constants/customers.constants';
import { CustomerInput } from 'interfaces/customers.interfaces';
import { Card } from 'shared-components/Card';
import { FormFields } from 'shared-components/FormFields';
import { Textarea } from 'shared-components/Textarea';

export const CustomerContactFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<CustomerInput>();

  return (
    <Card title='contacts' wrapperClassName='flex-1 self-stretch md:self-start' className='flex flex-col gap-6'>
      <FormFields fields={CUSTOMER_CONTACTS_FIELDS} register={register} errors={errors} />
      <Textarea
        {...register('additionalContacts')}
        error={errors['additionalContacts']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='customer-description'
        rows={4}
      />
    </Card>
  );
};
