export const TASKS_COLUMNS = [
  { id: 'actions', title: '', className: 'w-32' },
  { id: 'priority', title: 'priority' },
  { id: 'id', title: 'id' },
  { id: 'endDate', title: 'end_date' },
  { id: 'creationDate', title: 'creation_date' },
  { id: 'responsible', title: 'responsible' },
  { id: 'allResponsible', title: 'all_responsible' },
  { id: 'description', title: 'description' },
  { id: 'stage', title: 'stage' },
];
