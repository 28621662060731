import { useEffect, useState } from 'react';

import { addDays } from 'date-fns';
import { UseFormReset } from 'react-hook-form';

import { Complaint, ComplaintInput } from 'interfaces/complaints.interfaces';
import { formatToInputDate, parseDefaultDate, parseInputDate } from 'utils/date.utils';

export const useComplaintDates = (resetForm: UseFormReset<ComplaintInput>, complaint?: Complaint | null) => {
  const [defaultOptions, setDefaultOptions] = useState<{
    completed: Date;
  }>({
    completed: addDays(new Date(), 1),
  });

  useEffect(() => {
    if (complaint) {
      const parsedCompleted = parseDefaultDate(complaint.finishedAt);

      setDefaultOptions({
        completed: parseInputDate(formatToInputDate(parsedCompleted)),
      });

      resetForm({
        finishedAt: formatToInputDate(parsedCompleted),
      });
    }
  }, [complaint, resetForm]);

  return defaultOptions;
};
