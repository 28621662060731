import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { CitiesResponse, CityData, CityPayload, CityResponse } from 'interfaces/cities.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CitiesApi {
  constructor(private url: string) {}

  getCities(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CitiesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCityById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CityResponse>(`${this.url}/${payload}`, config);
  }

  updateCity({ id, ...data }: CityPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<CityResponse>(`${this.url}/${id}`, data, config);
  }

  createCity(data: CityData, config?: AxiosRequestConfig) {
    return httpApiClient.post<CityResponse>(`${this.url}`, data, config);
  }

  deleteCity(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const citiesApi = new CitiesApi('/cities');
