import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { CustomerRequestSourceInput } from 'interfaces/customer-request-sources.interfaces';
import {
  asyncCreateCustomerRequestSource,
  asyncGetCustomerRequestSource,
  asyncUpdateCustomerRequestSource,
  unsetCustomerRequestSource,
} from 'modules/customer-request-sources/action';
import { selectCustomerRequestSource } from 'modules/customer-request-sources/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const CustomerRequestSourceForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const customerRequestSource = useAppSelector(selectCustomerRequestSource);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CustomerRequestSourceInput>();

  const onSubmit: SubmitHandler<CustomerRequestSourceInput> = (data) => {
    dispatch(id ? asyncUpdateCustomerRequestSource({ id: +id, ...data }) : asyncCreateCustomerRequestSource(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.customerRequestSources);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetCustomerRequestSource()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetCustomerRequestSource(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: customerRequestSource?.name ?? '' });
    }
  }, [id, reset, customerRequestSource]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='customer-request-source-name'
          />
          <ItemNavigation navigateBack={AppRoutes.customerRequestSources} />
        </div>
      </Card>
    </form>
  );
};
