import { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useAuditDates } from 'hooks/useAuditDates';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { AuditInput } from 'interfaces/audits.interfaces';
import { asyncCreateAudit, asyncGetAudit, asyncUpdateAudit, unsetAudit } from 'modules/audits/action';
import { selectAudit } from 'modules/audits/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Textarea } from 'shared-components/Textarea';
import { formatToDefaultDate, formatToInputDate, parseDefaultDate } from 'utils/date.utils';
import { convertObjectsToSelectOption } from 'utils/select.utils';

import { AuditDatesFields } from './AuditDatesFields';
import { AuditVisitorFields } from './AuditVisitorFields';

export const AuditForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const { formatMessage } = useIntl();

  const isViewMode = pathname.includes('view');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const audit = useAppSelector(selectAudit);

  const methods = useForm<AuditInput>();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = methods;

  const defaultOptions = useAuditDates(reset, audit);

  const onSubmit: SubmitHandler<AuditInput> = ({
    auditors: listenerOptions,
    responsible: responsibleOption,
    departments: departmentsSelect,
    planned: plannedDate,
    ...data
  }) => {
    const auditors = listenerOptions.map(({ value }) => value) as number[];
    const departments = departmentsSelect.map(({ value }) => value) as number[];
    const responsible = responsibleOption.value as number;
    const planned = formatToDefaultDate(plannedDate as unknown as Date);

    const formattedData = { auditors, responsible, departments, planned, ...data };

    dispatch(id ? asyncUpdateAudit({ id: +id, ...formattedData }) : asyncCreateAudit(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.audits);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetAudit()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetAudit(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && audit) {
      reset({
        criteria: audit.criteria,
        responsible: { value: audit.responsible.id, label: audit.responsible.name },
        auditors: convertObjectsToSelectOption(audit.auditors),
        departments: convertObjectsToSelectOption(audit.departments),
        planned: formatToInputDate(parseDefaultDate(audit.plannedAt)),
      });
    }
  }, [id, reset, audit]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
        <FormProvider {...methods}>
          <div className='flex flex-col md:flex-row gap-6'>
            <div className='flex-1 flex flex-col gap-6 md:self-start'>
              <AuditDatesFields defaultStart={defaultOptions.start} isEditable={!isViewMode} />
              <AuditVisitorFields audit={audit} />
              <Textarea
                {...register('criteria', REQUIRED_VALIDATION)}
                isRequired
                rows={4}
                className='mt-2'
                error={errors['criteria']?.message as string}
                label='criteria'
                placeholder={formatMessage({ id: 'criteria' })}
                data-test='criteria'
              />
            </div>
          </div>
          {!isViewMode && <ItemNavigation navigateBack={AppRoutes.audits} />}
        </FormProvider>
      </form>
    </Card>
  );
};
