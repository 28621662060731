import { Card } from 'shared-components/Card';

import { ProfileChangePasswordForm } from './ProfileChangePasswordForm';
import { ProfileInfoForm } from './ProfileInfoForm';

export const ProfileSettings = () => {
  return (
    <div className='basis-full md:basis-2/3 flex flex-col gap-6'>
      <Card title='general_info'>
        <ProfileInfoForm />
      </Card>
      <Card title='reset_password'>
        <ProfileChangePasswordForm />
      </Card>
    </div>
  );
};
