import { ReactComponent as AuditSvg } from 'assets/svg/audit.svg';
import { ReactComponent as ProductGroupsSvg } from 'assets/svg/box.svg';
import { ReactComponent as CitySvg } from 'assets/svg/city.svg';
import { ReactComponent as ComplaintSvg } from 'assets/svg/complaint.svg';
import { ReactComponent as ComponentsSvg } from 'assets/svg/components.svg';
import { ReactComponent as CountrySvg } from 'assets/svg/country.svg';
import { ReactComponent as CustomerRequestSourcesSvg } from 'assets/svg/customer-request-source.svg';
import { ReactComponent as CustomerStatusSvg } from 'assets/svg/customer-status.svg';
import { ReactComponent as CustomerSvg } from 'assets/svg/customer.svg';
import { ReactComponent as DepartmentsSvg } from 'assets/svg/department.svg';
import { ReactComponent as DocumentSvg } from 'assets/svg/document.svg';
import { ReactComponent as EducationSvg } from 'assets/svg/education.svg';
import { ReactComponent as InconsistenciesSvg } from 'assets/svg/inconsistencies.svg';
import { ReactComponent as NotificationSvg } from 'assets/svg/notification.svg';
import { ReactComponent as ProviderSvg } from 'assets/svg/provider.svg';
import { ReactComponent as ReportsSvg } from 'assets/svg/report.svg';
import { ReactComponent as CustomerRequestStatusSvg } from 'assets/svg/request.svg';
import { ReactComponent as RequestSvg } from 'assets/svg/requests.svg';
import { ReactComponent as RiskSvg } from 'assets/svg/risk.svg';
import { ReactComponent as RolesSvg } from 'assets/svg/roles.svg';
import { ReactComponent as SwitcherSvg } from 'assets/svg/switcher.svg';
import { ReactComponent as TasksSvg } from 'assets/svg/tasks.svg';
import { ReactComponent as TileSvg } from 'assets/svg/tile.svg';
import { ReactComponent as UsersSvg } from 'assets/svg/users.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { RoutesItem } from 'page-components/page-layout/PagesCommonLayout';

export const NAVIGATION_LIST: RoutesItem[] = [
  {
    id: 'clients',
    icon: TileSvg,
    navigation: {
      ['tasks']: [{ icon: TasksSvg, title: 'tasks', path: AppRoutes.tasks }],
      ['customers_navbar']: [
        {
          title: 'customers',
          icon: CustomerSvg,
          path: AppRoutes.customers,
          permissions: ['customers.view'],
        },
        {
          title: 'customer_requests',
          icon: RequestSvg,
          path: AppRoutes.customerRequests,
          permissions: ['customerRequests.view'],
        },
      ],
      ['providers']: [
        { icon: ProviderSvg, title: 'providers', path: AppRoutes.providers, permissions: ['providers.view'] },
      ],
      ['audit']: [{ icon: AuditSvg, title: 'audits', path: AppRoutes.audits, permissions: ['audit.view'] }],
      ['risks']: [{ icon: RiskSvg, title: 'risks', path: AppRoutes.risks, permissions: ['risks.view'] }],
      ['notification_center']: [
        {
          icon: NotificationSvg,
          title: 'notifications',
          path: AppRoutes.notifications,
          permissions: ['notifications.view'],
        },
      ],
      ['inconsistencies_navbar']: [
        {
          title: 'inconsistencies',
          icon: InconsistenciesSvg,
          path: AppRoutes.inconsistencies,
          permissions: ['inconsistencies.view'],
        },
        { icon: ComplaintSvg, title: 'complaints', path: AppRoutes.complaints, permissions: ['complaints.view'] },
      ],
      ['library']: [
        { icon: DocumentSvg, title: 'documents', path: AppRoutes.documents, permissions: ['documents.view'] },
      ],
      ['personnel_management']: [
        { icon: UsersSvg, title: 'users', path: AppRoutes.users, permissions: ['users.view'] },
        { icon: EducationSvg, title: 'education', path: AppRoutes.educations, permissions: ['teaching.view'] },
      ],
      ['roles']: [{ icon: RolesSvg, title: 'roles', path: AppRoutes.roles, permissions: ['roles.view'] }],
      ['complects']: [
        {
          title: 'complects',
          icon: ComponentsSvg,
          path: AppRoutes.complects,
          permissions: ['complects.view'],
        },
      ],
    },
  },
  {
    id: 'risks',
    icon: SwitcherSvg,
    navigation: {
      ['customers_navbar']: [
        {
          title: 'customer_statuses',
          icon: CustomerStatusSvg,
          path: AppRoutes.customerStatuses,
          permissions: ['customerStatuses.view'],
        },
        {
          title: 'customer_request_statuses',
          icon: CustomerRequestStatusSvg,
          path: AppRoutes.customerRequestStatuses,
          permissions: ['customerRequestStatuses.view'],
        },
        {
          title: 'customer_request_sources',
          icon: CustomerRequestSourcesSvg,
          path: AppRoutes.customerRequestSources,
          permissions: ['customerRequestSources.view'],
        },
        {
          icon: ProductGroupsSvg,
          title: 'product_groups',
          path: AppRoutes.productGroups,
          permissions: ['productGroups.view'],
        },
      ],
      ['inconsistencies_navbar']: [
        {
          title: 'inconsistency_types',
          icon: InconsistenciesSvg,
          path: AppRoutes.inconsistencyTypes,
          permissions: ['inconsistencies.view'],
        },
      ],
      ['geography']: [
        { title: 'countries', icon: CountrySvg, path: AppRoutes.countries, permissions: ['countries.view'] },
        { title: 'cities', icon: CitySvg, path: AppRoutes.cities, permissions: ['cities.view'] },
      ],
      ['departments']: [
        { title: 'departments', icon: DepartmentsSvg, path: AppRoutes.departments, permissions: ['departments.view'] },
      ],
      ['library']: [
        {
          icon: DocumentSvg,
          title: 'document_types',
          path: AppRoutes.documentTypes,
          permissions: ['documentTypes.view'],
        },
      ],
    },
  },
  {
    id: 'reports',
    icon: ReportsSvg,
    navigation: {
      ['customers_reports']: [
        {
          title: 'customers',
          icon: CustomerStatusSvg,
          path: AppRoutes.reportsCustomers,
        },
        {
          title: 'customer_requests',
          icon: CustomerRequestStatusSvg,
          path: AppRoutes.reportsCustomerRequests,
        },
      ],
      ['inconsistencies_reports']: [
        {
          title: 'inconsistencies',
          icon: InconsistenciesSvg,
          path: AppRoutes.reportsInconsistencies,
        },
      ],
    },
  },
];
