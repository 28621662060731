import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { reportsApi } from 'apis/reports.apis';
import { ReportPayload } from 'interfaces/reports.interfaces';
import { showServerError } from 'utils/modules.utils';

export const REPORTS_SLICE_NAME = 'reports';

export const asyncGetCustomerReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getCustomerReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getCustomerReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomersDynamicReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getCustomersDynamicReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getCustomersDynamicReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerRequestsReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getCustomerRequestsReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getCustomerRequestsReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerRequestsDynamicReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getCustomerRequestsDynamicReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getCustomerRequestsDynamicReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetInconsistenciesReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getInconsistenciesReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getInconsistenciesReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetInconsistenciesDynamicReports = createAsyncThunk(
  `${REPORTS_SLICE_NAME}/getInconsistenciesDynamicReports`,
  async ({ reportType, ...payload }: { reportType: string } & ReportPayload, { rejectWithValue }) => {
    try {
      const response = await reportsApi.getInconsistenciesDynamicReports(reportType, payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetReport = createAction('UNSET_REPORT');
