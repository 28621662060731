import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Country } from 'interfaces/countries.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Country;
}

export const CountriesTableRow: FC<Props> = ({ handleDelete, data: { id, name } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.countries, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          editPermission='countries.update'
          viewRoute={viewRoute}
          deletePermission='countries.delete'
          viewPermission='countries.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{name}</TableData>
    </TableRow>
  );
};
