import { useLocation, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useAppSelector } from 'modules/store';
import { selectTask } from 'modules/tasks/selectors';
import { selectCurrentUser } from 'modules/user/selectors';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { TaskComments } from 'page-components/task/TaskComments';
import { TaskDelegate } from 'page-components/task/TaskDelegate';
import { TaskForm } from 'page-components/task/TaskForm';

export const Task = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const user = useAppSelector(selectCurrentUser);
  const task = useAppSelector(selectTask);
  const isAuthor = task?.owner.id === user?.id;

  const editTitle = pathname === AppRoutes.taskCreate ? 'create_task' : 'edit_task';
  const titleText = pathname.includes('view') ? 'view_task' : editTitle;

  return (
    <PageLayout title={titleText}>
      <div className='flex flex-col gap-6'>
        <TaskForm isAuthor={isAuthor} />
        {id && (
          <div className='flex gap-6 flex-col md:flex-row'>
            <TaskComments />
            <TaskDelegate />
          </div>
        )}
      </div>
    </PageLayout>
  );
};
