import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useAuditOptions } from 'hooks/useAuditOptions';
import { Audit, AuditInput } from 'interfaces/audits.interfaces';
import { Select } from 'shared-components/Select';

interface Props {
  audit?: Audit | null;
}

export const AuditVisitorFields: FC<Props> = ({ audit }) => {
  const {
    listenersOptions,
    defaultListenersOptions,
    defaultResponsibleOption,
    defaultDepartmentOption,
    departmentsOptions,
  } = useAuditOptions(audit);

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<AuditInput>();

  return (
    <>
      <Controller
        control={control}
        name='departments'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultDepartmentOption}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['departments']?.message}
            defaultValue={defaultDepartmentOption}
            isMulti
            isRequired
            label='departments'
            options={departmentsOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_departments' })}
          />
        )}
      />
      <Controller
        control={control}
        name='auditors'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultListenersOptions}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['auditors']?.message}
            defaultValue={defaultListenersOptions}
            isMulti
            isRequired
            label='listeners'
            options={listenersOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_auditors' })}
          />
        )}
      />
      <Controller
        control={control}
        name='responsible'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultResponsibleOption}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder={formatMessage({ id: 'search' })}
            isRequired
            label='responsible'
            error={errors['responsible']?.message}
            defaultValue={defaultResponsibleOption}
            options={listenersOptions ?? []}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </>
  );
};
