import { useEffect, useState } from 'react';

import { Listener } from 'interfaces/educations.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useEducationOptions = (listeners?: Listener[], responsibleId?: number) => {
  const dispatch = useAppDispatch();

  const [listenersOptions, setListenersOptions] = useState<SelectOption[]>();
  const [defaultListenersOptions, setDefaultListenersOptions] = useState<SelectOption[]>();
  const [defaultResponsibleOption, setDefaultResponsibleOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(getUsers())
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setListenersOptions(options);
        setDefaultListenersOptions(options.filter(({ value }) => listeners?.some(({ id }) => value === id)));
        setDefaultResponsibleOption(options.find(({ value }) => value === responsibleId));
      });
  }, [dispatch, listeners, responsibleId]);

  return { listenersOptions, defaultListenersOptions, defaultResponsibleOption };
};
