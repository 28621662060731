import { useEffect } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { AuditStatuses } from 'constants/audits.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { AuditCarryingInput } from 'interfaces/audits.interfaces';
import { asyncGetAudit, asyncProceedAudit } from 'modules/audits/action';
import { selectAudit } from 'modules/audits/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Textarea } from 'shared-components/Textarea';

export const AuditCarryingForm = () => {
  const { id } = useParams();

  const { pathname } = useLocation();
  const isView = pathname.includes('/view/');

  const audit = useAppSelector(selectAudit);

  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AuditCarryingInput>();

  const onSubmit: SubmitHandler<AuditCarryingInput> = (data) => {
    if (!id) return;

    const formattedData = { id: +id, ...data };

    dispatch(asyncProceedAudit(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.audits);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (!isView && audit && audit?.status !== AuditStatuses.created) {
      navigate(AppRoutes.audits);
    }
  }, [audit, navigate, isView]);

  useEffect(() => {
    if (id && audit) {
      reset({
        consistencies: audit.consistencies,
        inconsistencies: audit.inconsistencies,
      });
    }
  }, [id, reset, audit]);

  useEffect(() => {
    if (id) {
      dispatch(asyncGetAudit(id));
    }
  }, [dispatch, id]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col pt-4 gap-6'>
        <div className='flex flex-col md:flex-row gap-8'>
          <div className={twMerge(cn('flex-1 flex flex-col gap-6 md:self-start', { 'gap-6': isView }))}>
            <Textarea
              {...register('consistencies', REQUIRED_VALIDATION)}
              isRequired
              rows={4}
              error={errors['consistencies']?.message as string}
              label='consistencies'
              placeholder={formatMessage({ id: 'consistencies' })}
              data-test='consistencies'
            />
            <Textarea
              {...register('inconsistencies', REQUIRED_VALIDATION)}
              isRequired
              rows={4}
              error={errors['inconsistencies']?.message as string}
              label='inconsistencies'
              placeholder={formatMessage({ id: 'inconsistencies' })}
              data-test='inconsistencies'
            />
          </div>
        </div>
        {!isView && <ItemNavigation navigateBack={AppRoutes.audits} />}
      </form>
    </Card>
  );
};
