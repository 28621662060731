import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.documentsReducer;

export const selectDocument = createSelector(selectState, ({ document }) => document);

export const selectDocuments = createSelector(selectState, ({ documents }) => documents);

export const selectDocumentCount = createSelector(selectState, ({ documentsCount }) => documentsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
