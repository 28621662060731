import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Complect } from 'interfaces/complects.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Complect;
}

export const ComplectsTableRow: FC<Props> = ({ handleDelete, data: { id, name } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.complects, id);

  return (
    <TableRow>
      <TableData className='w-fit'>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='complects.update'
          deletePermission='complects.delete'
          viewPermission='complects.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{name}</TableData>
    </TableRow>
  );
};
