import { FormattedMessage } from 'react-intl';

export const RiskLevelCalculationInfo = () => {
  return (
    <div>
      <FormattedMessage id='risk_assessments_level' />
      {' = '}
      <FormattedMessage id='risk_assessments_probability' />
      {' * '}
      <FormattedMessage id='risk_assessments_consequences' />
    </div>
  );
};
