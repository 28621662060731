import { useLocation, useParams, useSearchParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CorrectionsTable } from 'page-components/corrections/CorrectionsTable';
import { InconsistencyForm } from 'page-components/inconsistencies/InconsistencyForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { getEditRoute } from 'utils/routes.utils';

export const Inconsistency = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();

  const providerId = searchParams.get('provider');

  const providerRoute = providerId ? getEditRoute(AppRoutes.providers, +providerId) : AppRoutes.inconsistencies;

  const editTitle = pathname === AppRoutes.inconsistencyCreate ? 'create_inconsistency' : 'edit_inconsistency';
  const titleText = pathname.includes('view') ? 'view_inconsistency' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <InconsistencyForm successRoute={providerRoute} />
      {id && <CorrectionsTable />}
    </PageLayout>
  );
};
