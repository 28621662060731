import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.componentsReducer;

export const selectComplects = createSelector(selectState, ({ complects }) => complects);

export const selectComplect = createSelector(selectState, ({ complect }) => complect);

export const selectComplectCount = createSelector(selectState, ({ complectsCount }) => complectsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
