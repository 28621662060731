import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CitiesResponse, City } from 'interfaces/cities.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import { asyncDeleteCity, asyncCreateCity, asyncGetCities, asyncGetCity, asyncUpdateCity, unsetCity } from './action';
import { CitiesSliceState } from './slice';

export function getCitiesReducer(builder: ActionReducerMapBuilder<CitiesSliceState>) {
  builder.addCase(asyncGetCities.fulfilled, (state, { payload }: PayloadAction<CitiesResponse>) => {
    state.cities = payload.data;
    state.citiesCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCities.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCityReducer(builder: ActionReducerMapBuilder<CitiesSliceState>) {
  builder.addCase(asyncGetCity.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCity.fulfilled, (state, { payload }: PayloadAction<City>) => {
    state.city = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCity.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCityReducer = withLoadingReducer(asyncUpdateCity);

export const createCityReducer = withLoadingReducer(asyncCreateCity);

export function deleteCityReducer(builder: ActionReducerMapBuilder<CitiesSliceState>) {
  builder.addCase(asyncDeleteCity.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCity.fulfilled, (state) => {
    state.city = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCity.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCityReducer(builder: ActionReducerMapBuilder<CitiesSliceState>) {
  builder.addCase(unsetCity, (state) => {
    state.city = null;
  });
}
