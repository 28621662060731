import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

import { parseDefaultDate } from 'utils/date.utils';

export const useStartEndQuery = () => {
  const [searchParams] = useSearchParams();

  const getFormattedDate = (dateString: string | null) => {
    return dateString ? parseDefaultDate(dateString) : null;
  };

  const queryStartDate = searchParams.get('startDate');
  const queryEndDate = searchParams.get('endDate');

  const [startDate, setStartDate] = useState<Date | null>(getFormattedDate(queryStartDate));
  const [endDate, setEndDate] = useState<Date | null>(getFormattedDate(queryEndDate));

  return { startDate, endDate, setStartDate, setEndDate };
};
