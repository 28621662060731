import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useCustomerRequestDefaultOptions } from 'hooks/useCustomerRequestDefaultOptions';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import {
  asyncCreateCustomerRequest,
  asyncUpdateCustomerRequest,
  unsetCustomerRequest,
} from 'modules/customer-requests/action';
import { selectCustomerRequest } from 'modules/customer-requests/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Textarea } from 'shared-components/Textarea';

import { CustomerRequestClient } from './CustomerRequestClient';
import { CustomerRequestClientView } from './CustomerRequestClientView';
import { CustomerRequestProducts } from './CustomerRequestProducts';
import { CustomerRequestResponsible } from './CustomerRequestResponsible';
import { CustomerRequestSource } from './CustomerRequestSource';
import { CustomerRequestStatus } from './CustomerRequestStatus';

interface Props {
  isUserCreate: boolean;
  createdCustomer?: SelectOption;
  handleIsUserCreate: VoidFunction;
  setCreatedUser: Dispatch<SetStateAction<SelectOption | undefined>>;
}

export const CustomerRequestForm: FC<Props> = ({
  isUserCreate,
  handleIsUserCreate,
  createdCustomer,
  setCreatedUser,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const request = useAppSelector(selectCustomerRequest);

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    handleSubmit,
    control,
    reset,
    register,
    formState: { errors },
  } = useForm<CustomerRequestInput>();

  const { defaultStatus, defaultSource, defaultProducts, defaultResponsible, defaultCustomer } =
    useCustomerRequestDefaultOptions();

  const onSubmit: SubmitHandler<CustomerRequestInput> = ({
    responsibleSelect,
    productsSelect,
    requestSourceSelect,
    customerRequestStatusSelect,
    customerSelect,
    ...data
  }) => {
    const responsible = responsibleSelect.value as number;
    const productGroups = productsSelect.map(({ value }) => value) as number[];
    const requestSource = requestSourceSelect.value as number;
    const requestStatus = customerRequestStatusSelect.value as number;
    const customer = customerSelect.value as number;

    const formattedData = { responsible, productGroups, requestSource, requestStatus, customer, ...data };

    dispatch(id ? asyncUpdateCustomerRequest({ id, ...formattedData }) : asyncCreateCustomerRequest(formattedData))
      .unwrap()
      .then(() => navigate(AppRoutes.customerRequests))
      .catch();
  };

  useEffect(() => {
    if (id) {
      reset({
        description: request?.description,
      });
    }
  }, [id, request, reset]);

  useBeforeRouteChange(() => dispatch(unsetCustomerRequest()));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
      <div className='flex gap-4'>
        {id && <CustomerRequestClientView customerId={request?.customer.id} />}
        <CustomerRequestClient
          error={errors['customerSelect']?.message}
          isUserCreate={isUserCreate}
          handleIsUserCreate={handleIsUserCreate}
          defaultCustomer={defaultCustomer}
          selectedCustomer={createdCustomer}
          setCreatedUser={setCreatedUser}
          control={control}
          className={cn({ hidden: id })}
        />
        <div className='flex flex-col gap-4 flex-1'>
          <CustomerRequestResponsible
            id={id}
            error={errors['responsibleSelect']?.message}
            defaultResponsible={defaultResponsible}
            control={control}
          />
          <Card title='customer_request_details' className='flex flex-col gap-6'>
            <CustomerRequestProducts
              id={id}
              error={errors['productsSelect']?.message}
              defaultProducts={defaultProducts}
              control={control}
            />
            <CustomerRequestSource
              id={id}
              error={errors['requestSourceSelect']?.message}
              defaultSource={defaultSource}
              control={control}
              isView={!!id}
            />
            <CustomerRequestStatus
              error={errors['customerRequestStatusSelect']?.message}
              defaultStatus={defaultStatus}
              control={control}
              isView={!!id}
              id={id}
            />
            <Textarea
              {...register('description', REQUIRED_VALIDATION)}
              isRequired
              label='description'
              placeholder={formatMessage({ id: 'description' })}
              data-test='customer-request-description'
              className='mt-2'
              rows={4}
            />
          </Card>
        </div>
      </div>
      <Card>
        <ItemNavigation navigateBack={AppRoutes.customerRequests} />
      </Card>
    </form>
  );
};
