import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { PermissionsResponse } from 'interfaces/permissions.interfaces';

export class PermissionsApi {
  constructor(private url: string) {}

  getPermissions(config?: AxiosRequestConfig) {
    return httpApiClient.get<PermissionsResponse>(`${this.url}`, config);
  }
}

export const permissionsApi = new PermissionsApi('/permissions');
