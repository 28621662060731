import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CustomerRequest, CustomerRequestsResponse } from 'interfaces/customer-requests.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCustomerRequest,
  asyncDeleteCustomerRequest,
  asyncGetCustomerRequest,
  asyncGetCustomerRequests,
  asyncUpdateCustomerRequest,
  unsetCustomerRequest,
} from './action';
import { CustomerRequestSliceState } from './slice';

export function getCustomerRequestsReducer(builder: ActionReducerMapBuilder<CustomerRequestSliceState>) {
  builder.addCase(asyncGetCustomerRequests.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerRequests.fulfilled, (state, { payload }: PayloadAction<CustomerRequestsResponse>) => {
    state.customerRequests = payload.data;
    state.customersRequestsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomerRequests.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCustomerRequestReducer(builder: ActionReducerMapBuilder<CustomerRequestSliceState>) {
  builder.addCase(asyncGetCustomerRequest.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerRequest.fulfilled, (state, { payload }: PayloadAction<CustomerRequest>) => {
    state.customerRequest = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomerRequest.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCustomerRequestReducer = withLoadingReducer(asyncUpdateCustomerRequest);

export const createCustomerRequestReducer = withLoadingReducer(asyncCreateCustomerRequest);

export function deleteCustomerRequestReducer(builder: ActionReducerMapBuilder<CustomerRequestSliceState>) {
  builder.addCase(asyncDeleteCustomerRequest.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCustomerRequest.fulfilled, (state) => {
    state.customerRequest = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCustomerRequest.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCustomerRequestReducer(builder: ActionReducerMapBuilder<CustomerRequestSliceState>) {
  builder.addCase(unsetCustomerRequest, (state) => {
    state.customerRequest = null;
  });
}
