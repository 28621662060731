import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  ComplaintPayload,
  ComplaintResponse,
  ComplaintsResponse,
  UpdateComplaintPayload,
} from 'interfaces/complaints.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class ComplaintsApi {
  constructor(private url: string) {}

  getComplaints(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<ComplaintsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getComplaint(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<ComplaintResponse>(`${this.url}/${payload}`, config);
  }

  updateComplaint({ id, ...data }: UpdateComplaintPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<ComplaintResponse>(`${this.url}/${id}`, data, config);
  }

  createComplaint(data: ComplaintPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ComplaintResponse>(`${this.url}`, data, config);
  }

  deleteComplaint(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const complaintsApi = new ComplaintsApi('/claims');
