import { createSlice } from '@reduxjs/toolkit';

import { DocumentType } from 'interfaces/document-types.interfaces';

import { DOCUMENT_TYPES_SLICE_NAME } from './action';
import {
  createDocumentTypeReducer,
  deleteDocumentTypeReducer,
  getDocumentTypeReducer,
  getDocumentTypesReducer,
  unsetDocumentTypeReducer,
  updateDocumentTypeReducer,
} from './reducers';

export interface DocumentTypesSliceState {
  isLoading: boolean;
  documentType: DocumentType | null;
  documentTypes: DocumentType[] | null;
  documentTypesCount: number | null;
}

export const initialState: DocumentTypesSliceState = {
  documentType: null,
  documentTypes: null,
  documentTypesCount: null,
  isLoading: false,
};

const documentTypesSlice = createSlice({
  name: DOCUMENT_TYPES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createDocumentTypeReducer(builder);
    deleteDocumentTypeReducer(builder);
    updateDocumentTypeReducer(builder);
    getDocumentTypesReducer(builder);
    getDocumentTypeReducer(builder);
    unsetDocumentTypeReducer(builder);
  },
});

export const documentTypesReducer = documentTypesSlice.reducer;
