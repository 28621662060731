import { useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { deleteUser, getUsers, unsetUsers } from 'modules/users/action';
import { selectIsLoading, selectUsers, selectUsersCount } from 'modules/users/selectors';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { UsersList } from 'page-components/users/users-list/UsersList';
import { UsersTable } from 'page-components/users/users-table/UsersTable';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { Pagination } from 'shared-components/Pagination';

export const Users = () => {
  const [userId, setUserId] = useState('');

  const dispatch = useAppDispatch();

  const users = useAppSelector(selectUsers);
  const usersCount = useAppSelector(selectUsersCount);
  const isLoading = useAppSelector(selectIsLoading);

  const { page, handlePageChange } = usePagination();

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const handleOnModalOpen = (id: string) => () => {
    setUserId(id);
    handleOpenModal();
  };

  const onUserDelete = async () => {
    await dispatch(deleteUser(userId)).then(() => {
      handleCloseModal();
    });
    await dispatch(getUsers());
  };

  useEffect(() => {
    dispatch(unsetUsers());
    dispatch(getUsers());
  }, [dispatch]);

  if (!users?.length) return null;

  return (
    <PageLayout title='users'>
      <Card className='p-0 sm:p-5' wrapperClassName='border-0 sm:border'>
        <Tabs>
          <TabList className='flex justify-end space-x-4 cursor-pointer text-mediumBlue font-medium'>
            <Tab data-test='table-view' className='group p-2 rounded outline-none transition-all hover:bg-gray/30'>
              <p>Таблиця</p>
            </Tab>
            <Tab data-test='list-view' className='group p-2 rounded outline-none transition-all hover:bg-gray/30'>
              <p>Список</p>
            </Tab>
          </TabList>
          <TabPanel>
            <UsersTable users={users} handleOpenDeleteModal={handleOnModalOpen} />
          </TabPanel>
          <TabPanel>
            <UsersList users={users} handleOpenDeleteModal={handleOnModalOpen} />
          </TabPanel>
        </Tabs>
        <Link to={AppRoutes.userCreate} className='mt-4' data-test='add-user'>
          <FormattedMessage id='add' />
        </Link>
      </Card>

      <Pagination current={page} total={usersCount ?? 0} onChange={handlePageChange} />
      <ConfirmationModal
        isOpened={isModalOpened}
        handleCloseModal={handleCloseModal}
        isLoading={isLoading}
        handleApply={onUserDelete}
        title='delete_user'
      />
    </PageLayout>
  );
};
