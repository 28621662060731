import { FC } from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { DateFormates } from 'constants/date.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CorrectionInput } from 'interfaces/corrections.interfaces';
import { Card } from 'shared-components/Card';
import { ErrorMessage } from 'shared-components/ErrorMessage';
import { FormLabel } from 'shared-components/FormLabel';

interface Props {
  defaultStart: Date;
  defaultRealStart: Date;
  isEditable: boolean;
}

export const CorrectionDatesFields: FC<Props> = ({ defaultStart, defaultRealStart, isEditable }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<CorrectionInput>();

  return (
    <Card title='terms' wrapperClassName='flex-1' className='flex flex-col gap-4'>
      <div className={cn({ 'flex gap-2 items-center': !isEditable })}>
        <FormLabel htmlFor='terms' className={cn('mb-2 flex', { 'text-black whitespace-nowrap mb-0': !isEditable })}>
          <FormattedMessage id='term' />
          {isEditable && '*:'}
        </FormLabel>
        <Controller
          control={control}
          name='terms'
          defaultValue={defaultStart as unknown as string}
          rules={REQUIRED_VALIDATION}
          render={({ field: { value, onChange, ...field } }) => (
            <DatePicker
              {...field}
              showIcon
              disabled={!isEditable}
              minDate={defaultStart}
              onChange={(date) => {
                date && onChange(date);
              }}
              selected={value ? new Date(value) : defaultStart}
              dateFormat={DateFormates.defaultDate}
              className='flex-1 h-full disabled:border-0'
              wrapperClassName='w-full'
            />
          )}
        />
        <ErrorMessage>{errors['terms']?.message}</ErrorMessage>
      </div>
      <div className={cn({ 'flex gap-2 items-center': !isEditable })}>
        <FormLabel
          htmlFor='realStart'
          className={cn('mb-2 flex', { 'text-black whitespace-nowrap mb-0': !isEditable })}
        >
          <FormattedMessage id='actual_date' />
          {isEditable && '*:'}
        </FormLabel>
        <Controller
          control={control}
          name='actualDate'
          defaultValue={defaultRealStart as unknown as string}
          rules={REQUIRED_VALIDATION}
          render={({ field: { value, onChange, ...field } }) => (
            <DatePicker
              {...field}
              showIcon
              disabled={!isEditable}
              minDate={defaultRealStart}
              onChange={(date) => {
                date && onChange(date);
              }}
              selected={value ? new Date(value) : defaultRealStart}
              dateFormat={DateFormates.defaultDate}
              className='flex-1 h-full disabled:border-0'
              wrapperClassName='w-full'
            />
          )}
        />
        <ErrorMessage>{errors['actualDate']?.message}</ErrorMessage>
      </div>
    </Card>
  );
};
