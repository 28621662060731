import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { EMAIL_VALIDATION } from 'constants/validation-rules.constants';
import { EmailResetPasswordInput } from 'interfaces/reset-password.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncSendResetPasswordEmail } from 'modules/user/action';
import { selectIsLoading } from 'modules/user/selectors';
import { Button } from 'shared-components/Button';
import { Input } from 'shared-components/Input';

export const EmailResetPassword = () => {
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EmailResetPasswordInput>();

  const onSubmit: SubmitHandler<EmailResetPasswordInput> = (data) => {
    dispatch(asyncSendResetPasswordEmail(data));
  };

  return (
    <form className='p-5 space-y-4' onSubmit={handleSubmit(onSubmit)} data-test='email-reset-form'>
      <Input
        {...register('email', EMAIL_VALIDATION)}
        label='email'
        error={errors['email']?.message}
        data-test='reset-email'
      />
      <Button type='submit' className='w-full' isLoading={isLoading} data-test='email-password-reset'>
        <FormattedMessage id='apply' />
      </Button>
    </form>
  );
};
