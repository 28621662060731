import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { ManagerActionRequestInfo } from 'page-components/manager-actions/ManagerActionRequestInfo';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const ManagerAction = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.managerActionCreate ? 'create_supervisor_action' : 'edit_supervisor_action';
  const titleText = pathname.includes('view') ? 'view_supervisor_action' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <ManagerActionRequestInfo />
    </PageLayout>
  );
};
