import { FC, ReactNode } from 'react';

import { toUpperCaseFirstLater } from 'utils/role.utils';

interface Props {
  subGroup: string;
  children: ReactNode;
}

export const RoleSubGroupLayout: FC<Props> = ({ subGroup, children }) => {
  return (
    <div className='flex flex-col gap-2'>
      <p>{toUpperCaseFirstLater(subGroup)}</p>
      <div className='grid gap-4 grid-cols-3 md:grid-cols-4'>{children}</div>
    </div>
  );
};
