import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  DocumentType,
  DocumentTypeInput,
  DocumentTypeResponse,
  DocumentTypesResponse,
} from 'interfaces/document-types.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class DocumentTypesApi {
  constructor(private url: string) {}

  getDocumentTypes(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<DocumentTypesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getDocumentTypeById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<DocumentTypeResponse>(`${this.url}/${payload}`, config);
  }

  updateDocumentType({ id, ...data }: DocumentType, config?: AxiosRequestConfig) {
    return httpApiClient.put<DocumentTypeResponse>(`${this.url}/${id}`, data, config);
  }

  createDocumentType(data: DocumentTypeInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<DocumentTypeResponse>(`${this.url}`, data, config);
  }

  deleteDocumentType(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const documentTypesApi = new DocumentTypesApi('/document-types');
