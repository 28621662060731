import { Field } from 'interfaces/shared/field.interfaces';

import { EMAIL_VALIDATION, PHONE_VALIDATION, REQUIRED_VALIDATION } from './validation-rules.constants';

export const USERS_COLUMNS = [
  { id: 'actions', title: '', className: 'w-32' },
  { id: 'name', title: 'name' },
  { id: 'email', title: 'email' },
  { id: 'role', title: 'role' },
  { id: 'permissions', title: 'permissions' },
];

export const USER_INFO_FIELDS: Field[] = [
  {
    name: 'name',
    options: REQUIRED_VALIDATION,
    'data-test': 'user-name',
    label: 'name',
    placeholder: 'name',
  },
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'user-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'phone',
    options: PHONE_VALIDATION,
    'data-test': 'user-phone',
    label: 'phone',
    placeholder: 'phone',
  },
];
