import { useEffect, useState } from 'react';

import { flattenTree } from 'react-accessible-treeview';

import { INCONSISTENCY_TYPES_COLUMNS } from 'constants/inconsistency-types.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteInconsistencyType, asyncGetInconsistencyTypes } from 'modules/inconsistency-types/action';
import {
  selectInconsistencyTypes,
  selectInconsistencyTypesCount,
  selectIsLoading,
} from 'modules/inconsistency-types/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';
import { convertDataForTreeView } from 'utils/inconsistency-types.utils';

import { InconsistencyTypesTableRow } from './InconsistencyTypesTableRow';

export const InconsistencyTypesTable = () => {
  const [instabilityId, setInstabilityId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const inconsistency = useAppSelector(selectInconsistencyTypes);
  const inconsistenciesCount = useAppSelector(selectInconsistencyTypesCount);

  const formattedGroups = flattenTree({ children: convertDataForTreeView(inconsistency), id: 0, name: '' })?.filter(
    (_, index) => index !== 0,
  );

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setInstabilityId(id);
    handleOpenModal();
  };

  const onInstabilityDelete = async () => {
    if (instabilityId) {
      await dispatch(asyncDeleteInconsistencyType(instabilityId));
      await dispatch(asyncGetInconsistencyTypes({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetInconsistencyTypes({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <>
      <CommonLayoutTable
        title='inconsistency_types_management'
        columns={INCONSISTENCY_TYPES_COLUMNS}
        creationPath={AppRoutes.inconsistencyTypeCreate}
        isLoading={isLoading}
      >
        {formattedGroups?.length ? (
          formattedGroups.map((productGroup) => (
            <InconsistencyTypesTableRow
              key={productGroup.id}
              data={productGroup}
              parent={formattedGroups.find((groups) => groups.id === productGroup.parent)}
              handleDelete={onModalOpen(+productGroup.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={INCONSISTENCY_TYPES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={inconsistenciesCount ?? 0} onChange={handlePageChange} />
      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onInstabilityDelete}
        title='delete_inconsistency_type'
      />
    </>
  );
};
