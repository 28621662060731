import { FC } from 'react';

import { ReactComponent as CautionSvg } from 'assets/svg/caution.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { RolesData } from 'interfaces/roles.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { TitleWithTooltip } from 'shared-components/TitleWithTooltip';
import { getEditRoute } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: RolesData;
}

export const RolesTableRow: FC<Props> = ({ handleDelete, data: { id, humanName, permissions } }) => {
  const titleText = permissions?.length > 0 ? 'granted_rights' : 'no_granted_rights';

  const roleRoute = getEditRoute(AppRoutes.roles, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          editRoute={roleRoute}
          handleDelete={handleDelete}
          editPermission='roles.update'
          deletePermission='roles.delete'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{humanName}</TableData>
      <TableData className='last:text-left'>
        <TitleWithTooltip
          id={`${id}`}
          text={titleText}
          icon={CautionSvg}
          tooltipChildren={
            permissions?.length > 0 && permissions.map((permission) => <p key={permission}>{permission}</p>)
          }
        />
      </TableData>
    </TableRow>
  );
};
