import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.documentTypesReducer;

export const selectDocumentType = createSelector(selectState, ({ documentType }) => documentType);

export const selectDocumentTypes = createSelector(selectState, ({ documentTypes }) => documentTypes);

export const selectDocumentTypesCount = createSelector(selectState, ({ documentTypesCount }) => documentTypesCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
