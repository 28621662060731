import { InconsistenciesFilters } from 'page-components/inconsistencies/InconsistenciesFilters';
import { InconsistenciesTable } from 'page-components/inconsistencies/InconsistenciesTable';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Inconsistencies = () => {
  return (
    <PageLayout title='inconsistencies' wrapperClassName='flex flex-col gap-4'>
      <InconsistenciesFilters />
      <InconsistenciesTable />
    </PageLayout>
  );
};
