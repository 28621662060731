import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customerRequestStatusesApi } from 'apis/customer-request-statuses.apis';
import { CustomerRequestStatus, CustomerRequestStatusInput } from 'interfaces/customer-request-statuses.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CUSTOMER_REQUEST_STATUSES_SLICE_NAME = 'customer-request-statuses';

export const asyncGetCustomerRequestStatuses = createAsyncThunk(
  `${CUSTOMER_REQUEST_STATUSES_SLICE_NAME}/getCustomerRequestStatuses`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await customerRequestStatusesApi.getCustomerRequestStatuses(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerRequestStatus = createAsyncThunk(
  `${CUSTOMER_REQUEST_STATUSES_SLICE_NAME}/getCustomerRequestStatus`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await customerRequestStatusesApi.getCustomerRequestStatusById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCustomerRequestStatus = createAsyncThunk(
  `${CUSTOMER_REQUEST_STATUSES_SLICE_NAME}/updateCustomerRequestStatus`,
  async (payload: CustomerRequestStatus, { rejectWithValue }) => {
    try {
      await customerRequestStatusesApi.updateCustomerRequestStatus(payload);
      toast.success(intl.formatMessage({ id: 'customer_request_status_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCustomerRequestStatus = createAsyncThunk(
  `${CUSTOMER_REQUEST_STATUSES_SLICE_NAME}/createCustomerRequestStatus`,
  async (payload: CustomerRequestStatusInput, { rejectWithValue }) => {
    try {
      await customerRequestStatusesApi.createCustomerRequestStatus(payload);
      toast.success(intl.formatMessage({ id: 'customer_request_status_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCustomerRequestStatus = createAsyncThunk(
  `${CUSTOMER_REQUEST_STATUSES_SLICE_NAME}/deleteCustomerRequestStatus`,
  async (id: number, { rejectWithValue }) => {
    try {
      await customerRequestStatusesApi.deleteCustomerRequestStatus(id);
      toast.success(intl.formatMessage({ id: 'customer_request_status_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCustomerRequestStatus = createAction('UNSET_CUSTOMER_REQUEST_STATUS');
