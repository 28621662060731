import { FC } from 'react';

import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { asyncConfirmNotification, asyncGetNotification } from 'modules/notifications/action';
import { selectNotification } from 'modules/notifications/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Button } from 'shared-components/Button';

interface Props {
  userId: number;
}

export const NotificationConfirm: FC<Props> = ({ userId }) => {
  const { id } = useParams();

  const user = useAppSelector(selectCurrentUser);
  const notification = useAppSelector(selectNotification);

  const dispatch = useAppDispatch();

  const onConfirm = async () => {
    if (!id) return;

    await dispatch(asyncConfirmNotification(+id));
    await dispatch(asyncGetNotification(id));
  };

  if (!notification) return null;

  return (
    <>
      {user?.id === userId ? (
        <Button onClick={onConfirm} className='py-1'>
          <FormattedMessage id='confirm' />
        </Button>
      ) : (
        <FormattedMessage id='not_confirmed' />
      )}
    </>
  );
};
