import { useEffect, useState } from 'react';

import { DocumentSelects } from 'interfaces/documents.interfaces';
import { asyncGetDocumentTypes } from 'modules/document-types/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useDocumentDefaultOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<DocumentSelects>();

  useEffect(() => {
    dispatch(asyncGetDocumentTypes({}))
      .unwrap()
      .then((response) => {
        const documentType = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, documentType }));
      });
  }, [dispatch]);

  return filterSelects;
};
