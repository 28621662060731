import { createSlice } from '@reduxjs/toolkit';

import { ProductGroup } from 'interfaces/product-groups.interfaces';

import { PRODUCT_GROUPS_SLICE_NAME } from './action';
import {
  createProductGroupReducer,
  deleteProductGroupReducer,
  getProductGroupReducer,
  getProductGroupsReducer,
  unsetProductGroupReducer,
  updateProductGroupReducer,
} from './reducers';

export interface ProductGroupsSliceState {
  isLoading: boolean;
  productGroup: ProductGroup | null;
  productGroups: ProductGroup[] | null;
  productGroupsCount: number | null;
}

export const initialState: ProductGroupsSliceState = {
  productGroup: null,
  productGroups: null,
  productGroupsCount: null,
  isLoading: false,
};

const productGroupsSlice = createSlice({
  name: PRODUCT_GROUPS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createProductGroupReducer(builder);
    deleteProductGroupReducer(builder);
    getProductGroupReducer(builder);
    getProductGroupsReducer(builder);
    unsetProductGroupReducer(builder);
    updateProductGroupReducer(builder);
  },
});

export const productGroupsReducer = productGroupsSlice.reducer;
