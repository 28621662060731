import { useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CityInput } from 'interfaces/cities.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncCreateCity, asyncGetCity, asyncUpdateCity } from 'modules/cities/action';
import { selectCity } from 'modules/cities/selectors';
import { asyncGetCountries } from 'modules/countries/action';
import { selectCountries } from 'modules/countries/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Select } from 'shared-components/Select';

export const CityForm = () => {
  const [countryOptions, setCountryOptions] = useState<SelectOption[]>();
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const city = useAppSelector(selectCity);
  const countries = useAppSelector(selectCountries);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<CityInput>();

  const onSubmit: SubmitHandler<CityInput> = (data) => {
    const { country, ...rest } = data;
    const formattedData = { country: +country.value, ...rest };

    dispatch(id ? asyncUpdateCity({ id: +id, ...formattedData }) : asyncCreateCity(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.cities);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetCity(id));
    }
    dispatch(asyncGetCountries({ page: 1, perPage: 100 }));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: city?.name ?? '', country: { value: city?.country.id, label: city?.country.name } ?? '' });
    }
  }, [id, reset, city]);

  useEffect(() => {
    if (countries) {
      setCountryOptions(countries.map(({ id, name }) => ({ value: id, label: name })));
    }
  }, [countries]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2 overflow-visible' titleClassName='rounded-t-lg'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='city-name'
          />
          <Controller
            control={control}
            name='country'
            rules={REQUIRED_VALIDATION}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder={formatMessage({ id: 'search_country' })}
                isRequired
                error={errors['country']?.message}
                defaultValue={countryOptions?.find((country) => country.value === city?.id)?.label}
                className='country z-50'
                options={countryOptions ?? []}
                value={value}
                onChange={onChange}
              />
            )}
          />
          <ItemNavigation navigateBack={AppRoutes.cities} />
        </div>
      </Card>
    </form>
  );
};
