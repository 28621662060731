import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customerRequestApi } from 'apis/customer-request.apis';
import {
  CustomerRequestInputPayload,
  UpdateCustomerRequestInputPayload,
} from 'interfaces/customer-requests.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CUSTOMERS_REQUEST_SLICE_NAME = 'customer-request';

export const asyncGetCustomerRequests = createAsyncThunk(
  `${CUSTOMERS_REQUEST_SLICE_NAME}/getCustomerRequests`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await customerRequestApi.getCustomerRequests(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerRequest = createAsyncThunk(
  `${CUSTOMERS_REQUEST_SLICE_NAME}/getCustomerRequest`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await customerRequestApi.getCustomerRequestById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCustomerRequest = createAsyncThunk(
  `${CUSTOMERS_REQUEST_SLICE_NAME}/updateCustomerRequest`,
  async (payload: UpdateCustomerRequestInputPayload, { rejectWithValue }) => {
    try {
      await customerRequestApi.updateCustomerRequest(payload);
      toast.success(intl.formatMessage({ id: 'customer_request_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCustomerRequest = createAsyncThunk(
  `${CUSTOMERS_REQUEST_SLICE_NAME}/createCustomerRequest`,
  async (payload: CustomerRequestInputPayload, { rejectWithValue }) => {
    try {
      await customerRequestApi.createCustomerRequest(payload);
      toast.success(intl.formatMessage({ id: 'customer_request_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCustomerRequest = createAsyncThunk(
  `${CUSTOMERS_REQUEST_SLICE_NAME}/deleteCustomerRequest`,
  async (id: number, { rejectWithValue }) => {
    try {
      await customerRequestApi.deleteCustomerRequest(id);
      toast.success(intl.formatMessage({ id: 'customer_request_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCustomerRequest = createAction('UNSET_CUSTOMER_REQUEST');
