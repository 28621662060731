import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { InconsistencyTypesResponse, InconsistencyItem } from 'interfaces/inconsistency-types.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncGetInconsistencyType,
  asyncCreateInconsistencyType,
  asyncDeleteInconsistencyType,
  asyncUpdateInconsistencyType,
  unsetInconsistencyType,
  asyncGetInconsistencyTypes,
} from './action';
import { InconsistencyTypeSliceState } from './slice';

export function getInconsistenciesTypeReducer(builder: ActionReducerMapBuilder<InconsistencyTypeSliceState>) {
  builder.addCase(
    asyncGetInconsistencyTypes.fulfilled,
    (state, { payload }: PayloadAction<InconsistencyTypesResponse>) => {
      state.inconsistencyTypes = payload.data;
      state.inconsistencyTypesCount = payload.pagination.total;
      state.isLoading = false;
    },
  );

  builder.addCase(asyncGetInconsistencyTypes.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getInconsistencyTypeReducer(builder: ActionReducerMapBuilder<InconsistencyTypeSliceState>) {
  builder.addCase(asyncGetInconsistencyType.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetInconsistencyType.fulfilled, (state, { payload }: PayloadAction<InconsistencyItem>) => {
    state.inconsistencyType = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetInconsistencyType.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateInconsistencyTypeReducer = withLoadingReducer(asyncUpdateInconsistencyType);

export const createInconsistencyTypeReducer = withLoadingReducer(asyncCreateInconsistencyType);

export function deleteInconsistencyTypeReducer(builder: ActionReducerMapBuilder<InconsistencyTypeSliceState>) {
  builder.addCase(asyncDeleteInconsistencyType.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteInconsistencyType.fulfilled, (state) => {
    state.inconsistencyType = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteInconsistencyType.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetInconsistencyTypeReducer(builder: ActionReducerMapBuilder<InconsistencyTypeSliceState>) {
  builder.addCase(unsetInconsistencyType, (state) => {
    state.inconsistencyType = null;
  });
}
