import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.inconsistenciesReducer;

export const selectInconsistency = createSelector(selectState, ({ inconsistency }) => inconsistency);

export const selectInconsistencies = createSelector(selectState, ({ inconsistencies }) => inconsistencies);

export const selectInconsistenciesCount = createSelector(
  selectState,
  ({ inconsistenciesCount }) => inconsistenciesCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
