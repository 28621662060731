import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { SelectOption } from 'interfaces/roles.interfaces';
import { TaskInput } from 'interfaces/tasks.interfaces';
import { Select } from 'shared-components/Select';

interface Props {
  defaultOption?: SelectOption;
}

export const TaskPriority: FC<Props> = ({ defaultOption }) => {
  const { formatMessage } = useIntl();

  const priorityOptions = [
    { label: 'Високий', value: 'high' },
    { label: 'Середній', value: 'medium' },
    { label: 'Низький', value: 'low' },
  ];

  const {
    control,
    formState: { errors },
  } = useFormContext<TaskInput>();

  return (
    <Controller
      control={control}
      name='prioritySelect'
      rules={REQUIRED_VALIDATION}
      defaultValue={defaultOption ?? priorityOptions[0]}
      render={({ field }) => (
        <Select
          {...field}
          isRequired
          label='priority'
          error={errors['prioritySelect']?.message}
          defaultValue={defaultOption}
          options={priorityOptions}
          className='priority flex-1 h-full'
          placeholder={formatMessage({ id: 'search_priority' })}
          data-test='task-priority'
        />
      )}
    />
  );
};
