import {
  Control,
  Controller,
  FieldError,
  FieldErrors,
  FieldValues,
  Path,
  PathValue,
  UseFormRegister,
} from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Field } from 'interfaces/shared/field.interfaces';

import { Input } from './Input';
import { Select } from './Select';

interface Props<T extends FieldValues> {
  fields: Field[];
  register: UseFormRegister<T>;
  errors: FieldErrors;
  control?: Control<T>;
}

export const FormFields = <T extends FieldValues>({ fields, register, errors, control }: Props<T>) => {
  const { formatMessage } = useIntl();

  return (
    <>
      {fields.map(
        (
          {
            label,
            name,
            options,
            placeholder,
            component = Input,
            defaultSelectData,
            selectData,
            isRequired,
            isMulti,
            ...props
          },
          index,
        ) => {
          const error = (errors as Record<string, FieldError>)[name]?.message;

          return component === Input ? (
            <Input
              {...register(name as Path<T>, options)}
              key={`${name} ${index}`}
              isRequired={!!options.required}
              error={error}
              label={label}
              placeholder={formatMessage({ id: placeholder })}
              {...props}
            />
          ) : (
            <Controller
              key={`${name} ${index}`}
              control={control}
              name={name as Path<T>}
              defaultValue={defaultSelectData as PathValue<T, Path<T>>}
              rules={options}
              render={({ field }) => (
                <Select
                  {...field}
                  isRequired={isRequired}
                  isMulti={isMulti}
                  label={label}
                  error={error}
                  defaultValue={defaultSelectData}
                  options={selectData}
                  placeholder={formatMessage({ id: placeholder })}
                />
              )}
            />
          );
        },
      )}
    </>
  );
};
