import { useState } from 'react';

import cn from 'classnames';

import { LOCALE, Locales } from 'i18n/locales';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { toggleLocale as toggleLanguageAction } from 'modules/user/action';
import { selectActiveLocale } from 'modules/user/selectors';
import { Toggle } from 'shared-components/Toggle';

export const LanguageToggleContainer = () => {
  const dispatch = useAppDispatch();
  const locale = useAppSelector(selectActiveLocale);

  const [activeLocale, setActiveLocale] = useState(locale);

  const toggleLanguage = () => {
    const newLocaleValue = activeLocale === Locales.UKRAINIAN ? Locales.ENGLISH : Locales.UKRAINIAN;

    localStorage.setItem(LOCALE, newLocaleValue);
    dispatch(toggleLanguageAction(newLocaleValue));
    setActiveLocale(newLocaleValue);
  };

  return (
    <div className='flex gap-2 items-center text-lightBlue/80'>
      <p className={cn({ 'text-deepBlue': activeLocale === Locales.ENGLISH })}>EN</p>

      <Toggle checked={activeLocale === Locales.UKRAINIAN} onChange={toggleLanguage} />

      <p className={cn({ 'text-deepBlue': activeLocale === Locales.UKRAINIAN })}>UA</p>
    </div>
  );
};
