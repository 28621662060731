import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  InconsistenciesResponse,
  InconsistencyPayload,
  InconsistencyResponse,
  UpdateInconsistencyPayload,
} from 'interfaces/inconsistencies.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class InconsistenciesApis {
  constructor(private url: string) {}

  getInconsistencies(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<InconsistenciesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getInconsistency(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<InconsistencyResponse>(`${this.url}/${payload}`, config);
  }

  updateInconsistency({ id, ...data }: UpdateInconsistencyPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<InconsistencyResponse>(`${this.url}/${id}`, data, config);
  }

  createInconsistency(data: InconsistencyPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<InconsistencyResponse>(`${this.url}`, data, config);
  }

  deleteInconsistency(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const inconsistenciesApi = new InconsistenciesApis('/inconsistencies');
