import { TableColumn } from './shared/table.constants';

export const CUSTOMER_REQUEST_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'products', title: 'product_groups' },
  { id: 'responsible', title: 'responsible' },
  { id: 'requestStatus', title: 'customer_request' },
  { id: 'creationDate', title: 'creation_date', className: 'last:text-left' },
];

export const CUSTOMER_REQUESTS_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  country: {
    label: 'country',
    name: 'countrySelect',
    placeholder: 'search_country',
  },
  responsible: {
    label: 'responsible',
    name: 'responsibleSelect',
    placeholder: 'search_responsible',
  },
  product: {
    label: 'product_group',
    name: 'productSelect',
    placeholder: 'search_product',
  },
  requestSource: {
    label: 'customer_request_sources',
    name: 'requestSourceSelect',
    placeholder: 'search_customer_request_source',
  },
  customerStatus: {
    label: 'customer_status',
    name: 'customerStatusSelect',
    placeholder: 'search_customer_request_source',
  },
  requestStatus: {
    label: 'customer_request_status',
    name: 'requestStatusSelect',
    placeholder: 'search_customer_status',
  },
};
