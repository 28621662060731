import { useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { useCustomerDefaultOptions } from 'hooks/useCustomerDefaultOptions';
import { useQueryParams } from 'hooks/useQueryParams';
import { ProvidersFiltersInput } from 'interfaces/providers.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { asyncGetComplects } from 'modules/complects/action';
import { useAppDispatch } from 'modules/store';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { Select } from 'shared-components/Select';

export const ProvidersFilters = () => {
  const { formatMessage } = useIntl();
  const { handleSubmit, register, control, reset } = useForm<ProvidersFiltersInput>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [componentsOptions, setComponentsOptions] = useState<SelectOption[]>();
  const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>();

  const dispatch = useAppDispatch();

  const filterSelects = useCustomerDefaultOptions();

  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const onSubmit: SubmitHandler<ProvidersFiltersInput> = (data) => {
    const complects = data.componentsSelect?.map(({ value }) => `${value}`);

    const queryParams = {
      complects,
      search: data?.search,
    };

    setQueryParams(queryParams);
  };

  const handleResetFilters = () => {
    setSearchParams({});

    reset({
      search: '',
      componentsSelect: [],
    });
  };

  useEffect(() => {
    dispatch(asyncGetComplects({}))
      .unwrap()
      .then(({ data }) => {
        setComponentsOptions(data.map((data) => ({ value: data.id, label: data.name })));

        const queryComponents = searchParams
          .get('complects')
          ?.split(',')
          .map((value) => +value);

        setDefaultOptions(
          data
            .filter(({ id }) => queryComponents?.includes(id))
            .map((component) => ({ value: component.id, label: component.name })),
        );
      });
  }, [dispatch, searchParams]);

  useEffect(() => {
    reset({
      search: searchParams.get('search') ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelects]);

  useQueryParams(queryParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className='flex flex-col gap-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-end'>
          <Input
            {...register('search')}
            label='search'
            className='flex-1'
            autoComplete='off'
            placeholder={formatMessage({ id: 'search' })}
          />
          {componentsOptions && (
            <Controller
              control={control}
              name='componentsSelect'
              defaultValue={defaultOptions}
              render={({ field }) => (
                <Select
                  {...field}
                  isMulti
                  label='components'
                  defaultValue={defaultOptions}
                  options={componentsOptions}
                  className='flex-1 h-full'
                  placeholder={formatMessage({ id: 'search_components' })}
                />
              )}
            />
          )}
        </div>
        <div className='flex gap-4'>
          <Button variant={ButtonVariants.CANCEL_OUTLINED} onClick={handleResetFilters}>
            <FormattedMessage id='reset_filters' />
          </Button>
          <Button type='submit'>
            <FormattedMessage id='filter' />
          </Button>
        </div>
      </Card>
    </form>
  );
};
