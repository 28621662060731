import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  CustomerRequestStatus,
  CustomerRequestStatusInput,
  CustomerRequestStatusResponse,
  CustomerRequestStatusesResponse,
} from 'interfaces/customer-request-statuses.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CustomerRequestStatusesApi {
  constructor(private url: string) {}

  getCustomerRequestStatuses(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestStatusesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCustomerRequestStatusById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestStatusResponse>(`${this.url}/${payload}`, config);
  }

  updateCustomerRequestStatus({ id, ...data }: CustomerRequestStatus, config?: AxiosRequestConfig) {
    return httpApiClient.put<CustomerRequestStatusesResponse>(`${this.url}/${id}`, data, config);
  }

  createCustomerRequestStatus(data: CustomerRequestStatusInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<CustomerRequestStatusesResponse>(`${this.url}`, data, config);
  }

  deleteCustomerRequestStatus(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const customerRequestStatusesApi = new CustomerRequestStatusesApi('/request-statuses');
