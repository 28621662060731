import { FormattedMessage } from 'react-intl';

import { selectNotification } from 'modules/notifications/selectors';
import { useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';

export const NotificationViewInfo = () => {
  const notification = useAppSelector(selectNotification);

  if (!notification) return null;

  return (
    <Card title={notification.name} className='flex gap-2'>
      <span className='font-bold'>
        <FormattedMessage id='description' />:
      </span>
      <p>{notification.description}</p>
    </Card>
  );
};
