import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { inconsistenciesApi } from 'apis/inconsistencies.apis';
import { InconsistencyPayload, UpdateInconsistencyPayload } from 'interfaces/inconsistencies.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const INCONSISTENCIES_SLICE_NAME = 'inconsistencies';

export const asyncGetInconsistencies = createAsyncThunk(
  `${INCONSISTENCIES_SLICE_NAME}/getInconsistencies`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await inconsistenciesApi.getInconsistencies(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetInconsistency = createAsyncThunk(
  `${INCONSISTENCIES_SLICE_NAME}/getInconsistency`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await inconsistenciesApi.getInconsistency(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateInconsistency = createAsyncThunk(
  `${INCONSISTENCIES_SLICE_NAME}/updateInconsistency`,
  async (payload: UpdateInconsistencyPayload, { rejectWithValue }) => {
    try {
      await inconsistenciesApi.updateInconsistency(payload);
      toast.success(intl.formatMessage({ id: 'inconsistency_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateInconsistency = createAsyncThunk(
  `${INCONSISTENCIES_SLICE_NAME}/createInconsistency`,
  async (payload: InconsistencyPayload, { rejectWithValue }) => {
    try {
      await inconsistenciesApi.createInconsistency(payload);
      toast.success(intl.formatMessage({ id: 'inconsistency_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteInconsistency = createAsyncThunk(
  `${INCONSISTENCIES_SLICE_NAME}/deleteInconsistency`,
  async (id: number, { rejectWithValue }) => {
    try {
      await inconsistenciesApi.deleteInconsistency(id);
      toast.success(intl.formatMessage({ id: 'inconsistency_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetInconsistency = createAction('UNSET_INCONSISTENCY');
