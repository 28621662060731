import { useEffect } from 'react';

import { useIntl } from 'react-intl';

import { ReactComponent as UsersSvg } from 'assets/svg/users.svg';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { selectIsLoading, selectUsersCount } from 'modules/users/selectors';
import { Notifications } from 'page-components/dashboard/notifications/Notifications';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { InfoBox } from 'shared-components/InfoBox';

export const Dashboard = () => {
  const { formatMessage } = useIntl();

  const usersCount = useAppSelector(selectUsersCount);
  const isLoading = useAppSelector(selectIsLoading);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUsers());
  }, [dispatch]);

  return (
    <PageLayout title='main' isLoading={isLoading}>
      <InfoBox
        icon={<UsersSvg className='w-16 h-16 fill-white' />}
        title={formatMessage({ id: 'users' })}
        description={usersCount ?? 0}
      />
      <Notifications />
    </PageLayout>
  );
};
