import { createSlice } from '@reduxjs/toolkit';

import { Education } from 'interfaces/educations.interfaces';

import { EDUCATIONS_SLICE_NAME } from './action';
import {
  createEducationReducer,
  deleteEducationReducer,
  getEducationReducer,
  getEducationsReducer,
  unsetEducationReducer,
  updateEducationReducer,
} from './reducers';

export interface EducationsSliceState {
  isLoading: boolean;
  education: Education | null;
  educations: Education[] | null;
  educationsCount: number | null;
}

export const initialState: EducationsSliceState = {
  education: null,
  educations: null,
  educationsCount: null,
  isLoading: false,
};

const educationsSlice = createSlice({
  name: EDUCATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createEducationReducer(builder);
    deleteEducationReducer(builder);
    getEducationReducer(builder);
    getEducationsReducer(builder);
    unsetEducationReducer(builder);
    updateEducationReducer(builder);
  },
});

export const educationsReducer = educationsSlice.reducer;
