import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  Department,
  DepartmentInput,
  DepartmentResponse,
  DepartmentsResponse,
} from 'interfaces/departments.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class DepartmentsApi {
  constructor(private url: string) {}

  getDepartments(query?: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<DepartmentsResponse>(`${this.url}?${generateQueryString(query ?? {})}`, config);
  }

  getDepartmentById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<DepartmentResponse>(`${this.url}/${payload}`, config);
  }

  updateDepartment({ id, ...data }: Department, config?: AxiosRequestConfig) {
    return httpApiClient.put<DepartmentResponse>(`${this.url}/${id}`, data, config);
  }

  createDepartment(data: DepartmentInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<DepartmentResponse>(`${this.url}`, data, config);
  }

  deleteDepartment(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const departmentsApi = new DepartmentsApi('/departments');
