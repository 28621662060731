import { FC } from 'react';

import { ReactComponent as MenuSvg } from 'assets/svg/menu.svg';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { Avatar } from 'shared-components/Avatar';
import { Button } from 'shared-components/Button';

import { LanguageToggleContainer } from './LanguageToggleContainer';

interface Props {
  handleOpenSidebar: VoidFunction;
  isSidebarOpen: boolean;
  userName?: string;
}

export const Header: FC<Props> = ({ handleOpenSidebar, userName }) => {
  return (
    <div className='z-10 lg:pl-20 min-h-[60px] h-[60px] p-2 sticky top-0 left-0 bg-white flex justify-between items-center'>
      <div>
        <Button className='block lg:hidden' variant={ButtonVariants.TRANSPARENT} onClick={handleOpenSidebar}>
          <MenuSvg />
        </Button>
      </div>
      <div className='px-4 flex items-center text-mediumBlue gap-6' data-test='open-pop-up'>
        <LanguageToggleContainer />

        <Avatar
          src='https://www.kraftwerk.at/app/uploads/fly-images/962/reference-img-worlds-of-adventure-park-4-1920x9999.jpg'
          alt='user-avatar'
        />
        <p className='ml-2 hidden sm:block select-none'>{userName}</p>
      </div>
    </div>
  );
};
