import { TableColumn } from './shared/table.constants';

export const DOCUMENTS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'name', title: 'name' },
  { id: 'description', title: 'description' },
  { id: 'document_type', title: 'document_type' },
  { id: 'department', title: 'department', className: 'last:text-left' },
];

export const DOCUMENTS_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  documentType: {
    label: 'document_type',
    name: 'documentTypeSelect',
    placeholder: 'search_document_type',
  },
};
