import { FC, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { InconsistencyInput } from 'interfaces/inconsistencies.interfaces';
import {
  asyncCreateInconsistency,
  asyncGetInconsistency,
  asyncUpdateInconsistency,
  unsetInconsistency,
} from 'modules/inconsistencies/action';
import { selectInconsistency } from 'modules/inconsistencies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';

import { InconsistencyAdditionalFields } from './InconsistencyAdditionalFields';
import { InconsistencyAnalysisFields } from './InconsistencyAnalysisFields';
import { InconsistencyGeneralFields } from './InconsistencyGeneralFields';

interface Props {
  successRoute: string;
}

export const InconsistencyForm: FC<Props> = ({ successRoute }) => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const inconsistency = useAppSelector(selectInconsistency);

  const methods = useForm<InconsistencyInput>();

  const { reset, handleSubmit } = methods;

  const onSubmit: SubmitHandler<InconsistencyInput> = ({
    department: departmentOption,
    inconsistencyType: inconsistency,
    ...data
  }) => {
    const department = departmentOption.value as number;
    const inconsistencyType = inconsistency.value as number;
    const supplier = searchParams.get('provider');

    const formattedData = { supplier: supplier ? +supplier : undefined, department, inconsistencyType, ...data };

    dispatch(id ? asyncUpdateInconsistency({ id: +id, ...formattedData }) : asyncCreateInconsistency(formattedData))
      .unwrap()
      .then(() => {
        navigate(successRoute);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetInconsistency()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetInconsistency(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && inconsistency) {
      reset({
        name: inconsistency.name,
        description: inconsistency.description,
        department: { value: inconsistency.department.id, label: inconsistency.department.name },
        inconsistencyType: { value: inconsistency.inconsistencyType.id, label: inconsistency.inconsistencyType.name },
        notes: inconsistency.notes,
        reasons: inconsistency.reasons,
        consequences: inconsistency.consequences,
      });
    }
  }, [id, reset, inconsistency]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <InconsistencyGeneralFields defaultDepartment={inconsistency?.department.id} />
            <InconsistencyAdditionalFields />
          </div>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <InconsistencyAnalysisFields />
          </div>
        </div>
        <ItemNavigation navigateBack={successRoute} />
      </FormProvider>
    </form>
  );
};
