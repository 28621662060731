import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.productGroupsReducer;

export const selectProductGroup = createSelector(selectState, ({ productGroup }) => productGroup);

export const selectProductGroups = createSelector(selectState, ({ productGroups }) => productGroups);

export const selectProductGroupsCount = createSelector(selectState, ({ productGroupsCount }) => productGroupsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
