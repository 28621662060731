import { ProductGroup, ProductGroupTree } from 'interfaces/product-groups.interfaces';

export const convertDataForTreeView = (data?: ProductGroup[] | null): ProductGroupTree[] => {
  return data
    ? data.map((item) => ({
        id: item.id,
        name: item.name,
        children: item.products ? convertDataForTreeView(item.products) : [],
      }))
    : [];
};
