import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { INCONSISTENCIES_COLUMNS } from 'constants/inconsistencies.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteInconsistency, asyncGetInconsistencies } from 'modules/inconsistencies/action';
import { selectInconsistencies, selectInconsistenciesCount, selectIsLoading } from 'modules/inconsistencies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

import { InconsistenciesTableRow } from './InconsistenciesTableRow';

export const InconsistenciesTable = () => {
  const [inconsistencyRequestId, setInconsistencyRequestId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const inconsistencies = useAppSelector(selectInconsistencies);
  const inconsistenciesCount = useAppSelector(selectInconsistenciesCount);

  const { search } = useLocation();
  const { startDate, endDate, search: searchQuery = '', department, inconsistencyType } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setInconsistencyRequestId(id);
    handleOpenModal();
  };

  const onInconsistencyRequestDelete = async () => {
    if (inconsistencyRequestId) {
      await dispatch(asyncDeleteInconsistency(+inconsistencyRequestId));
      await dispatch(asyncGetInconsistencies({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const dates = startDate && endDate ? `${startDate ?? ''}-${endDate ?? ''}` : '';
    const customersQueryParams = {
      search: searchQuery,
      dates,
      department,
      inconsistencyType,
    } as Record<string, string>;

    dispatch(
      asyncGetInconsistencies({
        page,
        perPage: 10,
        ...customersQueryParams,
      }),
    );
  }, [dispatch, page, startDate, endDate, searchQuery, department, inconsistencyType]);

  useQueryParams({ page });
  return (
    <>
      <CommonLayoutTable
        title='inconsistencies_management'
        columns={INCONSISTENCIES_COLUMNS}
        creationPath={AppRoutes.inconsistencyCreate}
        isLoading={isLoading}
      >
        {inconsistencies?.length ? (
          inconsistencies.map((inconsistency) => (
            <InconsistenciesTableRow
              key={inconsistency.id}
              data={inconsistency}
              handleDelete={onModalOpen(inconsistency.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={INCONSISTENCIES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={inconsistenciesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onInconsistencyRequestDelete}
        title='delete_inconsistency'
      />
    </>
  );
};
