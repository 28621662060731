import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ManagerAction } from 'interfaces/manager-actions.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateManagerAction,
  asyncDeleteManagerAction,
  asyncGetManagerAction,
  asyncUpdateManagerAction,
  unsetManagerAction,
} from './action';
import { ManagerActionsSliceState } from './slice';

export function getManagerActionReducer(builder: ActionReducerMapBuilder<ManagerActionsSliceState>) {
  builder.addCase(asyncGetManagerAction.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetManagerAction.fulfilled, (state, { payload }: PayloadAction<ManagerAction>) => {
    state.managerAction = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetManagerAction.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateManagerActionReducer = withLoadingReducer(asyncUpdateManagerAction);

export const createManagerActionReducer = withLoadingReducer(asyncCreateManagerAction);

export function deleteManagerActionReducer(builder: ActionReducerMapBuilder<ManagerActionsSliceState>) {
  builder.addCase(asyncDeleteManagerAction.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteManagerAction.fulfilled, (state) => {
    state.managerAction = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteManagerAction.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetManagerActionReducer(builder: ActionReducerMapBuilder<ManagerActionsSliceState>) {
  builder.addCase(unsetManagerAction, (state) => {
    state.managerAction = null;
  });
}
