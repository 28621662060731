import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Permission } from 'interfaces/permissions.interfaces';

import { asyncGetPermissions } from './action';
import { PermissionsSliceState } from './slice';

export function getPermissionsReducer(builder: ActionReducerMapBuilder<PermissionsSliceState>) {
  builder.addCase(asyncGetPermissions.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetPermissions.fulfilled, (state, { payload }: PayloadAction<Record<string, Permission[]>>) => {
    state.permissions = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetPermissions.rejected, (state) => {
    state.isLoading = false;
  });
}
