import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';

import { getInconsistenciesReportsFilters, INCONSISTENCIES_REPORT_FILTERS } from 'constants/reports.constants';
import { MetaData } from 'interfaces/corrections.interfaces';
import { InconsistencyReportTypes } from 'interfaces/reports.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { asyncGetInconsistencyTypes } from 'modules/inconsistency-types/action';
import { asyncGetInconsistenciesDynamicReports, asyncGetInconsistenciesReports } from 'modules/reports/action';
import { useAppDispatch } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { BarReportChart } from 'page-components/reports/BarReportChart';
import { LineReportChart } from 'page-components/reports/LineReportChart';
import { ReportFilters } from 'page-components/reports/ReportFilters';
import { ReportTypes } from 'page-components/reports/ReportTypes';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const InconsistencyReports = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const filterKey = searchParams.get('type');
  const { inconsistencyType = '', department = '', startDate, endDate } = qs.parse(search);

  const [fields, setFields] = useState<Record<string, Field[]>>({});

  const handleFieldsUpdate = (data: MetaData[], queryValue: number) => {
    const formattedOptions = convertObjectsToSelectOption(data);

    setFields(
      getInconsistenciesReportsFilters(
        formattedOptions,
        formattedOptions.find(({ value }) => value === queryValue),
      ),
    );
  };

  useEffect(() => {
    if (filterKey) {
      const commonData = {
        reportType: filterKey.split('_')[0],
        dates: startDate && endDate ? `${startDate ?? ''} - ${endDate ?? ''}` : '',
      };

      dispatch(
        !filterKey.includes('dynamic')
          ? asyncGetInconsistenciesReports({
              ...commonData,
              department: `${department}`,
              inconsistencyType: `${inconsistencyType}`,
            })
          : asyncGetInconsistenciesDynamicReports(commonData),
      );
    }
  }, [dispatch, endDate, filterKey, startDate, inconsistencyType, department]);

  useEffect(() => {
    if (filterKey?.includes('dynamic')) return;

    switch (filterKey) {
      case InconsistencyReportTypes.types:
        dispatch(asyncGetInconsistencyTypes({}))
          .unwrap()
          .then(({ data }) => {
            handleFieldsUpdate(data, +(inconsistencyType ?? 0));
          });
        break;
      case InconsistencyReportTypes.departments:
        dispatch(asyncGetDepartments({}))
          .unwrap()
          .then(({ data }) => {
            handleFieldsUpdate(data, +(department ?? 0));
          });
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterKey]);

  return (
    <PageLayout title='inconsistencies_reports' wrapperClassName='flex flex-col gap-4'>
      <ReportTypes filters={INCONSISTENCIES_REPORT_FILTERS} />
      <ReportFilters fields={filterKey && fields[filterKey] ? fields[filterKey] : []} />
      {filterKey?.includes('dynamic') ? <LineReportChart isDates /> : <BarReportChart />}
    </PageLayout>
  );
};
