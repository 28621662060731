import { useLocation, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ProviderForm } from 'page-components/provider/ProviderForm';
import { ProviderInconsistenciesTable } from 'page-components/provider/ProviderInconsistenciesTable';

export const Provider = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.providerCreate ? 'create_provider' : 'edit_provider';
  const titleText = pathname.includes('view') ? 'view_provider' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <ProviderForm />
      {id && <ProviderInconsistenciesTable />}
    </PageLayout>
  );
};
