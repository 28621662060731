import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.notificationsReducer;

export const selectNotification = createSelector(selectState, ({ notification }) => notification);

export const selectNotifications = createSelector(selectState, ({ notifications }) => notifications);

export const selectNotificationCount = createSelector(selectState, ({ notificationsCount }) => notificationsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
