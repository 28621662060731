import { FormatXMLElementFn, Options as IntlMessageFormatOptions } from 'intl-messageformat';
import { IntlProvider as ReactIntlProvider, IntlShape, MessageDescriptor, PrimitiveType, useIntl } from 'react-intl';

import { Locales } from 'i18n/locales';
import { messages } from 'i18n/messages';
import { useAppSelector } from 'modules/store';
import { selectActiveLocale } from 'modules/user/selectors';

let INTL: IntlShape;

export function IntlWrapper({ children }: { children: JSX.Element }) {
  INTL = useIntl();
  return children;
}

export const IntlGlobalProvider = ({ children }: { children: JSX.Element }) => {
  const locale = useAppSelector(selectActiveLocale) as Locales;

  return (
    <ReactIntlProvider messages={messages[locale]} locale={locale} defaultLocale={Locales.UKRAINIAN}>
      <IntlWrapper>{children}</IntlWrapper>
    </ReactIntlProvider>
  );
};

export class IntlTranslator {
  formatMessage(
    descriptor: MessageDescriptor,
    values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>,
    opts?: IntlMessageFormatOptions,
  ) {
    return INTL.formatMessage(descriptor, values, opts);
  }
}
