import { FC, ReactNode } from 'react';

import { useIntl } from 'react-intl';

import { Card } from 'shared-components/Card';
import { toUpperCaseFirstLater } from 'utils/role.utils';

interface Props {
  title: string;
  children: ReactNode;
}

export const RolePermissionLayout: FC<Props> = ({ title, children }) => {
  const { formatMessage } = useIntl();
  return (
    <Card
      title={`${formatMessage({ id: 'permissions_for' })} ${toUpperCaseFirstLater(title)}`}
      wrapperClassName='bg-mediumBlue'
      titleClassName='text-white font-normal'
      className='flex flex-col flex-1 p-4 gap-2 bg-white rounded-lg'
    >
      <div className='flex flex-col gap-2'>{children}</div>
    </Card>
  );
};
