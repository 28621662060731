import { useEffect, useState } from 'react';

import { UseFormReset } from 'react-hook-form';

import { Audit, AuditInput } from 'interfaces/audits.interfaces';
import { formatToInputDate, parseDefaultDate, parseInputDate } from 'utils/date.utils';

export const useAuditDates = (resetForm: UseFormReset<AuditInput>, audit?: Audit | null) => {
  const [defaultOptions, setDefaultOptions] = useState<{
    start: Date;
  }>({
    start: new Date(),
  });

  useEffect(() => {
    if (audit) {
      const parsedCreatedAt = parseDefaultDate(audit.plannedAt);

      setDefaultOptions({
        start: parseInputDate(formatToInputDate(parsedCreatedAt)),
      });

      resetForm({
        planned: formatToInputDate(parsedCreatedAt),
      });
    }
  }, [audit, resetForm]);

  return defaultOptions;
};
