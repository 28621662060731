import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.customerRequestReducer;

export const selectCustomerRequest = createSelector(selectState, ({ customerRequest }) => customerRequest);

export const selectCustomerRequests = createSelector(selectState, ({ customerRequests }) => customerRequests);

export const selectCustomerRequestsCount = createSelector(
  selectState,
  ({ customersRequestsCount }) => customersRequestsCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
