import { useEffect } from 'react';

import { UseFormGetValues, UseFormReset } from 'react-hook-form';

import { Permission } from 'interfaces/permissions.interfaces';
import { RoleInput } from 'interfaces/roles.interfaces';

export const useDisableCheckboxes = (
  fullAccess: string,
  permissions: Record<string, Permission[]>,
  reset: UseFormReset<RoleInput>,
  getValues: UseFormGetValues<RoleInput>,
) => {
  useEffect(() => {
    if (!!fullAccess) {
      const permissionNames = Object.values(permissions).flatMap((val) => val.map((val) => val.name));
      const initialValues = Object.fromEntries(
        permissionNames.map((name) => (name !== 'full.access' ? [name, false] : [name, true])),
      );

      const { humanName, usersSelect, supervisorsSelect, subordinatesSelect } = getValues();

      reset({ humanName, usersSelect, subordinatesSelect, supervisorsSelect, ...initialValues });
    }
  }, [permissions, fullAccess, reset, getValues]);
};
