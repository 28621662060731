import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.customerStatusesReducer;

export const selectCustomerStatus = createSelector(selectState, ({ customerStatus }) => customerStatus);

export const selectCustomerStatuses = createSelector(selectState, ({ customerStatuses }) => customerStatuses);

export const selectCustomerStatusesCount = createSelector(
  selectState,
  ({ customerStatusCount }) => customerStatusCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
