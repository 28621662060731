import { createSlice } from '@reduxjs/toolkit';

import { Provider } from 'interfaces/providers.interfaces';

import { PROVIDERS_SLICE_NAME } from './action';
import {
  deleteProviderReducer,
  createProviderReducer,
  getProviderReducer,
  updateProviderReducer,
  unsetProviderReducer,
  getProvidersReducer,
} from './reducers';

export interface ProvidersSliceState {
  isLoading: boolean;
  provider: Provider | null;
  providers: Provider[] | null;
  providersCount: number | null;
}

export const initialState: ProvidersSliceState = {
  provider: null,
  providers: null,
  providersCount: null,
  isLoading: false,
};

const providersSlice = createSlice({
  name: PROVIDERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    deleteProviderReducer(builder);
    createProviderReducer(builder);
    getProviderReducer(builder);
    updateProviderReducer(builder);
    unsetProviderReducer(builder);
    getProvidersReducer(builder);
  },
});

export const providersReducer = providersSlice.reducer;
