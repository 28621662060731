export enum AppRoutes {
  dashboard = '/',
  signIn = '/login',
  resetPassword = '/resetPassword',
  resetPasswordToken = '/resetPassword:token',
  profile = '/profile',
  users = '/users',
  user = '/users/:id',
  userCreate = '/users/create',
  userView = '/users/view/:id',
  roles = '/roles',
  role = '/roles/:id',
  roleCreate = '/roles/create',
  roleView = '/roles/create',
  countries = '/countries',
  country = '/countries/:id',
  countryCreate = '/countries/create',
  countryView = '/countries/view/:id',
  document = '/documents/:id',
  documents = '/documents',
  documentsCreate = '/documents/create',
  documentsView = '/documents/view/:id',
  documentTypes = '/document-types',
  documentType = '/document-types/:id',
  documentTypeCreate = '/document-types/create',
  documentTypeView = '/document-types/view/:id',
  cities = '/cities',
  city = '/cities/:id',
  cityCreate = '/cities/create',
  cityView = '/cities/view/:id',
  departments = '/departments',
  department = '/departments/:id',
  departmentCreate = '/departments/create',
  departmentView = '/departments/view/:id',
  tasks = '/tasks',
  task = '/tasks/:id',
  taskCreate = '/tasks/create',
  providers = '/providers',
  provider = '/providers/:id',
  providerView = '/providers/view/:id',
  providerCreate = '/providers/create',
  customers = '/customers',
  customer = '/customers/:id',
  customerCreate = '/customers/create',
  customerView = '/customers/view/:id',
  customerRequests = '/customerRequests',
  customerRequest = '/customerRequests/:id',
  customerRequestView = '/customerRequests/view/:id',
  customerRequestCreate = '/customerRequests/create',
  customerRequestSources = '/customerRequestSources',
  customerRequestSource = '/customerRequestSources/:id',
  customerRequestSourceView = '/customerRequestSources/view/:id',
  customerRequestSourceCreate = '/customerRequestSources/create',
  customerRequestStatuses = '/customerRequestStatuses',
  customerRequestStatus = '/customerRequestStatuses/:id',
  customerRequestStatusView = '/customerRequestStatuses/view/:id',
  customerRequestStatusCreate = '/customerRequestStatuses/create',
  customerStatuses = '/customerStatuses',
  customerStatus = '/customerStatuses/:id',
  customerStatusView = '/customerStatuses/view/:id',
  customerStatusCreate = '/customerStatuses/create',
  productGroups = '/productGroups',
  productGroup = '/productGroups/:id',
  productGroupView = '/productGroups/view/:id',
  productGroupCreate = '/productGroups/create',
  complects = '/complects',
  complect = '/complects/:id',
  complectView = '/complects/view/:id',
  complectCreate = '/complects/create',
  inconsistencyTypes = '/inconsistencyTypes',
  inconsistencyType = '/inconsistencyTypes/:id',
  inconsistencyTypeView = '/inconsistencyTypes/view/:id',
  inconsistencyTypeCreate = '/inconsistencyTypes/create',
  inconsistencies = '/inconsistencies',
  inconsistency = '/inconsistencies/:id',
  inconsistencyView = '/inconsistencies/view/:id',
  inconsistencyCreate = '/inconsistencies/create',
  complaints = '/complaints',
  complaint = '/complaints/:id',
  complaintView = '/complaints/view/:id',
  complaintCreate = '/complaints/create',
  educations = '/educations',
  education = '/educations/:id',
  educationView = '/educations/view/:id',
  educationCreate = '/educations/create',
  managerAction = '/manager-actions/:id',
  managerActionView = '/manager-actions/view/:id',
  managerActionCreate = '/manager-actions/create',
  corrections = '/corrections',
  correction = '/corrections/:id',
  correctionView = '/corrections/view/:id',
  correctionCreate = '/corrections/create',
  notifications = '/notifications',
  notification = '/notifications/:id',
  notificationView = '/notifications/view/:id',
  notificationsCreate = '/notifications/create',
  audits = '/audits',
  audit = '/audits/:id',
  auditProceed = '/audits/:id/proceed',
  auditFinish = '/audits/:id/finish',
  auditView = '/audits/view/:id',
  auditCreate = '/audits/create',
  risks = '/risks',
  risk = '/risks/:id',
  riskView = '/risks/view/:id',
  riskCreate = '/risks/create',
  riskProceed = '/risks/:id/proceed',
  reportsCustomers = '/reports/customers',
  reportsCustomerRequests = '/reports/customer-requests',
  reportsInconsistencies = '/reports/inconsistencies',
}
