import { FC } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { usePriorityColor } from 'hooks/usePriorityColor';
import { SelectOption } from 'interfaces/roles.interfaces';
import { Task } from 'interfaces/tasks.interfaces';
import { toUpperCaseFirstLater } from 'utils/role.utils';

interface Props {
  task: Task | null;
  defaultPriorityOption?: SelectOption;
}

export const TaskInfo: FC<Props> = ({ task, defaultPriorityOption }) => {
  const priorityColor = usePriorityColor(task?.priority ?? '');

  const usersCompleted = task?.performers.filter((performer) => performer?.completed).length;

  const infos = [
    {
      header: 'priority',
      text: toUpperCaseFirstLater(`${defaultPriorityOption?.value ?? ''}`),
      className: priorityColor,
    },
    {
      header: 'description',
      text: task?.content,
    },
    {
      header: 'confirmation_of_completion',
      text: `${usersCompleted} із ${task?.performers.length}`,
    },
  ];

  return (
    <div className='flex flex-col gap-4'>
      {infos.map(({ header, text, className }) => (
        <div key={header} className='flex flex-col gap-2'>
          <span>
            <FormattedMessage id={header} />
          </span>
          <span
            className={cn(className, 'self-start p-2 rounded-xl', {
              'bg-green-bright text-white': header !== 'description',
            })}
          >
            {text}
          </span>
        </div>
      ))}
    </div>
  );
};
