import { FC } from 'react';

import { RiskStatuses } from 'constants/risks.constsnts';
import { AppRoutes } from 'constants/routes-paths.constants';
import { Risk } from 'interfaces/risks.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

import { RiskTableRowAction } from './RiskTableRowAction';

interface Props {
  handleDelete: VoidFunction;
  data: Risk;
}

export const RisksTableRow: FC<Props> = ({ handleDelete, data: { id, name, responsible, department, status } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.risks, id);

  const user = useAppSelector(selectCurrentUser);

  const hasPermission = (permission?: string) => (permission ? user?.permissions.includes(permission) : true);

  const isFullAccess = hasPermission('full.access');
  const isProceedAccessible = isFullAccess || hasPermission('riskAssessments.proceed');

  return (
    <TableRow>
      <TableData>
        <div className='flex'>
          {status === RiskStatuses.created && (
            <ColumnDefaultControls
              handleDelete={handleDelete}
              editRoute={editRoute}
              viewRoute={viewRoute}
              editPermission='riskAssessments.update'
              deletePermission='riskAssessments.delete'
            />
          )}
          {isProceedAccessible && <RiskTableRowAction status={status} riskId={id} />}
        </div>
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{name}</TableData>
      <TableData>{responsible?.name}</TableData>
      <TableData className='last:text-left'>{department?.name}</TableData>
    </TableRow>
  );
};
