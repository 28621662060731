import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Risk, RisksResponse } from 'interfaces/risks.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncGetRisk,
  asyncGetRisks,
  asyncCreateRisk,
  asyncUpdateRisk,
  asyncDeleteRisk,
  unsetRisk,
  asyncProceedRisk,
  asyncCompleteRisk,
} from './action';
import { RisksSliceState } from './slice';

export function getRisksReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(asyncGetRisks.fulfilled, (state, { payload }: PayloadAction<RisksResponse>) => {
    state.risks = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetRisks.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getRiskReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(asyncGetRisk.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetRisk.fulfilled, (state, { payload }: PayloadAction<Risk>) => {
    state.risk = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetRisk.rejected, (state) => {
    state.isLoading = false;
  });
}

export function proceedRiskReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(asyncProceedRisk.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncProceedRisk.fulfilled, (state, { payload }: PayloadAction<Risk>) => {
    state.risk = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncProceedRisk.rejected, (state) => {
    state.isLoading = false;
  });
}

export function completeRiskReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(asyncCompleteRisk.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncCompleteRisk.fulfilled, (state, { payload }: PayloadAction<Risk>) => {
    state.risk = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncCompleteRisk.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateRiskReducer = withLoadingReducer(asyncUpdateRisk);

export const createRiskReducer = withLoadingReducer(asyncCreateRisk);

export function deleteRiskReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(asyncDeleteRisk.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteRisk.fulfilled, (state) => {
    state.risk = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteRisk.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetRiskReducer(builder: ActionReducerMapBuilder<RisksSliceState>) {
  builder.addCase(unsetRisk, (state) => {
    state.risk = null;
  });
}
