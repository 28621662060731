import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { Logo } from 'shared-components/Logo';

interface Props {
  title: string;
  children: ReactNode;
  additionalLink?: string;
  linkText?: string;
}

export const AuthLayout: FC<Props> = ({ title, additionalLink, linkText, children }) => {
  return (
    <div className='h-screen flex justify-center items-center flex-col px-2'>
      <Logo />
      <Card wrapperClassName='mt-4' className='w-full sm:w-[360px] bg-white' withoutSpacing>
        <div className='text-center py-3 px-5 border-b-[0.5px] border-lightBlue'>
          <h3 data-test='login-header'>
            <FormattedMessage id={title} />
          </h3>
        </div>
        {children}
        {additionalLink && linkText && (
          <div className='py-3 px-5 bg-lightBlue/20'>
            <Link
              variant={LinkVariants.TRANSPARENT}
              className='text-mediumBlue'
              to={additionalLink}
              data-test='additional-link'
            >
              <FormattedMessage id={linkText} />
            </Link>
          </div>
        )}
      </Card>
    </div>
  );
};
