import { useEffect, useState } from 'react';

import { EDUCATIONS_COLUMNS } from 'constants/educations.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteEducation, asyncGetEducations } from 'modules/educations/action';
import { selectEducations, selectEducationsCount, selectIsLoading } from 'modules/educations/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { EducationsTableRow } from 'page-components/educations/EducationsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Educations = () => {
  const [educationId, setEducationId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const educations = useAppSelector(selectEducations);
  const educationsCount = useAppSelector(selectEducationsCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setEducationId(id);
    handleOpenModal();
  };

  const onEducationDelete = async () => {
    if (educationId) {
      await dispatch(asyncDeleteEducation(educationId));
      await dispatch(asyncGetEducations({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetEducations({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='education' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='education_management'
        columns={EDUCATIONS_COLUMNS}
        creationPath={AppRoutes.educationCreate}
        isLoading={isLoading}
      >
        {educations?.length ? (
          educations.map((education) => (
            <EducationsTableRow key={education.id} data={education} handleDelete={onModalOpen(education.id)} />
          ))
        ) : (
          <TableNoData colSpan={EDUCATIONS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={educationsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onEducationDelete}
        title='delete_education'
      />
    </PageLayout>
  );
};
