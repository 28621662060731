import { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { DocumentInput } from 'interfaces/documents.interfaces';
import { asyncCreateDocument, asyncGetDocument, asyncUpdateDocument, unsetDocument } from 'modules/documents/action';
import { selectDocument } from 'modules/documents/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { convertObjectsToSelectOption } from 'utils/select.utils';

import { DocumentGeneralFields } from './DocumentGeneralFields';
import { DocumentTypeFields } from './DocumentTypeFields';

export const DocumentForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const document = useAppSelector(selectDocument);

  const methods = useForm<DocumentInput>();

  const { reset, handleSubmit } = methods;

  const onSubmit: SubmitHandler<DocumentInput> = ({ departmentSelect, documentTypeSelect, ...data }) => {
    const departments = departmentSelect.map(({ value }) => value) as number[];
    const documentType = documentTypeSelect.value as number;

    const formattedData = { departments, documentType, ...data };

    dispatch(id ? asyncUpdateDocument({ id: +id, ...formattedData }) : asyncCreateDocument(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.documents);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetDocument(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && document) {
      reset({
        name: document.name,
        description: document.description,
        departmentSelect: convertObjectsToSelectOption(document.departments),
        documentTypeSelect: { value: document?.documentType?.id, label: document?.documentType?.name },
      });
    }
  }, [id, reset, document]);

  useBeforeRouteChange(() => dispatch(unsetDocument()));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <DocumentGeneralFields />
          </div>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <DocumentTypeFields document={document} />
          </div>
        </div>
        <ItemNavigation navigateBack={AppRoutes.documents} />
      </FormProvider>
    </form>
  );
};
