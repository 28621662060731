import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useNotificationOptions } from 'hooks/useNotificationOptions';
import { Notification, NotificationInput } from 'interfaces/notifications.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  notification?: Notification | null;
}

export const NotificationTypeFields: FC<Props> = ({ notification }) => {
  const { departmentsOptions, subordinatesOptions, defaultDepartmentOption, defaultSubordinatesOption } =
    useNotificationOptions(notification);

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<NotificationInput>();

  return (
    <Card title='document' wrapperClassName='overflow-visible flex-1' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='departmentSelect'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultDepartmentOption}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['departmentSelect']?.message}
            defaultValue={defaultDepartmentOption}
            isRequired
            isMulti
            label='department'
            options={departmentsOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_departments' })}
          />
        )}
      />
      <Controller
        control={control}
        name='employeesSelect'
        defaultValue={defaultSubordinatesOption}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['employeesSelect']?.message}
            defaultValue={defaultSubordinatesOption}
            isMulti
            label='subordinates'
            options={subordinatesOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_subordinates' })}
          />
        )}
      />
    </Card>
  );
};
