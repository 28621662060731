import { useEffect, useState } from 'react';

import { ComplaintSelects } from 'interfaces/complaints.interfaces';
import { asyncGetCustomers } from 'modules/customers/action';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useCompleintsFilterOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<ComplaintSelects>();

  useEffect(() => {
    dispatch(asyncGetCustomers({}))
      .unwrap()
      .then((response) => {
        const customer = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, customer }));
      });

    dispatch(getUsers({}))
      .unwrap()
      .then((response) => {
        const responsible = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, responsible }));
      });
  }, [dispatch]);

  return filterSelects;
};
