import { useEffect, useState } from 'react';

import TreeView, { flattenTree, INode, ITreeViewOnSelectProps } from 'react-accessible-treeview';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useOpen } from 'hooks/useOpen';
import { ProductGroupInput } from 'interfaces/product-groups.interfaces';
import {
  asyncCreateProductGroup,
  asyncDeleteProductGroup,
  asyncGetProductGroup,
  asyncGetProductGroups,
  asyncUpdateProductGroup,
  unsetProductGroup,
} from 'modules/product-groups/action';
import { selectProductGroup, selectProductGroups } from 'modules/product-groups/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { TreeItem } from 'shared-components/TreeItem';
import { convertDataForTreeView } from 'utils/product-groups.utils';
import { getEditRoute } from 'utils/routes.utils';

export const ProductGroupForm = () => {
  const [treeData, setTreeData] = useState<INode[]>();
  const [defaultSelectedIds, setDefaultSelectedIds] = useState<number[]>();
  const [selectedId, setSelectedId] = useState<number>();
  const [editId, setEditId] = useState<number>();

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const productGroup = useAppSelector(selectProductGroup);
  const productGroups = useAppSelector(selectProductGroups);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ProductGroupInput>();

  const onSubmit: SubmitHandler<ProductGroupInput> = (data) => {
    const formattedData = { ...(selectedId ? { group: selectedId } : {}), ...data };

    dispatch(id ? asyncUpdateProductGroup({ id: +id, ...formattedData }) : asyncCreateProductGroup(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.productGroups);
      })
      .catch(() => 'error');
  };

  const onTreeSelect = (event: ITreeViewOnSelectProps) => {
    const selectedId = event.treeState.selectedIds.values().next().value;
    const selectedNode = treeData?.find((node) => node.id === selectedId);

    if (selectedNode?.parent && selectedNode.parent > 0) {
      return;
    }

    setSelectedId(selectedId);
  };

  const onProductGroupDelete = (elementId: string | number) => () => {
    const id = parseInt(`${elementId}`);

    if (id) {
      handleOpenModal();
      setEditId(id);
    }
  };

  const onDeleteApply = async () => {
    if (editId) {
      await dispatch(asyncDeleteProductGroup(editId));
      id && (await dispatch(asyncGetProductGroup(id)));
      await dispatch(asyncGetProductGroups({}));
      handleCloseModal();
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetProductGroup(id));
    }
    dispatch(asyncGetProductGroups({}));
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({
        name: productGroup?.name ?? '',
      });
    }

    const treeResponse = flattenTree({ children: convertDataForTreeView(productGroups), id: 0, name: '' });
    const defaultSelected = treeResponse
      .filter((treeItem) => treeItem.children?.find((childrenItem) => childrenItem === productGroup?.id))
      .map((product) => +product.id);

    setTreeData(treeResponse);
    setDefaultSelectedIds(defaultSelected);
  }, [id, reset, productGroup, productGroups]);

  useBeforeRouteChange(() => {
    dispatch(unsetProductGroup());
  });

  if (!treeData?.length || (!defaultSelectedIds?.length && id)) return null;

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card title='general_info' wrapperClassName='w-full md:w-1/2 overflow-visible' titleClassName='rounded-t-lg'>
          <div className='flex flex-col gap-4'>
            <Input
              {...register('name', REQUIRED_VALIDATION)}
              isRequired
              error={errors['name']?.message}
              label='title'
              placeholder={formatMessage({ id: 'title' })}
              data-test='product-group-name'
            />
            <TreeView
              data={treeData}
              defaultSelectedIds={defaultSelectedIds}
              onSelect={onTreeSelect}
              nodeRenderer={(props) => (
                <TreeItem
                  editPermission='productGroups.update'
                  deletePermission='productGroups.delete'
                  handleDelete={onProductGroupDelete(props.element.id)}
                  editRoute={getEditRoute(AppRoutes.productGroups, props.element.id as number)}
                  {...props}
                />
              )}
            />
            <ItemNavigation navigateBack={AppRoutes.productGroups} />
          </div>
        </Card>
      </form>
      <ConfirmationModal
        isOpened={isModalOpened}
        handleCloseModal={handleCloseModal}
        handleApply={onDeleteApply}
        title='delete_product_group'
      />
    </>
  );
};
