import { Path, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { RISKS_MARKS_FIELDS, RISKS_PROCEED_FIELDS } from 'constants/risks.constsnts';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { RiskProceedInput, RiskProceedPayload } from 'interfaces/risks.interfaces';
import { asyncProceedRisk } from 'modules/risks/action';
import { useAppDispatch } from 'modules/store';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { RateRange } from 'shared-components/RateRange';
import { Textarea } from 'shared-components/Textarea';

export const RiskProceedForm = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    reset,
    control,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RiskProceedInput>({
    defaultValues: {
      probabilityRate: [1],
      seriousnessRate: [1],
    },
  });

  const onSubmit: SubmitHandler<RiskProceedInput> = ({ probabilityRate, seriousnessRate, ...data }) => {
    if (!id) return;

    const formattedData: RiskProceedPayload = {
      id: +id,
      probability: probabilityRate[0],
      seriousness: seriousnessRate[0],
      ...data,
    };

    dispatch(asyncProceedRisk(formattedData))
      .unwrap()
      .then(() => {
        reset({
          probabilityRate: [1],
          seriousnessRate: [1],
          name: '',
          howToDecrease: '',
          howToControl: '',
          notes: '',
        });
      })
      .catch(() => 'error');
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <div className='grid grid-cols-1 md:grid-cols-2 gap-6'>
        <Card title='rick_addition' className='flex flex-col gap-8'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='risk-description'
          />
          <Textarea
            className='flex flex-col gap-2 justify-end'
            {...register('notes', REQUIRED_VALIDATION)}
            label='notes'
            placeholder={formatMessage({ id: 'notes' })}
            rows={4}
          />
        </Card>
        <Card wrapperClassName='overflow-visible' className='flex flex-col gap-8 pb-8' title='risk_assessment'>
          {RISKS_MARKS_FIELDS.map(({ name, max, min, label, icon, tooltipChildren }) => (
            <RateRange
              className='flex-1 pl-3'
              control={control}
              key={name}
              name={name}
              max={max as number}
              min={min as number}
              label={label}
              icon={icon}
              tooltipChildren={tooltipChildren}
            />
          ))}
        </Card>
        <Card wrapperClassName='md:col-span-2' className='flex flex-col md:flex-row gap-6' title='risk_prevent'>
          {RISKS_PROCEED_FIELDS.map(({ name, options, label }) => (
            <Textarea
              key={name}
              className='flex flex-col gap-2 justify-end'
              {...register(name as Path<RiskProceedInput>, options)}
              label={label}
              placeholder={formatMessage({ id: label })}
              rows={4}
            />
          ))}
        </Card>
      </div>
      <Button className='self-end' type='submit'>
        <FormattedMessage id='add' />
      </Button>
    </form>
  );
};
