import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Customer } from 'interfaces/customers.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { formatToDefaultDate, parseDefaultDate } from 'utils/date.utils';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Customer;
}

export const CustomersTableRow: FC<Props> = ({
  handleDelete,
  data: { id, name, createdAt, country, city, email, phone, customerStatus },
}) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.customers, id);

  const creationDate = parseDefaultDate(createdAt);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='customers.update'
          deletePermission='customers.delete'
          viewPermission='customers.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{name}</TableData>
      <TableData>{country.name}</TableData>
      <TableData>{city.name}</TableData>
      <TableData>{email}</TableData>
      <TableData>{phone}</TableData>
      <TableData>{customerStatus.name}</TableData>
      <TableData className='last:text-left'>{formatToDefaultDate(creationDate)}</TableData>
    </TableRow>
  );
};
