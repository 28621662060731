import { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { NotificationInput } from 'interfaces/notifications.interfaces';
import {
  asyncCreateNotification,
  asyncGetNotification,
  asyncUpdateNotification,
  unsetNotification,
} from 'modules/notifications/action';
import { selectNotification } from 'modules/notifications/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { convertObjectsToSelectOption } from 'utils/select.utils';

import { NotificationGeneralFields } from './NotificationGeneralFields';
import { NotificationTypeFields } from './NotificationTypeFields';

export const NotificationForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const notification = useAppSelector(selectNotification);

  const methods = useForm<NotificationInput>();

  const { reset, handleSubmit } = methods;

  const onSubmit: SubmitHandler<NotificationInput> = ({ departmentSelect, employeesSelect, ...data }) => {
    const departments = departmentSelect.map(({ value }) => value) as number[];
    const subordinates = employeesSelect?.map(({ value }) => value) as number[];

    const formattedData = { departments, subordinates, ...data };

    dispatch(id ? asyncUpdateNotification({ id: +id, ...formattedData }) : asyncCreateNotification(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.notifications);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetNotification(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && notification) {
      reset({
        name: notification.name,
        description: notification.description,
        departmentSelect: convertObjectsToSelectOption(notification.departments),
        employeesSelect: convertObjectsToSelectOption(notification.employees ?? []),
      });
    }
  }, [id, reset, notification]);

  useBeforeRouteChange(() => dispatch(unsetNotification()));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <NotificationGeneralFields />
          </div>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <NotificationTypeFields notification={notification} />
          </div>
        </div>
        <ItemNavigation navigateBack={AppRoutes.notifications} />
      </FormProvider>
    </form>
  );
};
