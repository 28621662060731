import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Task, TasksResponse } from 'interfaces/tasks.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncGetTasks,
  asyncCreateTask,
  asyncDeleteTask,
  asyncGetTask,
  asyncUpdateTask,
  unsetTask,
  asyncAddCommentTask,
  asyncConfirmByPerformer,
  asyncConfirmByOwner,
  asyncChangePerformer,
  unsetTasks,
} from './action';
import { TasksSliceState } from './slice';

export function getTasksReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncGetTasks.fulfilled, (state, { payload }: PayloadAction<TasksResponse>) => {
    state.tasks = payload.data;
    state.tasksCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetTasks.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getTaskReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncGetTask.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetTask.fulfilled, (state, { payload }: PayloadAction<Task>) => {
    state.task = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetTask.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateTaskReducer = withLoadingReducer(asyncUpdateTask);

export const createTaskReducer = withLoadingReducer(asyncCreateTask);

export function deleteTaskReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncDeleteTask.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteTask.fulfilled, (state) => {
    state.task = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteTask.rejected, (state) => {
    state.isLoading = false;
  });
}

export function addCommentTaskReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncAddCommentTask.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncAddCommentTask.fulfilled, (state, { payload }: PayloadAction<Task>) => {
    state.task = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncAddCommentTask.rejected, (state) => {
    state.isLoading = false;
  });
}

export function changePerformerReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncChangePerformer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncChangePerformer.fulfilled, (state, { payload }: PayloadAction<Task>) => {
    state.isLoading = false;
    state.task = payload;
  });

  builder.addCase(asyncChangePerformer.rejected, (state) => {
    state.isLoading = false;
  });
}

export function confirmByPerformerReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(asyncConfirmByPerformer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncConfirmByPerformer.fulfilled, (state, { payload }: PayloadAction<Task>) => {
    state.isLoading = false;
    state.task = payload;
  });

  builder.addCase(asyncConfirmByPerformer.rejected, (state) => {
    state.isLoading = false;
  });
}

export const confirmByOwnerReducer = withLoadingReducer(asyncConfirmByOwner);

export function unsetTaskReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(unsetTask, (state) => {
    state.task = null;
  });
}

export function unsetTasksReducer(builder: ActionReducerMapBuilder<TasksSliceState>) {
  builder.addCase(unsetTasks, (state) => {
    state.tasks = null;
  });
}
