import { createSlice } from '@reduxjs/toolkit';

import { Document } from 'interfaces/documents.interfaces';

import { DOCUMENT_SLICE_NAME } from './action';
import {
  createDocumentReducer,
  deleteDocumentReducer,
  updateDocumentReducer,
  unsetDocumentReducer,
  getDocumentReducer,
  getDocumentsReducer,
} from './reducers';

export interface DocumentsSliceState {
  isLoading: boolean;
  document: Document | null;
  documents: Document[] | null;
  documentsCount: number | null;
}

export const initialState: DocumentsSliceState = {
  document: null,
  documents: null,
  documentsCount: null,
  isLoading: false,
};

const documentsSlice = createSlice({
  name: DOCUMENT_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createDocumentReducer(builder);
    deleteDocumentReducer(builder);
    updateDocumentReducer(builder);
    getDocumentsReducer(builder);
    getDocumentReducer(builder);
    unsetDocumentReducer(builder);
  },
});

export const documentsReducer = documentsSlice.reducer;
