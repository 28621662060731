import { FC, useEffect } from 'react';

import { FormattedMessage } from 'react-intl';

import { asyncGetCustomer } from 'modules/customers/action';
import { selectCustomer } from 'modules/customers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';

interface Props {
  customerId?: number;
}

export const CustomerRequestClientView: FC<Props> = ({ customerId }) => {
  const dispatch = useAppDispatch();

  const customer = useAppSelector(selectCustomer);

  const customerData: Record<string, string | undefined> = {
    name: customer?.name,
    country: customer?.country.name,
    city: customer?.city.name,
    company: customer?.company,
    contact: customer?.contactPerson,
    email: customer?.email,
    phone: customer?.phone,
    skype: customer?.skype,
    additional_contacts: customer?.additionalContacts,
    language: customer?.language,
    status: customer?.customerStatus.name,
  };

  useEffect(() => {
    if (customerId) {
      dispatch(asyncGetCustomer(`${customerId}`));
    }
  }, [customerId, dispatch]);

  if (!customer) return null;

  return (
    <Card title='customer' wrapperClassName='flex-1' className='flex flex-col gap-6'>
      {Object.keys(customerData).map((customerKey) => (
        <div className='flex justify-between gap-2 border-b border-gray2' key={customerKey}>
          <span className='font-bold'>
            <FormattedMessage id={customerKey} />:
          </span>
          <span>{customerData[customerKey]}</span>
        </div>
      ))}
    </Card>
  );
};
