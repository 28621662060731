import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  CustomerRequestSource,
  CustomerRequestSourceResponse,
  CustomerRequestSourcesResponse,
  CustomerRequestSourceInput,
} from 'interfaces/customer-request-sources.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CustomerRequestSourceApi {
  constructor(private url: string) {}

  getCustomerRequestSources(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestSourcesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCustomerRequestSourceById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestSourceResponse>(`${this.url}/${payload}`, config);
  }

  updateCustomerRequestSource({ id, ...data }: CustomerRequestSource, config?: AxiosRequestConfig) {
    return httpApiClient.put<CustomerRequestSourcesResponse>(`${this.url}/${id}`, data, config);
  }

  createCustomerRequestSource(data: CustomerRequestSourceInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<CustomerRequestSourceResponse>(`${this.url}`, data, config);
  }

  deleteCustomerRequestSource(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const customerRequestSourceApi = new CustomerRequestSourceApi('/request-sources');
