import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CountriesResponse, Country } from 'interfaces/countries.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCountry,
  asyncDeleteCountry,
  asyncGetCountry,
  asyncUpdateCountry,
  asyncGetCountries,
  unsetCountry,
} from './action';
import { CountriesSliceState } from './slice';

export function getCountriesReducer(builder: ActionReducerMapBuilder<CountriesSliceState>) {
  builder.addCase(asyncGetCountries.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCountries.fulfilled, (state, { payload }: PayloadAction<CountriesResponse>) => {
    state.countries = payload.data;
    state.countriesCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCountries.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCountryReducer(builder: ActionReducerMapBuilder<CountriesSliceState>) {
  builder.addCase(asyncGetCountry.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCountry.fulfilled, (state, { payload }: PayloadAction<Country>) => {
    state.country = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCountry.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCountryReducer = withLoadingReducer(asyncUpdateCountry);
export const createCountryReducer = withLoadingReducer(asyncCreateCountry);

export function deleteCountryReducer(builder: ActionReducerMapBuilder<CountriesSliceState>) {
  builder.addCase(asyncDeleteCountry.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCountry.fulfilled, (state) => {
    state.country = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCountry.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCountryReducer(builder: ActionReducerMapBuilder<CountriesSliceState>) {
  builder.addCase(unsetCountry, (state) => {
    state.country = null;
  });
}
