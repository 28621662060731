import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  ProductGroupData,
  ProductGroupPayload,
  ProductGroupResponse,
  ProductGroupsResponse,
} from 'interfaces/product-groups.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class ProductGroupsApi {
  constructor(private url: string) {}

  getProductGroups(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<ProductGroupsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getProductGroupById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<ProductGroupResponse>(`${this.url}/${payload}`, config);
  }

  updateProductGroup({ id, ...data }: ProductGroupPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<ProductGroupResponse>(`${this.url}/${id}`, data, config);
  }

  createProductGroup(data: ProductGroupData, config?: AxiosRequestConfig) {
    return httpApiClient.post<ProductGroupResponse>(`${this.url}`, data, config);
  }

  deleteProductGroup(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const productGroupsApi = new ProductGroupsApi('/product-groups');
