import { useEffect, useState } from 'react';

import { COMPLECTS_COLUMNS } from 'constants/complects.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteComplect, asyncGetComplects } from 'modules/complects/action';
import { selectComplectCount, selectComplects, selectIsLoading } from 'modules/complects/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ComplectsTableRow } from 'page-components/complects/ComplectsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Complects = () => {
  const [complectId, setComplectId] = useState('');

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const complects = useAppSelector(selectComplects);
  const complectsCount = useAppSelector(selectComplectCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: string) => () => {
    setComplectId(id);
    handleOpenModal();
  };

  const onComplectDelete = async () => {
    if (complectId) {
      await dispatch(asyncDeleteComplect(complectId));
      await dispatch(asyncGetComplects({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetComplects({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='complects' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='complects_management'
        columns={COMPLECTS_COLUMNS}
        creationPath={AppRoutes.complectCreate}
        isLoading={isLoading}
      >
        {complects?.length ? (
          complects.map((complect) => (
            <ComplectsTableRow key={complect.id} data={complect} handleDelete={onModalOpen(`${complect.id}`)} />
          ))
        ) : (
          <TableNoData colSpan={COMPLECTS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={complectsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onComplectDelete}
        title='delete_complect'
      />
    </PageLayout>
  );
};
