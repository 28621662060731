import { useEffect, useState } from 'react';

import { CUSTOMER_STATUSES_COLUMNS } from 'constants/client-statuses.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteCustomerStatus, asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import {
  selectCustomerStatuses,
  selectCustomerStatusesCount,
  selectIsLoading,
} from 'modules/customer-statuses/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CustomerStatusesTableRow } from 'page-components/customer-statuses/CustomerStatusesTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const CustomerStatuses = () => {
  const [clientStatusId, setClientStatusId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const customerStatuses = useAppSelector(selectCustomerStatuses);
  const customerStatusesCount = useAppSelector(selectCustomerStatusesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setClientStatusId(id);
    handleOpenModal();
  };

  const onCustomerStatusDelete = async () => {
    if (clientStatusId) {
      await dispatch(asyncDeleteCustomerStatus(clientStatusId));
      await dispatch(asyncGetCustomerStatuses({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetCustomerStatuses({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='customer_statuses' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='customer_statuses_management'
        columns={CUSTOMER_STATUSES_COLUMNS}
        creationPath={AppRoutes.customerStatusCreate}
        isLoading={isLoading}
      >
        {customerStatuses?.length ? (
          customerStatuses.map((customerStatus) => (
            <CustomerStatusesTableRow
              key={customerStatus.id}
              data={customerStatus}
              handleDelete={onModalOpen(customerStatus.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMER_STATUSES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={customerStatusesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCustomerStatusDelete}
        title='delete_customer_status'
      />
    </PageLayout>
  );
};
