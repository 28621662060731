import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CorrectionInput } from 'interfaces/corrections.interfaces';
import { Card } from 'shared-components/Card';
import { Textarea } from 'shared-components/Textarea';

export const CorrectionGeneralFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<CorrectionInput>();

  return (
    <Card title='description' wrapperClassName='flex-1 overflow-visible' className='flex flex-col gap-6'>
      <Textarea
        {...register('description', REQUIRED_VALIDATION)}
        isRequired
        label='reasons'
        error={errors['description']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='correction-description'
        rows={4}
      />
    </Card>
  );
};
