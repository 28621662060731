import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.rolesReducer;

export const selectRole = createSelector(selectState, ({ role }) => role);

export const selectRoles = createSelector(selectState, ({ roles }) => roles);

export const selectRolesCount = createSelector(selectState, ({ rolesCount }) => rolesCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
