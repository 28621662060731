import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.customerRequestStatusesReducer;

export const selectCustomerRequestStatus = createSelector(
  selectState,
  ({ customerRequestStatus }) => customerRequestStatus,
);

export const selectCustomerRequestStatuses = createSelector(
  selectState,
  ({ customerRequestStatuses }) => customerRequestStatuses,
);

export const selectCustomerRequestStatusesCount = createSelector(
  selectState,
  ({ customerRequestStatusesCount }) => customerRequestStatusesCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
