import { forwardRef, TextareaHTMLAttributes } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { TEXTAREA_CLASSNAMES } from 'constants/textarea.constants';
import { ErrorMessage } from 'shared-components/ErrorMessage';
import { FormLabel } from 'shared-components/FormLabel';

export interface TextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
  className?: string;
  label?: string;
  isRequired?: boolean;
  isView?: boolean;
}

export const Textarea = forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, label, error, rows, placeholder, isView: isDisabled, isRequired, ...props }, ref) => {
    const { pathname } = useLocation();
    const isView = isDisabled || pathname.includes('/view/');

    return (
      <div className={twMerge('w-full relative', className)}>
        <textarea
          {...props}
          id={props.name}
          className={cn(
            TEXTAREA_CLASSNAMES.default,
            {
              [TEXTAREA_CLASSNAMES.error]: error,
              [TEXTAREA_CLASSNAMES.disabled]: props.disabled,
            },
            { 'pl-0 py-0 border-none bg-transparent pointer-events-none': isView },
          )}
          ref={ref}
          rows={isView ? 0 : rows}
          placeholder={isView ? '' : placeholder}
        />
        <FormLabel
          className={cn('transition-all absolute mx-auto top-0 bottom-0 left-2.5 pt-2.5 cursor-text', {
            'text-red': error,
            'pointer-events-none !text-base': isView,
          })}
          htmlFor={props.name}
        >
          {label && <FormattedMessage id={label} defaultMessage={label} />}
          {isRequired && !isView && '*'}
        </FormLabel>
        <ErrorMessage>{error && <FormattedMessage id={error} defaultMessage={error} />}</ErrorMessage>
      </div>
    );
  },
);

Textarea.displayName = 'Textarea';
