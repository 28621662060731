import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ReactComponent as ProfileSvg } from 'assets/svg/profile.svg';
import { TaskResponsible } from 'interfaces/tasks.interfaces';
import { useAppDispatch } from 'modules/store';
import { asyncConfirmByPerformer, asyncGetTask } from 'modules/tasks/action';
import { Button } from 'shared-components/Button';
import { formatToDefaultDate, parseDateWithHours } from 'utils/date.utils';

interface Props {
  currentUserId?: number;
  delegates?: TaskResponsible[];
  isPerformer: boolean;
  taskId?: string;
}

export const TaskDelegatesList: FC<Props> = ({ delegates, currentUserId, taskId, isPerformer }) => {
  const dispatch = useAppDispatch();

  const onConfirmAction = () => {
    if (!taskId) return;
    dispatch(asyncConfirmByPerformer({ id: +taskId, status: 'confirm' }));
    dispatch(asyncGetTask(taskId));
  };

  if (!delegates?.length) return null;

  return (
    <div className='flex flex-col gap-4'>
      {delegates?.map(({ id, name, assigned, completed }, index) => (
        <div key={`${id}-${index}`} className='flex gap-2'>
          <ProfileSvg className='fill-mediumBlue shrink-0 h-8 w-8 p-2 rounded-xl bg-white' />
          <div className='rounded-xl bg-white p-4 flex-1'>
            <div className='flex justify-between mb-4 border-b'>
              <div>
                <FormattedMessage id='added_delegates' />: <span className='text-mediumBlue'>{name} </span>
              </div>
              <span>{formatToDefaultDate(parseDateWithHours(assigned))}</span>
            </div>

            {completed || !(currentUserId === id && isPerformer) ? (
              <p>
                <FormattedMessage
                  id={completed ? 'confirmed_execution' : 'waiting_for_execution'}
                  values={completed ? { date: formatToDefaultDate(parseDateWithHours(completed)) } : undefined}
                />
              </p>
            ) : (
              <Button onClick={onConfirmAction} className='py-1'>
                <FormattedMessage id='confirm_execution' />
              </Button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
