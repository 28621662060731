import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.complaintsReducer;

export const selectComplaint = createSelector(selectState, ({ complaint }) => complaint);

export const selectComplaints = createSelector(selectState, ({ complaints }) => complaints);

export const selectCompleintsCount = createSelector(selectState, ({ complaintsCount }) => complaintsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
