import { FormattedMessage } from 'react-intl';

import { RiskConsequence } from 'interfaces/risks.interfaces';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';

interface Props {
  data: RiskConsequence;
}

export const RiskConsequencesTableRow = ({ data: { mark, seriousness, consequences } }: Props) => {
  return (
    <TableRow className='text-center'>
      <TableData>{mark}</TableData>
      <TableData>
        <FormattedMessage id={seriousness} />
      </TableData>
      <TableData className='last:text-left'>
        <FormattedMessage id={consequences} />
      </TableData>
    </TableRow>
  );
};
