import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.educationsReducer;

export const selectEducation = createSelector(selectState, ({ education }) => education);

export const selectEducations = createSelector(selectState, ({ educations }) => educations);

export const selectEducationsCount = createSelector(selectState, ({ educationsCount }) => educationsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
