import { FC, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Self } from 'interfaces/self.interfaces';
import { UserInput, UserInputPayload } from 'interfaces/users.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { createUser, updateUser } from 'modules/users/action';
import { selectUsers } from 'modules/users/selectors';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';

import { UserDepartments } from './UserDepartments';
import { UserInfo } from './UserInfo';
import { UserRoles } from './UserRoles';
import { UserSubordinates } from './UserSubordinates';
import { UserSupervisors } from './UserSupervisors';

interface Props {
  user: Self | null;
}

export const UserForm: FC<Props> = ({ user }) => {
  const dispatch = useAppDispatch();

  const { id } = useParams();
  const navigate = useNavigate();

  const users = useAppSelector(selectUsers);
  const usersSelect = users?.map(({ id, name }) => ({ id, name })) ?? [];

  const methods = useForm<UserInput>();

  const { handleSubmit, reset } = methods;

  const onSubmit: SubmitHandler<UserInput> = ({
    department,
    rolesSelect,
    subordinatesSelect,
    supervisorsSelect,
    phone,
    ...data
  }) => {
    const departmentId = department.value as number;
    const roles = rolesSelect.map(({ value }) => value) as number[];
    const subordinates = subordinatesSelect?.map(({ value }) => value) as number[];
    const supervisors = supervisorsSelect?.map(({ value }) => value) as number[];

    const formattedData: UserInputPayload = {
      roles,
      subordinates,
      supervisors,
      department: departmentId,
      phone: phone?.length > 0 ? phone : undefined,
      ...data,
    };

    dispatch(id ? updateUser({ id, ...formattedData }) : createUser(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.users);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id && user) {
      reset({ name: user.name, email: user.email, phone: user?.phone ?? undefined });
    }
  }, [id, reset, user]);

  return (
    <FormProvider {...methods}>
      <form className='flex flex-col gap-6' onSubmit={handleSubmit(onSubmit)}>
        <div className='flex flex-1 gap-6 flex-col md:flex-row'>
          <UserInfo />
          <div className='flex flex-col flex-1 self-start gap-6'>
            <UserRoles userRoles={user?.roles ?? []} />
          </div>
        </div>
        <div className='flex gap-6'>
          <UserSubordinates defaultSubordinates={user?.subordinates ?? []} userSubordinates={usersSelect} />
          <UserSupervisors defaultSupervisors={user?.supervisors ?? []} userSupervisors={usersSelect} />
        </div>
        <UserDepartments />
        <Card>
          <ItemNavigation navigateBack={AppRoutes.users} />
        </Card>
      </form>
    </FormProvider>
  );
};
