import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { CUSTOMERS_COLUMNS } from 'constants/customers.constants';
import { PROVIDERS_COLUMNS } from 'constants/providers.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteProvider, asyncGetProviders } from 'modules/providers/action';
import { selectIsLoading, selectProviders, selectProvidersCount } from 'modules/providers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ProvidersFilters } from 'page-components/providers/ProvidersFilters';
import { ProviderTableRow } from 'page-components/providers/ProviderTableRow';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Providers = () => {
  const [providerId, setProviderId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const providers = useAppSelector(selectProviders);
  const providersCount = useAppSelector(selectProvidersCount);

  const { search } = useLocation();
  const { complects, search: searchQuery = '' } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setProviderId(id);
    handleOpenModal();
  };

  const onProviderDelete = async () => {
    if (providerId) {
      await dispatch(asyncDeleteProvider(`${providerId}`));
      await dispatch(asyncGetProviders({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const customersQueryParams = {
      search: searchQuery,
      complects,
    } as Record<string, string>;

    dispatch(
      asyncGetProviders({
        page,
        perPage: 10,
        ...customersQueryParams,
      }),
    );
  }, [dispatch, page, complects, searchQuery]);

  useQueryParams({ page });

  return (
    <PageLayout title='providers' wrapperClassName='flex flex-col gap-4'>
      <ProvidersFilters />
      <CommonLayoutTable
        title='providers_management'
        columns={PROVIDERS_COLUMNS}
        creationPath={AppRoutes.providerCreate}
        isLoading={isLoading}
      >
        {providers?.length ? (
          providers.map((provider) => (
            <ProviderTableRow key={provider.id} data={provider} handleDelete={onModalOpen(provider.id)} />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMERS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={providersCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onProviderDelete}
        title='delete_provider'
      />
    </PageLayout>
  );
};
