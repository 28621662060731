import { FC } from 'react';

import cn from 'classnames';
import { differenceInDays } from 'date-fns';
import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { ReactComponent as CautionSvg } from 'assets/svg/caution.svg';
import { ReactComponent as ViewSvg } from 'assets/svg/view.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { usePriorityColor } from 'hooks/usePriorityColor';
import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { Task } from 'interfaces/tasks.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Link } from 'shared-components/Link';
import { Select } from 'shared-components/Select';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { TitleWithTooltip } from 'shared-components/TitleWithTooltip';
import { formatToDefaultDate, parseDateWithHours, parseDefaultDate } from 'utils/date.utils';
import { toUpperCaseFirstLater } from 'utils/role.utils';
import { getEditRoute } from 'utils/routes.utils';

interface Props {
  handleOpenDeleteModal: VoidFunction;
  isEditable?: boolean;
  data: Task;
}

export const TasksTableRow: FC<Props> = ({
  handleOpenDeleteModal,
  data: { id, priority, createdAt, deadlineAt, performer, performers, owner, content, completed },
  isEditable = true,
}) => {
  const { formatMessage } = useIntl();

  const taskRoute = getEditRoute(AppRoutes.tasks, id);

  const deadlineDate = parseDefaultDate(deadlineAt);
  const createdDate = parseDateWithHours(createdAt);

  const isExpired = !(differenceInDays(new Date(), deadlineDate) <= 0);

  const user = useAppSelector(selectCurrentUser);

  const { control } = useFormContext();

  const priorityColor = usePriorityColor(priority);

  const isPerformer = user?.id === performer.id;
  const isAuthor = user?.id === owner.id;
  const isTaskCompleted = performers.every(({ completed }) => completed);

  const performerOptions = [
    {
      value: { id, status: 'confirm' },
      label: formatMessage({ id: 'confirm_execution' }),
    },
    {
      value: { id, status: 'return' },
      label: formatMessage({ id: 'return_for_revision' }),
    },
  ];

  const confirmOptions = [
    {
      value: { id, owner, performers, status: 'return' },
      label: formatMessage({ id: 'return_for_revision' }),
    },
    {
      value: { id, owner, performers, status: 'confirm' },
      label: formatMessage({ id: 'close_the_task' }),
    },
  ];

  return (
    <TableRow className={cn({ 'bg-red/10': isExpired })}>
      <TableData className='py-2'>
        <div className='flex'>
          {isEditable && <ColumnDefaultControls handleDelete={handleOpenDeleteModal} editRoute={taskRoute} />}
          <Link
            variant={LinkVariants.TRANSPARENT}
            to={`${taskRoute}&mode=view`}
            className='px-2 flex items-center group'
            data-test='view-task'
          >
            <ViewSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
          </Link>
        </div>
      </TableData>
      <TableData>
        <span className={cn('p-2 rounded-xl text-white text-sm', priorityColor)}>
          {toUpperCaseFirstLater(priority)}
        </span>
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{formatToDefaultDate(deadlineDate)}</TableData>
      <TableData>{formatToDefaultDate(createdDate)}</TableData>
      <TableData>{owner.name}</TableData>
      <TableData>
        <TitleWithTooltip
          className='justify-center'
          id={`${id}`}
          icon={CautionSvg}
          tooltipChildren={performers.map((responsible) => (
            <p key={responsible.id}>{responsible.name}</p>
          ))}
        />
      </TableData>
      <TableData>
        <TitleWithTooltip
          className='justify-center'
          id={content}
          icon={CautionSvg}
          tooltipChildren={<p key={content}>{content}</p>}
        />
      </TableData>
      <TableData>
        <div
          className={cn({
            'opacity-0 pointer-events-none': !isEditable || !!completed,
          })}
        >
          <Controller
            control={control}
            name={`performer.${id}`}
            render={({ field: { onChange, value } }) => (
              <Select
                placeholder=''
                isDisabled={!((isTaskCompleted && isAuthor) || (isPerformer && !isTaskCompleted))}
                className='country z-50'
                options={isTaskCompleted && isAuthor ? confirmOptions : performerOptions}
                value={value}
                onChange={onChange}
              />
            )}
          />
        </div>
      </TableData>
    </TableRow>
  );
};
