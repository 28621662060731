import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ReactComponent as LetterSvg } from 'assets/svg/letter.svg';
import { Comment } from 'interfaces/tasks.interfaces';
import { formatToDefaultDate, parseDateWithHours } from 'utils/date.utils';

interface Props {
  comments?: Comment[];
}

export const TaskCommentsList: FC<Props> = ({ comments }) => {
  if (!comments?.length) return null;

  return (
    <div className='flex flex-col gap-4'>
      {comments?.map(({ content, created, author }) => (
        <div key={created} className='flex gap-2'>
          <LetterSvg className='fill-mediumBlue shrink-0 h-8 w-8 p-2 rounded-xl bg-white' />
          <div className='rounded-xl bg-white p-4 flex-1'>
            <div className='flex justify-between mb-4 border-b'>
              <div>
                <span className='text-mediumBlue'>{author} </span>
                <FormattedMessage id='added_comment' />
              </div>
              <span>{formatToDefaultDate(parseDateWithHours(created))}</span>
            </div>
            <p>{content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
