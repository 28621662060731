import { Locales } from './locales';

export const messages: { [key in Locales]: Record<string, string> } = {
  [Locales.UKRAINIAN]: {
    sign_in_header: 'Вхід до системи',
    email: 'Пошта',
    password: 'Пароль',
    phone: 'Телефон',
    remember_me: "Запам'ятати мене",
    sign_in: 'Вхід',
    registered: 'Зареєстровано',
    restoration_password: 'Відновлення паролю',
    restoration_email_sent: 'Ми надіслали посилання для зміни пароля електронною поштою',
    restoration_token: 'Код з пошти',
    restoration_token_question: 'Отримали листа для зміни пароля?',
    restoration_success: 'Ваш пароль скинуто',

    name: 'Імʼя',
    security: 'Безпека',

    profile: 'Профіль користувача',
    delete_profile: 'Видалити профіль',
    delete_profile_question: 'Ви бажаєте видалити профіль?',

    reset_password: 'Скидання паролю',
    current_password: 'Поточний пароль',
    new_password: 'Новий пароль',
    confirm_new_password: 'Підтвердити новий пароль',
    passwords_not_matches: 'Паролі не співпадають',
    change_password: 'Змінити пароль',

    main: 'Головна',
    notifications_count: 'Сповіщень: {current} із {max}',
    unconfirmed_notifications: 'Непідтверджені сповіщення',
    no_notifications: 'Ви не маєте непідтверджених сповіщень',
    view: 'Переглянути',

    roles: 'Ролі',
    role: 'Роль',
    role_name: 'Назва(системна)',
    role_management: 'Управління ролями',
    access: 'Доступ',
    id: 'ID',
    create_role: 'Створення ролі',
    edit_role: 'Редагування ролі',
    view_role: 'Перегляд ролі',
    delete_role: 'Видалити роль',
    delete_user_role: 'Видалити роль у співробітника',
    role_created: 'Роль створена успішно',
    role_updated: 'Роль оновлена успішно',
    role_deleted: 'Роль видалена успішно',
    granted_rights: 'Надані права',
    no_granted_rights: 'Прав не надано',
    permissions: 'Дозволи',
    permissions_for: 'Дозволи для:',
    all_permissions: 'Всі дозволи',
    available_permissions: 'Доступні права доступу',

    risks_navbar: 'Оцінка ризиків',
    risks: 'Ризики',

    suppliers: 'Постачальники',

    geography: 'Географія',

    country: 'Країна',
    countries: 'Країни',
    countries_management: 'Управління країнами',
    country_updated: 'Оновлення країни успішно',
    country_created: 'Створення країни успішно',
    country_deleted: 'Видалення країни успішно',
    create_country: 'Створення країни',
    edit_country: 'Редагування країни',
    view_country: 'Перегляд країни',
    delete_country: 'Видалити країну',

    document: 'Документ',
    documents: 'Документи',
    documents_management: 'Управління документами',
    document_updated: 'Оновлення документа успішно',
    document_created: 'Створення документа успішно',
    document_deleted: 'Видалення документа успішно',
    create_document: 'Створення документа',
    edit_document: 'Редагування документа',
    view_document: 'Перегляд документа',
    delete_document: 'Видалити документ',

    document_type: 'Тип документа',
    document_types: 'Типи документів',
    document_types_management: 'Управління типами документа',
    document_type_updated: 'Оновлення типу документа успішно',
    document_type_created: 'Створення типу документа успішно',
    document_type_deleted: 'Видалення типу документа успішно',
    create_document_type: 'Створення типу документа',
    edit_document_type: 'Редагування типу документа',
    view_document_type: 'Перегляд типу документа',
    delete_document_type: 'Видалити тип документа',

    complect: 'Комплектуюче',
    complects: 'Комплектуючі',
    complects_management: 'Управління комплектуючами',
    complect_updated: 'Оновлення комплектуючих успішно',
    complect_created: 'Створення комплектуючих успішно',
    complect_deleted: 'Видалення комплектуючих успішно',
    create_complect: 'Створення комплектуючого',
    edit_complect: 'Редагування комплектуючого',
    view_complect: 'Перегляд комплектуючого',
    delete_complect: 'Видалити комплектуюче',

    customers_navbar: 'Робота з клієнтами',
    customer_status: 'Статус клієнта',
    customer_statuses: 'Статуси клієнтів',
    customer_statuses_management: 'Управління статусами клієнтів',
    customer_status_created: 'Створення статуса успішно',
    customer_status_deleted: 'Видалення статуса успішно',
    customer_status_updated: 'Оновлення статуса успішно',
    create_customer_status: 'Створення статуса',
    edit_customer_status: 'Редагування статуса',
    view_customer_status: 'Перегляд статусу',
    delete_customer_status: 'Видалити статус',

    city: 'Місто',
    cities: 'Міста',
    cities_management: 'Управління містами',
    city_updated: 'Оновлення міста успішно',
    city_created: 'Створення міста успішно',
    city_deleted: 'Видалення міста успішно',
    create_city: 'Створення міста',
    edit_city: 'Редагування міста',
    view_city: 'Перегляд міста',
    delete_city: 'Видалити місто',

    department: 'Відділ',
    departments: 'Відділи',
    departments_management: 'Управління відділами',
    department_updated: 'Оновлення відділа успішно',
    department_created: 'Створення відділа успішно',
    department_deleted: 'Видалення відділа успішно',
    create_department: 'Створення відділа',
    edit_department: 'Редагування відділа',
    view_department: 'Перегляд відділа',
    delete_departments: 'Видалити відділ',

    customer: 'Клієнт',
    customers: 'Клієнти',
    customers_management: 'Управління клієнтами',
    customer_updated: 'Оновлення клієнта успішно',
    customer_created: 'Створення клієнта успішно',
    customer_deleted: 'Видалення клієнта успішно',
    create_customer: 'Створення клієнта',
    edit_customer: 'Редагування клієнта',
    view_customer: 'Перегляд клієнта',
    delete_customer: 'Видалити клієнта',
    customer_search: 'Пошук (email, телефон, имя)',
    create_customer_action: 'Створити клієнта',
    customer_info: 'Дані клієнта',
    customers_dynamic: 'Динаміка клієнтів',

    customer_request: 'Запит клієнтів',
    customer_requests: 'Запити клієнтів',
    customer_requests_management: 'Управління запитами клієнтів',
    customer_request_updated: 'Оновлення запиту клієнта успішно',
    customer_request_created: 'Створення запиту клієнта успішно',
    customer_request_deleted: 'Видалення запиту клієнта успішно',
    create_customer_request: 'Створення запиту клієнта',
    edit_customer_request: 'Редагування запиту клієнта',
    view_customer_request: 'Перегляд запиту клієнта',
    delete_customer_request: 'Видалити запит клієнта',
    customer_request_details: 'Деталі запиту',

    customer_request_source: 'Джерело запитів',
    customer_request_sources: 'Джерела запитів',
    customer_request_sources_management: 'Управління джерелами запитів клієнтів',
    customer_request_source_updated: 'Оновлення джерела запиту клієнтів успішно',
    customer_request_source_created: 'Створення джерела запиту клієнтів успішно',
    customer_request_source_deleted: 'Видалення джерела запиту клієнтів успішно',
    create_customer_request_source: 'Створення джерела запиту клієнтів',
    edit_customer_request_source: 'Редагування джерела запиту клієнтів',
    view_customer_request_source: 'Перегляд джерела запиту клієнтів',
    delete_customer_request_source: 'Видалити джерело запиту клієнтів',

    customer_request_status: 'Статус запиту',
    customer_request_statuses: 'Статуси запитів',
    customer_request_statuses_management: 'Управління статусами запитів',
    customer_request_status_updated: 'Оновлення статуса запиту успішно',
    customer_request_status_created: 'Створення статуса запиту успішно',
    customer_request_status_deleted: 'Видалення статуса запиту успішно',
    create_customer_request_status: 'Створення статуса запиту',
    edit_customer_request_status: 'Редагування статуса запиту',
    view_customer_request_status: 'Перегляд статусу запиту',
    delete_customer_request_status: 'Видалити статус запиту',

    products: 'Продукція',
    product_group: 'Група продукції',
    product_groups: 'Групи продукції',
    product_groups_management: 'Управління групами продукції',
    product_group_updated: 'Оновлення групи продукції успішно',
    product_group_created: 'Створення групи продукції успішно',
    product_group_deleted: 'Видалення групи продукції успішно',
    create_product_group: 'Створення групи продукції',
    edit_product_group: 'Редагування групи продукції',
    view_product_group: 'Перегляд групи продукції',
    delete_product_group: 'Видалити групу продукції',

    notification_center: 'Центр сповіщень',
    self_notifications: 'Ваші сповіщення',
    notification: 'Сповіщення',
    notifications: 'Сповіщення',
    notifications_management: 'Управління сповіщеннями',
    notification_updated: 'Оновлення сповіщення успішно',
    notification_created: 'Створення сповіщення успішно',
    notification_deleted: 'Видалення сповіщення успішно',
    create_notification: 'Створення сповіщення',
    edit_notification: 'Редагування сповіщення',
    view_notification: 'Перегляд сповіщення',
    delete_notification: 'Видалити сповіщення',
    confirmed_notification: 'Сповіщення підтверджено',

    audit: 'Аудит',
    audits: 'Аудити',
    audits_management: 'Управління аудитами',
    audit_updated: 'Оновлення аудиту успішно',
    audit_created: 'Створення аудиту успішно',
    audit_deleted: 'Видалення аудиту успішно',
    audit_proceeded: 'Проведення аудиту успішно',
    audit_finished: 'Завершення аудиту успішно',
    create_audit: 'Створення аудиту',
    edit_audit: 'Редагування аудиту',
    view_audit: 'Перегляд аудиту',
    delete_audit: 'Видалити аудит',
    audits_proceed: 'Проведення аудиту',
    audits_finish: 'Завершення аудиту',

    consistencies: 'Відповідності',

    inconsistencies_navbar: 'Невідповідності та рекламації',
    inconsistency: 'Невідповідність',
    inconsistencies: 'Невідповідності',
    inconsistencies_management: 'Управління невідповідностями',
    inconsistency_updated: 'Оновлення невідповідності успішно',
    inconsistency_created: 'Створення невідповідності успішно',
    inconsistency_deleted: 'Видалення невідповідності успішно',
    create_inconsistency: 'Створення невідповідності',
    edit_inconsistency: 'Редагування невідповідності',
    view_inconsistency: 'Перегляд невідповідності',
    delete_inconsistency: 'Видалити невідповідності',
    sub_inconsistencies: 'Підвиди невідповідності',

    inconsistency_type: 'Тип невідповідності',
    inconsistency_types: 'Типи невідповідності',
    inconsistency_types_management: 'Управління типами невідповідностей',
    inconsistency_type_updated: 'Оновлення типу невідповідністі успішно',
    inconsistency_type_created: 'Створення типу невідповідністі успішно',
    inconsistency_type_deleted: 'Видалення типу невідповідністі успішно',
    create_inconsistency_type: 'Створення типу невідповідністі',
    edit_inconsistency_type: 'Редагування типу невідповідністі',
    view_inconsistency_type: 'Перегляд типу невідповідністі',
    delete_inconsistency_type: 'Видалити тип невідповідністі',
    sub_inconsistency_type: 'Підвиди типу невідповідністі',

    complaint: 'Рекламація',
    complaints: 'Рекламації',
    complaints_management: 'Управління рекламаціями',
    complaint_updated: 'Оновлення рекламації успішно',
    complaint_created: 'Створення рекламації успішно',
    complaint_deleted: 'Видалення рекламації успішно',
    create_complaint: 'Створення рекламації',
    edit_complaint: 'Редагування рекламації',
    view_complaint: 'Перегляд рекламації',
    delete_complaint: 'Видалити рекламацію',

    supervisor: 'Менеджер',
    supervisors: 'Менеджери',
    supervisors_management: 'Управління менеджерами',
    supervisor_updated: 'Оновлення менеджера успішно',
    supervisor_created: 'Створення менеджера успішно',
    supervisor_deleted: 'Видалення менеджера успішно',
    create_supervisor: 'Створення менеджера',
    edit_supervisor: 'Редагування менеджера',
    view_supervisor: 'Перегляд менеджера',
    delete_supervisor: 'Видалити менеджера',

    supervisor_action: ' Дія менеджера',
    supervisor_actions: 'Дії менеджера',
    supervisor_action_updated: 'Оновлення дії менеджера успішно',
    supervisor_action_created: 'Створення дії менеджера успішно',
    supervisor_action_deleted: 'Видалення дії менеджера успішно',
    create_supervisor_action: 'Створення дії менеджера',
    edit_supervisor_action: 'Редагування дії менеджера',
    view_supervisor_action: 'Перегляд дії менеджера',
    delete_supervisor_action: 'Видалити дію менеджера',

    subordinate: 'Підлеглий',
    subordinates: 'Підлеглі',
    subordinates_management: 'Управління підлеглими',
    subordinate_updated: 'Оновлення підлеглого успішно',
    subordinate_created: 'Створення підлеглого успішно',
    subordinate_deleted: 'Видалення підлеглого успішно',
    create_subordinate: 'Створення підлеглого',
    edit_subordinate: 'Редагування підлеглого',
    view_subordinate: 'Перегляд підлеглого',
    delete_subordinates: 'Видалити підлеглого',

    correction: 'Корегуюча дія',
    corrections: 'Корегуюча дії',
    corrections_management: 'Управління корегуючими діями',
    correction_updated: 'Оновлення корегучої дії успішно',
    correction_created: 'Створення корегучої дії успішно',
    correction_deleted: 'Видалення корегучої дії успішно',
    create_correction: 'Створення корегучої дії',
    edit_correction: 'Редагування корегучої дії',
    view_correction: 'Перегляд корегучої дії',
    delete_correction: 'Видалити корегучу дію',

    users: 'Співробітники',
    no_users: 'Співробітників не знайдено',
    users_navbar: 'Робота з співробітниками',
    delete_user: 'Видалити співробітника',
    link_reset_password: 'Надіслати посилання для скидання паролю',
    create_user: 'Створення співробітника',
    edit_user: 'Редагування співробітника',
    view_user: 'Перегляд співробітника',
    user_created: 'Співробітника створено успішно',
    user_updated: 'Співробітника оновлено успішно',
    user_deleted: 'Співробітника видалено успішно',
    users_requests: 'Запити співробітників',

    education: 'Навчання',
    education_management: 'Управління навчанням',
    education_updated: 'Оновлення навчання успішно',
    education_created: 'Створення навчання успішно',
    education_deleted: 'Видалення навчання успішно',
    create_education: 'Створення навчання',
    edit_education: 'Редагування навчання',
    view_education: 'Перегляд навчання',
    delete_education: 'Видалити навчання',
    subject: 'Тематика',
    responsible_and_listeners: 'Відповідальний та слухачі',
    listeners: 'Аудитори',

    provider: 'Постачальник',
    providers: 'Постачальники',
    providers_management: 'Управління постачальниками',
    provider_updated: 'Оновлення постачальника успішно',
    provider_created: 'Створення постачальника успішно',
    provider_deleted: 'Видалення постачальника успішно',
    create_provider: 'Створення постачальника',
    edit_provider: 'Редагування постачальника',
    view_provider: 'Перегляд постачальника',
    delete_provider: 'Видалити постачальника',
    provider_search: 'Пошук (назва, ЄДРПОУ, e-mail, телефон, контакт, юр. адреса, факт. адреса)',
    mark: 'Оцінка',
    marks: 'Оцінки',

    risk_assessment: 'Оцінка ризиків',
    risk_assessments: 'Оцінки ризиків',
    risk_assessments_managment: 'Управління оцінками ризиків',
    risk_assessments_proceed: 'Виконання оцінки ризиків',
    risk_assessments_probability: 'Ймовірність виникнення ризику',
    risk_assessments_consequences: 'Серйозність наслідків',
    risk_assessments_level: 'Рівень ризику',
    risk_assessments_criticality: 'Оцінка критичності',
    risk_assessments_reduce: 'Засоби зниження ризиків',
    risk_assessments_control: 'Засоби контролю критичних параметрів',
    risk_updated: 'Оновлення оцінки ризиків успішно',
    risk_created: 'Створення оцінки ризиків успішно',
    risk_deleted: 'Видалення оцінки ризиків успішно',
    rick_completed: 'Завершення оцінки ризиків успішно',
    create_risk: 'Створення оцінки ризиків',
    edit_risk: 'Редагування оцінки ризиків',
    view_risk: 'Перегляд оцінки ризиків',
    delete_risk: 'Видалити оцінку ризиків',
    risk_not_critical: 'Не критична (прийнятна)',
    risk_medium: 'Середня',
    risk_high: 'Неприйнятна',
    risk_prevent: 'Запобігання ризиками',
    rick_consequence: 'Встановлюються для кожного ризику',
    rick_addition: 'Додавання оцінки ризиків',
    risk_complete: 'Завершити оцінки ризиків',

    components: 'Комплектуючі',

    actions: 'Корегуючі дії',

    reasons: 'Причини',
    analysis: 'Аналіз',
    consequences: 'Наслідки',
    notes: 'Нотатки',
    performer: 'Виконавець',
    seriousness: 'Серйозність',
    probability: 'Ймовірність',
    value: 'Значення',
    library: 'Бібліотека',

    personnel_management: 'Кадрове управління ',

    title: 'Назва',
    yes: 'Так',
    no: 'Ні',
    add: 'Додати +',
    delete: 'Видалити',
    go_back: 'Повернутись',
    save_changes: 'Зберегти Зміни',
    update_info: 'Оновити інформацію',
    apply: 'Відправити',
    search: 'Пошук',

    my_tasks: 'Мої задачі',
    my_completed_tasks: 'Мої виконані задачі',
    task: 'Задача',
    task_comments: 'Коментарі до задачі',
    tasks: 'Задачі',
    tasks_management: 'Управління задачами',
    task_updated: 'Оновлення задачі успішно',
    task_created: 'Створення задачі успішно',
    task_deleted: 'Видалення задачі успішно',
    create_task: 'Створення задачі',
    edit_task: 'Редагування задачі',
    view_task: 'Перегляд задачі',
    delete_task: 'Видалити задачу',
    responsible: 'Відповідальний',
    responsible_and_executor: 'Відповідальний та виконавець',
    users_connected_to_task: "Користувачі пов'язані із завданням",
    executor: 'Виконавець',
    executors: 'Виконавці',
    current_executor: 'Поточний виконавець',
    all_responsible: 'Всі відповідальні',
    confirmation_of_completion: 'Підтвердження про виконання',
    stage: 'Виконання',
    confirm_execution: 'Підтвердити виконання',
    confirmed_execution: 'Підтверджено виконання {date}',
    confirmed_execution_successful: 'Підтверджено виконання',
    return_for_revision: 'Повернути на доопрацювання',
    close_the_task: 'Закрити задачу',

    waiting_for_execution: 'Чекає на виконання',

    add_comment: 'Додати коментар',
    added_comment: 'додав(-ла) коментар',

    add_delegate: 'Призначити виконавця',
    added_delegate_successful: 'Призначння виконавця успішне',
    added_delegates: 'Делегування задачі',

    general_info: 'Загальна інформація',
    contact_info: 'Контактна інформація',
    additional_info: 'Додаткові інформація',
    information: 'Інформація',
    date: 'Дата',
    edit: 'Редагувати',
    no_data: 'Дані відсутні',
    status: 'Статус',
    statuses: 'Статуси',
    priority: 'Пріорітет',
    per_page: 'Кількість записів',
    deadlines: 'Терміни',
    description: 'Опис',
    author: 'Автор',
    reset_filters: 'Cкинути фільтри',
    filter: 'Відфільтрувати',
    company: 'Компанія',
    language: 'Мова',
    contacts: 'Контактні дані',
    contact: 'Контактна особа',
    additional_contacts: 'Додаткові контакти',
    resources: 'Ресурси',
    files: 'Файли',
    downloaded_files: 'Завантажені файли',
    upload: 'Завантажити',
    source: 'Джерело',
    sources: 'Джерела',
    results: 'Результати',
    reset_zoom: 'Скинути масштабування',
    type: 'Тип',

    creation_date: 'Дата створення',
    term: 'Строк виконання',
    terms: 'Строки виконання',
    end_date: 'Кінцева дата',
    completed: 'Дата виконанння',
    date_range: 'Проміжок часу',
    planned_date: 'Запланована дата',
    actual_date: 'Фактична дата',
    sent_at: 'Отримано',
    confirmed_at: 'Підтвердження',
    criteria: 'Критерії',
    conclusions: 'Висновки',
    performance: 'Результативність',
    recommendations: 'Рекомендації',
    receiver: 'Отримувач',
    receivers: 'Отримувачі',

    confirm: 'Підтвердити',
    not_confirmed: 'Не підтверджено',

    skype: 'Skype',
    other: 'Різне',
    quality: 'Якість продукції',
    deadline: 'Строки поставки',
    condition: 'Умови поставки',
    cost: 'Вартість та умови оплати комплектуючих',
    code: 'ЄДРПОУ',
    address: 'Адреса',
    addresses: 'Адреси',
    low: 'Низька',
    medium: 'Середня',
    high: 'Висока',
    complete: 'Виконати',

    legal_address: 'Фактична адреса',

    min_password_length: 'Мінімальна довжина паролю 8',
    uppercase_validation: 'Пароль має містити принаймні одну велику літеру',
    lowercase_validation: 'Пароль повинен містити хоча б одну нижню літеру',

    would_like_to_leave: 'Ви бажаєте вийти?',
    search_components: 'Оберіть комплектуючі',
    search_departments: 'Оберіть відділ',
    search_auditors: 'Оберіть аудиторів',
    search_country: 'Оберіть країну',
    search_status: 'Оберіть статус',
    search_customer: 'Оберіть клієнта',
    search_responsible: 'Оберіть відповідального',
    search_performer: 'Оберіть виконкавця',
    search_product: 'Оберіть групу продукції',
    search_customer_request_source: 'Оберіть джерело запиту клієнта',
    search_customer_request_status: 'Оберіть статус запиту клієнта',
    search_city: 'Оберіть місто',
    search_customer_status: 'Оберіть статус клієнта',
    search_document_type: 'Оберіть тип документа',
    search_inconsistency: 'Оберіть невіпдповідність',
    search_subordinates: 'Оберіть підлеглих',
    search_users: 'Оберіть співробітники',
    search_delegate: 'Оберіть виконавця',
    search_priority: 'Оберіть пріорітет',
    search_roles: 'Оберіть ролі',
    search_supervisors: 'Оберіть менеджерів',

    customers_reports: 'Звіти по клієнтах',
    inconsistencies_reports: 'Звіти по невідповідностях',
    customer_requests_reports: 'Звіти по запитах клієнтів',
    country_report: 'Загальний по країнам',
    status_report: 'Загальний по статусам',
    source_report: 'Загальний по джерелам',
    department_report: 'Загальний по відділам',
    inconsistency_type_report: 'Загальний по типам невідоповідності',

    statuses_dynamic: 'Динаміка по статусам',
    countries_dynamic: 'Динаміка по країнам',
    sources_dynamic: 'Динаміка по джерелам',
    inconsistency_types_dynamic: 'Динаміка по типам невідповідності',
    departments_dynamic: 'Динаміка по відділам',
  },
  [Locales.ENGLISH]: {
    sign_in_header: 'Sign in to the system',
    email: 'Email',
    password: 'Password',
    phone: 'Phone',
    remember_me: 'Remember me',
    sign_in: 'Sign in',
    registered: 'Registered',
    restoration_password: 'Password restoration',
    restoration_email_sent: 'We have sent an email with a link to reset your password',
    restoration_token: 'Token from email',
    restoration_token_question: 'Received an email to reset your password?',
    restoration_success: 'Your password has been reset',

    name: 'Name',
    security: 'Security',

    profile: 'User profile',
    delete_profile: 'Delete profile',
    delete_profile_question: 'Do you want to delete the profile?',

    reset_password: 'Reset password',
    current_password: 'Current password',
    new_password: 'New password',
    confirm_new_password: 'Confirm new password',
    passwords_not_matches: 'Passwords do not match',
    change_password: 'Change password',

    main: 'Dashboard',
    notifications_count: 'Notifications: {current} out of {max}',
    unconfirmed_notifications: 'Unconfirmed notifications',
    no_notifications: 'You have no unconfirmed notifications',
    view: 'View',

    roles: 'Roles',
    role: 'Role',
    role_name: 'Name (system)',
    role_management: 'Role management',
    access: 'Access',
    id: 'ID',
    create_role: 'Create role',
    edit_role: 'Edit role',
    view_role: 'View role',
    delete_role: 'Delete role',
    delete_user_role: 'Delete role from employee',
    role_created: 'Role created successfully',
    role_updated: 'Role updated successfully',
    role_deleted: 'Role deleted successfully',
    granted_rights: 'Granted rights',
    no_granted_rights: 'No rights granted',
    permissions: 'Permissions',
    permissions_for: 'Permissions for:',
    all_permissions: 'All permissions',
    available_permissions: 'Available access rights',

    risks_navbar: 'Risk assessment',
    risks: 'Risks',

    suppliers: 'Suppliers',

    geography: 'Geography',

    country: 'Country',
    countries: 'Countries',
    countries_management: 'Country management',
    country_updated: 'Country updated successfully',
    country_created: 'Country created successfully',
    country_deleted: 'Country deleted successfully',
    create_country: 'Create country',
    edit_country: 'Edit country',
    view_country: 'View country',
    delete_country: 'Delete country',

    document: 'Document',
    documents: 'Documents',
    documents_management: 'Document management',
    document_updated: 'Document updated successfully',
    document_created: 'Document created successfully',
    document_deleted: 'Document deleted successfully',
    create_document: 'Create document',
    edit_document: 'Edit document',
    view_document: 'View document',
    delete_document: 'Delete document',

    document_type: 'Document type',
    document_types: 'Document types',
    document_types_management: 'Document type management',
    document_type_updated: 'Document type updated successfully',
    document_type_created: 'Document type created successfully',
    document_type_deleted: 'Document type deleted successfully',
    create_document_type: 'Create document type',
    edit_document_type: 'Edit document type',
    view_document_type: 'View document type',
    delete_document_type: 'Delete document type',

    complect: 'Component',
    complects: 'Components',
    complects_management: 'Component management',
    complect_updated: 'Component updated successfully',
    complect_created: 'Component created successfully',
    complect_deleted: 'Component deleted successfully',
    create_complect: 'Create component',
    edit_complect: 'Edit component',
    view_complect: 'View component',
    delete_complect: 'Delete component',

    customers_navbar: 'Working with customers',
    customer_status: 'Customer status',
    customer_statuses: 'Customer statuses',
    customer_statuses_management: 'Customer status management',
    customer_status_created: 'Status created successfully',
    customer_status_deleted: 'Status deleted successfully',
    customer_status_updated: 'Status updated successfully',
    create_customer_status: 'Create status',
    edit_customer_status: 'Edit status',
    view_customer_status: 'View status',
    delete_customer_status: 'Delete status',

    city: 'City',
    cities: 'Cities',
    cities_management: 'City management',
    city_updated: 'City updated successfully',
    city_created: 'City created successfully',
    city_deleted: 'City deleted successfully',
    create_city: 'Create city',
    edit_city: 'Edit city',
    view_city: 'View city',
    delete_city: 'Delete city',

    department: 'Department',
    departments: 'Departments',
    departments_management: 'Department management',
    department_updated: 'Department updated successfully',
    department_created: 'Department created successfully',
    department_deleted: 'Department deleted successfully',
    create_department: 'Create department',
    edit_department: 'Edit department',
    view_department: 'View department',
    delete_departments: 'Delete department',

    customer: 'Customer',
    customers: 'Customers',
    customers_management: 'Customer management',
    customer_updated: 'Customer updated successfully',
    customer_created: 'Customer created successfully',
    customer_deleted: 'Customer deleted successfully',
    create_customer: 'Create customer',
    edit_customer: 'Edit customer',
    view_customer: 'View customer',
    delete_customer: 'Delete customer',
    customer_search: 'Search (email, phone, name)',
    create_customer_action: 'Create customer',
    customer_info: 'Customer information',
    customers_dynamic: 'Customers dynamic',

    customer_request: 'Customer request',
    customer_requests: 'Customer requests',
    customer_requests_management: 'Customer request management',
    customer_request_updated: 'Customer request updated successfully',
    customer_request_created: 'Customer request created successfully',
    customer_request_deleted: 'Customer request deleted successfully',
    create_customer_request: 'Create customer request',
    edit_customer_request: 'Edit customer request',
    view_customer_request: 'View customer request',
    delete_customer_request: 'Delete customer request',
    customer_request_details: 'Customer request details',

    customer_request_source: 'Customer request source',
    customer_request_sources: 'Customer request sources',
    customer_request_sources_management: 'Customer request source management',
    customer_request_source_updated: 'Customer request source updated successfully',
    customer_request_source_created: 'Customer request source created successfully',
    customer_request_source_deleted: 'Customer request source deleted successfully',
    create_customer_request_source: 'Create customer request source',
    edit_customer_request_source: 'Edit customer request source',
    view_customer_request_source: 'View customer request source',
    delete_customer_request_source: 'Delete customer request source',

    customer_request_status: 'Customer request status',
    customer_request_statuses: 'Customer request statuses',
    customer_request_statuses_management: 'Customer request status management',
    customer_request_status_updated: 'Customer request status updated successfully',
    customer_request_status_created: 'Customer request status created successfully',
    customer_request_status_deleted: 'Customer request status deleted successfully',
    create_customer_request_status: 'Create customer request status',
    edit_customer_request_status: 'Edit customer request status',
    view_customer_request_status: 'View customer request status',
    delete_customer_request_status: 'Delete customer request status',

    products: 'Products',
    product_group: 'Product group',
    product_groups: 'Product groups',
    product_groups_management: 'Product group management',
    product_group_updated: 'Product group updated successfully',
    product_group_created: 'Product group created successfully',
    product_group_deleted: 'Product group deleted successfully',
    create_product_group: 'Create product group',
    edit_product_group: 'Edit product group',
    view_product_group: 'View product group',
    delete_product_group: 'Delete product group',

    notification_center: 'Notification center',
    self_notifications: 'Your notifications',
    notification: 'Notification',
    notifications: 'Notifications',
    notifications_management: 'Notification management',
    notification_updated: 'Notification updated successfully',
    notification_created: 'Notification created successfully',
    notification_deleted: 'Notification deleted successfully',
    create_notification: 'Create notification',
    edit_notification: 'Edit notification',
    view_notification: 'View notification',
    delete_notification: 'Delete notification',
    confirmed_notification: 'Notification confirmed',

    audit: 'Audit',
    audits: 'Audits',
    audits_management: 'Audit management',
    audit_updated: 'Audit updated successfully',
    audit_created: 'Audit created successfully',
    audit_deleted: 'Audit deleted successfully',
    audit_proceeded: 'Audit proceeded successfully',
    audit_finished: 'Audit finished successfully',
    create_audit: 'Create audit',
    edit_audit: 'Edit audit',
    view_audit: 'View audit',
    delete_audit: 'Delete audit',
    audits_proceed: 'Proceed with audit',
    audits_finish: 'Finish audit',

    consistencies: 'Consistencies',

    inconsistencies_navbar: 'Inconsistencies and complaints',
    inconsistency: 'Inconsistency',
    inconsistencies: 'Inconsistencies',
    inconsistencies_management: 'Inconsistency management',
    inconsistency_updated: 'Inconsistency updated successfully',
    inconsistency_created: 'Inconsistency created successfully',
    inconsistency_deleted: 'Inconsistency deleted successfully',
    create_inconsistency: 'Create inconsistency',
    edit_inconsistency: 'Edit inconsistency',
    view_inconsistency: 'View inconsistency',
    delete_inconsistency: 'Delete inconsistency',
    sub_inconsistencies: 'Sub inconsistencies',

    inconsistency_type: 'Inconsistency type',
    inconsistency_types: 'Inconsistency types',
    inconsistency_types_management: 'Inconsistency type management',
    inconsistency_type_updated: 'Inconsistency type updated successfully',
    inconsistency_type_created: 'Inconsistency type created successfully',
    inconsistency_type_deleted: 'Inconsistency type deleted successfully',
    create_inconsistency_type: 'Create inconsistency type',
    edit_inconsistency_type: 'Edit inconsistency type',
    view_inconsistency_type: 'View inconsistency type',
    delete_inconsistency_type: 'Delete inconsistency type',
    sub_inconsistency_type: 'Sub inconsistency types',

    complaint: 'Complaint',
    complaints: 'Complaints',
    complaints_management: 'Complaints management',
    complaint_updated: 'Complaint updated successfully',
    complaint_created: 'Complaint created successfully',
    complaint_deleted: 'Complaint deleted successfully',
    create_complaint: 'Create complaint',
    edit_complaint: 'Edit complaint',
    view_complaint: 'View complaint',
    delete_complaint: 'Delete complaint',

    supervisor: 'Supervisor',
    supervisors: 'Supervisors',
    supervisors_management: 'Supervisors management',
    supervisor_updated: 'Supervisor updated successfully',
    supervisor_created: 'Supervisor created successfully',
    supervisor_deleted: 'Supervisor deleted successfully',
    create_supervisor: 'Create supervisor',
    edit_supervisor: 'Edit supervisor',
    view_supervisor: 'View supervisor',
    delete_supervisor: 'Delete supervisor',

    supervisor_action: 'Supervisor action',
    supervisor_actions: 'Supervisor actions',
    supervisor_action_updated: 'Supervisor action updated successfully',
    supervisor_action_created: 'Supervisor action created successfully',
    supervisor_action_deleted: 'Supervisor action deleted successfully',
    create_supervisor_action: 'Create supervisor action',
    edit_supervisor_action: 'Edit supervisor action',
    view_supervisor_action: 'View supervisor action',
    delete_supervisor_action: 'Delete supervisor action',

    subordinate: 'Subordinate',
    subordinates: 'Subordinates',
    subordinates_management: 'Subordinates management',
    subordinate_updated: 'Subordinate updated successfully',
    subordinate_created: 'Subordinate created successfully',
    subordinate_deleted: 'Subordinate deleted successfully',
    create_subordinate: 'Create subordinate',
    edit_subordinate: 'Edit subordinate',
    view_subordinate: 'View subordinate',
    delete_subordinates: 'Delete subordinate',

    correction: 'Correction',
    corrections: 'Corrections',
    corrections_management: 'Corrections management',
    correction_updated: 'Correction updated successfully',
    correction_created: 'Correction created successfully',
    correction_deleted: 'Correction deleted successfully',
    create_correction: 'Create correction',
    edit_correction: 'Edit correction',
    view_correction: 'View correction',
    delete_correction: 'Delete correction',

    users: 'Users',
    no_users: 'No users found',
    users_navbar: 'User management',
    delete_user: 'Delete user',
    link_reset_password: 'Send password reset link',
    create_user: 'Create user',
    edit_user: 'Edit user',
    view_user: 'View user',
    user_created: 'User created successfully',
    user_updated: 'User updated successfully',
    user_deleted: 'User deleted successfully',
    users_requests: 'User requests',

    education: 'Education',
    education_management: 'Education management',
    education_updated: 'Education updated successfully',
    education_created: 'Education created successfully',
    education_deleted: 'Education deleted successfully',
    create_education: 'Create education',
    edit_education: 'Edit education',
    view_education: 'View education',
    delete_education: 'Delete education',
    subject: 'Subject',
    responsible_and_listeners: 'Responsible and listeners',
    listeners: 'Listeners',

    provider: 'Provider',
    providers: 'Providers',
    providers_management: 'Providers management',
    provider_updated: 'Provider updated successfully',
    provider_created: 'Provider created successfully',
    provider_deleted: 'Provider deleted successfully',
    create_provider: 'Create provider',
    edit_provider: 'Edit provider',
    view_provider: 'View provider',
    delete_provider: 'Delete provider',
    provider_search: 'Search (name, EDRPOU, email, phone, contact, legal address, actual address)',
    mark: 'Mark',
    marks: 'Marks',

    risk_assessment: 'Risk Assessment',
    risk_assessments: 'Risk Assessments',
    risk_assessments_managment: 'Risk Assessments Management',
    risk_assessments_proceed: 'Performing a risk assessment',
    risk_assessments_probability: 'Probability of risk',
    risk_assessments_consequences: 'The seriousness of the consequences',
    risk_assessments_level: 'Risk level',
    risk_assessments_criticality: 'Assessment of criticality',
    risk_assessments_reduce: 'Means of reducing risks',
    risk_assessments_control: 'Means of control of critical parameters',
    risk_updated: 'Risk Assessment Successfully Updated',
    risk_created: 'Risk Assessment Successfully Created',
    risk_deleted: 'Risk Assessment Successfully Deleted',
    rick_completed: 'Risk Assessment Successfully Updated',
    create_risk: 'Create Risk Assessment',
    edit_risk: 'Edit Risk Assessment',
    view_risk: 'View Risk Assessment',
    delete_risk: 'Delete Risk Assessment',
    risk_not_critical: 'Not critical (acceptable)',
    risk_medium: 'Average',
    risk_high: 'Unacceptable',
    risk_prevent: 'Prevention of risks',
    rick_consequence: 'Set for each risk',
    rick_complete: 'Complete Risk Assessment',

    components: 'Components',

    actions: 'Actions',

    reasons: 'Reasons',
    analysis: 'Analysis',
    consequences: 'Consequences',
    notes: 'Notes',
    performer: 'Performer',
    seriousness: 'Seriousness',
    probability: 'Probability',
    value: 'Value',
    library: 'Library',

    personnel_management: 'Personnel management',

    title: 'Title',
    yes: 'Yes',
    no: 'No',
    add: 'Add +',
    delete: 'Delete',
    go_back: 'Go back',
    save_changes: 'Save changes',
    update_info: 'Update info',
    apply: 'Apply',
    search: 'Search',

    my_tasks: 'My tasks',
    my_completed_tasks: 'My completed tasks',
    task: 'Task',
    task_comments: 'Task comments',
    tasks: 'Tasks',
    tasks_management: 'Tasks management',
    task_updated: 'Task updated successfully',
    task_created: 'Task created successfully',
    task_deleted: 'Task deleted successfully',
    create_task: 'Create task',
    edit_task: 'Edit task',
    view_task: 'View task',
    delete_task: 'Delete task',
    responsible: 'Responsible',
    responsible_and_executor: 'Responsible and executor',
    users_connected_to_task: 'Users connected to the task',
    executor: 'Executor',
    executors: 'Executors',
    current_executor: 'Current executor',
    all_responsible: 'All responsible',
    confirmation_of_completion: 'Confirmation of completion',
    stage: 'Stage',
    confirm_execution: 'Confirm execution',
    confirmed_execution: 'Execution confirmed {date}',
    confirmed_execution_successful: 'Execution confirmed',
    return_for_revision: 'Return for revision',
    close_the_task: 'Close the task',

    waiting_for_execution: 'Waiting for execution',

    add_comment: 'Add comment',
    added_comment: 'added a comment',

    add_delegate: 'Add delegate',
    added_delegate_successful: 'Delegate added successfully',
    added_delegates: 'Task delegates',

    general_info: 'General information',
    contact_info: 'Contact information',
    additional_info: 'Additional information',
    information: 'Information',
    date: 'Date',
    edit: 'Edit',
    no_data: 'No data',
    status: 'Status',
    statuses: 'Statuses',
    priority: 'Priority',
    per_page: 'Per page',
    deadlines: 'Deadlines',
    description: 'Description',
    author: 'Author',
    reset_filters: 'Reset filters',
    filter: 'Filter',
    company: 'Company',
    language: 'Language',
    contacts: 'Contacts',
    contact: 'Contact person',
    additional_contacts: 'Additional contacts',
    resources: 'Resources',
    files: 'Files',
    downloaded_files: 'Downloaded files',
    upload: 'Upload',
    source: 'Source',
    sources: 'Sources',
    results: 'Results',
    reset_zoom: 'Reset zoom',
    type: 'Type',

    creation_date: 'Creation date',
    term: 'Term',
    terms: 'Terms',
    end_date: 'End date',
    completed: 'Completed',
    date_range: 'Date range',
    planned_date: 'Planned date',
    actual_date: 'Actual date',
    sent_at: 'Sent at',
    confirmed_at: 'Confirmed at',
    criteria: 'Criteria',
    conclusions: 'Conclusions',
    performance: 'Performance',
    recommendations: 'Recommendations',
    receiver: 'Receiver',
    receivers: 'Receivers',

    confirm: 'Confirm',
    not_confirmed: 'Not confirmed',

    skype: 'Skype',
    other: 'Other',
    quality: 'Product quality',
    deadline: 'Delivery deadlines',
    condition: 'Delivery conditions',
    cost: 'Cost and payment terms of components',
    code: 'EDRPOU',
    address: 'Address',
    addresses: 'Addresses',
    low: 'Low',
    medium: 'Medium',
    high: 'High',
    complete: 'Perform',

    legal_address: 'Legal address',

    min_password_length: 'Minimum password length is 8',
    uppercase_validation: 'Password must contain at least one uppercase letter',
    lowercase_validation: 'Password must contain at least one lowercase letter',

    would_like_to_leave: 'Would you like to leave?',
    search_components: 'Choose components',
    search_departments: 'Choose department',
    search_auditors: 'Choose auditors',
    search_country: 'Choose country',
    search_customer: 'Choose customer',
    search_responsible: 'Choose responsible',
    search_performer: 'Choose performer',
    search_product: 'Choose product group',
    search_customer_request_source: 'Choose customer request source',
    search_customer_request_status: 'Choose customer request status',
    search_city: 'Choose city',
    search_customer_status: 'Choose customer status',
    search_document_type: 'Choose document type',
    search_inconsistency: 'Choose inconsistency',
    search_subordinates: 'Choose subordinates',
    search_users: 'Choose users',
    search_priority: 'Choose priority',
    search_roles: 'Choose roles',
    search_status: 'Choose status',
    search_supervisors: 'Choose supervisors',

    country_report: 'General by country',
    status_report: 'General by status',
    source_report: 'General by source',
    department_report: 'General by department',
    inconsistency_type_report: 'General by inconsistency type',
    customers_reports: 'Customers Reports',
    inconsistencies_reports: 'Inconsistencies Reports',
    customer_requests_reports: 'Customer Requests Reports',

    statuses_dynamic: 'Dynamic by statuses',
    countries_dynamic: 'Dynamic by countries',
    sources_dynamic: 'Dynamic by sources',
    inconsistency_types_dynamic: 'Dynamic by inconsistency type',
    departments_dynamic: 'Dynamic by departments',
  },
};
