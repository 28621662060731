import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { CUSTOMER_REQUEST_COLUMNS } from 'constants/customer-request.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteCustomerRequest, asyncGetCustomerRequests } from 'modules/customer-requests/action';
import {
  selectCustomerRequests,
  selectCustomerRequestsCount,
  selectIsLoading,
} from 'modules/customer-requests/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CustomerRequestsFilters } from 'page-components/customers-requests/CustomerRequestsFilters';
import { CustomerRequestsTableRow } from 'page-components/customers-requests/CustomerRequestsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const CustomerRequests = () => {
  const [customersRequestId, setCustomersRequestId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const customerRequests = useAppSelector(selectCustomerRequests);
  const customerRequestsCount = useAppSelector(selectCustomerRequestsCount);

  const { search } = useLocation();
  const {
    country,
    startDate,
    endDate,
    search: searchQuery = '',
    responsible,
    requestSource,
    requestStatus,
    product,
    customerStatus,
  } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setCustomersRequestId(id);
    handleOpenModal();
  };

  const onCustomerRequestDelete = async () => {
    if (customersRequestId) {
      await dispatch(asyncDeleteCustomerRequest(+customersRequestId));
      await dispatch(asyncGetCustomerRequests({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const dates = startDate && endDate ? `${startDate ?? ''}-${endDate ?? ''}` : '';
    const customersQueryParams = {
      search: searchQuery,
      country,
      dates,
      responsible,
      requestSource,
      requestStatus,
      product,
      customerStatus,
    } as Record<string, string>;

    dispatch(
      asyncGetCustomerRequests({
        page,
        perPage: 10,
        ...customersQueryParams,
      }),
    );
  }, [
    dispatch,
    page,
    country,
    startDate,
    endDate,
    searchQuery,
    responsible,
    requestSource,
    requestStatus,
    product,
    customerStatus,
  ]);

  useQueryParams({ page });

  return (
    <PageLayout title='customer_requests' wrapperClassName='flex flex-col gap-4'>
      <CustomerRequestsFilters />
      <CommonLayoutTable
        title='customer_requests_management'
        columns={CUSTOMER_REQUEST_COLUMNS}
        creationPath={AppRoutes.customerRequestCreate}
        isLoading={isLoading}
      >
        {customerRequests?.length ? (
          customerRequests.map((customerRequest) => (
            <CustomerRequestsTableRow
              key={customerRequest.id}
              data={customerRequest}
              handleDelete={onModalOpen(customerRequest.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMER_REQUEST_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={customerRequestsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCustomerRequestDelete}
        title='delete_customer_request'
      />
    </PageLayout>
  );
};
