import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.customersReducer;

export const selectCustomer = createSelector(selectState, ({ customer }) => customer);

export const selectCustomers = createSelector(selectState, ({ customers }) => customers);

export const selectCustomersCount = createSelector(selectState, ({ customersCount }) => customersCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
