import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  AuditCarryingPayload,
  AuditFinishPayload,
  AuditPayload,
  AuditResponse,
  AuditsResponse,
  UpdateAuditPayload,
} from 'interfaces/audits.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class AuditsApi {
  constructor(private url: string) {}

  getAudits(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<AuditsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getAuditById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<AuditResponse>(`${this.url}/${payload}`, config);
  }

  updateAudit({ id, ...data }: UpdateAuditPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<AuditResponse>(`${this.url}/${id}`, data, config);
  }

  createAudit(data: AuditPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<AuditResponse>(`${this.url}`, data, config);
  }

  proceedAudit({ id, ...data }: AuditCarryingPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<AuditResponse>(`${this.url}/${id}/proceed`, data, config);
  }

  finishAudit({ id, ...data }: AuditFinishPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<AuditResponse>(`${this.url}/${id}/complete`, data, config);
  }

  deleteAudit(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const auditsApi = new AuditsApi('/audits');
