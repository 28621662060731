import { createSlice } from '@reduxjs/toolkit';

import { Department } from 'interfaces/departments.interfaces';

import { DEPARTMENTS_SLICE_NAME } from './action';
import {
  createDepartmentReducer,
  deleteDepartmentReducer,
  getDepartmentReducer,
  getDepartmentsReducer,
  unsetDepartmentReducer,
  updateDepartmentReducer,
} from './reducers';

export interface DepartmentsSliceState {
  isLoading: boolean;
  department: Department | null;
  departments: Department[] | null;
  departmentsCount: number | null;
}

export const initialState: DepartmentsSliceState = {
  department: null,
  departments: null,
  departmentsCount: null,
  isLoading: false,
};

const departmentsSlice = createSlice({
  name: DEPARTMENTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createDepartmentReducer(builder);
    deleteDepartmentReducer(builder);
    getDepartmentReducer(builder);
    getDepartmentsReducer(builder);
    unsetDepartmentReducer(builder);
    updateDepartmentReducer(builder);
  },
});

export const departmentsReducer = departmentsSlice.reducer;
