import { useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';

import { MANAGER_ACTIONS_COLUMNS } from 'constants/manager-actions.constants';
import { useOpen } from 'hooks/useOpen';
import { asyncGetCustomerRequest } from 'modules/customer-requests/action';
import { selectCustomerRequest } from 'modules/customer-requests/selectors';
import { asyncDeleteManagerAction } from 'modules/manager-actions/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components/Button';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { Modal } from 'shared-components/Modal';
import { Table } from 'shared-components/table/Table';
import { TableNoData } from 'shared-components/table/TableNoData';

import { ManagerActionForm } from './ManagerActionForm';
import { ManagerActionsTableRow } from './ManagerActionsTableRow';

export const ManagerActionsTable = () => {
  const { id } = useParams();
  const [managerActionId, setManagerActionId] = useState<number>();

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useAppDispatch();

  const customerRequest = useAppSelector(selectCustomerRequest);

  const [isDeleteModalOpened, handleOpenDeleteModal, handleCloseDeleteModal] = useOpen();
  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const onModalOpen = (id: number) => () => {
    setManagerActionId(id);
    handleOpenDeleteModal();
  };

  const onManagerActionDelete = async () => {
    if (managerActionId && id) {
      await dispatch(asyncDeleteManagerAction({ requestId: id, commentId: `${managerActionId}` }));
      await dispatch(asyncGetCustomerRequest(id));
      handleCloseDeleteModal();
    }
  };

  const onEditOpenModal = (id: number) => () => {
    setManagerActionId(id);
    handleOpenModal();
  };

  const onEditCloseModal = () => {
    searchParams.delete('view');
    setSearchParams(searchParams);
    handleCloseModal();
  };

  if (!customerRequest) return null;

  return (
    <>
      <Card title='supervisor_actions' className='flex flex-col gap-5'>
        <Table columns={MANAGER_ACTIONS_COLUMNS}>
          {customerRequest.comments?.length ? (
            customerRequest.comments.map((managerAction) => (
              <ManagerActionsTableRow
                key={managerAction.id}
                data={managerAction}
                handleOpen={onEditOpenModal(managerAction.id)}
                handleDelete={onModalOpen(managerAction.id)}
              />
            ))
          ) : (
            <TableNoData colSpan={MANAGER_ACTIONS_COLUMNS.length} />
          )}
        </Table>
        <Button className='self-start' data-test='add-item' onClick={handleOpenModal}>
          <FormattedMessage id='add' />
        </Button>
      </Card>
      <ConfirmationModal
        isOpened={isDeleteModalOpened}
        handleCloseModal={handleCloseDeleteModal}
        handleApply={onManagerActionDelete}
        title='delete_supervisor_action'
      />
      <Modal isOpened={isModalOpened} handleClose={onEditCloseModal}>
        <ManagerActionForm managerActionId={managerActionId} handleCloseModal={onEditCloseModal} />
      </Modal>
    </>
  );
};
