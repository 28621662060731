import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CustomerRequest } from 'interfaces/customer-requests.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { formatToDefaultDate, parseDefaultDate } from 'utils/date.utils';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: CustomerRequest;
}

export const CustomerRequestsTableRow: FC<Props> = ({
  handleDelete,
  data: { id, updatedAt, productGroups, responsible, requestStatus },
}) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.customerRequests, id);

  const creationDate = parseDefaultDate(updatedAt);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='customerRequests.update'
          deletePermission='customerRequests.delete'
          viewPermission='customerRequests.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{productGroups.map(({ name }) => name)}</TableData>
      <TableData className='last:text-left'>{responsible.name}</TableData>
      <TableData className='last:text-left'>{requestStatus.name}</TableData>
      <TableData className='last:text-left'>{formatToDefaultDate(creationDate)}</TableData>
    </TableRow>
  );
};
