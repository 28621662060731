import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { DOCUMENTS_COLUMNS } from 'constants/documents.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteDocument, asyncGetDocuments } from 'modules/documents/action';
import { selectDocumentCount, selectDocuments, selectIsLoading } from 'modules/documents/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DocumentsFilters } from 'page-components/documents/DocumentsFilters';
import { DocumentsTableRow } from 'page-components/documents/DocumentsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Documents = () => {
  const [documentId, setDocumentId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const documents = useAppSelector(selectDocuments);
  const documentsCount = useAppSelector(selectDocumentCount);

  const { search } = useLocation();
  const { documentType, search: searchQuery = '' } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setDocumentId(id);
    handleOpenModal();
  };

  const onDocumentDelete = async () => {
    if (documentId) {
      await dispatch(asyncDeleteDocument(+documentId));
      await dispatch(asyncGetDocuments({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const queryParams = {
      search: searchQuery,
      documentType,
    } as Record<string, string>;

    dispatch(
      asyncGetDocuments({
        page,
        perPage: 10,
        ...queryParams,
      }),
    );
  }, [dispatch, page, documentType, searchQuery]);

  useQueryParams({ page });

  return (
    <PageLayout title='documents' wrapperClassName='flex flex-col gap-4'>
      <DocumentsFilters />
      <CommonLayoutTable
        title='documents_management'
        columns={DOCUMENTS_COLUMNS}
        creationPath={AppRoutes.documentsCreate}
        isLoading={isLoading}
      >
        {documents?.length ? (
          documents.map((document) => (
            <DocumentsTableRow key={document.id} data={document} handleDelete={onModalOpen(document.id)} />
          ))
        ) : (
          <TableNoData colSpan={DOCUMENTS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={documentsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onDocumentDelete}
        title='delete_document'
      />
    </PageLayout>
  );
};
