import { EMAIL_PATTERN, PHONE_PATTERN } from 'constants/patterns.contants';

export const REQUIRED_VALIDATION = {
  required: "Обов'язкове поле",
};

export const EMAIL_VALIDATION = {
  ...REQUIRED_VALIDATION,
  pattern: { message: 'Невірна пошта', value: EMAIL_PATTERN },
};

export const PASSWORD_VALIDATION = {
  ...REQUIRED_VALIDATION,
  validate: (value: string) => {
    const hasUpperCase = /[A-Z]/.test(value);
    const hasLowerCase = /[a-z]/.test(value);

    if (!hasUpperCase) {
      return 'uppercase_validation';
    }

    if (!hasLowerCase) {
      return 'lowercase_validation';
    }

    return undefined;
  },
  minLength: {
    value: 8,
    message: 'min_password_length',
  },
};

export const PHONE_VALIDATION = {
  pattern: { message: 'Невірний телефон', value: PHONE_PATTERN },
};
