import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { RiskForm } from 'page-components/risks/RiskForm';

export const Risk = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.riskCreate ? 'create_risk' : 'edit_risk';
  const titleText = pathname.includes('view') ? 'view_risk' : editTitle;

  return (
    <PageLayout title={titleText}>
      <RiskForm />
    </PageLayout>
  );
};
