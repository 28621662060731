import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Locales } from 'i18n/locales';
import { Self } from 'interfaces/self.interfaces';
import {
  asyncLogOut,
  asyncSignIn,
  getSelf,
  setUser,
  unsetUser,
  unsetIsAuthorized,
  asyncSendResetPasswordEmail,
  asyncSendResetPassword,
  toggleLocale,
} from 'modules/user/action';
import { UserSliceState } from 'modules/user/slice';
import { withLoadingReducer } from 'utils/reducers.utils';

export function signInReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncSignIn.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncSignIn.fulfilled, (state) => {
    state.isAuth = true;
    state.isLoading = false;
  });

  builder.addCase(asyncSignIn.rejected, (state) => {
    state.isAuth = false;
    state.isLoading = false;
  });
}

export function logOutReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(asyncLogOut.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncLogOut.fulfilled, (state) => {
    state.isAuth = false;
    state.isLoading = false;
  });

  builder.addCase(asyncLogOut.rejected, (state) => {
    state.isLoading = false;
  });
}

export const sendResetPasswordEmailReducer = withLoadingReducer(asyncSendResetPasswordEmail);

export const sendResetPasswordReducer = withLoadingReducer(asyncSendResetPassword);

export function getSelfReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(getSelf.fulfilled, (state, action: PayloadAction<Self>) => {
    state.currentUser = action.payload;
    state.isLoading = false;
    state.isAuth = true;
  });

  builder.addCase(getSelf.rejected, (state) => {
    state.isAuth = false;
    state.isLoading = false;
  });
}

export function setUserReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(setUser, (state, action: PayloadAction<Self>) => {
    state.currentUser = action.payload;
  });
}

export function unsetIsAuthorizedReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(unsetIsAuthorized, (state) => {
    state.isAuth = false;
  });
}

export function unsetUserReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(unsetUser, (state) => {
    state.isAuth = false;
    state.currentUser = null;
  });
}

export function changeLocaleReducer(builder: ActionReducerMapBuilder<UserSliceState>) {
  builder.addCase(toggleLocale, (state, { payload }: PayloadAction<Locales>) => {
    state.locale = payload;
  });
}
