import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CustomerRequestSourceForm } from 'page-components/customer-request-source/CustomerRequestSourceForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const CustomerRequestSource = () => {
  const { pathname } = useLocation();

  const editTitle =
    pathname === AppRoutes.customerRequestSourceCreate
      ? 'create_customer_request_source'
      : 'edit_customer_request_source';
  const titleText = pathname.includes('view') ? 'view_customer_request_source' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <CustomerRequestSourceForm />
    </PageLayout>
  );
};
