import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { NOTIFICATIONS_COLUMNS } from 'constants/notifications.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteNotification, asyncGetNotifications } from 'modules/notifications/action';
import { selectIsLoading, selectNotificationCount, selectNotifications } from 'modules/notifications/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { NotificationsFilters } from 'page-components/notifications/NotificationsFilters';
import { NotificationsTableRow } from 'page-components/notifications/NotificationsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Notifications = () => {
  const [notificationsId, setNotificationsId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const notifications = useAppSelector(selectNotifications);
  const notificationsCount = useAppSelector(selectNotificationCount);

  const { search } = useLocation();
  const { department, search: searchQuery = '' } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setNotificationsId(id);
    handleOpenModal();
  };

  const onDocumentDelete = async () => {
    if (notificationsId) {
      await dispatch(asyncDeleteNotification(+notificationsId));
      await dispatch(asyncGetNotifications({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const queryParams = {
      search: searchQuery,
      department,
    } as Record<string, string>;

    dispatch(
      asyncGetNotifications({
        page,
        perPage: 10,
        ...queryParams,
      }),
    );
  }, [dispatch, page, department, searchQuery]);

  useQueryParams({ page });

  return (
    <PageLayout title='notifications' wrapperClassName='flex flex-col gap-4'>
      <NotificationsFilters />
      <CommonLayoutTable
        title='notifications_management'
        columns={NOTIFICATIONS_COLUMNS}
        creationPath={AppRoutes.notificationsCreate}
        isLoading={isLoading}
      >
        {notifications?.length ? (
          notifications.map((notification) => (
            <NotificationsTableRow
              key={notification.id}
              data={notification}
              handleDelete={onModalOpen(notification.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={NOTIFICATIONS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={notificationsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onDocumentDelete}
        title='delete_notification'
      />
    </PageLayout>
  );
};
