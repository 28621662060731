import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  ProviderInputPayload,
  ProviderResponse,
  ProvidersResponse,
  UpdateProvider,
} from 'interfaces/providers.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class ProvidersApi {
  constructor(private url: string) {}

  getProviders({ complects, ...query }: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<ProvidersResponse>(
      `${this.url}?${generateQueryString(query ?? {})}${complects?.length ? `&complects[]=${complects}` : ''}`,
      config,
    );
  }

  getProviderById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<ProviderResponse>(`${this.url}/${payload}`, config);
  }

  updateProvider({ id, ...data }: UpdateProvider, config?: AxiosRequestConfig) {
    return httpApiClient.put<ProviderResponse>(`${this.url}/${id}`, data, config);
  }

  createProvider(data: ProviderInputPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ProviderResponse>(`${this.url}`, data, config);
  }

  deleteProvider(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const providersApi = new ProvidersApi('/suppliers');
