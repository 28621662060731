import { useMemo } from 'react';

import { ReactComponent as LoadingIcon } from 'assets/svg/loading.svg';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { Report, ReportDate } from 'interfaces/reports.interfaces';
import { unsetReport } from 'modules/reports/action';
import { selectIsLoading, selectReports } from 'modules/reports/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Chart } from 'shared-components/Chart';
import { getBarChart, getDatesBarChart } from 'utils/chart.utils';

interface Props {
  isDates?: boolean;
}

export const BarReportChart = ({ isDates }: Props) => {
  const dispatch = useAppDispatch();

  const report = useAppSelector(selectReports);
  const isLoading = useAppSelector(selectIsLoading);

  const config = useMemo(
    () => report && (isDates ? getDatesBarChart(report as ReportDate) : getBarChart(report as Report)),
    [isDates, report],
  );

  useBeforeRouteChange(() => dispatch(unsetReport()));

  return (
    <div className='flex flex-col gap-8'>
      {isLoading && (
        <div className='p-8 flex items-center justify-center'>
          <LoadingIcon className='w-16 h-16' />
        </div>
      )}
      {report && config && <Chart config={config} />}
    </div>
  );
};
