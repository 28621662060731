import { createSlice } from '@reduxjs/toolkit';

import { Report, ReportDate } from 'interfaces/reports.interfaces';

import { REPORTS_SLICE_NAME } from './action';
import {
  getCustomerReportsReducer,
  getCustomerRequestsDynamicReportsReducer,
  getCustomerRequestsReportsReducer,
  getCustomersDynamicReportsReducer,
  getInconsistenciesDynamicReportsReducer,
  getInconsistenciesReportsReducer,
  unsetReportReducer,
} from './reducers';

export interface ReportsSliceState {
  isLoading: boolean;
  reports: Report | ReportDate | null;
}

export const initialState: ReportsSliceState = {
  reports: null,
  isLoading: false,
};

const reportsSlice = createSlice({
  name: REPORTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getCustomerReportsReducer(builder);
    getCustomerRequestsReportsReducer(builder);
    getInconsistenciesReportsReducer(builder);
    getCustomersDynamicReportsReducer(builder);
    getCustomerRequestsDynamicReportsReducer(builder);
    getInconsistenciesDynamicReportsReducer(builder);
    unsetReportReducer(builder);
  },
});

export const reportsReducer = reportsSlice.reducer;
