import { createSlice } from '@reduxjs/toolkit';

import { Task } from 'interfaces/tasks.interfaces';

import { TASKS_SLICE_NAME } from './action';
import {
  deleteTaskReducer,
  createTaskReducer,
  getTaskReducer,
  getTasksReducer,
  unsetTaskReducer,
  updateTaskReducer,
  addCommentTaskReducer,
  confirmByOwnerReducer,
  confirmByPerformerReducer,
  changePerformerReducer,
  unsetTasksReducer,
} from './reducers';

export interface TasksSliceState {
  isLoading: boolean;
  task: Task | null;
  tasks: Task[] | null;
  tasksCount: number | null;
}

export const initialState: TasksSliceState = {
  task: null,
  tasks: null,
  tasksCount: null,
  isLoading: false,
};

const tasksSlice = createSlice({
  name: TASKS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    confirmByPerformerReducer(builder);
    confirmByOwnerReducer(builder);
    changePerformerReducer(builder);
    createTaskReducer(builder);
    deleteTaskReducer(builder);
    getTasksReducer(builder);
    getTaskReducer(builder);
    unsetTaskReducer(builder);
    unsetTasksReducer(builder);
    updateTaskReducer(builder);
    addCommentTaskReducer(builder);
  },
});

export const tasksReducer = tasksSlice.reducer;
