import { useEffect } from 'react';

export const useLocalStorage = (onStorageChange: VoidFunction) => {
  useEffect(() => {
    window.addEventListener('sessionStorageChange', onStorageChange);

    return () => {
      window.removeEventListener('sessionStorageChange', onStorageChange);
    };
  }, [onStorageChange]);
};
