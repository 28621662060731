import { TableColumn } from './shared/table.constants';

export const EDUCATIONS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'planned_date', title: 'planned_date' },
  { id: 'actual_date', title: 'actual_date' },
  { id: 'subject', title: 'subject' },
  { id: 'responsible', title: 'responsible', className: 'last:text-left' },
];
