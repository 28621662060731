import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Card } from 'shared-components/Card';
import { TitleWithValue } from 'shared-components/TitleWithValue';

interface Props {
  content: Record<string, string | undefined>;
  title: string;
}

export const CardInfoItem: FC<Props> = ({ content, title }) => {
  return (
    <Card title={title} wrapperClassName='flex flex-col flex-1 self-start' className='grid grid-cols-2 gap-8'>
      {Object.keys(content).map((contentKey) => (
        <div key={contentKey} className='flex flex-col gap-2'>
          <TitleWithValue className='text-mediumBlue' title={contentKey} />
          <span>{content[contentKey] ? content[contentKey] : <FormattedMessage id='no_data' />}</span>
        </div>
      ))}
    </Card>
  );
};
