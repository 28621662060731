import { AppRoutes } from 'constants/routes-paths.constants';

export const getViewRoute = (route: AppRoutes, id: number) => `${route}/view/${id}`;

export const getEditRoute = (route: AppRoutes, id: number) => `${route}/${id}`;

export const getProceedRoute = (route: AppRoutes, id: number) => `${route}/${id}/proceed`;

export const getCommonRoutes = (route: AppRoutes, id: number) => ({
  editRoute: getEditRoute(route, id),
  viewRoute: getViewRoute(route, id),
});
