import { FC } from 'react';

import { NotificationsListItem } from 'page-components/dashboard/notifications/NotificationsListItem';

interface Props {
  notifications?: string[];
  isLoading?: boolean;
  emptyText?: string;
}

export const NotificationsList: FC<Props> = ({ notifications, isLoading, emptyText }) => {
  if (isLoading) return <div>Loading....</div>;

  if (!notifications?.length) return <p className='text-center'>{emptyText || 'Ви не маєте сповіщень'}</p>;

  return (
    <div className='overflow-x-auto'>
      <table className='w-full border-spacing-y-px border-separate'>
        <tbody>
          {notifications.map((notification) => (
            <NotificationsListItem key={notification} />
          ))}
        </tbody>
      </table>
    </div>
  );
};
