import { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { NOTIFICATIONS_FILTERS } from 'constants/notifications.constants';
import { useNotificationsDefaultOptions } from 'hooks/useNotificationsDefaultOptions';
import { useQueryParams } from 'hooks/useQueryParams';
import { NotificationFiltersInput } from 'interfaces/notifications.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { FilterSelects } from 'shared-components/FilterSelects';
import { Input } from 'shared-components/Input';

export const NotificationsFilters = () => {
  const { handleSubmit, register, control, reset } = useForm<NotificationFiltersInput>();

  const [searchParams, setSearchParams] = useSearchParams();

  const { formatMessage } = useIntl();

  const filterSelects = useNotificationsDefaultOptions();

  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const onSubmit: SubmitHandler<NotificationFiltersInput> = (data) => {
    const department = data.departmentSelect?.value as number;

    const queryParams = {
      department,
      search: data?.search,
    };

    setQueryParams(queryParams);
  };

  const handleResetFilters = () => {
    setSearchParams({});

    reset({
      search: '',
      departmentSelect: { value: '', label: '' },
    });
  };

  useEffect(() => {
    reset({
      search: searchParams.get('search') ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelects]);

  useQueryParams(queryParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className='flex flex-col gap-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 gap-2 items-end'>
          {filterSelects && (
            <FilterSelects filterSelects={filterSelects} data={NOTIFICATIONS_FILTERS} control={control} />
          )}
          <Input
            {...register('search')}
            label='search'
            className='flex-1'
            autoComplete='off'
            placeholder={formatMessage({ id: 'search' })}
          />
        </div>
        <div className='flex gap-4'>
          <Button variant={ButtonVariants.CANCEL_OUTLINED} onClick={handleResetFilters}>
            <FormattedMessage id='reset_filters' />
          </Button>
          <Button type='submit'>
            <FormattedMessage id='filter' />
          </Button>
        </div>
      </Card>
    </form>
  );
};
