import { FC } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { LinkVariants } from 'interfaces/shared/link.interfaces';

import { Button } from './Button';
import { Link } from './Link';

interface Props {
  navigateBack: string;
  className?: string;
  confirmLabel?: string;
  btnClassName?: string;
}

export const ItemNavigation: FC<Props> = ({ navigateBack, className, btnClassName, confirmLabel }) => {
  const { pathname } = useLocation();
  const isView = pathname.includes('/view/');

  return (
    <div className={cn(twMerge('flex gap-4 items-center', className))}>
      <Link
        data-test='go-back'
        to={navigateBack}
        variant={LinkVariants.CANCEL_OUTLINED}
        className={cn(
          'w-fit relative disabled:pointer-events-none flex items-center py-2.5 px-4 rounded-lg transition-all flex-1 md:flex-grow-0',
        )}
      >
        <FormattedMessage id='go_back' />
      </Link>
      <Button
        disabled={isView}
        type='submit'
        data-test='save-changes'
        className={twMerge(cn('flex-1 md:flex-grow-0 whitespace-nowrap', btnClassName))}
      >
        <FormattedMessage id={confirmLabel ? confirmLabel : 'save_changes'} />
      </Button>
    </div>
  );
};
