import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';

import { PROFILE_INFO_FIELDS } from 'constants/profile.constants';
import { ProfileInput } from 'interfaces/profile.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Button } from 'shared-components/Button';
import { FormFields } from 'shared-components/FormFields';

export const ProfileInfoForm = () => {
  const user = useAppSelector(selectCurrentUser);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ProfileInput>({
    defaultValues: {
      name: user?.name,
      email: user?.email,
    },
  });

  const onSubmit: SubmitHandler<ProfileInput> = () => toast.success('User Updated');

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
      <FormFields fields={PROFILE_INFO_FIELDS} register={register} errors={errors} />
      <Button className='self-center' type='submit' data-test='update-profile'>
        <FormattedMessage id='update_info' />
      </Button>
    </form>
  );
};
