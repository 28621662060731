import { useEffect, useState } from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import qs from 'query-string';
import { useLocation, useSearchParams } from 'react-router-dom';

import { CUSTOMERS_REPORT_FILTERS, getCustomerReportsFilters } from 'constants/reports.constants';
import { CustomerRequestReportTypes } from 'interfaces/reports.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { asyncGetCountries } from 'modules/countries/action';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { asyncGetCustomerReports, asyncGetCustomersDynamicReports } from 'modules/reports/action';
import { useAppDispatch } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { BarReportChart } from 'page-components/reports/BarReportChart';
import { LineReportChart } from 'page-components/reports/LineReportChart';
import { ReportFilters } from 'page-components/reports/ReportFilters';
import { ReportTypes } from 'page-components/reports/ReportTypes';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const CustomerReports = () => {
  const dispatch = useAppDispatch();

  const [searchParams] = useSearchParams();
  const { search } = useLocation();

  const filterKey = searchParams.get('type');
  const { country = '', status = '', startDate, endDate } = qs.parse(search);

  const [fields, setFields] = useState<Record<string, Field[]>>({});

  useEffect(() => {
    if (filterKey) {
      const commonData = {
        reportType: filterKey.split('_')[0],
        dates: startDate && endDate ? `${startDate ?? ''} - ${endDate ?? ''}` : '',
      };

      dispatch(
        !filterKey.includes('dynamic')
          ? asyncGetCustomerReports({
              ...commonData,
              country: `${country}`,
              status: `${status}`,
            })
          : (asyncGetCustomersDynamicReports(commonData) as unknown as AnyAction),
      );
    }
  }, [country, dispatch, endDate, filterKey, startDate, status]);

  useEffect(() => {
    if (filterKey?.includes('dynamic')) return;

    const isCountry = filterKey === CustomerRequestReportTypes.countries;

    dispatch(isCountry ? asyncGetCountries({}) : asyncGetCustomerStatuses({}))
      .unwrap()
      .then(({ data }) => {
        const formattedOptions = convertObjectsToSelectOption(data);
        setFields(
          getCustomerReportsFilters(
            formattedOptions,
            formattedOptions.find(({ value }) =>
              isCountry ? +value === +(country ?? -1) : +value === +(status ?? -1),
            ),
          ),
        );
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filterKey]);

  return (
    <PageLayout title='customers_reports' wrapperClassName='flex flex-col gap-4'>
      <ReportTypes filters={CUSTOMERS_REPORT_FILTERS} />
      <ReportFilters fields={filterKey && fields[filterKey] ? fields[filterKey] : []} />
      {filterKey?.includes('dynamic') ? <LineReportChart isDates /> : <BarReportChart />}
    </PageLayout>
  );
};
