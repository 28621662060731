import { AuditCarryingForm } from 'page-components/audits/AuditCarryingForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const AuditCarrying = () => {
  return (
    <PageLayout title='audits_proceed'>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
        <AuditCarryingForm />
      </div>
    </PageLayout>
  );
};
