import { FC } from 'react';

import cn from 'classnames';

import { ReactComponent as CautionSvg } from 'assets/svg/caution.svg';
import { ReactComponent as ConfirmSvg } from 'assets/svg/confirm.svg';
import { ReactComponent as ProceedSvg } from 'assets/svg/forward.svg';
import { AuditStatuses } from 'constants/audits.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { Audit } from 'interfaces/audits.interfaces';
import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { Link } from 'shared-components/Link';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { TitleWithTooltip } from 'shared-components/TitleWithTooltip';
import { getAuditFinishRoute, getAuditProceedRoute } from 'utils/audit.utils';
import { formatToDefaultDate, parseDefaultDate } from 'utils/date.utils';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Audit;
}

export const AuditsTableRow: FC<Props> = ({
  handleDelete,
  data: { id, plannedAt, responsible, criteria, departments, auditors, status },
}) => {
  const auditProceedRoute = getAuditProceedRoute(id);
  const auditFinishRoute = getAuditFinishRoute(id);
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.audits, id);

  const startDate = parseDefaultDate(plannedAt);

  return (
    <TableRow>
      <TableData>
        <div className='flex'>
          <ColumnDefaultControls
            handleDelete={handleDelete}
            editRoute={editRoute}
            editClassName={status !== AuditStatuses.created ? 'hidden' : ''}
            editPermission='audits.update'
            viewRoute={viewRoute}
            deletePermission='audits.delete'
            deleteClassName={status === AuditStatuses.created ? '' : 'hidden'}
            viewPermission='audits.view'
          />
          <Link
            variant={LinkVariants.TRANSPARENT}
            className={cn('px-2 items-center group hidden', { flex: status === AuditStatuses.created })}
            to={auditProceedRoute}
            data-test='edit-item'
          >
            <ProceedSvg className='transition-all w-4 h-4 fill-mediumBlue group-hover:stroke-extraLightBlue/50' />
          </Link>
          <Link
            variant={LinkVariants.TRANSPARENT}
            className={cn('px-2 hidden items-center group', { flex: status === AuditStatuses.proceed })}
            to={auditFinishRoute}
            data-test='edit-item'
          >
            <ConfirmSvg className='transition-all fill-mediumBlue w-4 h-4 group-hover:stroke-extraLightBlue/50' />
          </Link>
        </div>
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{formatToDefaultDate(startDate)}</TableData>
      <TableData>{criteria}</TableData>
      <TableData>
        <TitleWithTooltip
          className='justify-center'
          id={`${id}-1`}
          icon={CautionSvg}
          tooltipChildren={departments.map((department) => (
            <p key={department.id}>{department.name}</p>
          ))}
        />
      </TableData>
      <TableData>
        <TitleWithTooltip
          className='justify-center'
          id={`${id}-2`}
          icon={CautionSvg}
          tooltipChildren={auditors.map((auditor) => (
            <p key={auditor.id}>{auditor.name}</p>
          ))}
        />
      </TableData>
      <TableData className='last:text-left'>{responsible.name}</TableData>
    </TableRow>
  );
};
