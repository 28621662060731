import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { USERS_COLUMNS } from 'constants/users.constants';
import { Self } from 'interfaces/self.interfaces';
import { Table } from 'shared-components/table/Table';

import { UsersTableRow } from './UsersTableRow';

interface Props {
  handleOpenDeleteModal: (id: string) => VoidFunction;
  users: Self[];
}

export const UsersTable: FC<Props> = ({ handleOpenDeleteModal, users }) => {
  if (!users)
    return (
      <p className='flex items-center justify-center text-center'>
        <FormattedMessage id='no_users' />
      </p>
    );

  return (
    <div data-test='users-table'>
      <Table columns={USERS_COLUMNS}>
        {users.map((user) => (
          <UsersTableRow handleOpenDeleteModal={handleOpenDeleteModal(`${user.id}`)} key={user.id} data={user} />
        ))}
      </Table>
    </div>
  );
};
