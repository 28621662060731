import { FC, ReactNode } from 'react';

import cn from 'classnames';
import ListPagination from 'rc-pagination';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as ArrowIcon } from 'assets/svg/right-vector.svg';
import { PAGINATION_ELEMENT_TYPE } from 'constants/shared/pagination.constants';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';

import { Button } from './Button';

interface Props {
  current: number;
  pageSize?: number;
  total: number;
  onChange: (page: number) => void;
  className?: string;
}

const ARROW_STYLES = 'p-1.5 border border-mediumBlue hover:bg-extraLightBlue';

export const Pagination: FC<Props> = ({ current, pageSize = 15, total, onChange, className }) => {
  const paginationRender = (_: number, type: string, element: ReactNode) => {
    if (type === PAGINATION_ELEMENT_TYPE.prev || type === PAGINATION_ELEMENT_TYPE.next) {
      return (
        <Button variant={ButtonVariants.SECONDARY} className={ARROW_STYLES}>
          <ArrowIcon className={cn({ 'rotate-180': type === 'prev' })} />
        </Button>
      );
    }

    if (type === PAGINATION_ELEMENT_TYPE.jumpNext || type === PAGINATION_ELEMENT_TYPE.jumpPrev) {
      return <span>...</span>;
    }
    return element;
  };

  return (
    <ListPagination
      data-test='pagination'
      hideOnSinglePage
      current={current}
      showLessItems={true}
      pageSize={pageSize}
      className={twMerge(
        cn(
          'flex gap-4 justify-center items-center [&>*]:flex [&>*]:items-center [&>*]:justify-center [&>*]:w-2.5 [&>*]:h-2.5 [&>*]:cursor-pointer [&>*]:p-5',
          className,
        ),
      )}
      total={total}
      onChange={onChange}
      itemRender={paginationRender}
    />
  );
};
