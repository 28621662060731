import { useIntl } from 'react-intl';

import { NotificationsList } from 'page-components/dashboard/notifications/NotificationsList';
import { Card } from 'shared-components/Card';

export const Notifications = () => {
  const { formatMessage } = useIntl();
  return (
    <div className='w-full lg:flex mt-4 space-y-4 lg:space-y-0 lg:space-x-4'>
      <Card wrapperClassName='lg:w-1/2 h-fit' title='unconfirmed_notifications'>
        <NotificationsList emptyText={formatMessage({ id: 'no_notifications' })} />
      </Card>
      <Card wrapperClassName='lg:w-1/2 h-fit' className='pt-0.5' title='notifications' withoutSpacing>
        <NotificationsList notifications={['1', '2', '3']} />
      </Card>
    </div>
  );
};
