import { ElementType, FC, ReactNode } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Tooltip } from 'react-tooltip';
import { twMerge } from 'tailwind-merge';

interface Props {
  id: string;
  text?: string;
  icon?: ElementType;
  tooltipChildren?: ReactNode;
  className?: string;
}

export const TitleWithTooltip: FC<Props> = ({ text, id, icon: Icon, tooltipChildren, className }) => {
  return (
    <div className={twMerge(cn('flex gap-2 items-center', className))}>
      {text && (
        <span>
          <FormattedMessage id={text} defaultMessage='' />
        </span>
      )}

      {tooltipChildren && (
        <>
          {Icon && <Icon className='cursor-pointer w-6' data-tooltip-id={id} data-test='tooltip-icon' />}
          <Tooltip data-test={id} id={id} className='z-50'>
            {tooltipChildren}
          </Tooltip>
        </>
      )}
    </div>
  );
};
