import { FC, ReactNode } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';
interface Props {
  children: ReactNode;
  className?: string;
  titleClassName?: string;
  wrapperClassName?: string;
  withoutSpacing?: boolean;
  withoutBorder?: boolean;
  title?: string;
}

export const Card: FC<Props> = ({
  children,
  className,
  titleClassName,
  withoutSpacing,
  withoutBorder,
  title,
  wrapperClassName,
}) => {
  return (
    <div
      className={twMerge(
        cn('border border-gray2/60 rounded-10 overflow-hidden', { 'border-none': withoutBorder }, wrapperClassName),
      )}
    >
      {title && (
        <div className={twMerge(cn('py-3 px-5 text-black font-bold', titleClassName))}>
          <h4>
            <FormattedMessage id={title} defaultMessage={title} />
          </h4>
        </div>
      )}
      <div className={twMerge(cn('p-5 w-full', { 'p-0': withoutSpacing }, className))}>{children}</div>
    </div>
  );
};
