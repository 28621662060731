import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CustomerRequestStatusForm } from 'page-components/customer-request-statuses/CustomerRequestStatusForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const CustomerRequestStatus = () => {
  const { pathname } = useLocation();

  const editTitle =
    pathname === AppRoutes.customerRequestStatusCreate
      ? 'create_customer_request_status'
      : 'edit_customer_request_status';
  const titleText = pathname.includes('view') ? 'view_customer_request_status' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <CustomerRequestStatusForm />
    </PageLayout>
  );
};
