import { useEffect, useState } from 'react';

import { CustomerSelects } from 'interfaces/customers.interfaces';
import { asyncGetCities } from 'modules/cities/action';
import { asyncGetCountries } from 'modules/countries/action';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useCustomerDefaultOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<CustomerSelects>();

  useEffect(() => {
    dispatch(asyncGetCities({}))
      .unwrap()
      .then((response) => {
        const city = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, city }));
      });

    dispatch(asyncGetCountries({}))
      .unwrap()
      .then((response) => {
        const country = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, country }));
      });

    dispatch(asyncGetCustomerStatuses({}))
      .unwrap()
      .then((response) => {
        const status = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, status }));
      });
  }, [dispatch]);

  return filterSelects;
};
