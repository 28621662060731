import { RISKS_ASSESSMENTS_PREVENT_COLUMNS } from 'constants/risks.constsnts';
import { selectRisk } from 'modules/risks/selectors';
import { useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Table } from 'shared-components/table/Table';
import { TableNoData } from 'shared-components/table/TableNoData';

import { RiskPreventTableRow } from './RiskPreventTableRow';

export const RiskPreventTable = () => {
  const risk = useAppSelector(selectRisk);

  if (!risk) return null;

  return (
    <Card title='risk_prevent' className='flex flex-col gap-5'>
      <Table columns={RISKS_ASSESSMENTS_PREVENT_COLUMNS}>
        {risk?.risks?.length ? (
          risk.risks.map((risk) => <RiskPreventTableRow key={risk.id} data={risk} />)
        ) : (
          <TableNoData colSpan={RISKS_ASSESSMENTS_PREVENT_COLUMNS.length} />
        )}
      </Table>
    </Card>
  );
};
