import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Card } from 'shared-components/Card';

export const ProfileAbout = () => {
  const user = useAppSelector(selectCurrentUser);

  if (!user) return null;
  return (
    <Card title='information'>
      <span>Ролі</span>
      <ul className='list-disc p-4'>
        {user.permissions.map((permissionName) => (
          <li key={permissionName}>{permissionName}</li>
        ))}
      </ul>
    </Card>
  );
};
