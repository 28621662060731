import { FC, useState } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as LogoutIcon } from 'assets/svg/exit.svg';
import { ReactComponent as HomeSvg } from 'assets/svg/home.svg';
import { ReactComponent as ProfileSvg } from 'assets/svg/profile.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncLogOut } from 'modules/user/action';
import { selectIsLoading } from 'modules/user/selectors';
import { RoutesItem } from 'page-components/page-layout/PagesCommonLayout';
import { Button } from 'shared-components/Button';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Logo } from 'shared-components/Logo';

interface Props {
  isOpen: boolean;
  navigationList: RoutesItem[];
  activeId: string;
  handleCloseSidebar: VoidFunction;
}

export const Sidebar: FC<Props> = ({ isOpen, navigationList, handleCloseSidebar, activeId }) => {
  const [, setActivePermission] = useState('');

  const event = new Event('sessionStorageChange');

  const isLoading = useAppSelector(selectIsLoading);

  const dispatch = useAppDispatch();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [isLogoutModelOpened, handleOpenLogoutModel, handleCloseLogoutModel] = useOpen();

  const handleGoDashboard = () => {
    setActivePermission('');
    sessionStorage.setItem('sidebar', '');
    window.dispatchEvent(event);
    navigate(AppRoutes.dashboard);

    if (window.innerWidth < 1024) {
      handleCloseSidebar();
    }
  };

  const handleLogout = () => {
    dispatch(asyncLogOut());
  };

  return (
    <>
      <aside
        className={twMerge(
          cn(
            'flex flex-col justify-between fixed -left-[94px] transition-all duration-300 lg:left-0 top-0 w-16 h-screen bg-deepBlue [&_svg]:w-8 [&_svg]:h-8 z-30',
            {
              'left-0': isOpen,
            },
          ),
        )}
      >
        <div className='flex flex-col'>
          <div className='flex justify-center mt-4 mb-20 sm:mb-40 px-2'>
            <Logo onlyIcon />
          </div>
          <ul className='relative flex flex-col gap-8'>
            <li
              className={cn('z-10 hover:bg-lightBlue', {
                'sidebar-active rounded-full translate-x-7 bg-lightBlue': pathname === AppRoutes.dashboard && !activeId,
              })}
            >
              <Button
                onClick={handleGoDashboard}
                variant={ButtonVariants.TRANSPARENT}
                className={cn('w-16 h-16 flex justify-center items-center transition-all')}
              >
                <HomeSvg />
              </Button>
            </li>
            {navigationList?.map(({ id, navigation, icon: Icon }) => {
              const elementPaths = Object.values(navigation).flatMap((subArray) => subArray.map((item) => item.path));
              const isSelectedByPath = elementPaths.some((path) => pathname === path);

              const isSelected = activeId ? activeId === id : isSelectedByPath;
              return (
                <li
                  key={id}
                  className={cn(
                    'flex justify-center z-10 items-center w-16 h-16 transition-all cursor-pointer hover:bg-lightBlue',
                    {
                      'sidebar-active rounded-full translate-x-7 bg-lightBlue': isSelected,
                    },
                  )}
                >
                  <Button
                    onClick={() => {
                      setActivePermission(id);
                      sessionStorage.setItem('sidebar', id);
                      window.dispatchEvent(event);
                      navigate(pathname);
                    }}
                    variant={ButtonVariants.TRANSPARENT}
                    className='flex justify-center items-center z-10'
                  >
                    <div className='relative'>
                      <Icon className='[&_g]:fill-white' />
                      {elementPaths.some((path) => AppRoutes.users === path) && (
                        <div className='bg-red h-2 w-2 rounded-full absolute -top-2 -right-2' />
                      )}
                    </div>
                  </Button>
                </li>
              );
            })}
            <li
              className={cn(
                'flex justify-center z-10 items-center transition-all w-16 h-16 cursor-pointer hover:bg-lightBlue',
                {
                  'sidebar-active rounded-full translate-x-7 bg-lightBlue': pathname === AppRoutes.profile && !activeId,
                },
              )}
            >
              <Button
                variant={ButtonVariants.TRANSPARENT}
                className='flex justify-center items-center'
                onClick={() => {
                  sessionStorage.setItem('sidebar', '');
                  const event = new Event('sessionStorageChange');
                  window.dispatchEvent(event);
                  navigate(AppRoutes.profile);
                }}
              >
                <ProfileSvg className='fill-white' />
              </Button>
            </li>
            <div className='absolute indicator transition-all' />
          </ul>
        </div>
        <Button onClick={handleOpenLogoutModel} className='mb-2' variant={ButtonVariants.TRANSPARENT}>
          <LogoutIcon className='[&_g]:fill-white' />
        </Button>
      </aside>
      <ConfirmationModal
        title='would_like_to_leave'
        isOpened={isLogoutModelOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseLogoutModel}
        handleApply={handleLogout}
      />
    </>
  );
};
