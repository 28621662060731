import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.inconsistencyTypeReducer;

export const selectInconsistencyType = createSelector(selectState, ({ inconsistencyType }) => inconsistencyType);

export const selectInconsistencyTypes = createSelector(selectState, ({ inconsistencyTypes }) => inconsistencyTypes);

export const selectInconsistencyTypesCount = createSelector(
  selectState,
  ({ inconsistencyTypesCount }) => inconsistencyTypesCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
