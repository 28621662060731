import { useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { AuthLayout } from 'page-components/auth/AuthLayout';
import { EmailResetPassword } from 'page-components/reset-password/EmailResetPassword';
import { ResetPasswordForm } from 'page-components/reset-password/ResetPasswordForm';

export const ResetPassword = () => {
  const { token } = useParams();

  return (
    <AuthLayout title='restoration_password' additionalLink={AppRoutes.signIn} linkText='sign_in_header'>
      <>{token ? <ResetPasswordForm /> : <EmailResetPassword />}</>
    </AuthLayout>
  );
};
