import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { EducationForm } from 'page-components/educations/EducationForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Education = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.userCreate ? 'create_education' : 'edit_education';
  const titleText = pathname.includes('view') ? 'view_education' : editTitle;

  return (
    <PageLayout title={titleText}>
      <EducationForm />
    </PageLayout>
  );
};
