import { FC } from 'react';

import { useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Inconsistency } from 'interfaces/inconsistencies.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { formatToDefaultDate, parseDefaultDate } from 'utils/date.utils';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Inconsistency;
}

export const InconsistenciesTableRow: FC<Props> = ({
  handleDelete,
  data: { id: inconsistencyId, createdAt, description, actions, department, inconsistencyType, owner },
}) => {
  const { id } = useParams();

  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.inconsistencies, inconsistencyId);

  const creationDate = parseDefaultDate(createdAt);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={`${editRoute}${id ? `&provider=${id}` : ''}`}
          viewRoute={`${viewRoute}${id ? `&provider=${id}` : ''}`}
          editPermission='inconsistencies.update'
          deletePermission='inconsistencies.delete'
          viewPermission='inconsistencies.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{formatToDefaultDate(creationDate)}</TableData>
      <TableData>{description}</TableData>
      <TableData>{actions}</TableData>
      <TableData>{department.name}</TableData>
      <TableData>{inconsistencyType.name}</TableData>
      <TableData className='last:text-left'>{owner.name}</TableData>
    </TableRow>
  );
};
