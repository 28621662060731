import { useEffect, useState } from 'react';

import { DEPARTMENTS_COLUMNS } from 'constants/departments.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteDepartment, asyncGetDepartments } from 'modules/departments/action';
import { selectDepartments, selectDepartmentsCount, selectIsLoading } from 'modules/departments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DepartmentsTableRow } from 'page-components/departments/DepartmentsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Departments = () => {
  const [departmentsId, setDepartmentsId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const departments = useAppSelector(selectDepartments);
  const departmentsCount = useAppSelector(selectDepartmentsCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setDepartmentsId(id);
    handleOpenModal();
  };

  const onDepartmentDelete = async () => {
    if (departmentsId) {
      await dispatch(asyncDeleteDepartment(departmentsId));
      await dispatch(asyncGetDepartments({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetDepartments({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='departments' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='departments_management'
        columns={DEPARTMENTS_COLUMNS}
        creationPath={AppRoutes.departmentCreate}
        isLoading={isLoading}
      >
        {departments?.length ? (
          departments.map((department) => (
            <DepartmentsTableRow key={department.id} data={department} handleDelete={onModalOpen(department.id)} />
          ))
        ) : (
          <TableNoData colSpan={DEPARTMENTS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={departmentsCount ?? 0} onChange={handlePageChange} />
      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onDepartmentDelete}
        title='delete_departments'
      />
    </PageLayout>
  );
};
