import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.departmentsReducer;

export const selectDepartment = createSelector(selectState, ({ department }) => department);

export const selectDepartments = createSelector(selectState, ({ departments }) => departments);

export const selectDepartmentsCount = createSelector(selectState, ({ departmentsCount }) => departmentsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
