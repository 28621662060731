import { createSlice } from '@reduxjs/toolkit';

import { Notification } from 'interfaces/notifications.interfaces';

import { NOTIFICATIONS_SLICE_NAME } from './action';
import {
  createNotificationReducer,
  deleteNotificationReducer,
  unsetNotificationReducer,
  getNotificationReducer,
  getNotificationsReducer,
  updateNotificationReducer,
  confirmNotificationReducer,
} from './reducers';

export interface NotificationsSliceState {
  isLoading: boolean;
  notification: Notification | null;
  notifications: Notification[] | null;
  notificationsCount: number | null;
}

export const initialState: NotificationsSliceState = {
  notification: null,
  notifications: null,
  notificationsCount: null,
  isLoading: false,
};

const notificationsSlice = createSlice({
  name: NOTIFICATIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createNotificationReducer(builder);
    deleteNotificationReducer(builder);
    updateNotificationReducer(builder);
    getNotificationsReducer(builder);
    getNotificationReducer(builder);
    unsetNotificationReducer(builder);
    confirmNotificationReducer(builder);
  },
});

export const notificationsReducer = notificationsSlice.reducer;
