import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useEducationOptions } from 'hooks/useEducationOptions';
import { EducationInput, Listener } from 'interfaces/educations.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  responsibleId?: number;
  defaultListeners?: Listener[];
}

export const EducationVisitorFields: FC<Props> = ({ defaultListeners, responsibleId }) => {
  const { listenersOptions, defaultListenersOptions, defaultResponsibleOption } = useEducationOptions(
    defaultListeners,
    responsibleId,
  );

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<EducationInput>();

  return (
    <Card title='responsible_and_listeners' wrapperClassName='overflow-visible flex-1' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='users'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultListenersOptions}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['users']?.message}
            defaultValue={defaultListenersOptions}
            isMulti
            isRequired
            label='listeners'
            options={listenersOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_auditors' })}
          />
        )}
      />
      <Controller
        control={control}
        name='responsible'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultResponsibleOption}
        render={({ field: { onChange, value } }) => (
          <Select
            isRequired
            label='responsible'
            error={errors['responsible']?.message}
            defaultValue={defaultResponsibleOption}
            options={listenersOptions ?? []}
            value={value}
            onChange={onChange}
            placeholder={formatMessage({ id: 'search_responsible' })}
          />
        )}
      />
    </Card>
  );
};
