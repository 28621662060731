import { ReactComponent as CautionSvg } from 'assets/svg/caution.svg';
import { RiskConsequence, RiskProbability } from 'interfaces/risks.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { RiskConsequencesTable } from 'page-components/risks/RiskConsequencesTable';
import { RiskLevelCalculationInfo } from 'page-components/risks/RiskLevelCalculationInfo';
import { RiskProbabilityTable } from 'page-components/risks/RiskProbabilityTable';

import { TableColumn } from './shared/table.constants';
import { REQUIRED_VALIDATION } from './validation-rules.constants';

export enum RiskStatuses {
  created = 'created',
  proceed = 'proceed',
  completed = 'completed',
}

export const RISKS_ASSESSMENTS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'name', title: 'name' },
  { id: 'responsible', title: 'responsible' },
  { id: 'department', title: 'department', className: 'last:text-left' },
];

export const RISKS_ASSESSMENTS_MARKS_COLUMNS: TableColumn[] = [
  { id: 'description', title: 'description' },
  {
    id: 'risk_assessments_probability',
    title: 'risk_assessments_probability',
    tooltipChildren: RiskProbabilityTable,
    icon: CautionSvg,
  },
  {
    id: 'risk_assessments_consequences',
    title: 'risk_assessments_consequences',
    tooltipChildren: RiskConsequencesTable,
    icon: CautionSvg,
  },
  {
    id: 'risk_assessments_level',
    title: 'risk_assessments_level',
    tooltipChildren: RiskLevelCalculationInfo,
    icon: CautionSvg,
  },
  {
    id: 'risk_assessments_criticality',
    title: 'risk_assessments_criticality',
    className: 'last:text-left',
    tooltipChildren: RiskProbabilityTable,
    icon: CautionSvg,
  },
];

export const RISKS_ASSESSMENTS_PREVENT_COLUMNS: TableColumn[] = [
  { id: 'description', title: 'description' },
  { id: 'risk_assessments_reduce', title: 'risk_assessments_reduce', className: 'text-center' },
  { id: 'risk_assessments_control', title: 'risk_assessments_control', className: 'text-center' },
  { id: 'notes', title: 'notes', className: 'last:text-left' },
];

export const RISKS_CONSEQUENCES_COLUMNS: TableColumn[] = [
  { id: 'mark', title: 'mark', className: 'text-white first:text-center' },
  { id: 'seriousness', title: 'seriousness', className: 'text-white' },
  { id: 'consequences', title: 'consequences', className: 'last:text-left, text-white' },
];

export const RISKS_PROBABILITIES_COLUMNS: TableColumn[] = [
  { id: 'mark', title: 'mark', className: 'text-white first:text-center' },
  { id: 'probability', title: 'probability', className: 'text-white' },
  { id: 'value', title: 'value', className: 'last:text-left, text-white' },
];

export const RISKS_CONSEQUENCES: RiskConsequence[] = [
  {
    seriousness: 'low',
    mark: 1,
    consequences: 'rick_consequence',
  },
  {
    seriousness: 'medium',
    mark: 2,
    consequences: 'rick_consequence',
  },
  {
    seriousness: 'high',
    mark: 3,
    consequences: 'rick_consequence',
  },
];

export const RISKS_PROBABILITIES: RiskProbability[] = [
  {
    probability: 'low',
    mark: 1,
    value: '[0.1-10]%',
  },
  {
    probability: 'medium',
    mark: 2,
    value: '(10-60]%',
  },
  {
    probability: 'high',
    mark: 3,
    value: '(60-100]%',
  },
];

export const RISKS_MARKS_FIELDS: Field[] = [
  {
    name: 'probabilityRate',
    options: {},
    type: 'range',
    max: 3,
    min: 1,
    'data-test': 'risk-probability',
    label: 'risk_assessments_probability',
    tooltipChildren: RiskProbabilityTable,
    icon: CautionSvg,
  },
  {
    name: 'seriousnessRate',
    options: {},
    type: 'range',
    max: 3,
    min: 1,
    'data-test': 'risk-consequences',
    label: 'risk_assessments_consequences',
    tooltipChildren: RiskConsequencesTable,
    icon: CautionSvg,
  },
];

export const RISKS_PROCEED_FIELDS: Field[] = [
  {
    name: 'howToDecrease',
    options: REQUIRED_VALIDATION,
    'data-test': 'risk-reduce',
    label: 'risk_assessments_reduce',
  },
  {
    name: 'howToControl',
    options: REQUIRED_VALIDATION,
    'data-test': 'risk-control',
    label: 'risk_assessments_control',
  },
];
