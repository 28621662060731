import { Permission } from 'interfaces/permissions.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';

export const getObjectKeysAsNumber = (value: Record<string, string | boolean | SelectOption | SelectOption[]>) => {
  return Object.keys(value)
    .map((key) => Object.values(value[key]).filter((value) => !isNaN(parseInt(value))))
    .flat()
    .map((value) => +value);
};

export const getSubGroups = (permissions: Record<string, Permission[]>, permissionGroup: string) => {
  const subGroup = permissions[permissionGroup].map(({ name }) => name.split('.')[0]);
  const uniqueSubGroups = new Set(subGroup);

  return Array.from(uniqueSubGroups);
};

export const toUpperCaseFirstLater = (str: string) => str.charAt(0).toUpperCase() + str.slice(1);
export const replaceCamelCase = (str: string) => str.replace(/([a-z])([A-Z])/g, '$1 $2');
