import { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useComplaintDates } from 'hooks/useComplaintDates';
import { ComplaintInput } from 'interfaces/complaints.interfaces';
import {
  asyncCreateComplaint,
  asyncGetComplaint,
  asyncUpdateComplaint,
  unsetComplaint,
} from 'modules/complaints/action';
import { selectComplaint } from 'modules/complaints/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { formatToDefaultDate, formatToInputDate, parseDefaultDate } from 'utils/date.utils';

import { ComplaintAdditionalFields } from './ComplaintAdditionalFields';
import { ComplaintDatesFields } from './ComplaintDatesFields';
import { ComplaintGeneralFields } from './ComplaintGeneralFields';
import { ComplaintResponsible } from './ComplaintResponsible';

export const ComplaintForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const isViewMode = pathname.includes('view');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const complaint = useAppSelector(selectComplaint);

  const methods = useForm<ComplaintInput>();

  const { reset, handleSubmit } = methods;

  const defaultOptions = useComplaintDates(reset, complaint);

  const onSubmit: SubmitHandler<ComplaintInput> = ({
    customer: customerOption,
    responsible: responsibleOption,
    finishedAt: finished,
    ...data
  }) => {
    const customer = customerOption.value as number;
    const responsible = responsibleOption.value as number;
    const finishedAt = formatToDefaultDate(finished as unknown as Date);

    const formattedData = { customer, responsible, finishedAt, ...data };

    dispatch(id ? asyncUpdateComplaint({ id: +id, ...formattedData }) : asyncCreateComplaint(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.complaints);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetComplaint()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetComplaint(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && complaint) {
      reset({
        description: complaint.description,
        customer: { value: complaint.customer.id, label: complaint.customer.name },
        responsible: { value: complaint.responsible.id, label: complaint.responsible.name },
        finishedAt: formatToInputDate(parseDefaultDate(complaint.finishedAt)),
        operations: complaint.operations,
        resources: complaint.resources,
      });
    }
  }, [id, reset, complaint]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <ComplaintGeneralFields defaultCustomer={complaint?.customer.id} />
          </div>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <ComplaintDatesFields defaultCompletedDate={defaultOptions?.completed} isEditable={!isViewMode} />
            <ComplaintResponsible />
          </div>
        </div>
        <ComplaintAdditionalFields />
        <ItemNavigation navigateBack={AppRoutes.complaints} />
      </FormProvider>
    </form>
  );
};
