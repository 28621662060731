import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ReactComponent as TimeSvg } from 'assets/svg/time.svg';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CommentInput } from 'interfaces/tasks.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncAddCommentTask, asyncGetTask } from 'modules/tasks/action';
import { selectTask } from 'modules/tasks/selectors';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Textarea } from 'shared-components/Textarea';

import { TaskCommentsList } from './TaskCommentsList';

export const TaskComments = () => {
  const { id } = useParams();

  const task = useAppSelector(selectTask);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<CommentInput>();

  const onSubmit: SubmitHandler<CommentInput> = async (data) => {
    if (!id) return;

    await dispatch(asyncAddCommentTask({ id, ...data }));
    await dispatch(asyncGetTask(id));
  };

  return (
    <Card title='task_comments' className='flex flex-col gap-4' wrapperClassName='flex-1 self-stretch md:self-start'>
      {task?.comments?.length ? (
        <div className='flex flex-col overflow-hidden'>
          <TaskCommentsList comments={task.comments} />
          <div className='self-start p-1 rounded-xl bg-white relative'>
            <TimeSvg className='shrink-0 stroke-mediumBlue w-6 h-6' />
            <div className='absolute bg-lightBlue w-2 left-1/2 -z-10 bottom-0 h-screen -translate-x-1/2' />
          </div>
        </div>
      ) : null}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Textarea
          {...register('content', REQUIRED_VALIDATION)}
          isRequired
          label='description'
          error={errors['content']?.message}
          placeholder={formatMessage({ id: 'description' })}
          data-test='task-description'
          rows={4}
        />
        <Button type='submit'>
          <FormattedMessage id='add_comment' />
        </Button>
      </form>
    </Card>
  );
};
