import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customersApi } from 'apis/customers.apis';
import { CustomerPayload, CustomerUpdate } from 'interfaces/customers.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CUSTOMERS_SLICE_NAME = 'customers';

export const asyncGetCustomers = createAsyncThunk(
  `${CUSTOMERS_SLICE_NAME}/getCustomers`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await customersApi.getCustomers(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomer = createAsyncThunk(
  `${CUSTOMERS_SLICE_NAME}/getCustomer`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await customersApi.getCustomerById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCustomer = createAsyncThunk(
  `${CUSTOMERS_SLICE_NAME}/updateCustomer`,
  async (payload: CustomerUpdate, { rejectWithValue }) => {
    try {
      const response = await customersApi.updateCustomer(payload);
      toast.success(intl.formatMessage({ id: 'customer_updated' }));
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCustomer = createAsyncThunk(
  `${CUSTOMERS_SLICE_NAME}/createCustomer`,
  async (payload: CustomerPayload, { rejectWithValue }) => {
    try {
      const response = await customersApi.createCustomer(payload);
      toast.success(intl.formatMessage({ id: 'customer_created' }));
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCustomer = createAsyncThunk(
  `${CUSTOMERS_SLICE_NAME}/deleteCustomer`,
  async (id: number, { rejectWithValue }) => {
    try {
      await customersApi.deleteCustomer(id);
      toast.success(intl.formatMessage({ id: 'customer_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCustomer = createAction('UNSET_CUSTOMER');
