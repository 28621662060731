import { createSlice } from '@reduxjs/toolkit';

import { Complaint } from 'interfaces/complaints.interfaces';

import { COMPLAINTS_SLICE_NAME } from './action';
import {
  createComplaintReducer,
  deleteComplaintReducer,
  getComplaintReducer,
  getComplaintsReducer,
  unsetComplaintReducer,
  updateComplaintReducer,
} from './reducers';

export interface ComplaintsSliceState {
  isLoading: boolean;
  complaint: Complaint | null;
  complaints: Complaint[] | null;
  complaintsCount: number | null;
}

export const initialState: ComplaintsSliceState = {
  complaint: null,
  complaints: null,
  complaintsCount: null,
  isLoading: false,
};

const complaintsSlice = createSlice({
  name: COMPLAINTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createComplaintReducer(builder);
    deleteComplaintReducer(builder);
    getComplaintReducer(builder);
    getComplaintsReducer(builder);
    unsetComplaintReducer(builder);
    updateComplaintReducer(builder);
  },
});

export const complaintsReducer = complaintsSlice.reducer;
