import { useState } from 'react';

import { useParams } from 'react-router-dom';

import { INCONSISTENCIES_COLUMNS } from 'constants/inconsistencies.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { asyncDeleteInconsistency } from 'modules/inconsistencies/action';
import { asyncGetProvider } from 'modules/providers/action';
import { selectProvider, selectIsLoading } from 'modules/providers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

import { InconsistenciesTableRow } from '../inconsistencies/InconsistenciesTableRow';

export const ProviderInconsistenciesTable = () => {
  const { id } = useParams();
  const [inconsistencyRequestId, setInconsistencyRequestId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const provider = useAppSelector(selectProvider);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const onModalOpen = (id: number) => () => {
    setInconsistencyRequestId(id);
    handleOpenModal();
  };

  const onInconsistencyRequestDelete = async () => {
    if (inconsistencyRequestId && id) {
      await dispatch(asyncDeleteInconsistency(+inconsistencyRequestId));
      await dispatch(asyncGetProvider(id));
      handleCloseModal();
    }
  };

  return (
    <>
      <CommonLayoutTable
        title='inconsistencies_management'
        columns={INCONSISTENCIES_COLUMNS}
        creationPath={`${AppRoutes.inconsistencyCreate}&provider=${id}`}
        isLoading={isLoading}
      >
        {provider?.inconsistencies?.length ? (
          provider.inconsistencies.map((inconsistency) => (
            <InconsistenciesTableRow
              key={inconsistency.id}
              data={inconsistency}
              handleDelete={onModalOpen(inconsistency.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={INCONSISTENCIES_COLUMNS.length} />
        )}
      </CommonLayoutTable>

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onInconsistencyRequestDelete}
        title='delete_inconsistency'
      />
    </>
  );
};
