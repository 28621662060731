import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ReactComponent as CalendarSvg } from 'assets/svg/calendar.svg';
import { ReactComponent as EmailSvg } from 'assets/svg/email.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { Self } from 'interfaces/self.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Avatar } from 'shared-components/Avatar';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { TitleWithValue } from 'shared-components/TitleWithValue';
import { getEditRoute } from 'utils/routes.utils';

interface Props {
  handleOpenDeleteModal: VoidFunction;
  user: Self;
}

export const UserCard: FC<Props> = ({ handleOpenDeleteModal, user: { roles, name, email, id } }) => {
  const user = useAppSelector(selectCurrentUser);

  const isFullAccess = user?.permissions.includes('full.access');
  const isUserEditable = user?.permissions.includes('users.update');
  const isUserDeletable = user?.permissions.includes('users.delete');

  const userRoute = getEditRoute(AppRoutes.users, id);

  return (
    <Card title={name} withoutSpacing wrapperClassName='flex flex-col flex-1' className='flex flex-col flex-1'>
      <div className='flex justify-between text-sm text-mediumBlue py-3 px-5'>
        <div className='w-7/12'>
          <TitleWithValue
            title='roles'
            value={Object.values(roles)
              .map((role) => role.name)
              .join(',')}
            className='mb-2'
          />
          <TitleWithValue title='registered' value='2023-09-11' icon={CalendarSvg} />
          <TitleWithValue title='email' value={email} icon={EmailSvg} />
        </div>
        <div className='flex justify-end pl-4'>
          <Avatar
            src='https://www.kraftwerk.at/app/uploads/fly-images/962/reference-img-worlds-of-adventure-park-4-1920x9999.jpg'
            alt='user-avatar'
            className='min-w-20 w-20 h-20'
          />
        </div>
      </div>
      <div className='flex items-end justify-end py-3 px-5 flex-1'>
        {(isFullAccess || isUserEditable) && (
          <Link to={userRoute} className='px-3 py-2 h-[28px] text-sm leading-[19px]'>
            <FormattedMessage id='edit' />
          </Link>
        )}
        {(isFullAccess || isUserDeletable) && (
          <Button
            onClick={handleOpenDeleteModal}
            className='ml-2 px-3 py-2 h-[28px] text-sm leading-[19px]'
            variant={ButtonVariants.CANCEL_OUTLINED}
          >
            <FormattedMessage id='delete' />
          </Button>
        )}
      </div>
    </Card>
  );
};
