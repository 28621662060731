import { useEffect, useState } from 'react';

import { RISKS_ASSESSMENTS_COLUMNS } from 'constants/risks.constsnts';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { asyncDeleteRisk, asyncGetRisks } from 'modules/risks/action';
import { selectRisks } from 'modules/risks/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { RisksTableRow } from 'page-components/risks/RisksTableRow';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Risks = () => {
  const dispatch = useAppDispatch();
  const { risks, pagination, isLoading } = useAppSelector(selectRisks);
  const { page, handlePageChange } = usePagination();
  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const [deletedRiskId, setDeletedRiskId] = useState<number>();

  const handleDelete = (id: number) => () => {
    setDeletedRiskId(id);
    handleOpenModal();
  };

  const handleConfirmDelete = async () => {
    if (deletedRiskId) {
      await dispatch(asyncDeleteRisk(deletedRiskId));
      await dispatch(asyncGetRisks({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetRisks({ page, perPage: 10 }));
  }, [dispatch, page]);

  return (
    <PageLayout title='risk_assessments' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='risk_assessments_managment'
        columns={RISKS_ASSESSMENTS_COLUMNS}
        creationPath={AppRoutes.riskCreate}
        isLoading={isLoading}
      >
        {risks?.length ? (
          risks.map((risk) => <RisksTableRow key={risk.id} data={risk} handleDelete={handleDelete(risk?.id)} />)
        ) : (
          <TableNoData colSpan={RISKS_ASSESSMENTS_COLUMNS.length} />
        )}
      </CommonLayoutTable>

      <Pagination pageSize={10} current={page} total={pagination?.total ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={handleConfirmDelete}
        title='delete_risk'
      />
    </PageLayout>
  );
};
