import { useEffect, useState } from 'react';

import { CustomerRequestsSelects } from 'interfaces/customer-requests.interfaces';
import { asyncGetCountries } from 'modules/countries/action';
import { asyncGetCustomerRequestSources } from 'modules/customer-request-sources/action';
import { asyncGetCustomerRequestStatuses } from 'modules/customer-request-statuses/action';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { asyncGetProductGroups } from 'modules/product-groups/action';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useCustomerRequestsDefaultOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<CustomerRequestsSelects>();

  useEffect(() => {
    dispatch(asyncGetCountries({}))
      .unwrap()
      .then((response) => {
        const country = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, country }));
      });

    dispatch(asyncGetCustomerStatuses({}))
      .unwrap()
      .then((response) => {
        const customerStatus = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, customerStatus }));
      });

    dispatch(getUsers({}))
      .unwrap()
      .then((response) => {
        const responsible = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, responsible }));
      });

    dispatch(asyncGetProductGroups({}))
      .unwrap()
      .then((response) => {
        const product = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, product }));
      });

    dispatch(asyncGetCustomerRequestSources({}))
      .unwrap()
      .then((response) => {
        const requestSource = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, requestSource }));
      });

    dispatch(asyncGetCustomerRequestStatuses({}))
      .unwrap()
      .then((response) => {
        const requestStatus = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, requestStatus }));
      });
  }, [dispatch]);

  return filterSelects;
};
