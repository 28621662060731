import { FC, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { CUSTOMER_GENERAL_FIELDS } from 'constants/customers.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { Customer, CustomerInput } from 'interfaces/customers.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCities } from 'modules/cities/action';
import { asyncGetCountries } from 'modules/countries/action';
import { useAppDispatch } from 'modules/store';
import { Card } from 'shared-components/Card';
import { FormFields } from 'shared-components/FormFields';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

interface Props {
  customer: Customer | null;
}

export const CustomerGeneralFields: FC<Props> = ({ customer }) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [countyOptions, setCountyOptions] = useState<SelectOption[]>();
  const [countyOption, setCountyOption] = useState<SelectOption>();
  const [defaultCountryOption, setDefaultCountyOption] = useState<SelectOption>();

  const [cityOptions, setCityOptions] = useState<SelectOption[]>();
  const [cityOption, setCityOption] = useState<SelectOption>();

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<CustomerInput>();

  useEffect(() => {
    dispatch(asyncGetCountries({}))
      .unwrap()
      .then((response) => {
        const countries = response.data;
        const customerCountry = countries.find(({ id }) => id === customer?.country.id);

        if (customerCountry) {
          setDefaultCountyOption({ value: customerCountry.id, label: customerCountry.name });
        }
        setCountyOptions(convertObjectsToSelectOption(countries));
      });
  }, [dispatch, customer]);

  useEffect(() => {
    if (!countyOption) return;

    dispatch(asyncGetCities({ country: `${countyOption.value}` }))
      .unwrap()
      .then((response) => {
        const cities = response.data;
        const customerCity = cities.find(({ id }) => id === customer?.city.id);

        if (customerCity) {
          setCityOption({ value: customerCity.id, label: customerCity.name });
        }
        setCityOptions(convertObjectsToSelectOption(cities));
      });
  }, [dispatch, countyOption, customer]);

  return (
    <Card title='general_info' wrapperClassName='flex-1 ' className='flex flex-col gap-6'>
      <FormFields fields={CUSTOMER_GENERAL_FIELDS} register={register} errors={errors} />
      <Controller
        control={control}
        name='country'
        defaultValue={defaultCountryOption}
        rules={REQUIRED_VALIDATION}
        render={({ field: { onChange, ...field } }) => (
          <Select
            {...field}
            isRequired
            label='country'
            error={errors['country']?.message}
            defaultValue={defaultCountryOption}
            onChange={(event) => {
              setCountyOption(event as SelectOption);
              onChange(event);
            }}
            options={countyOptions}
            className='performer flex-1 h-full'
            placeholder={formatMessage({ id: 'search_country' })}
          />
        )}
      />
      <Controller
        control={control}
        name='city'
        defaultValue={cityOption}
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            label='city'
            error={errors['city']?.message}
            isDisabled={!cityOptions?.length}
            defaultValue={cityOption}
            options={cityOptions}
            className='performer flex-1 h-full'
            placeholder={formatMessage({ id: 'search_city' })}
          />
        )}
      />
    </Card>
  );
};
