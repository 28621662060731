import { useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { asyncGetRole } from 'modules/roles/action';
import { selectRole } from 'modules/roles/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { RoleForm } from 'page-components/role/RoleForm';

export const Role = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();

  const role = useAppSelector(selectRole);

  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.roleCreate ? 'create_role' : 'edit_role';
  const titleText = pathname.includes('view') ? 'view_role' : editTitle;

  useEffect(() => {
    if (id) {
      dispatch(asyncGetRole(id));
    }
  }, [dispatch, id]);

  return (
    <PageLayout wrapperClassName='grid role' title={titleText}>
      <RoleForm role={role} />
    </PageLayout>
  );
};
