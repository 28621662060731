import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { store } from 'modules/store';
import { IntlGlobalProvider } from 'page-components/provider/IntlGlobalProvider';
import { PagesWrapper } from 'pages/PagesWrapper';

function App() {
  return (
    <Provider store={store}>
      <IntlGlobalProvider>
        <BrowserRouter>
          <PagesWrapper />
        </BrowserRouter>
      </IntlGlobalProvider>
    </Provider>
  );
}

export default App;
