import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Complaint, ComplaintsResponse } from 'interfaces/complaints.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateComplaint,
  asyncUpdateComplaint,
  asyncDeleteComplaint,
  asyncGetComplaint,
  asyncGetComplaints,
  unsetComplaint,
} from './action';
import { ComplaintsSliceState } from './slice';

export function getComplaintsReducer(builder: ActionReducerMapBuilder<ComplaintsSliceState>) {
  builder.addCase(asyncGetComplaints.fulfilled, (state, { payload }: PayloadAction<ComplaintsResponse>) => {
    state.complaints = payload.data;
    state.complaintsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetComplaints.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getComplaintReducer(builder: ActionReducerMapBuilder<ComplaintsSliceState>) {
  builder.addCase(asyncGetComplaint.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetComplaint.fulfilled, (state, { payload }: PayloadAction<Complaint>) => {
    state.complaint = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetComplaint.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateComplaintReducer = withLoadingReducer(asyncUpdateComplaint);

export const createComplaintReducer = withLoadingReducer(asyncCreateComplaint);

export function deleteComplaintReducer(builder: ActionReducerMapBuilder<ComplaintsSliceState>) {
  builder.addCase(asyncDeleteComplaint.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteComplaint.fulfilled, (state) => {
    state.complaint = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteComplaint.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetComplaintReducer(builder: ActionReducerMapBuilder<ComplaintsSliceState>) {
  builder.addCase(unsetComplaint, (state) => {
    state.complaint = null;
  });
}
