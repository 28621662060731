import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { QueryParams } from 'interfaces/query.interfaces';
import { RoleData, RoleResponse, RolesResponse, UpdateRoleData } from 'interfaces/roles.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class RolesApi {
  constructor(private url: string) {}

  getRoles(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<RolesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getRoleById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<RoleResponse>(`${this.url}/${payload}`, config);
  }

  updateRole({ id, ...data }: UpdateRoleData, config?: AxiosRequestConfig) {
    return httpApiClient.put<RolesResponse>(`${this.url}/${id}`, data, config);
  }

  createRole(data: RoleData, config?: AxiosRequestConfig) {
    return httpApiClient.post<RolesResponse>(`${this.url}`, data, config);
  }

  deleteRole(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const rolesApi = new RolesApi('/roles');
