import { FC, ReactNode, useEffect, useState } from 'react';

import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as LoaderSvg } from 'assets/svg/loading.svg';

interface Props {
  title: string;
  children: ReactNode;
  wrapperClassName?: string;
  isLoading?: boolean;
}

export const PageLayout: FC<Props> = ({ title, children, wrapperClassName, isLoading }) => {
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setShowContent(true);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <>
      <h1 className='text-2xl font-bold mb-4'>
        <FormattedMessage id={title} defaultMessage={title} />
      </h1>
      <div className={twMerge('relative', wrapperClassName)}>
        {(isLoading || !showContent) && (
          <div className='w-full h-full bg-lightGray absolute z-[100] inset-0 flex items-start justify-center'>
            <div className='flex items-start justify-center w-[50vw] h-[50vh]'>
              <LoaderSvg data-test='loading-svg' className='w-20 h-20' />
            </div>
          </div>
        )}
        {children}
      </div>
    </>
  );
};
