import { RiskItem } from 'interfaces/risks.interfaces';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';

interface Props {
  data: RiskItem;
}

export const RiskPreventTableRow = ({ data: { name, howToDecrease, howToControl, notes } }: Props) => {
  return (
    <TableRow className='text-center'>
      <TableData>{name}</TableData>
      <TableData>{howToDecrease}</TableData>
      <TableData>{howToControl}</TableData>
      <TableData className='last:text-left'>{notes}</TableData>
    </TableRow>
  );
};
