import { Field } from 'interfaces/shared/field.interfaces';

import { TableColumn } from './shared/table.constants';
import { REQUIRED_VALIDATION } from './validation-rules.constants';

export const PROVIDERS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'name', title: 'name' },
  { id: 'code', title: 'code' },
  { id: 'phone', title: 'phone' },
  { id: 'email', title: 'email' },
  { id: 'components', title: 'complects' },
  { id: 'mark', title: 'mark', className: 'last:text-left' },
];

export const PROVIDER_GENERAL_FIELDS: Field[] = [
  {
    name: 'name',
    options: REQUIRED_VALIDATION,
    'data-test': 'provider-name',
    label: 'name',
    placeholder: 'name',
  },
  {
    name: 'taxNumber',
    options: REQUIRED_VALIDATION,
    'data-test': 'provider-company',
    label: 'code',
    placeholder: 'code',
  },
];

export const PROVIDER_CONTACTS_FIELDS: Field[] = [
  {
    name: 'email',
    options: REQUIRED_VALIDATION,
    'data-test': 'provider-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'phone',
    options: REQUIRED_VALIDATION,
    'data-test': 'provider-phone',
    label: 'phone',
    placeholder: 'phone',
  },
  {
    name: 'contactPerson',
    options: {},
    'data-test': 'provider-contact',
    label: 'contact',
    placeholder: 'contact',
  },
];

export const PROVIDER_MARKS_FIELDS: Field[] = [
  {
    name: 'costRate',
    options: {},
    type: 'range',
    max: 1,
    min: 0,
    'data-test': 'provider-cost',
    label: 'cost',
  },
  {
    name: 'qualityRate',
    options: {},
    type: 'range',
    max: 2,
    min: 0,
    'data-test': 'provider-quality',
    label: 'quality',
  },
  {
    name: 'termRate',
    options: {},
    type: 'range',
    max: 2,
    min: 0,
    'data-test': 'provider-deadline',
    label: 'deadline',
  },
  {
    name: 'conditionRate',
    options: {},
    type: 'range',
    max: 1,
    min: 0,
    'data-test': 'provider-condition',
    label: 'condition',
  },
];
