import { FC } from 'react';

import cn from 'classnames';
import DatePicker from 'react-datepicker';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { DateFormates } from 'constants/date.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { TaskInput } from 'interfaces/tasks.interfaces';
import { ErrorMessage } from 'shared-components/ErrorMessage';
import { FormLabel } from 'shared-components/FormLabel';

interface Props {
  defaultDate: Date;
  isEditable: boolean;
}

export const TaskEndDate: FC<Props> = ({ defaultDate, isEditable }) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<TaskInput>();

  return (
    <div className={cn({ 'flex gap-2 items-center': !isEditable })}>
      <FormLabel htmlFor='deadline' className={cn('mb-2 flex', { 'text-black whitespace-nowrap mb-0': !isEditable })}>
        <FormattedMessage id='end_date' />
        {isEditable && '*:'}
      </FormLabel>
      <Controller
        control={control}
        name='deadline'
        defaultValue={defaultDate as unknown as string}
        rules={REQUIRED_VALIDATION}
        render={({ field: { value, onChange, ...field } }) => (
          <DatePicker
            {...field}
            showIcon
            disabled={!isEditable}
            minDate={defaultDate}
            onChange={(date) => {
              date && onChange(date);
            }}
            selected={value ? new Date(value) : defaultDate}
            dateFormat={DateFormates.defaultDate}
            className='flex-1 h-full disabled:border-0'
            wrapperClassName='w-full'
          />
        )}
      />
      <ErrorMessage>{errors['deadline']?.message}</ErrorMessage>
    </div>
  );
};
