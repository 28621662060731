import { FC } from 'react';

import { useFormContext } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Permission } from 'interfaces/permissions.interfaces';
import { RoleInput, RolesData } from 'interfaces/roles.interfaces';
import { Card } from 'shared-components/Card';
import { Checkbox } from 'shared-components/Checkbox';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { getSubGroups, toUpperCaseFirstLater } from 'utils/role.utils';

import { RoleSubGroupLayout } from './RoleSubGroupLayout';

interface Props {
  permissionName: string;
  role: RolesData | null;
  permissions: Record<string, Permission[]>;
}

export const RolePermissionTab: FC<Props> = ({ permissions, permissionName, role }) => {
  const { id } = useParams();

  const {
    register,
    formState: { errors },
  } = useFormContext<RoleInput>();

  const subGroups = getSubGroups(permissions, permissionName);

  return (
    <Card title={toUpperCaseFirstLater(permissionName)} className='flex flex-col gap-6'>
      <div key={permissionName} className='flex flex-col flex-1 p-4 gap-2 bg-white rounded-lg'>
        <div className='flex flex-col gap-4 flex-wrap'>
          {subGroups.map((subGroup) => (
            <RoleSubGroupLayout key={subGroup} subGroup={subGroup}>
              {permissions[permissionName].map(
                ({ id: permissionsId, name }) =>
                  subGroup === name.split('.')[0] && (
                    <Checkbox
                      defaultChecked={!!id && role?.permissions.some((permission) => permission === name)}
                      key={permissionsId}
                      {...register(name)}
                      error={errors[name]?.message}
                      value={permissionsId}
                      label={toUpperCaseFirstLater(name.split('.')[1]).replace(/([a-z])([A-Z])/g, '$1 $2')}
                    />
                  ),
              )}
            </RoleSubGroupLayout>
          ))}
        </div>
      </div>
      <ItemNavigation navigateBack={AppRoutes.roles} />
    </Card>
  );
};
