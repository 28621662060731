import { forwardRef, InputHTMLAttributes } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { INPUT_CLASSNAMES } from 'constants/shared/input.constants';
import { ErrorMessage } from 'shared-components/ErrorMessage';
import { FormLabel } from 'shared-components/FormLabel';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  className?: string;
  label?: string;
  isRequired?: boolean;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ className, label, isRequired, error, ...props }, ref) => {
    const { pathname } = useLocation();
    const isView = pathname.includes('/view/');

    return (
      <div className={twMerge('w-full relative mt-3', className)}>
        <input
          id={props.name}
          {...props}
          className={cn(
            INPUT_CLASSNAMES.default,
            {
              [INPUT_CLASSNAMES.error]: error,
              [INPUT_CLASSNAMES.disabled]: props.disabled,
            },
            { 'pl-0 border-none bg-transparent pointer-events-none': isView },
          )}
          ref={ref}
        />
        <FormLabel
          className={cn('transition-all absolute mx-auto top-0 bottom-0 left-2.5 pt-2.5 cursor-text', {
            'text-red': error,
            'pointer-events-none !text-base': isView,
          })}
          htmlFor={props.name}
        >
          {label && <FormattedMessage id={label} defaultMessage={label} />}
          {isRequired && !isView && '*'}
        </FormLabel>
        <ErrorMessage>{error && <FormattedMessage id={error} defaultMessage={error} />}</ErrorMessage>
      </div>
    );
  },
);

Input.displayName = 'Input';
