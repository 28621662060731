import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { CountriesResponse, Country, CountryInput, CountryResponse } from 'interfaces/countries.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CountriesApi {
  constructor(private url: string) {}

  getCountries(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CountriesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCountryById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CountryResponse>(`${this.url}/${payload}`, config);
  }

  updateCountry({ id, ...data }: Country, config?: AxiosRequestConfig) {
    return httpApiClient.put<CountriesResponse>(`${this.url}/${id}`, data, config);
  }

  createCountry(data: CountryInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<CountriesResponse>(`${this.url}`, data, config);
  }

  deleteCountry(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const countriesApi = new CountriesApi('/countries');
