import { createSlice } from '@reduxjs/toolkit';

import { ManagerAction } from 'interfaces/manager-actions.interfaces';

import { MANAGER_ACTIONS_SLICE_NAME } from './action';
import {
  createManagerActionReducer,
  deleteManagerActionReducer,
  getManagerActionReducer,
  unsetManagerActionReducer,
  updateManagerActionReducer,
} from './reducers';

export interface ManagerActionsSliceState {
  isLoading: boolean;
  managerAction: ManagerAction | null;
}

export const initialState: ManagerActionsSliceState = {
  managerAction: null,

  isLoading: false,
};

const managerActionsSlice = createSlice({
  name: MANAGER_ACTIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createManagerActionReducer(builder);
    deleteManagerActionReducer(builder);
    getManagerActionReducer(builder);
    unsetManagerActionReducer(builder);
    updateManagerActionReducer(builder);
  },
});

export const managerActionsReducer = managerActionsSlice.reducer;
