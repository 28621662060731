import { createSlice } from '@reduxjs/toolkit';

import { Self } from 'interfaces/self.interfaces';
import {
  getUserReducer,
  getUsersReducer,
  unsetUserReducer,
  updateUserReducer,
  createUserReducer,
  deleteUserReducer,
  unsetUsersReducer,
} from 'modules/users/reducers';

import { USERS_SLICE_NAME } from './action';

export interface UsersSliceState {
  user: Self | null;
  users: Self[] | null;
  usersCount: number | null;
  isLoading: boolean;
}

export const initialState: UsersSliceState = {
  user: null,
  users: null,
  usersCount: null,
  isLoading: false,
};

const usersSlice = createSlice({
  name: USERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getUserReducer(builder);
    getUsersReducer(builder);
    unsetUserReducer(builder);
    updateUserReducer(builder);
    createUserReducer(builder);
    deleteUserReducer(builder);
    unsetUsersReducer(builder);
  },
});

export const usersReducer = usersSlice.reducer;
