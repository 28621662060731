import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CustomerStatus, CustomerStatusesResponse } from 'interfaces/customer-statuses.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCustomerStatus,
  asyncDeleteCustomerStatus,
  asyncGetCustomerStatus,
  asyncGetCustomerStatuses,
  asyncUpdateCustomerStatus,
  unsetCustomerStatus,
} from './action';
import { CustomerStatusesSliceState } from './slice';

export function getCustomerStatusesReducer(builder: ActionReducerMapBuilder<CustomerStatusesSliceState>) {
  builder.addCase(asyncGetCustomerStatuses.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerStatuses.fulfilled, (state, { payload }: PayloadAction<CustomerStatusesResponse>) => {
    state.customerStatuses = payload.data;
    state.customerStatusCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomerStatuses.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCustomerStatusReducer(builder: ActionReducerMapBuilder<CustomerStatusesSliceState>) {
  builder.addCase(asyncGetCustomerStatus.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerStatus.fulfilled, (state, { payload }: PayloadAction<CustomerStatus>) => {
    state.customerStatus = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomerStatus.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCustomerStatusReducer = withLoadingReducer(asyncUpdateCustomerStatus);

export const createCustomerStatusReducer = withLoadingReducer(asyncCreateCustomerStatus);

export function deleteCustomerStatusReducer(builder: ActionReducerMapBuilder<CustomerStatusesSliceState>) {
  builder.addCase(asyncDeleteCustomerStatus.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCustomerStatus.fulfilled, (state) => {
    state.customerStatus = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCustomerStatus.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCustomerStatusReducer(builder: ActionReducerMapBuilder<CustomerStatusesSliceState>) {
  builder.addCase(unsetCustomerStatus, (state) => {
    state.customerStatus = null;
  });
}
