import { useEffect, useState } from 'react';

import qs from 'query-string';
import { useLocation } from 'react-router-dom';

import { COMPLAINTS_COLUMNS } from 'constants/complaints.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteComplaint, asyncGetComplaints } from 'modules/complaints/action';
import { selectComplaints, selectCompleintsCount, selectIsLoading } from 'modules/complaints/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ComplaintsFilters } from 'page-components/complaints/ComplaintsFilters';
import { ComplaintsTableRow } from 'page-components/complaints/ComplaintsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Complaints = () => {
  const [complaintsId, setComplaintsId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const complaints = useAppSelector(selectComplaints);
  const compleintsCount = useAppSelector(selectCompleintsCount);

  const { search } = useLocation();
  const { startDate, endDate, search: searchQuery = '', responsible, customer } = qs.parse(search);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setComplaintsId(id);
    handleOpenModal();
  };

  const onComplaintIdDelete = async () => {
    if (complaintsId) {
      await dispatch(asyncDeleteComplaint(+complaintsId));
      await dispatch(asyncGetComplaints({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    const dates = startDate && endDate ? `${startDate ?? ''}-${endDate ?? ''}` : '';
    const customersQueryParams = {
      search: searchQuery,
      dates,
      responsible,
      customer,
    } as Record<string, string>;

    dispatch(
      asyncGetComplaints({
        page,
        perPage: 10,
        ...customersQueryParams,
      }),
    );
  }, [dispatch, page, startDate, endDate, searchQuery, customer, responsible]);

  useQueryParams({ page });

  return (
    <PageLayout title='complaints' wrapperClassName='flex flex-col gap-4'>
      <ComplaintsFilters />
      <CommonLayoutTable
        title='complaints_management'
        columns={COMPLAINTS_COLUMNS}
        creationPath={AppRoutes.complaintCreate}
        isLoading={isLoading}
      >
        {complaints?.length ? (
          complaints.map((complaint) => (
            <ComplaintsTableRow key={complaint.id} data={complaint} handleDelete={onModalOpen(complaint.id)} />
          ))
        ) : (
          <TableNoData colSpan={COMPLAINTS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={compleintsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onComplaintIdDelete}
        title='delete_complaint'
      />
    </PageLayout>
  );
};
