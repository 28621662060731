import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { CustomerPayload, CustomerResponse, CustomersResponse, CustomerUpdate } from 'interfaces/customers.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CustomersApi {
  constructor(private url: string) {}

  getCustomers(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomersResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCustomerById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerResponse>(`${this.url}/${payload}`, config);
  }

  updateCustomer({ id, ...data }: CustomerUpdate, config?: AxiosRequestConfig) {
    return httpApiClient.put<CustomerResponse>(`${this.url}/${id}`, data, config);
  }

  createCustomer(data: CustomerPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<CustomerResponse>(`${this.url}`, data, config);
  }

  deleteCustomer(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const customersApi = new CustomersApi('/customers');
