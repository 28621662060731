import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { notificationsApi } from 'apis/notifications.apis';
import { NotificationPayload, UpdateNotificationPayload } from 'interfaces/notifications.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const NOTIFICATIONS_SLICE_NAME = 'notifications';

export const asyncGetNotifications = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/getNotifications`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await notificationsApi.getNotifications(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetNotification = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/getNotification`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await notificationsApi.getNotificationById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateNotification = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/updateNotification`,
  async (payload: UpdateNotificationPayload, { rejectWithValue }) => {
    try {
      await notificationsApi.updateNotification(payload);
      toast.success(intl.formatMessage({ id: 'notification_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateNotification = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/createNotification`,
  async (payload: NotificationPayload, { rejectWithValue }) => {
    try {
      await notificationsApi.createNotification(payload);
      toast.success(intl.formatMessage({ id: 'notification_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteNotification = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/deleteNotification`,
  async (id: number, { rejectWithValue }) => {
    try {
      await notificationsApi.deleteNotification(id);
      toast.success(intl.formatMessage({ id: 'notification_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncConfirmNotification = createAsyncThunk(
  `${NOTIFICATIONS_SLICE_NAME}/confirmNotification`,
  async (id: number, { rejectWithValue }) => {
    try {
      await notificationsApi.confirmNotification(id);
      toast.success(intl.formatMessage({ id: 'confirmed_notification' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetNotification = createAction('UNSET_NOTIFICATION');
