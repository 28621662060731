import { useState } from 'react';

import { useSearchParams } from 'react-router-dom';

export const usePagination = () => {
  const [searchParams] = useSearchParams();
  const queryPage = +(searchParams.get('page') ?? 0);
  const queryPerPage = +(searchParams.get('perPage') ?? 5);

  const [page, setPage] = useState(queryPage - (queryPage > 0 ? 1 : 0) ?? 0);
  const [perPage, setPerPage] = useState(queryPerPage);

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handlePerPageChange = (perPage: number) => {
    setPerPage(perPage);
    setPage(0);
  };

  return { page, handlePageChange, perPage, handlePerPageChange };
};
