import { createSlice } from '@reduxjs/toolkit';

import { InconsistencyItem } from 'interfaces/inconsistency-types.interfaces';

import { INCONSISTENCY_TYPE_SLICE_NAME } from './action';
import {
  createInconsistencyTypeReducer,
  deleteInconsistencyTypeReducer,
  getInconsistenciesTypeReducer,
  getInconsistencyTypeReducer,
  unsetInconsistencyTypeReducer,
  updateInconsistencyTypeReducer,
} from './reducers';

export interface InconsistencyTypeSliceState {
  isLoading: boolean;
  inconsistencyType: InconsistencyItem | null;
  inconsistencyTypes: InconsistencyItem[] | null;
  inconsistencyTypesCount: number | null;
}

export const initialState: InconsistencyTypeSliceState = {
  inconsistencyType: null,
  inconsistencyTypes: null,
  inconsistencyTypesCount: null,
  isLoading: false,
};

const inconsistencyTypeSlice = createSlice({
  name: INCONSISTENCY_TYPE_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createInconsistencyTypeReducer(builder);
    deleteInconsistencyTypeReducer(builder);
    getInconsistenciesTypeReducer(builder);
    getInconsistencyTypeReducer(builder);
    unsetInconsistencyTypeReducer(builder);
    updateInconsistencyTypeReducer(builder);
  },
});

export const inconsistencyTypeReducer = inconsistencyTypeSlice.reducer;
