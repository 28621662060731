import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Task } from 'interfaces/tasks.interfaces';

interface Props {
  task: Task | null;
}

export const TaskResponsibleInfo: FC<Props> = ({ task }) => {
  const responsibleInfos = [
    {
      header: 'author',
      text: task?.owner.name,
    },
    {
      header: 'executor',
      text: task?.responsible.name,
    },
    {
      header: 'current_executor',
      text: task?.performer.name,
    },
  ];

  return (
    <div>
      {responsibleInfos.map(({ header, text }) => (
        <div key={header}>
          <span className='font-bold'>
            <FormattedMessage id={header} />:
          </span>
          <span> {text}</span>
        </div>
      ))}
    </div>
  );
};
