import { FC } from 'react';

import cn from 'classnames';
import { INodeRendererProps } from 'react-accessible-treeview';
import { useLocation } from 'react-router-dom';

import { Checkbox } from './Checkbox';
import { ColumnDefaultControls } from './table/ColumnDefaultControls';

interface Props extends INodeRendererProps {
  handleDelete: VoidFunction;
  editPermission: string;
  editRoute: string;
  deletePermission: string;
}

export const TreeItem: FC<Props> = ({
  getNodeProps,
  level,
  isSelected,
  element,
  handleDelete,
  editRoute,
  editPermission,
  deletePermission,
}) => {
  const { pathname } = useLocation();
  const isView = pathname.includes('/view/');

  return (
    <div
      {...getNodeProps()}
      style={{ paddingLeft: 20 * (level > 1 ? level - 1 : 0.4) }}
      className={cn(
        'flex gap-2 items-center justify-between cursor-pointer transition-all group hover:bg-white p-2 rounded-md',
        { 'active:pointer-events-none hidden': level > 1 },
      )}
    >
      <div className='flex items-center'>
        <div className='flex'>
          {level < 2 && !isView && <Checkbox className='pointer-events-none' checked={isSelected} readOnly />}
          {element.name}
        </div>
      </div>
      <div>
        <ColumnDefaultControls
          className='transition-all opacity-0 group-hover:opacity-100 pointer-events-auto'
          handleDelete={handleDelete}
          editRoute={editRoute}
          editPermission={editPermission}
          deletePermission={deletePermission}
        />
      </div>
    </div>
  );
};
