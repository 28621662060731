import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { userApi } from 'apis/user.apis';
import { QueryParams } from 'interfaces/query.interfaces';
import { UpdateUser, UserInputPayload } from 'interfaces/users.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const USERS_SLICE_NAME = 'users';

export const getUsers = createAsyncThunk(`${USERS_SLICE_NAME}/getUsers`, async (queries?: QueryParams) => {
  const response = await userApi.getUsers(queries);

  return response.data;
});

export const getUser = createAsyncThunk(`${USERS_SLICE_NAME}/getUser`, async (id: string) => {
  const response = await userApi.getUser(id);

  return response.data.data;
});

export const createUser = createAsyncThunk(
  `${USERS_SLICE_NAME}/createUser`,
  async (payload: UserInputPayload, { rejectWithValue }) => {
    try {
      await userApi.createUser(payload);
      toast.success(intl.formatMessage({ id: 'user_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const updateUser = createAsyncThunk(
  `${USERS_SLICE_NAME}/updateUser`,
  async (payload: UpdateUser, { rejectWithValue }) => {
    try {
      await userApi.updateUser(payload);
      toast.success(intl.formatMessage({ id: 'user_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const deleteUser = createAsyncThunk(
  `${USERS_SLICE_NAME}/deleteUser`,
  async (id: string, { rejectWithValue }) => {
    try {
      await userApi.deleteUser(id);
      toast.success('user_deleted');
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetUser = createAction('UNSET_USER');

export const unsetUsers = createAction('UNSET_USERS');
