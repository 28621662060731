import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { ComplectInput } from 'interfaces/complects.interfaces';
import { asyncCreateComplect, asyncGetComplect, asyncUpdateComplect, unsetComplect } from 'modules/complects/action';
import { selectComplect } from 'modules/complects/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const ComplectForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const complect = useAppSelector(selectComplect);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ComplectInput>();

  const onSubmit: SubmitHandler<ComplectInput> = (data) => {
    dispatch(id ? asyncUpdateComplect({ id: +id, ...data }) : asyncCreateComplect(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.complects);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetComplect()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetComplect(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: complect?.name ?? '' });
    }
  }, [id, reset, complect]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='complect-name'
          />
          <ItemNavigation navigateBack={AppRoutes.complects} />
        </div>
      </Card>
    </form>
  );
};
