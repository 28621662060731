import { AuditFinishForm } from 'page-components/audits/AuditFinishForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const AuditFinish = () => {
  return (
    <PageLayout title='audits_finish'>
      <div className='grid grid-cols-1 sm:grid-cols-2'>
        <AuditFinishForm />
      </div>
    </PageLayout>
  );
};
