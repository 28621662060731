import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { ReportFilter } from 'interfaces/reports.interfaces';
import { Button } from 'shared-components/Button';

interface Props {
  filters: ReportFilter[];
}

export const ReportTypes = ({ filters }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const onFilterClick = (query: string) => () => {
    searchParams.set('type', query);
    setSearchParams(searchParams);
  };

  return (
    <div className='grid gap-4 grid-cols-1 sm:grid-cols-2'>
      {filters.map(({ id, name, query }) => (
        <Button
          className={cn('w-full', {
            'text-mediumBlue stroke-mediumBlue fill-mediumBlue bg-transparent': query === searchParams.get('type'),
          })}
          key={id}
          onClick={onFilterClick(query)}
        >
          <FormattedMessage id={name} />
        </Button>
      ))}
    </div>
  );
};
