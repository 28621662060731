import { useEffect, useState } from 'react';

import { addDays } from 'date-fns';
import { UseFormReset } from 'react-hook-form';

import { Education, EducationInput } from 'interfaces/educations.interfaces';
import { formatToInputDate, parseDefaultDate, parseInputDate } from 'utils/date.utils';

export const useEducationDates = (resetForm: UseFormReset<EducationInput>, education?: Education | null) => {
  const [defaultOptions, setDefaultOptions] = useState<{
    start: Date;
    realStart: Date;
  }>({
    start: new Date(),
    realStart: addDays(new Date(), 1),
  });

  useEffect(() => {
    if (education) {
      const parsedCreatedAt = parseDefaultDate(education.plannedAt);
      const parsedCompleted = parseDefaultDate(education.finishedAt);

      setDefaultOptions({
        start: parseInputDate(formatToInputDate(parsedCreatedAt)),
        realStart: parseInputDate(formatToInputDate(parsedCompleted)),
      });

      resetForm({
        plannedAt: formatToInputDate(parsedCreatedAt),
        finishedAt: formatToInputDate(parsedCompleted),
      });
    }
  }, [education, resetForm]);

  return defaultOptions;
};
