import { FC, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { RoleInput, RolesData, SelectOption } from 'interfaces/roles.interfaces';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { Select } from 'shared-components/Select';

interface Props {
  role: RolesData | null;
}

export const RoleUsers: FC<Props> = ({ role }) => {
  const [userOptions, setUserOptions] = useState<SelectOption[]>([]);

  const dispatch = useAppDispatch();

  const users = role?.users;

  const { formatMessage } = useIntl();

  const { control, setValue } = useFormContext<RoleInput>();

  useEffect(() => {
    dispatch(getUsers())
      .unwrap()
      .then((response) => {
        const results = response.data.map((user) => ({ label: user.name, value: user.id }));
        setValue(
          'usersSelect',
          results.filter(({ value }) => users?.some(({ id }) => value === id)),
        );
        setUserOptions(results);
      });
  }, [dispatch, users, setValue]);

  return (
    <>
      <Controller
        control={control}
        name='usersSelect'
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            isMulti
            options={userOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_users' })}
          />
        )}
      />
    </>
  );
};
