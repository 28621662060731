import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { Complect, ComplectInput, ComplectResponse, ComplectsResponse } from 'interfaces/complects.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class ComplectsApi {
  constructor(private url: string) {}

  getComplects(payload: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<ComplectsResponse>(`${this.url}?${generateQueryString(payload)}`, config);
  }

  getComplectById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<ComplectResponse>(`${this.url}/${payload}`, config);
  }

  updateComplect({ id, ...data }: Complect, config?: AxiosRequestConfig) {
    return httpApiClient.put<ComplectResponse>(`${this.url}/${id}`, data, config);
  }

  createComplect(data: ComplectInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<ComplectResponse>(`${this.url}`, data, config);
  }

  deleteComplect(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const complectsApi = new ComplectsApi('/complects');
