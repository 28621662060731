import { createSlice } from '@reduxjs/toolkit';

import { CustomerRequestSource } from 'interfaces/customer-request-sources.interfaces';

import { CUSTOMER_REQUEST_SOURCES_SLICE_NAME } from './action';
import {
  createCustomerRequestSourceReducer,
  deleteCustomerRequestSourceReducer,
  unsetCustomerRequestSourceReducer,
  getCustomerRequestSourceReducer,
  getCustomerRequestSourcesReducer,
  updateCustomerRequestSourceReducer,
} from './reducers';

export interface CustomerRequestSourcesSliceState {
  isLoading: boolean;
  customerRequestSource: CustomerRequestSource | null;
  customerRequestSources: CustomerRequestSource[] | null;
  customerRequestSourcesCount: number | null;
}

export const initialState: CustomerRequestSourcesSliceState = {
  customerRequestSource: null,
  customerRequestSources: null,
  customerRequestSourcesCount: null,
  isLoading: false,
};

const customerRequestSourceSlice = createSlice({
  name: CUSTOMER_REQUEST_SOURCES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomerRequestSourceReducer(builder);
    deleteCustomerRequestSourceReducer(builder);
    unsetCustomerRequestSourceReducer(builder);
    getCustomerRequestSourceReducer(builder);
    getCustomerRequestSourcesReducer(builder);
    updateCustomerRequestSourceReducer(builder);
  },
});

export const customerRequestSourceReducer = customerRequestSourceSlice.reducer;
