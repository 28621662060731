import React from 'react';

import { Control, FieldValues } from 'react-hook-form';
import { useSearchParams } from 'react-router-dom';

import { SelectOption } from 'interfaces/roles.interfaces';
import { FilterSelectOptions } from 'interfaces/select.interfaces';

import { SelectFilter } from './SelectFilter';

interface Props {
  filterSelects: Record<string, SelectOption[] | undefined>;
  data: FilterSelectOptions;
  control: Control<FieldValues>;
}

export const FilterSelects = ({ filterSelects, data, control }: Props) => {
  const [searchParams] = useSearchParams();

  return (
    <>
      {Object.keys(filterSelects).map((filterKey) => {
        const filterData = data[filterKey];
        const defaultOption = filterSelects[filterKey]?.find(
          (select) => `${select.value}` === searchParams.get(filterKey),
        );

        return (
          <SelectFilter
            key={filterKey}
            defaultOption={defaultOption}
            label={filterData.label}
            placeholder={filterData.placeholder}
            name={filterData.name}
            control={control}
            options={filterSelects[filterKey]}
          />
        );
      })}
    </>
  );
};
