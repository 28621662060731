import { FC } from 'react';

import { Employee } from 'interfaces/notifications.interfaces';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';

import { NotificationConfirm } from './NotificationConfirm';

interface Props {
  data: Employee;
}

export const NotificationTableRow: FC<Props> = ({ data: { id, name, confirmedAt } }) => {
  return (
    <TableRow>
      <TableData className='py-4'>{id}</TableData>
      <TableData>{name}</TableData>
      <TableData className='last:text-left'>
        {confirmedAt ? confirmedAt : <NotificationConfirm userId={id} />}
      </TableData>
    </TableRow>
  );
};
