import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  CustomerRequestInputPayload,
  CustomerRequestResponse,
  CustomerRequestsResponse,
  UpdateCustomerRequestInputPayload,
} from 'interfaces/customer-requests.interfaces';
import {
  ManagerActionPayload,
  ManagerActionResponse,
  UpdateManagerActionPayload,
} from 'interfaces/manager-actions.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CustomerRequestApi {
  constructor(private url: string) {}

  getCustomerRequests(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCustomerRequestById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerRequestResponse>(`${this.url}/${payload}`, config);
  }

  updateCustomerRequest({ id, ...data }: UpdateCustomerRequestInputPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<CustomerRequestResponse>(`${this.url}/${id}`, data, config);
  }

  createCustomerRequest(data: CustomerRequestInputPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<CustomerRequestResponse>(`${this.url}`, data, config);
  }

  deleteCustomerRequest(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }

  getManagerAction(requestId: string, commentId: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<ManagerActionResponse>(`${this.url}/${requestId}/comments/${commentId}`, config);
  }

  updateManagerAction({ requestId, commentId, ...data }: UpdateManagerActionPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<ManagerActionResponse>(`${this.url}/${requestId}/comments/${commentId}}`, data, config);
  }

  createManagerAction({ requestId, ...data }: ManagerActionPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ManagerActionResponse>(`${this.url}/${requestId}/comments/`, data, config);
  }

  deleteManagerAction(requestId: string, commentId: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${requestId}/comments/${commentId}`, config);
  }
}

export const customerRequestApi = new CustomerRequestApi('/customer-requests');
