import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { DateFormates } from 'constants/date.constants';
import { useQueryParams } from 'hooks/useQueryParams';
import { useStartEndQuery } from 'hooks/useStartEndQuery';
import { QueryParams } from 'interfaces/query.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { Field } from 'interfaces/shared/field.interfaces';
import { Button } from 'shared-components/Button';
import { FormFields } from 'shared-components/FormFields';
import { FormLabel } from 'shared-components/FormLabel';
import { Select } from 'shared-components/Select';
import { formatToDefaultDate } from 'utils/date.utils';

interface Props {
  fields?: Field[];
}

export const ReportFilters = ({ fields }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const type = searchParams.get('type');

  const { startDate, endDate, setStartDate, setEndDate } = useStartEndQuery();

  const [queryParams, setQueryParams] = useState<QueryParams>({});

  const {
    reset,
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const resetFormData = fields?.reduce((rest, field) => {
    if (field?.component === Select) {
      return { ...rest, [field?.name]: { value: '', reset: '' } };
    }
    return { ...rest, [field?.name]: '' };
  }, {});

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleResetForm = () => {
    if (!type) return;
    setSearchParams({ type });
    reset({ dateRange: '', ...resetFormData });
  };

  const onSubmit: SubmitHandler<FieldValues> = ({ dateRange, ...data }) => {
    const [startDate, endDate] = dateRange ? (dateRange as unknown as [Date | undefined, Date | undefined]) : [];

    const queryParams = {
      startDate: startDate ? `${formatToDefaultDate(startDate)}` : undefined,
      endDate: endDate ? `${formatToDefaultDate(endDate)}` : undefined,
    };

    const dynamicQueries = Object.entries(data).reduce((rest, [key, value]) => {
      if (value && typeof value === 'object' && 'value' in value) {
        return { ...rest, [key]: value.value };
      }
      return { ...rest, [key]: value };
    }, {});

    setQueryParams({ ...queryParams, ...dynamicQueries });
  };

  useEffect(() => {
    const typeQuery = searchParams.get('type');

    if (typeQuery) {
      setSearchParams({ type: typeQuery });

      reset({ dateRange: '', ...resetFormData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type]);

  useQueryParams(queryParams);

  useEffect(() => {
    reset({
      dateRange: [startDate, endDate],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='grid grid-cols-1 sm:grid-cols-2 gap-4'>
      <div>
        <FormLabel htmlFor='dateRange'>
          <FormattedMessage id='date_range' />
        </FormLabel>
        <div className='mt-2'>
          <Controller
            control={control}
            name='dateRange'
            defaultValue={[startDate, endDate]}
            render={({ field: { onChange, ...field } }) => (
              <DatePicker
                showIcon
                {...field}
                onChange={(dates) => {
                  handleDateChange(dates);
                  onChange(dates);
                }}
                startDate={startDate}
                autoComplete='off'
                endDate={endDate}
                dateFormat={DateFormates.defaultDate}
                wrapperClassName='w-full'
                className='customer flex-1 h-full disabled:border-0'
                selectsRange
              />
            )}
          />
        </div>
      </div>
      {fields && <FormFields fields={fields} register={register} errors={errors} control={control} />}
      <div className='flex gap-4'>
        <Button
          variant={ButtonVariants.CANCEL_OUTLINED}
          className='flex-1 sm:flex-initial sm:self-end'
          onClick={handleResetForm}
        >
          <FormattedMessage id='reset_filters' />
        </Button>
        <Button className='flex-1 sm:flex-initial sm:self-end' type='submit'>
          <FormattedMessage id='search' />
        </Button>
      </div>
    </form>
  );
};
