import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Notification, NotificationsResponse } from 'interfaces/notifications.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateNotification,
  asyncDeleteNotification,
  asyncGetNotification,
  asyncUpdateNotification,
  unsetNotification,
  asyncGetNotifications,
  asyncConfirmNotification,
} from './action';
import { NotificationsSliceState } from './slice';

export function getNotificationsReducer(builder: ActionReducerMapBuilder<NotificationsSliceState>) {
  builder.addCase(asyncGetNotifications.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetNotifications.fulfilled, (state, { payload }: PayloadAction<NotificationsResponse>) => {
    state.notifications = payload.data;
    state.notificationsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetNotifications.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getNotificationReducer(builder: ActionReducerMapBuilder<NotificationsSliceState>) {
  builder.addCase(asyncGetNotification.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetNotification.fulfilled, (state, { payload }: PayloadAction<Notification>) => {
    state.notification = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetNotification.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateNotificationReducer = withLoadingReducer(asyncUpdateNotification);
export const createNotificationReducer = withLoadingReducer(asyncCreateNotification);
export const confirmNotificationReducer = withLoadingReducer(asyncConfirmNotification);

export function deleteNotificationReducer(builder: ActionReducerMapBuilder<NotificationsSliceState>) {
  builder.addCase(asyncDeleteNotification.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteNotification.fulfilled, (state) => {
    state.notification = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteNotification.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetNotificationReducer(builder: ActionReducerMapBuilder<NotificationsSliceState>) {
  builder.addCase(unsetNotification, (state) => {
    state.notification = null;
  });
}
