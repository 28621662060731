import { TableColumn } from './shared/table.constants';

export const INCONSISTENCIES_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'creation_date', title: 'creation_date' },
  { id: 'description', title: 'description' },
  { id: 'corrective_actions', title: 'actions' },
  { id: 'department', title: 'department' },
  { id: 'inconsistency_type', title: 'inconsistency_type' },
  { id: 'author', title: 'author', className: 'last:text-left' },
];

export const INCONSISTENCIES_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  department: {
    label: 'department',
    name: 'departmentSelect',
    placeholder: 'search_departments',
  },
  inconsistencyType: {
    label: 'inconsistency_type',
    name: 'instabilityTypeSelect',
    placeholder: 'search_inconsistency',
  },
};
