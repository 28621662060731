import { FC } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as EditSvg } from 'assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ReactComponent as ViewSvg } from 'assets/svg/view.svg';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';

import { Button } from '../Button';
import { Link } from '../Link';

export interface ControlProps {
  handleDelete: VoidFunction;
  editPermission?: string;
  editClassName?: string;
  deletePermission?: string;
  deleteClassName?: string;
  viewPermission?: string;
  editRoute: string;
  viewRoute?: string;
  className?: string;
}

export const ColumnDefaultControls: FC<ControlProps> = ({
  handleDelete,
  editRoute,
  editPermission,
  editClassName,
  viewRoute,
  deletePermission,
  deleteClassName,
  viewPermission,
  className,
}) => {
  const user = useAppSelector(selectCurrentUser);

  const hasPermission = (permission?: string) => (permission ? user?.permissions.includes(permission) : true);

  const isFullAccess = hasPermission('full.access');

  return (
    <div className={twMerge('flex', className)}>
      {(isFullAccess || hasPermission(editPermission)) && (
        <Link
          variant={LinkVariants.TRANSPARENT}
          className={twMerge(cn('px-2 flex items-center group', editClassName))}
          to={editRoute}
          data-test='edit-item'
        >
          <EditSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
        </Link>
      )}
      {(isFullAccess || hasPermission(deletePermission)) && (
        <Button
          onClick={(event) => {
            event.stopPropagation();
            handleDelete();
          }}
          className={twMerge(cn('px-2 group', deleteClassName))}
          variant={ButtonVariants.TRANSPARENT}
          data-test='remove-item'
        >
          <DeleteSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
        </Button>
      )}
      {viewPermission && (isFullAccess || hasPermission(viewPermission)) && (
        <Link
          variant={LinkVariants.TRANSPARENT}
          className='px-2 flex items-center group'
          to={viewRoute ?? ''}
          data-test='edit-item'
        >
          <ViewSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
        </Link>
      )}
    </div>
  );
};
