import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Education, EducationsResponse } from 'interfaces/educations.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateEducation,
  asyncDeleteEducation,
  asyncGetEducation,
  asyncGetEducations,
  asyncUpdateEducation,
  unsetEducation,
} from './action';
import { EducationsSliceState } from './slice';

export function getEducationsReducer(builder: ActionReducerMapBuilder<EducationsSliceState>) {
  builder.addCase(asyncGetEducations.fulfilled, (state, { payload }: PayloadAction<EducationsResponse>) => {
    state.educations = payload.data;
    state.educationsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetEducations.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getEducationReducer(builder: ActionReducerMapBuilder<EducationsSliceState>) {
  builder.addCase(asyncGetEducation.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetEducation.fulfilled, (state, { payload }: PayloadAction<Education>) => {
    state.education = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetEducation.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateEducationReducer = withLoadingReducer(asyncUpdateEducation);

export const createEducationReducer = withLoadingReducer(asyncCreateEducation);

export function deleteEducationReducer(builder: ActionReducerMapBuilder<EducationsSliceState>) {
  builder.addCase(asyncDeleteEducation.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteEducation.fulfilled, (state) => {
    state.education = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteEducation.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetEducationReducer(builder: ActionReducerMapBuilder<EducationsSliceState>) {
  builder.addCase(unsetEducation, (state) => {
    state.education = null;
  });
}
