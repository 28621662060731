import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Avatar } from 'shared-components/Avatar';

export const ProfileAvatar = () => {
  const user = useAppSelector(selectCurrentUser);

  if (!user) return null;
  return (
    <div className='flex flex-col items-center gap-2'>
      <Avatar
        className='w-20 h-20'
        src='https://www.kraftwerk.at/app/uploads/fly-images/962/reference-img-worlds-of-adventure-park-4-1920x9999.jpg'
        alt='user-avatar'
      />
      <p className='text-xl'>{user.name}</p>
    </div>
  );
};
