import { useEffect, useState } from 'react';

import { NotificationSelects } from 'interfaces/notifications.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useNotificationsDefaultOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<NotificationSelects>();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const departmentSelect = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, departmentSelect }));
      });
  }, [dispatch]);

  return filterSelects;
};
