import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { selectIsLoading } from 'modules/product-groups/selectors';
import { useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ProductGroupForm } from 'page-components/product-groups/ProductGroupForm';

export const ProductGroup = () => {
  const { pathname } = useLocation();

  const isLoading = useAppSelector(selectIsLoading);

  const editTitle = pathname === AppRoutes.productGroupCreate ? 'create_product_group' : 'edit_product_group';
  const titleText = pathname.includes('view') ? 'view_product_group' : editTitle;

  return (
    <PageLayout isLoading={isLoading} title={titleText} wrapperClassName='flex flex-col gap-4'>
      <ProductGroupForm />
    </PageLayout>
  );
};
