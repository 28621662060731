import { FC, useEffect, useState } from 'react';

import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  id?: string;
  control: Control<CustomerRequestInput>;
  defaultResponsible?: SelectOption;
  error?: string;
}

export const CustomerRequestResponsible: FC<Props> = ({ defaultResponsible, control, error, id }) => {
  const user = useAppSelector(selectCurrentUser);

  const { formatMessage } = useIntl();

  const [responsibleOptions, setResponsibleOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    if (!user) return;

    const responsible = user.subordinates.map(({ id, name }) => ({ value: id, label: name }));
    const formattedResponsible = [{ value: user.id, label: user.name }, ...responsible];

    setResponsibleOptions(formattedResponsible);
  }, [user]);

  if (!responsibleOptions?.length || (!defaultResponsible && id)) return null;

  return (
    <Card title='responsible'>
      <Controller
        control={control}
        name='responsibleSelect'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultResponsible ?? responsibleOptions[0]}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            defaultValue={defaultResponsible ?? responsibleOptions[0]}
            options={responsibleOptions}
            className='flex-1 h-full'
            error={error}
            placeholder={formatMessage({ id: 'search_responsible' })}
          />
        )}
      />
    </Card>
  );
};
