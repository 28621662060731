import { replaceCamelCase, toUpperCaseFirstLater } from './role.utils';

export const getFormattedName = (name: string) => {
  const label = toUpperCaseFirstLater(name.split('.')[1]);
  return replaceCamelCase(label);
};

export const generateRandomRGBA = () => {
  const red = Math.floor(Math.random() * 256);
  const green = Math.floor(Math.random() * 256);
  const blue = Math.floor(Math.random() * 256);

  const rgbaColor = 'rgba(' + red + ', ' + green + ', ' + blue + ', ' + '0.2)';

  return rgbaColor;
};
