import { useEffect, useState } from 'react';

import { Risk } from 'interfaces/risks.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useRiskOptions = (risk: Risk | null) => {
  const dispatch = useAppDispatch();

  const [responsibleOptions, setResponsibleOptions] = useState<SelectOption[]>();
  const [departmentsOptions, setDepartmentsOptions] = useState<SelectOption[]>();

  const [defaultResponsibleOption, setDefaultResponsibleOption] = useState<SelectOption>();
  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(getUsers())
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setResponsibleOptions(options);
        setDefaultResponsibleOption(options.find(({ value }) => value === risk?.responsible.id));
      });

    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDepartmentsOptions(options);
        setDefaultDepartmentOption(options.find(({ value }) => value === risk?.department?.id));
      });
  }, [dispatch, risk]);

  return { responsibleOptions, departmentsOptions, defaultResponsibleOption, defaultDepartmentOption };
};
