import { createSlice } from '@reduxjs/toolkit';

import { City } from 'interfaces/cities.interfaces';

import { CITIES_SLICE_NAME } from './action';
import {
  createCityReducer,
  deleteCityReducer,
  unsetCityReducer,
  getCitiesReducer,
  getCityReducer,
  updateCityReducer,
} from './reducers';

export interface CitiesSliceState {
  isLoading: boolean;
  city: City | null;
  cities: City[] | null;
  citiesCount: number | null;
}

export const initialState: CitiesSliceState = {
  city: null,
  cities: null,
  citiesCount: null,
  isLoading: false,
};

const citiesSlice = createSlice({
  name: CITIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCityReducer(builder);
    deleteCityReducer(builder);
    unsetCityReducer(builder);
    getCitiesReducer(builder);
    getCityReducer(builder);
    updateCityReducer(builder);
  },
});

export const citiesReducer = citiesSlice.reducer;
