import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { complectsApi } from 'apis/complects.apis';
import { Complect, ComplectInput } from 'interfaces/complects.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const COMPLECTS_SLICE_NAME = 'complects';

export const asyncGetComplects = createAsyncThunk(
  `${COMPLECTS_SLICE_NAME}/getComplects`,
  async (payload: QueryParams, { rejectWithValue }) => {
    try {
      const response = await complectsApi.getComplects(payload);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetComplect = createAsyncThunk(
  `${COMPLECTS_SLICE_NAME}/getComplect`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await complectsApi.getComplectById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateComplect = createAsyncThunk(
  `${COMPLECTS_SLICE_NAME}/updateComplect`,
  async (payload: Complect, { rejectWithValue }) => {
    try {
      await complectsApi.updateComplect(payload);
      toast.success(intl.formatMessage({ id: 'city_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateComplect = createAsyncThunk(
  `${COMPLECTS_SLICE_NAME}/createComplect`,
  async (payload: ComplectInput, { rejectWithValue }) => {
    try {
      await complectsApi.createComplect(payload);
      toast.success(intl.formatMessage({ id: 'city_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteComplect = createAsyncThunk(
  `${COMPLECTS_SLICE_NAME}/deleteComplect`,
  async (id: string, { rejectWithValue }) => {
    try {
      await complectsApi.deleteComplect(id);
      toast.success(intl.formatMessage({ id: 'city_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetComplect = createAction('UNSET_COMPLECT');
