import { FC } from 'react';

import { useSearchParams } from 'react-router-dom';

import { ReactComponent as EditSvg } from 'assets/svg/edit.svg';
import { ReactComponent as DeleteSvg } from 'assets/svg/trash-can.svg';
import { ReactComponent as ViewSvg } from 'assets/svg/view.svg';
import { ManagerAction } from 'interfaces/manager-actions.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { Button } from 'shared-components/Button';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { formatToDefaultDate, parseDateWithHours } from 'utils/date.utils';

interface Props {
  handleOpen: VoidFunction;
  handleDelete: VoidFunction;
  data: ManagerAction;
}

export const ManagerActionsTableRow: FC<Props> = ({
  handleOpen,
  handleDelete,
  data: { id, content, requestStatus, createdAt },
}) => {
  const creationDate = parseDateWithHours(createdAt);
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <TableRow>
      <TableData>
        <div className='flex'>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleOpen();
            }}
            className='px-2 group'
            variant={ButtonVariants.TRANSPARENT}
            data-test='remove-item'
          >
            <EditSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleDelete();
            }}
            className='px-2 group'
            variant={ButtonVariants.TRANSPARENT}
            data-test='remove-item'
          >
            <DeleteSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
          </Button>
          <Button
            onClick={(event) => {
              event.stopPropagation();
              handleOpen();
              searchParams.set('view', 'true');
              setSearchParams(searchParams);
            }}
            className='px-2 group'
            variant={ButtonVariants.TRANSPARENT}
            data-test='remove-item'
          >
            <ViewSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
          </Button>
        </div>
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{formatToDefaultDate(creationDate)}</TableData>
      <TableData>{content}</TableData>
      <TableData className='last:text-left'>{requestStatus.name}</TableData>
    </TableRow>
  );
};
