import { FC } from 'react';

import { Outlet, Navigate, useLocation } from 'react-router-dom';

export const PermissionRoute: FC<{
  hasPermission?: boolean;
  to: string;
}> = ({ hasPermission, to }) => {
  const location = useLocation();

  const navigateState = !hasPermission ? { from: location.pathname, search: location.search } : {};

  return hasPermission ? <Outlet /> : <Navigate to={to} state={navigateState} replace />;
};
