import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { countriesApi } from 'apis/countries.apis';
import { Country, CountryInput } from 'interfaces/countries.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const COUNTRIES_SLICE_NAME = 'countries';

export const asyncGetCountries = createAsyncThunk(
  `${COUNTRIES_SLICE_NAME}/getCountries`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await countriesApi.getCountries(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCountry = createAsyncThunk(
  `${COUNTRIES_SLICE_NAME}/getCountry`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await countriesApi.getCountryById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCountry = createAsyncThunk(
  `${COUNTRIES_SLICE_NAME}/updateCountry`,
  async (payload: Country, { rejectWithValue }) => {
    try {
      await countriesApi.updateCountry(payload);
      toast.success(intl.formatMessage({ id: 'country_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCountry = createAsyncThunk(
  `${COUNTRIES_SLICE_NAME}/createCountry`,
  async (payload: CountryInput, { rejectWithValue }) => {
    try {
      await countriesApi.createCountry(payload);
      toast.success(intl.formatMessage({ id: 'country_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCountry = createAsyncThunk(
  `${COUNTRIES_SLICE_NAME}/deleteCountry`,
  async (id: number, { rejectWithValue }) => {
    try {
      await countriesApi.deleteCountry(id);
      toast.success(intl.formatMessage({ id: 'country_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCountry = createAction('UNSET_COUNTRY');
