import { InconsistencyItem, InconsistencyTree } from 'interfaces/inconsistency-types.interfaces';

export const convertDataForTreeView = (data?: InconsistencyItem[] | null): InconsistencyTree[] => {
  return data
    ? data.map((item) => ({
        id: item.id,
        name: item.name,
        children: item.children ? convertDataForTreeView(item.children) : [],
      }))
    : [];
};
