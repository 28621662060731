import { useEffect, useState } from 'react';

import { flattenTree } from 'react-accessible-treeview';

import { PRODUCT_GROUPS_COLUMNS } from 'constants/product-groups.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteProductGroup, asyncGetProductGroups } from 'modules/product-groups/action';
import { selectIsLoading, selectProductGroups, selectProductGroupsCount } from 'modules/product-groups/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ProductGroupsTableRow } from 'page-components/product-groups/ProductGroupsTableRow';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';
import { convertDataForTreeView } from 'utils/product-groups.utils';

export const ProductGroups = () => {
  const [productGroupId, setProductGroupId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const productGroups = useAppSelector(selectProductGroups);
  const productGroupsCount = useAppSelector(selectProductGroupsCount);

  const formattedGroups = flattenTree({ children: convertDataForTreeView(productGroups), id: 0, name: '' })?.filter(
    (_, index) => index !== 0,
  );

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setProductGroupId(id);
    handleOpenModal();
  };

  const onProductGroupDelete = async () => {
    if (productGroupId) {
      await dispatch(asyncDeleteProductGroup(productGroupId));
      await dispatch(asyncGetProductGroups({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetProductGroups({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='product_groups' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='product_groups_management'
        columns={PRODUCT_GROUPS_COLUMNS}
        creationPath={AppRoutes.productGroupCreate}
        isLoading={isLoading}
      >
        {formattedGroups?.length ? (
          formattedGroups.map((productGroup) => (
            <ProductGroupsTableRow
              key={productGroup.id}
              data={productGroup}
              parent={formattedGroups.find((groups) => groups.id === productGroup.parent)}
              handleDelete={onModalOpen(+productGroup.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={PRODUCT_GROUPS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={productGroupsCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onProductGroupDelete}
        title='delete_product_group'
      />
    </PageLayout>
  );
};
