import { createSlice } from '@reduxjs/toolkit';

import { Country } from 'interfaces/countries.interfaces';

import { COUNTRIES_SLICE_NAME } from './action';
import {
  createCountryReducer,
  deleteCountryReducer,
  unsetCountryReducer,
  getCountriesReducer,
  getCountryReducer,
  updateCountryReducer,
} from './reducers';

export interface CountriesSliceState {
  isLoading: boolean;
  country: Country | null;
  countries: Country[] | null;
  countriesCount: number | null;
}

export const initialState: CountriesSliceState = {
  country: null,
  countries: null,
  countriesCount: null,
  isLoading: false,
};

const countriesSlice = createSlice({
  name: COUNTRIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCountryReducer(builder);
    deleteCountryReducer(builder);
    unsetCountryReducer(builder);
    getCountriesReducer(builder);
    getCountryReducer(builder);
    updateCountryReducer(builder);
  },
});

export const countriesReducer = countriesSlice.reducer;
