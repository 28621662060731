import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { DocumentsResponse, Document } from 'interfaces/documents.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateDocument,
  asyncDeleteDocument,
  asyncGetDocument,
  asyncGetDocuments,
  asyncUpdateDocument,
  unsetDocument,
} from './action';
import { DocumentsSliceState } from './slice';

export function getDocumentsReducer(builder: ActionReducerMapBuilder<DocumentsSliceState>) {
  builder.addCase(asyncGetDocuments.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetDocuments.fulfilled, (state, { payload }: PayloadAction<DocumentsResponse>) => {
    state.documents = payload.data;
    state.documentsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDocuments.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getDocumentReducer(builder: ActionReducerMapBuilder<DocumentsSliceState>) {
  builder.addCase(asyncGetDocument.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetDocument.fulfilled, (state, { payload }: PayloadAction<Document>) => {
    state.document = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDocument.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateDocumentReducer = withLoadingReducer(asyncUpdateDocument);
export const createDocumentReducer = withLoadingReducer(asyncCreateDocument);

export function deleteDocumentReducer(builder: ActionReducerMapBuilder<DocumentsSliceState>) {
  builder.addCase(asyncDeleteDocument.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteDocument.fulfilled, (state) => {
    state.document = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteDocument.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetDocumentReducer(builder: ActionReducerMapBuilder<DocumentsSliceState>) {
  builder.addCase(unsetDocument, (state) => {
    state.document = null;
  });
}
