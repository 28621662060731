import { TableColumn } from './shared/table.constants';

export const COMPLAINTS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'creation_date', title: 'creation_date' },
  { id: 'description', title: 'description' },
  { id: 'customer', title: 'customer' },
  { id: 'responsible', title: 'responsible', className: 'last:text-left' },
];

export const COMPLAINTS_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  responsible: {
    label: 'responsible',
    name: 'responsibleSelect',
    placeholder: 'search_responsible',
  },
  customer: {
    label: 'customer',
    name: 'customerSelect',
    placeholder: 'search_customer',
  },
};
