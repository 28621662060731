import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { documentsApi } from 'apis/document.apis';
import { UpdateDocumentPayload, DocumentPayload } from 'interfaces/documents.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const DOCUMENT_SLICE_NAME = 'documents';

export const asyncGetDocuments = createAsyncThunk(
  `${DOCUMENT_SLICE_NAME}/getDocuments`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await documentsApi.getDocuments(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetDocument = createAsyncThunk(
  `${DOCUMENT_SLICE_NAME}/getDocument`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await documentsApi.getDocumentById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateDocument = createAsyncThunk(
  `${DOCUMENT_SLICE_NAME}/updateDocument`,
  async (payload: UpdateDocumentPayload, { rejectWithValue }) => {
    try {
      await documentsApi.updateDocument(payload);
      toast.success(intl.formatMessage({ id: 'document_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateDocument = createAsyncThunk(
  `${DOCUMENT_SLICE_NAME}/createDocument`,
  async (payload: DocumentPayload, { rejectWithValue }) => {
    try {
      await documentsApi.createDocument(payload);
      toast.success(intl.formatMessage({ id: 'document_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteDocument = createAsyncThunk(
  `${DOCUMENT_SLICE_NAME}/deleteDocument`,
  async (id: number, { rejectWithValue }) => {
    try {
      await documentsApi.deleteDocument(id);
      toast.success(intl.formatMessage({ id: 'document_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetDocument = createAction('UNSET_DOCUMENT');
