import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import { RiskStatuses } from 'constants/risks.constsnts';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { asyncCompleteRisk } from 'modules/risks/action';
import { selectRisk } from 'modules/risks/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components/Button';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const RiskProceedNavigation = () => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const risk = useAppSelector(selectRisk);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const handleConfirmComplete = () => {
    if (!id) return;
    dispatch(asyncCompleteRisk(+id));
  };

  return (
    <Card className='flex gap-2'>
      <ItemNavigation btnClassName='hidden' confirmLabel='complete' navigateBack={AppRoutes.risks} />
      {risk?.status !== RiskStatuses.completed && (
        <>
          <Button onClick={handleOpenModal}>
            <FormattedMessage id='complete' />
          </Button>
          <ConfirmationModal
            isOpened={isModalOpened}
            handleCloseModal={handleCloseModal}
            handleApply={handleConfirmComplete}
            title='risk_complete'
          />
        </>
      )}
    </Card>
  );
};
