import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customerStatusesApi } from 'apis/customer-statuses.apis';
import { CustomerStatus, CustomerStatusInput } from 'interfaces/customer-statuses.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CUSTOMER_STATUSES_SLICE_NAME = 'customer-statuses';

export const asyncGetCustomerStatuses = createAsyncThunk(
  `${CUSTOMER_STATUSES_SLICE_NAME}/getCustomerStatuses`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await customerStatusesApi.getCustomerStatuses(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerStatus = createAsyncThunk(
  `${CUSTOMER_STATUSES_SLICE_NAME}/getCustomerStatus`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await customerStatusesApi.getCustomerStatusById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCustomerStatus = createAsyncThunk(
  `${CUSTOMER_STATUSES_SLICE_NAME}/updateCustomerStatus`,
  async (payload: CustomerStatus, { rejectWithValue }) => {
    try {
      await customerStatusesApi.updateCustomerStatus(payload);
      toast.success(intl.formatMessage({ id: 'customer_status_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCustomerStatus = createAsyncThunk(
  `${CUSTOMER_STATUSES_SLICE_NAME}/createCustomerStatus`,
  async (payload: CustomerStatusInput, { rejectWithValue }) => {
    try {
      await customerStatusesApi.createCustomerStatus(payload);
      toast.success(intl.formatMessage({ id: 'customer_status_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCustomerStatus = createAsyncThunk(
  `${CUSTOMER_STATUSES_SLICE_NAME}/deleteCustomerStatus`,
  async (id: number, { rejectWithValue }) => {
    try {
      await customerStatusesApi.deleteCustomerStatus(id);
      toast.success(intl.formatMessage({ id: 'customer_status_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCustomerStatus = createAction('UNSET_CUSTOMER_STATUS');
