import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { EducationInput } from 'interfaces/educations.interfaces';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { Textarea } from 'shared-components/Textarea';

export const EducationGeneralFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<EducationInput>();

  return (
    <Card title='general_info' wrapperClassName='flex-1' className='flex flex-col gap-8'>
      <Input
        {...register('theme', REQUIRED_VALIDATION)}
        isRequired
        error={errors['theme']?.message as string}
        label='subject'
        placeholder={formatMessage({ id: 'subject' })}
        data-test='subject'
      />
      <Textarea
        {...register('description', REQUIRED_VALIDATION)}
        isRequired
        label='description'
        error={errors['description']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='notes-description'
        rows={4}
      />
    </Card>
  );
};
