import { ElementType, FC } from 'react';

import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import { twMerge } from 'tailwind-merge';

interface Props {
  title: string;
  value?: string;
  icon?: ElementType;
  className?: string;
  textClassName?: string;
}

export const TitleWithValue: FC<Props> = ({ title, value, icon: Icon, className, textClassName }) => {
  return (
    <div className={twMerge('flex items-center mb-1', className)}>
      {Icon && <Icon className='min-w-6 w-6 h-6 mr-1 flex-shrink-0' />}
      <p className={twMerge(cn('font-bold flex flex-wrap', textClassName))}>
        <FormattedMessage id={title} defaultMessage={title} />: <span className='font-normal break-all	'>{value}</span>
      </p>
    </div>
  );
};
