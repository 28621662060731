import { FC, useEffect, useState } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useDisableCheckboxes } from 'hooks/useDisableCheckboxes';
import { RoleInput, RolesData } from 'interfaces/roles.interfaces';
import { asyncGetPermissions } from 'modules/permissions/action';
import { selectPermissions } from 'modules/permissions/selectors';
import { asyncCreateRole, asyncUpdateRole, unsetRole } from 'modules/roles/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { getObjectKeysAsNumber, toUpperCaseFirstLater } from 'utils/role.utils';

import { RoleAllPermissions } from './RoleAllPermissions';
import { RolePermissionTab } from './RolePermissionTab';
import { RoleUsers } from './RoleUsers';

interface Props {
  role: RolesData | null;
}

export const RoleForm: FC<Props> = ({ role }) => {
  const [permissionGroups, setPermissionGroups] = useState<string[]>([]);

  const { formatMessage } = useIntl();

  const permissions = useAppSelector(selectPermissions);

  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const { id } = useParams();

  const methods = useForm<RoleInput>();

  const {
    watch,
    reset,
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = methods;

  const onSubmit: SubmitHandler<RoleInput> = async (values) => {
    const { humanName, usersSelect, supervisorsSelect, subordinatesSelect, ...checks } = values;

    const permissionIds = getObjectKeysAsNumber(checks);
    const users = usersSelect.map(({ value }) => value) as number[];

    const formattedData = { humanName, users, permissions: permissionIds, supervisorsSelect, subordinatesSelect };

    await dispatch(id ? asyncUpdateRole({ id, ...formattedData }) : asyncCreateRole(formattedData));

    dispatch(unsetRole());
    navigate(AppRoutes.roles);
  };

  const fullAccess = watch('full.access');

  useDisableCheckboxes(fullAccess, permissions ?? {}, reset, getValues);

  useBeforeRouteChange(() => dispatch(unsetRole()));

  useEffect(() => {
    dispatch(asyncGetPermissions())
      .unwrap()
      .then((response) => {
        setPermissionGroups(Object.keys(response));
      });
  }, [dispatch, id]);

  useEffect(() => {
    const isPreviousRoleUnset = id === `${role?.id}`;

    if (id && role && isPreviousRoleUnset && permissions) {
      const permissionIds = Object.values(permissions)
        .flatMap((value) => value)
        .filter((value) => role.permissions.some((permission) => permission === value.name))
        .map((value) => ({ name: value.name, id: value.id }));

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const initialValues = permissionIds.reduce((obj, item) => ((obj[item.name] = item.id), obj), {});
      reset({ humanName: role.humanName, ...initialValues });
    }
  }, [id, reset, role, permissions]);

  if (!permissions) return null;

  return (
    <>
      <FormProvider {...methods}>
        <form id='main' onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-8'>
          <div className='flex flex-col md:flex-row justify-between gap-4 mb-4'>
            <Card wrapperClassName='w-full md:w-1/2 lg:w-1/3 self-start' title='general_info'>
              <Input
                {...register('humanName', REQUIRED_VALIDATION)}
                isRequired
                label='title'
                error={errors['humanName']?.message}
                placeholder={formatMessage({ id: 'title' })}
                data-test='role-name'
              />
            </Card>
            <Card title='users' wrapperClassName='flex-1 overflow-visible'>
              <RoleUsers role={role} />
            </Card>
          </div>
          <Tabs>
            <TabList className='flex justify-start space-x-4 cursor-pointer text-mediumBlue font-medium'>
              <Tab className='p-2 rounded outline-none'>
                <FormattedMessage id='all_permissions' />
              </Tab>
              {!fullAccess &&
                permissionGroups.map((permission) => (
                  <Tab data-test='permission-tab' key={permission} className='p-2 rounded outline-none'>
                    {toUpperCaseFirstLater(permission)}
                  </Tab>
                ))}
            </TabList>
            <TabPanel>
              <RoleAllPermissions permissions={permissions} isFullAccess={!!fullAccess} />
            </TabPanel>
            {!fullAccess &&
              permissionGroups.map((permission) => (
                <TabPanel data-test='permission-tab-item' key={permission}>
                  <RolePermissionTab permissionName={permission} permissions={permissions} role={role} />
                </TabPanel>
              ))}
          </Tabs>
        </form>
      </FormProvider>
    </>
  );
};
