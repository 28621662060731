import { useEffect, useState } from 'react';

import { CUSTOMER_REQUEST_STATUSES_COLUMNS } from 'constants/customer-request-statuses';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  asyncDeleteCustomerRequestStatus,
  asyncGetCustomerRequestStatuses,
} from 'modules/customer-request-statuses/action';
import {
  selectCustomerRequestStatuses,
  selectCustomerRequestStatusesCount,
  selectIsLoading,
} from 'modules/customer-request-statuses/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CustomerRequestStatusesTableRow } from 'page-components/customer-request-statuses/CustomerRequestStatusesTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const CustomerRequestStatuses = () => {
  const [countryId, setCountryId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const customerRequestStatuses = useAppSelector(selectCustomerRequestStatuses);
  const customerRequestStatusesCount = useAppSelector(selectCustomerRequestStatusesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setCountryId(id);
    handleOpenModal();
  };

  const onCustomerRequestStatusDelete = async () => {
    if (countryId) {
      await dispatch(asyncDeleteCustomerRequestStatus(countryId));
      await dispatch(asyncGetCustomerRequestStatuses({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetCustomerRequestStatuses({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='customer_request_statuses' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='customer_request_statuses_management'
        columns={CUSTOMER_REQUEST_STATUSES_COLUMNS}
        creationPath={AppRoutes.customerRequestStatusCreate}
        isLoading={isLoading}
      >
        {customerRequestStatuses?.length ? (
          customerRequestStatuses.map((customerRequestStatus) => (
            <CustomerRequestStatusesTableRow
              key={customerRequestStatus.id}
              data={customerRequestStatus}
              handleDelete={onModalOpen(customerRequestStatus.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMER_REQUEST_STATUSES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={customerRequestStatusesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCustomerRequestStatusDelete}
        title='delete_customer_request_status'
      />
    </PageLayout>
  );
};
