import { forwardRef, InputHTMLAttributes } from 'react';

import cn from 'classnames';
import { twMerge } from 'tailwind-merge';

import { ReactComponent as CheckMarkSvg } from 'assets/svg/checkmark.svg';
import { FormLabel } from 'shared-components/FormLabel';

export interface Props extends InputHTMLAttributes<HTMLInputElement> {
  error?: string | object;
  labelClassName?: string;
  label?: string;
}

export const Checkbox = forwardRef<HTMLInputElement, Props>(
  ({ label, error, labelClassName, className, disabled, ...props }, ref) => {
    return (
      <FormLabel
        className={cn(
          'flex items-center space-x-2 cursor-pointer select-none',
          { 'opacity-50': disabled },
          labelClassName,
        )}
      >
        <span className='relative flex items-center'>
          <input
            disabled={disabled}
            type='checkbox'
            className={twMerge(
              cn(
                'peer appearance-none border border-mediumBlue rounded w-4 h-4 checked:bg-mediumBlue disabled:opacity-50',
                {
                  'border-red': error,
                },
                className,
              ),
            )}
            ref={ref}
            {...props}
          />
          <CheckMarkSvg className='absolute w-3 h-3 top-0.5 left-0.5 hidden peer-checked:block text-white' />
        </span>
        <span className='text-gray-700'>{label}</span>
      </FormLabel>
    );
  },
);

Checkbox.displayName = 'Checkbox';
