import { useEffect, useState } from 'react';

import { Audit } from 'interfaces/audits.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useAuditOptions = (audit?: Audit | null) => {
  const dispatch = useAppDispatch();

  const [listenersOptions, setListenersOptions] = useState<SelectOption[]>();
  const [departmentsOptions, setDepartmentsOptions] = useState<SelectOption[]>();

  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<SelectOption[]>();
  const [defaultListenersOptions, setDefaultListenersOptions] = useState<SelectOption[]>();
  const [defaultResponsibleOption, setDefaultResponsibleOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(getUsers())
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setListenersOptions(options);
        setDefaultListenersOptions(options.filter(({ value }) => audit?.auditors?.some(({ id }) => value === id)));
        setDefaultResponsibleOption(options.find(({ value }) => value === audit?.responsible?.id));
      });

    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDepartmentsOptions(options);
        setDefaultDepartmentOption(options.filter(({ value }) => audit?.departments?.some(({ id }) => value === id)));
      });
  }, [dispatch, audit]);

  return {
    listenersOptions,
    defaultListenersOptions,
    defaultResponsibleOption,
    departmentsOptions,
    defaultDepartmentOption,
  };
};
