import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Department, DepartmentsResponse } from 'interfaces/departments.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateDepartment,
  asyncGetDepartment,
  asyncDeleteDepartment,
  asyncUpdateDepartment,
  asyncGetDepartments,
  unsetDepartment,
} from './action';
import { DepartmentsSliceState } from './slice';

export function getDepartmentsReducer(builder: ActionReducerMapBuilder<DepartmentsSliceState>) {
  builder.addCase(asyncGetDepartments.fulfilled, (state, { payload }: PayloadAction<DepartmentsResponse>) => {
    state.departments = payload.data;
    state.departmentsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDepartments.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getDepartmentReducer(builder: ActionReducerMapBuilder<DepartmentsSliceState>) {
  builder.addCase(asyncGetDepartment.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetDepartment.fulfilled, (state, { payload }: PayloadAction<Department>) => {
    state.department = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetDepartment.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateDepartmentReducer = withLoadingReducer(asyncUpdateDepartment);

export const createDepartmentReducer = withLoadingReducer(asyncCreateDepartment);

export function deleteDepartmentReducer(builder: ActionReducerMapBuilder<DepartmentsSliceState>) {
  builder.addCase(asyncDeleteDepartment.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteDepartment.fulfilled, (state) => {
    state.department = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteDepartment.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetDepartmentReducer(builder: ActionReducerMapBuilder<DepartmentsSliceState>) {
  builder.addCase(unsetDepartment, (state) => {
    state.department = null;
  });
}
