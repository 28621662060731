import { useEffect } from 'react';

import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { asyncGetNotification } from 'modules/notifications/action';
import { useAppDispatch } from 'modules/store';
import { NotificationTable } from 'page-components/notifications/NotificationTable';
import { NotificationViewInfo } from 'page-components/notifications/NotificationViewInfo';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { Button } from 'shared-components/Button';

export const NotificationView = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const onGoBack = () => navigate(-1);

  useEffect(() => {
    if (id) {
      dispatch(asyncGetNotification(id));
    }
  }, [dispatch, id]);

  return (
    <PageLayout title='view_notification' wrapperClassName='flex flex-col gap-4'>
      <NotificationViewInfo />
      <NotificationTable />
      <Button onClick={onGoBack} variant={ButtonVariants.CANCEL_OUTLINED}>
        <FormattedMessage id='go_back' />
      </Button>
    </PageLayout>
  );
};
