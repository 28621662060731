import { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useEducationDates } from 'hooks/useEducationDates';
import { EducationInput } from 'interfaces/educations.interfaces';
import {
  asyncCreateEducation,
  asyncGetEducation,
  asyncUpdateEducation,
  unsetEducation,
} from 'modules/educations/action';
import { selectEducation } from 'modules/educations/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { formatToDefaultDate, formatToInputDate, parseDefaultDate } from 'utils/date.utils';

import { EducationDatesFields } from './EducationDatesFields';
import { EducationGeneralFields } from './EducationGeneralFields';
import { EducationVisitorFields } from './EducationVisitorFields';

export const EducationForm = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const isViewMode = pathname.includes('view');

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const education = useAppSelector(selectEducation);

  const methods = useForm<EducationInput>();

  const { reset, handleSubmit } = methods;

  const defaultOptions = useEducationDates(reset, education);

  const onSubmit: SubmitHandler<EducationInput> = ({
    users: listenerOptions,
    responsible: responsibleOption,
    finishedAt: finishedDate,
    plannedAt: plannedDate,
    ...data
  }) => {
    const users = listenerOptions.map(({ value }) => value) as number[];
    const responsible = responsibleOption.value as number;
    const finishedAt = formatToDefaultDate(finishedDate as unknown as Date);
    const plannedAt = formatToDefaultDate(plannedDate as unknown as Date);

    const formattedData = { users, responsible, finishedAt, plannedAt, ...data };

    dispatch(id ? asyncUpdateEducation({ id: +id, ...formattedData }) : asyncCreateEducation(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.educations);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetEducation()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetEducation(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && education) {
      reset({
        theme: education.theme,
        description: education.description,
        responsible: { value: education.responsible.id, label: education.responsible.name },
        users: education.users.map(({ id, name }) => ({ value: id, label: name })),
        plannedAt: formatToInputDate(parseDefaultDate(education.plannedAt)),
        finishedAt: formatToInputDate(parseDefaultDate(education.finishedAt)),
      });
    }
  }, [id, reset, education]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <FormProvider {...methods}>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <EducationGeneralFields />
          </div>
          <div className='flex-1 flex flex-col gap-6 md:self-start'>
            <EducationDatesFields
              defaultStart={defaultOptions.start}
              defaultRealStart={defaultOptions.realStart}
              isEditable={!isViewMode}
            />
            <EducationVisitorFields defaultListeners={education?.users} />
          </div>
        </div>
        <ItemNavigation navigateBack={AppRoutes.educations} />
      </FormProvider>
    </form>
  );
};
