import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { citiesApi } from 'apis/cities.apis';
import { CityData, CityPayload } from 'interfaces/cities.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CITIES_SLICE_NAME = 'cities';

export const asyncGetCities = createAsyncThunk(
  `${CITIES_SLICE_NAME}/getCities`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await citiesApi.getCities(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCity = createAsyncThunk(
  `${CITIES_SLICE_NAME}/getCity`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await citiesApi.getCityById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCity = createAsyncThunk(
  `${CITIES_SLICE_NAME}/updateCity`,
  async (payload: CityPayload, { rejectWithValue }) => {
    try {
      await citiesApi.updateCity(payload);
      toast.success(intl.formatMessage({ id: 'city_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCity = createAsyncThunk(
  `${CITIES_SLICE_NAME}/createCity`,
  async (payload: CityData, { rejectWithValue }) => {
    try {
      await citiesApi.createCity(payload);
      toast.success(intl.formatMessage({ id: 'city_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCity = createAsyncThunk(
  `${CITIES_SLICE_NAME}/deleteCity`,
  async (id: number, { rejectWithValue }) => {
    try {
      await citiesApi.deleteCity(id);
      toast.success(intl.formatMessage({ id: 'city_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCity = createAction('UNSET_CITY');
