import { useFormContext } from 'react-hook-form';

import { USER_INFO_FIELDS } from 'constants/users.constants';
import { UserInput } from 'interfaces/users.interfaces';
import { Card } from 'shared-components/Card';
import { FormFields } from 'shared-components/FormFields';

export const UserInfo = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext<UserInput>();

  return (
    <Card title='general_info' wrapperClassName='flex-1 self-start' className='flex flex-col gap-6'>
      <FormFields fields={USER_INFO_FIELDS} register={register} errors={errors} />
    </Card>
  );
};
