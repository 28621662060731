import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { documentTypesApi } from 'apis/document-types.apis';
import { DocumentType, DocumentTypeInput } from 'interfaces/document-types.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const DOCUMENT_TYPES_SLICE_NAME = 'document-types';

export const asyncGetDocumentTypes = createAsyncThunk(
  `${DOCUMENT_TYPES_SLICE_NAME}/getDocumentTypes`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await documentTypesApi.getDocumentTypes(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetDocumentType = createAsyncThunk(
  `${DOCUMENT_TYPES_SLICE_NAME}/getDocumentType`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await documentTypesApi.getDocumentTypeById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateDocumentType = createAsyncThunk(
  `${DOCUMENT_TYPES_SLICE_NAME}/updateDocumentType`,
  async (payload: DocumentType, { rejectWithValue }) => {
    try {
      await documentTypesApi.updateDocumentType(payload);
      toast.success(intl.formatMessage({ id: 'document_type_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateDocumentType = createAsyncThunk(
  `${DOCUMENT_TYPES_SLICE_NAME}/createDocumentType`,
  async (payload: DocumentTypeInput, { rejectWithValue }) => {
    try {
      await documentTypesApi.createDocumentType(payload);
      toast.success(intl.formatMessage({ id: 'document_type_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteDocumentType = createAsyncThunk(
  `${DOCUMENT_TYPES_SLICE_NAME}/deleteDocumentType`,
  async (id: number, { rejectWithValue }) => {
    try {
      await documentTypesApi.deleteDocumentType(id);
      toast.success(intl.formatMessage({ id: 'document_type_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetDocumentType = createAction('UNSET_DOCUMENT_TYPE');
