import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { productGroupsApi } from 'apis/product-groups.apis';
import { ProductGroupData, ProductGroupPayload } from 'interfaces/product-groups.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const PRODUCT_GROUPS_SLICE_NAME = 'product-groups';

export const asyncGetProductGroups = createAsyncThunk(
  `${PRODUCT_GROUPS_SLICE_NAME}/getProductGroups`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await productGroupsApi.getProductGroups(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetProductGroup = createAsyncThunk(
  `${PRODUCT_GROUPS_SLICE_NAME}/getProductGroup`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await productGroupsApi.getProductGroupById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateProductGroup = createAsyncThunk(
  `${PRODUCT_GROUPS_SLICE_NAME}/updateProductGroup`,
  async (payload: ProductGroupPayload, { rejectWithValue }) => {
    try {
      await productGroupsApi.updateProductGroup(payload);
      toast.success(intl.formatMessage({ id: 'product_group_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateProductGroup = createAsyncThunk(
  `${PRODUCT_GROUPS_SLICE_NAME}/createProductGroup`,
  async (payload: ProductGroupData, { rejectWithValue }) => {
    try {
      await productGroupsApi.createProductGroup(payload);
      toast.success(intl.formatMessage({ id: 'product_group_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteProductGroup = createAsyncThunk(
  `${PRODUCT_GROUPS_SLICE_NAME}/deleteProductGroup`,
  async (id: number, { rejectWithValue }) => {
    try {
      await productGroupsApi.deleteProductGroup(id);
      toast.success(intl.formatMessage({ id: 'product_group_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetProductGroup = createAction('UNSET_PRODUCT_GROUP');
