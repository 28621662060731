import { FormattedMessage } from 'react-intl';

import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { Link } from 'shared-components/Link';

export const NotificationsListItem = () => {
  return (
    <tr className='bg-red1/90 text-white [&>td]:p-4 [&>td]:w-fit'>
      <td>1</td>
      <td>08.11.2023</td>
      <td>Test Notification</td>
      <td>
        <FormattedMessage id='notifications_count' values={{ current: 1, max: 4 }} />
      </td>
      <td>
        <Link variant={LinkVariants.TRANSPARENT} to='/'>
          <FormattedMessage id='view' />
        </Link>
      </td>
    </tr>
  );
};
