import { NOTIFICATION_COLUMNS } from 'constants/notifications.constants';
import { selectNotification } from 'modules/notifications/selectors';
import { useAppSelector } from 'modules/store';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

import { NotificationTableRow } from './NotificationTableRow';

export const NotificationTable = () => {
  const notification = useAppSelector(selectNotification);

  if (!notification) return null;

  return (
    <CommonLayoutTable title='receivers' columns={NOTIFICATION_COLUMNS}>
      {notification?.employees?.length ? (
        notification?.employees.map((notification) => (
          <NotificationTableRow key={notification.id} data={notification} />
        ))
      ) : (
        <TableNoData colSpan={NOTIFICATION_COLUMNS.length} />
      )}
    </CommonLayoutTable>
  );
};
