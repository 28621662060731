import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { InconsistencyInput } from 'interfaces/inconsistencies.interfaces';
import { Card } from 'shared-components/Card';
import { Textarea } from 'shared-components/Textarea';

export const InconsistencyAdditionalFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<InconsistencyInput>();

  return (
    <Card title='description' wrapperClassName='flex-1' className='flex flex-col gap-6'>
      <Textarea
        {...register('description')}
        label='description'
        error={errors['description']?.message}
        placeholder={formatMessage({ id: 'description' })}
        data-test='description'
        rows={4}
      />
    </Card>
  );
};
