import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { QueryParams } from 'interfaces/query.interfaces';
import { Self } from 'interfaces/self.interfaces';
import { UpdateUser, UserInputPayload, UsersResponse } from 'interfaces/users.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class UserApi {
  constructor(private url: string) {}

  getUsers(queries?: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<UsersResponse>(`${this.url}/users?${generateQueryString(queries ?? {})}`, config);
  }

  getUser(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Self }>(`${this.url}/users/${id}`, config);
  }

  getSelf(config?: AxiosRequestConfig) {
    return httpApiClient.get<{ data: Self }>(`${this.url}/profile`, config);
  }

  createUser(data: UserInputPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/users`, data, config);
  }

  updateUser({ id, ...data }: UpdateUser, config?: AxiosRequestConfig) {
    return httpApiClient.put(`${this.url}/users/${id}`, data, config);
  }

  deleteUser(id: string, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/users/${id}`, config);
  }
}

export const userApi = new UserApi('');
