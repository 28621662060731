import { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import { ROLE_COLUMNS } from 'constants/roles.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteRole, asyncGetRoles } from 'modules/roles/action';
import { selectIsLoading, selectRoles, selectRolesCount } from 'modules/roles/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { Pagination } from 'shared-components/Pagination';
import { Table } from 'shared-components/table/Table';

import { RolesTableRow } from './RolesTableRow';

export const RolesTable = () => {
  const [roleId, setRoleId] = useState(-1);

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const roles = useAppSelector(selectRoles);
  const rolesCount = useAppSelector(selectRolesCount);
  const isLoading = useAppSelector(selectIsLoading);

  const { page, handlePageChange } = usePagination();

  const [isDeletionModalOpened, handleOpenDeleteModal, handleCloseDeleteModal] = useOpen();

  const onRoleDelete = async () => {
    if (roleId) {
      await dispatch(asyncDeleteRole(roleId));
      await dispatch(asyncGetRoles({ page }));
      handleCloseDeleteModal();
    }
  };

  const onModalOpen = (id: number) => () => {
    setRoleId(id);
    handleOpenDeleteModal();
  };

  useEffect(() => {
    dispatch(asyncGetRoles({ page }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <>
      <Card title={formatMessage({ id: 'role_management' })} className='flex flex-col gap-5'>
        <Table isLoading={isLoading} columns={ROLE_COLUMNS}>
          {roles?.map((role) => (
            <RolesTableRow key={role.id} data={role} handleDelete={onModalOpen(role.id)} />
          ))}
        </Table>
        <Link to={AppRoutes.roleCreate} className='p-2' data-test='add-role'>
          <FormattedMessage id='add' />
        </Link>
        <Pagination current={page} total={rolesCount ?? 0} onChange={handlePageChange} />
      </Card>
      <ConfirmationModal
        isOpened={isDeletionModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseDeleteModal}
        handleApply={onRoleDelete}
        title={formatMessage({ id: 'delete_role' })}
      />
    </>
  );
};
