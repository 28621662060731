import { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

import { Card } from 'shared-components/Card';

interface Props {
  title: string;
  description: string | number;
  icon: ReactNode;
  className?: string;
}

export const InfoBox: FC<Props> = ({ title, description, icon, className }) => (
  <Card wrapperClassName='max-w-[290px]' className={twMerge('flex flex-nowrap p-2', className)}>
    <div className='flex justify-center items-center mr-2.5 rounded-10 bg-[#17a2b8]'>{icon}</div>
    <div className='flex flex-col justify-between w-full'>
      <h5>{title}</h5>
      <p className='font-medium'>{description}</p>
    </div>
  </Card>
);
