import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { risksApi } from 'apis/risks.api';
import { QueryParams } from 'interfaces/query.interfaces';
import { RiskPayload, RiskProceedPayload, UpdateRiskPayload } from 'interfaces/risks.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

import { PROVIDERS_SLICE_NAME } from '../providers/action';

export const RISKS_SLICE_NAME = 'risks';

export const asyncGetRisks = createAsyncThunk(
  `${RISKS_SLICE_NAME}/getRisks`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await risksApi.getRisks(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetRisk = createAsyncThunk(
  `${PROVIDERS_SLICE_NAME}/getRisk`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await risksApi.getRiskById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateRisk = createAsyncThunk(
  `${PROVIDERS_SLICE_NAME}/updateRisk`,
  async (payload: UpdateRiskPayload, { rejectWithValue }) => {
    try {
      await risksApi.updateRisk(payload);
      toast.success(intl.formatMessage({ id: 'risk_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateRisk = createAsyncThunk(
  `${PROVIDERS_SLICE_NAME}/createProvider`,
  async (payload: RiskPayload, { rejectWithValue }) => {
    try {
      await risksApi.createRisk(payload);
      toast.success(intl.formatMessage({ id: 'risk_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteRisk = createAsyncThunk(
  `${RISKS_SLICE_NAME}/deleteRisk`,
  async (id: number, { rejectWithValue }) => {
    try {
      await risksApi.deleteRisk(id);
      toast.success(intl.formatMessage({ id: 'risk_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncProceedRisk = createAsyncThunk(
  `${PROVIDERS_SLICE_NAME}/proceedRisk`,
  async (payload: RiskProceedPayload, { rejectWithValue }) => {
    try {
      const response = await risksApi.proceedRisk(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCompleteRisk = createAsyncThunk(
  `${PROVIDERS_SLICE_NAME}/completeRisk`,
  async (payload: number, { rejectWithValue }) => {
    try {
      const response = await risksApi.completeRisk(payload);
      toast.success(intl.formatMessage({ id: 'rick_completed' }));
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetRisk = createAction('UNSET_RISK');
