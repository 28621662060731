import { FC, HTMLProps } from 'react';

import { twMerge } from 'tailwind-merge';

type Props = HTMLProps<HTMLImageElement>;

export const Avatar: FC<Props> = ({ src, alt, className }) => {
  return (
    <img src={src} alt={alt} className={twMerge('rounded-full min-w-9 w-9 h-9 shadow-[1px_1px_3px]', className)} />
  );
};
