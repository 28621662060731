import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { QueryParams } from 'interfaces/query.interfaces';
import {
  RiskPayload,
  RiskProceedPayload,
  RiskResponse,
  RisksResponse,
  UpdateRiskPayload,
} from 'interfaces/risks.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class RisksApi {
  constructor(private url: string) {}

  getRisks(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<RisksResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getRiskById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<RiskResponse>(`${this.url}/${payload}`, config);
  }

  updateRisk({ id, ...data }: UpdateRiskPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<RiskResponse>(`${this.url}/${id}`, data, config);
  }

  proceedRisk({ id, ...data }: RiskProceedPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<RiskResponse>(`${this.url}/${id}/risks`, data, config);
  }

  completeRisk(payload: number, config?: AxiosRequestConfig) {
    return httpApiClient.put<RiskResponse>(`${this.url}/${payload}/complete`, {}, config);
  }

  createRisk(data: RiskPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<RiskResponse>(`${this.url}`, data, config);
  }

  deleteRisk(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const risksApi = new RisksApi('/risk-assessments');
