import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CountryForm } from 'page-components/countries/CountryForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Country = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.countryCreate ? 'create_country' : 'edit_country';
  const titleText = pathname.includes('view') ? 'view_country' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <CountryForm />
    </PageLayout>
  );
};
