import { createSlice } from '@reduxjs/toolkit';

import { Permission } from 'interfaces/permissions.interfaces';

import { PERMISSIONS_SLICE_NAME } from './action';
import { getPermissionsReducer } from './reducers';

export interface PermissionsSliceState {
  isLoading: boolean;
  permissions: Record<string, Permission[]> | null;
}

export const initialState: PermissionsSliceState = {
  permissions: null,
  isLoading: false,
};

const rolesSlice = createSlice({
  name: PERMISSIONS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getPermissionsReducer(builder);
  },
});

export const permissionsReducer = rolesSlice.reducer;
