import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { CustomerRequestSource, CustomerRequestSourcesResponse } from 'interfaces/customer-request-sources.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCustomerRequestSource,
  asyncDeleteCustomerRequestSource,
  asyncGetCustomerRequestSource,
  asyncGetCustomerRequestSources,
  asyncUpdateCustomerRequestSource,
  unsetCustomerRequestSource,
} from './action';
import { CustomerRequestSourcesSliceState } from './slice';

export function getCustomerRequestSourcesReducer(builder: ActionReducerMapBuilder<CustomerRequestSourcesSliceState>) {
  builder.addCase(asyncGetCustomerRequestSources.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomerRequestSources.fulfilled,
    (state, { payload }: PayloadAction<CustomerRequestSourcesResponse>) => {
      state.customerRequestSources = payload.data;
      state.customerRequestSourcesCount = payload.pagination.total;
      state.isLoading = false;
    },
  );

  builder.addCase(asyncGetCustomerRequestSources.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCustomerRequestSourceReducer(builder: ActionReducerMapBuilder<CustomerRequestSourcesSliceState>) {
  builder.addCase(asyncGetCustomerRequestSource.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomerRequestSource.fulfilled,
    (state, { payload }: PayloadAction<CustomerRequestSource>) => {
      state.customerRequestSource = payload;
      state.isLoading = false;
    },
  );

  builder.addCase(asyncGetCustomerRequestSource.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCustomerRequestSourceReducer = withLoadingReducer(asyncUpdateCustomerRequestSource);

export const createCustomerRequestSourceReducer = withLoadingReducer(asyncCreateCustomerRequestSource);

export function deleteCustomerRequestSourceReducer(builder: ActionReducerMapBuilder<CustomerRequestSourcesSliceState>) {
  builder.addCase(asyncDeleteCustomerRequestSource.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCustomerRequestSource.fulfilled, (state) => {
    state.customerRequestSource = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCustomerRequestSource.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCustomerRequestSourceReducer(builder: ActionReducerMapBuilder<CustomerRequestSourcesSliceState>) {
  builder.addCase(unsetCustomerRequestSource, (state) => {
    state.customerRequestSource = null;
  });
}
