import { FC, useEffect } from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { TASKS_COLUMNS } from 'constants/tasks.constants';
import { TaskSelectOption } from 'interfaces/roles.interfaces';
import { Task, TaskResponsible } from 'interfaces/tasks.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncConfirmByOwner, asyncConfirmByPerformer, asyncGetTasks } from 'modules/tasks/action';
import { selectCurrentUser } from 'modules/user/selectors';
import { Table } from 'shared-components/table/Table';

import { TasksTableRow } from './TasksTableRow';

interface Props {
  handleOpenDeleteModal: (id: string) => VoidFunction;
  tasks: Task[];
  isEditable?: boolean;
}

export const TasksTable: FC<Props> = ({ handleOpenDeleteModal, tasks, isEditable }) => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectCurrentUser);

  const [searchParams] = useSearchParams();
  const page = +(searchParams.get('page') ?? 0);
  const perPage = +(searchParams.get('perPage') ?? 5);

  const methods = useForm({ mode: 'onChange' });

  const { handleSubmit, watch, reset } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const performerStatus = data.performer?.find((dataItem: TaskSelectOption) => dataItem?.value)?.value;
    if (!performerStatus) return;

    const isAuthor = user?.id === performerStatus?.owner?.id;
    const isTaskCompleted = performerStatus?.performers?.every(({ completed }: TaskResponsible) => completed);

    await dispatch(
      isAuthor && isTaskCompleted
        ? asyncConfirmByOwner(performerStatus)
        : (asyncConfirmByPerformer(performerStatus) as unknown as AnyAction),
    );
    await dispatch(asyncGetTasks({ page, perPage }));
    const fieldName = `performer.${performerStatus.id}`;
    reset({ [fieldName]: '' });
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [handleSubmit, watch]);

  if (!tasks?.length)
    return (
      <p className='flex items-center justify-center text-center'>
        <FormattedMessage id='no_users' />
      </p>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div data-test='tasks-table'>
        <FormProvider {...methods}>
          <Table columns={TASKS_COLUMNS}>
            {tasks.map((task) => (
              <TasksTableRow
                handleOpenDeleteModal={handleOpenDeleteModal(`${task.id}`)}
                key={task.id}
                data={task}
                isEditable={isEditable}
              />
            ))}
          </Table>
        </FormProvider>
      </div>
    </form>
  );
};
