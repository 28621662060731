import { FC, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { RoleInput, SelectOption } from 'interfaces/roles.interfaces';
import { Role } from 'interfaces/self.interfaces';
import { asyncGetRoles } from 'modules/roles/action';
import { useAppDispatch } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  userRoles: Role[];
}

export const UserRoles: FC<Props> = ({ userRoles }) => {
  const [roleOptions, setRoleOptions] = useState<SelectOption[]>([]);

  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<RoleInput>();

  useEffect(() => {
    dispatch(asyncGetRoles({}))
      .unwrap()
      .then((response) => {
        const results = response.data.map((user) => ({ label: user.name, value: user.id }));
        setValue(
          'rolesSelect',
          results.filter(({ value }) => userRoles?.some(({ id }) => value === id)),
        );
        setRoleOptions(results);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setValue]);

  return (
    <Card title='roles' wrapperClassName='overflow-visible'>
      <Controller
        control={control}
        name='rolesSelect'
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            error={errors['rolesSelect']?.message}
            isMulti
            options={roleOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_roles' })}
          />
        )}
      />
    </Card>
  );
};
