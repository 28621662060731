import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { TaskInput, TaskResponsible } from 'interfaces/tasks.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

interface Props {
  performer?: TaskResponsible;
}

export const TaskPerformer: FC<Props> = ({ performer }) => {
  const user = useAppSelector(selectCurrentUser);

  const { formatMessage } = useIntl();

  const responsibleOptions = [
    { value: user?.id ?? -1, label: user?.name ?? '' },
    ...convertObjectsToSelectOption(user?.subordinates ?? []),
  ];

  const defaultResponsible = performer ? { value: performer.id, label: performer.name } : undefined;

  const {
    control,
    formState: { errors },
  } = useFormContext<TaskInput>();

  return (
    <Controller
      control={control}
      name='responsible'
      defaultValue={defaultResponsible ?? responsibleOptions[0]}
      rules={REQUIRED_VALIDATION}
      render={({ field }) => (
        <Select
          {...field}
          isRequired
          label='responsible'
          error={errors['allResponsibleSelect']?.message}
          defaultValue={defaultResponsible ?? responsibleOptions[0]}
          options={responsibleOptions}
          className='performer flex-1 h-full'
          placeholder={formatMessage({ id: 'search_responsible' })}
        />
      )}
    />
  );
};
