import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  EducationPayload,
  EducationResponse,
  EducationsResponse,
  UpdateEducationPayload,
} from 'interfaces/educations.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class EducationsApi {
  constructor(private url: string) {}

  getEducations(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<EducationsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getEducation(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<EducationResponse>(`${this.url}/${payload}`, config);
  }

  updateEducation({ id, ...data }: UpdateEducationPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<EducationResponse>(`${this.url}/${id}`, data, config);
  }

  createEducation(data: EducationPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<EducationResponse>(`${this.url}`, data, config);
  }

  deleteEducation(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const educationApi = new EducationsApi('/courses');
