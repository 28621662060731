import { createSlice } from '@reduxjs/toolkit';

import { Complect } from 'interfaces/complects.interfaces';

import { COMPLECTS_SLICE_NAME } from './action';
import {
  getComplectsReducer,
  createComplectReducer,
  deleteComplectReducer,
  unsetComplectReducer,
  updateComplectReducer,
  getComplectReducer,
} from './reducers';

export interface ComplectsSliceState {
  isLoading: boolean;
  complect: Complect | null;
  complects: Complect[] | null;
  complectsCount: number | null;
}

export const initialState: ComplectsSliceState = {
  complects: null,
  complect: null,
  isLoading: false,
  complectsCount: null,
};

const componentsSlice = createSlice({
  name: COMPLECTS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getComplectsReducer(builder);
    createComplectReducer(builder);
    deleteComplectReducer(builder);
    unsetComplectReducer(builder);
    updateComplectReducer(builder);
    getComplectReducer(builder);
  },
});

export const componentsReducer = componentsSlice.reducer;
