import { ButtonVariants } from 'interfaces/shared/button.interfaces';

export const ButtonVariantsStyles: { [key in ButtonVariants]: string } = {
  [ButtonVariants.MAIN]:
    'bg-mediumBlue border border-mediumBlue justify-center hover:text-mediumBlue hover:stroke-mediumBlue hover:fill-mediumBlue hover:bg-transparent',
  [ButtonVariants.SECONDARY]: '',
  [ButtonVariants.CANCEL_OUTLINED]:
    'bg-transparent border border-red justify-center text-red hover:bg-red hover:text-white',
  [ButtonVariants.TRANSPARENT]: 'bg-transparent border-none rounded-none',
};
