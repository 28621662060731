import { useEffect } from 'react';

import cn from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { AuditStatuses } from 'constants/audits.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { selectAudit } from 'modules/audits/selectors';
import { useAppSelector } from 'modules/store';
import { AuditCarryingForm } from 'page-components/audits/AuditCarryingForm';
import { AuditFinishForm } from 'page-components/audits/AuditFinishForm';
import { AuditForm } from 'page-components/audits/AuditForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const Audit = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isView = pathname.includes('/view/');

  const audit = useAppSelector(selectAudit);

  const editTitle = pathname === AppRoutes.auditCreate ? 'create_audit' : 'edit_audit';
  const titleText = pathname.includes('view') ? 'view_audit' : editTitle;

  useEffect(() => {
    if (!isView && audit && audit?.status !== AuditStatuses.created) {
      navigate(AppRoutes.audits);
    }
  }, [audit, navigate, isView]);

  return (
    <PageLayout title={titleText}>
      <div className='flex flex-col gap-4'>
        <div
          className={twMerge(
            cn('grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4', { 'md:grid-cols-2 xl:grid-cols-2': !isView }),
          )}
        >
          <AuditForm />
          {audit?.consistencies && isView && <AuditCarryingForm />}
          {audit?.conclusions && isView && <AuditFinishForm />}
        </div>
        {isView && <ItemNavigation className='basis-full' navigateBack={AppRoutes.audits} />}
      </div>
    </PageLayout>
  );
};
