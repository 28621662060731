export type Report = Record<string, number>;

export type ReportDate = Record<string, Report>;

export interface ReportsResponse {
  data: Report;
}

export interface ReportDatesResponse {
  data: ReportDate;
}

export enum CustomerReportsTypes {
  statuses = 'statuses',
  countries = 'countries',
}

export enum CustomerRequestReportTypes {
  sources = 'sources',
  statuses = 'statuses',
  countries = 'countries',
}

export enum InconsistencyReportTypes {
  types = 'types',
  departments = 'departments',
}

export interface ReportFilter {
  id: number;
  name: string;
  query: string;
}

export interface ReportPayload {
  country?: string;
  status?: string;
  source?: string;
  department?: string;
  inconsistencyType?: string;
  dates: string;
}

export interface ChartData {
  label: string;
  data: number[];
}
