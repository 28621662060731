import { InconsistencyTypesTable } from 'page-components/inconsistency-types/InconsistencyTypesTable';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const InconsistencyTypes = () => {
  return (
    <PageLayout title='inconsistency_types' wrapperClassName='flex flex-col gap-4'>
      <InconsistencyTypesTable />
    </PageLayout>
  );
};
