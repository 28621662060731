import { useEffect, useState } from 'react';

import { SelectOption } from 'interfaces/roles.interfaces';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useCorrectionOptions = (performerId?: number, responsibleId?: number) => {
  const [usersOptions, setUsersOptions] = useState<SelectOption[]>();
  const [defaultPerformerOption, setDefaultPerformerOption] = useState<SelectOption>();
  const [defaultResponsibleOption, setDefaultResponsibleOption] = useState<SelectOption>();

  const user = useAppSelector(selectCurrentUser);

  useEffect(() => {
    if (!user) return;
    const defaultUser = { value: user.id, label: user.name };
    const users = [defaultUser, ...convertObjectsToSelectOption(user.subordinates)];
    setUsersOptions(users);

    setDefaultPerformerOption(users.find(({ value }) => value === performerId) ?? defaultUser);
    setDefaultResponsibleOption(users.find(({ value }) => value === responsibleId) ?? defaultUser);
  }, [user, performerId, responsibleId]);

  return { usersOptions, defaultPerformerOption, defaultResponsibleOption };
};
