import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  CustomerStatus,
  CustomerStatusInput,
  CustomerStatusResponse,
  CustomerStatusesResponse,
} from 'interfaces/customer-statuses.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class CustomerStatusesApi {
  constructor(private url: string) {}

  getCustomerStatuses(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerStatusesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getCustomerStatusById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<CustomerStatusResponse>(`${this.url}/${payload}`, config);
  }

  updateCustomerStatus({ id, ...data }: CustomerStatus, config?: AxiosRequestConfig) {
    return httpApiClient.put<CustomerStatusesResponse>(`${this.url}/${id}`, data, config);
  }

  createCustomerStatus(data: CustomerStatusInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<CustomerStatusesResponse>(`${this.url}`, data, config);
  }

  deleteCustomerStatus(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const customerStatusesApi = new CustomerStatusesApi('/customer-statuses');
