import { Dispatch, FC, SetStateAction, useEffect, useState } from 'react';

import cn from 'classnames';
import { Control, Controller } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomers } from 'modules/customers/action';
import { useAppDispatch } from 'modules/store';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  control: Control<CustomerRequestInput>;
  defaultCustomer?: SelectOption;
  selectedCustomer?: SelectOption;
  isUserCreate: boolean;
  handleIsUserCreate: VoidFunction;
  setCreatedUser: Dispatch<SetStateAction<SelectOption | undefined>>;
  error?: string;
  className?: string;
}

export const CustomerRequestClient: FC<Props> = ({
  defaultCustomer,
  control,
  isUserCreate,
  handleIsUserCreate,
  selectedCustomer,
  setCreatedUser,
  error,
  className,
}) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [customerOptions, setCustomerOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    dispatch(asyncGetCustomers({}))
      .unwrap()
      .then(({ data }) => {
        const formattedCustomers = data.map(({ id, name }) => ({ value: id, label: name }));

        setCustomerOptions(formattedCustomers);
      });
  }, [dispatch, selectedCustomer]);

  if (!customerOptions) return null;

  return (
    <Card title='customer' wrapperClassName={cn('self-start flex-1', className)} className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='customerSelect'
        rules={REQUIRED_VALIDATION}
        defaultValue={selectedCustomer ?? defaultCustomer}
        render={({ field: { value, onChange, ...field } }) => (
          <Select
            {...field}
            isRequired
            value={selectedCustomer ?? value}
            onChange={(event) => {
              if (selectedCustomer) {
                setCreatedUser(undefined);
              }
              onChange(event);
            }}
            isDisabled={isUserCreate}
            defaultValue={defaultCustomer}
            options={customerOptions}
            className='flex-1 h-full'
            error={error}
            placeholder={formatMessage({ id: 'search_customer' })}
          />
        )}
      />
      <Button onClick={handleIsUserCreate}>
        <FormattedMessage id='create_customer_action' />
      </Button>
    </Card>
  );
};
