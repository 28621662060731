import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { InconsistenciesResponse, Inconsistency } from 'interfaces/inconsistencies.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateInconsistency,
  asyncDeleteInconsistency,
  asyncGetInconsistencies,
  asyncUpdateInconsistency,
  unsetInconsistency,
  asyncGetInconsistency,
} from './action';
import { InconsistenciesSliceState } from './slice';

export function getInconsistenciesReducer(builder: ActionReducerMapBuilder<InconsistenciesSliceState>) {
  builder.addCase(asyncGetInconsistencies.fulfilled, (state, { payload }: PayloadAction<InconsistenciesResponse>) => {
    state.inconsistencies = payload.data;
    state.inconsistenciesCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetInconsistencies.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getInconsistencyReducer(builder: ActionReducerMapBuilder<InconsistenciesSliceState>) {
  builder.addCase(asyncGetInconsistency.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetInconsistency.fulfilled, (state, { payload }: PayloadAction<Inconsistency>) => {
    state.inconsistency = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetInconsistency.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateInconsistencyReducer = withLoadingReducer(asyncUpdateInconsistency);

export const createInconsistencyReducer = withLoadingReducer(asyncCreateInconsistency);

export function deleteInconsistencyReducer(builder: ActionReducerMapBuilder<InconsistenciesSliceState>) {
  builder.addCase(asyncDeleteInconsistency.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteInconsistency.fulfilled, (state) => {
    state.inconsistency = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteInconsistency.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetInconsistencyReducer(builder: ActionReducerMapBuilder<InconsistenciesSliceState>) {
  builder.addCase(unsetInconsistency, (state) => {
    state.inconsistency = null;
  });
}
