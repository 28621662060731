import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Provider, ProvidersResponse } from 'interfaces/providers.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateProvider,
  asyncDeleteProvider,
  asyncGetProvider,
  asyncGetProviders,
  asyncUpdateProvider,
  unsetProvider,
} from './action';
import { ProvidersSliceState } from './slice';

export function getProvidersReducer(builder: ActionReducerMapBuilder<ProvidersSliceState>) {
  builder.addCase(asyncGetProviders.fulfilled, (state, { payload }: PayloadAction<ProvidersResponse>) => {
    state.providers = payload.data;
    state.providersCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetProviders.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getProviderReducer(builder: ActionReducerMapBuilder<ProvidersSliceState>) {
  builder.addCase(asyncGetProvider.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetProvider.fulfilled, (state, { payload }: PayloadAction<Provider>) => {
    state.provider = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetProvider.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateProviderReducer = withLoadingReducer(asyncUpdateProvider);

export const createProviderReducer = withLoadingReducer(asyncCreateProvider);

export function deleteProviderReducer(builder: ActionReducerMapBuilder<ProvidersSliceState>) {
  builder.addCase(asyncDeleteProvider.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteProvider.fulfilled, (state) => {
    state.provider = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteProvider.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetProviderReducer(builder: ActionReducerMapBuilder<ProvidersSliceState>) {
  builder.addCase(unsetProvider, (state) => {
    state.provider = null;
  });
}
