import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { SignInInput, SignInResponse, SignOutResponse } from 'interfaces/auth/sign-in.interfaces';
import { EmailResetPasswordInput, ResetPasswordPayload } from 'interfaces/reset-password.interfaces';

export class AuthApi {
  constructor(private url: string) {}

  signIn(data: SignInInput, config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SignInResponse }>(`${this.url}/login`, data, config);
  }

  logOut(config?: AxiosRequestConfig) {
    return httpApiClient.post<{ data: SignOutResponse }>(`${this.url}/logout`, {}, config);
  }

  resetPasswordEmail(data: EmailResetPasswordInput, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/password/email`, data, config);
  }

  resetPassword(data: ResetPasswordPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/password/reset`, data, config);
  }
}

export const authApi = new AuthApi('');
