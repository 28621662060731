import { useState } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { SelectOption } from 'interfaces/roles.interfaces';
import { CustomerRequestForm } from 'page-components/customers-requests/CustomerRequestForm';
import { CustomersForm } from 'page-components/customers/CustomersForm';
import { ManagerActionsTable } from 'page-components/manager-actions/ManagerActionsTable';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const CustomerRequest = () => {
  const { id } = useParams();
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.customerRequestCreate ? 'create_customer_request' : 'edit_customer_request';
  const titleText = pathname.includes('view') ? 'view_customer_request' : editTitle;

  const [isUserCreate, setIsUserCreate] = useState<boolean>(false);
  const [createdUser, setCreatedUser] = useState<SelectOption>();

  const handleIsUserCreate = () => setIsUserCreate(true);
  const handleOnUserCreate = () => {
    setIsUserCreate(false);
  };

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <CustomerRequestForm
        isUserCreate={isUserCreate}
        createdCustomer={createdUser}
        setCreatedUser={setCreatedUser}
        handleIsUserCreate={handleIsUserCreate}
      />
      {isUserCreate && <CustomersForm handleOnUserCreate={handleOnUserCreate} setCreatedUser={setCreatedUser} />}
      {id && <ManagerActionsTable />}
    </PageLayout>
  );
};
