import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.userReducer;

export const selectIsAuth = createSelector(selectState, ({ isAuth }) => isAuth);

export const selectCurrentUser = createSelector(selectState, ({ currentUser }) => currentUser);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);

export const selectActiveLocale = createSelector(selectState, ({ locale }) => locale);
