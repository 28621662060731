import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Supervisor } from 'interfaces/supervisors.interfaces';
import { UserInput } from 'interfaces/users.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

interface Props {
  userSupervisors: Supervisor[];
  defaultSupervisors: Supervisor[];
}

export const UserSupervisors: FC<Props> = ({ userSupervisors, defaultSupervisors }) => {
  const supervisorsOptions = convertObjectsToSelectOption(userSupervisors);
  const defaultOptions = convertObjectsToSelectOption(defaultSupervisors);

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<UserInput>();

  return (
    <Card title='supervisors' wrapperClassName='overflow-visible flex-1'>
      <Controller
        control={control}
        name='supervisorsSelect'
        defaultValue={defaultOptions}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['supervisorsSelect']?.message}
            defaultValue={defaultOptions}
            isMulti
            options={supervisorsOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_supervisors' })}
          />
        )}
      />
    </Card>
  );
};
