import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.tasksReducer;

export const selectTask = createSelector(selectState, ({ task }) => task);

export const selectTasks = createSelector(selectState, ({ tasks }) => tasks);

export const selectTasksCount = createSelector(selectState, ({ tasksCount }) => tasksCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
