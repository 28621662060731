import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.citiesReducer;

export const selectCity = createSelector(selectState, ({ city }) => city);

export const selectCities = createSelector(selectState, ({ cities }) => cities);

export const selectCitiesCount = createSelector(selectState, ({ citiesCount }) => citiesCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
