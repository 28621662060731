import { createSlice } from '@reduxjs/toolkit';

import { Inconsistency } from 'interfaces/inconsistencies.interfaces';

import { INCONSISTENCIES_SLICE_NAME } from './action';
import {
  createInconsistencyReducer,
  deleteInconsistencyReducer,
  getInconsistenciesReducer,
  getInconsistencyReducer,
  unsetInconsistencyReducer,
  updateInconsistencyReducer,
} from './reducers';

export interface InconsistenciesSliceState {
  isLoading: boolean;
  inconsistency: Inconsistency | null;
  inconsistencies: Inconsistency[] | null;
  inconsistenciesCount: number | null;
}

export const initialState: InconsistenciesSliceState = {
  inconsistency: null,
  inconsistencies: null,
  inconsistenciesCount: null,
  isLoading: false,
};

const inconsistenciesSlice = createSlice({
  name: INCONSISTENCIES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createInconsistencyReducer(builder);
    deleteInconsistencyReducer(builder);
    getInconsistenciesReducer(builder);
    getInconsistencyReducer(builder);
    unsetInconsistencyReducer(builder);
    updateInconsistencyReducer(builder);
  },
});

export const inconsistenciesReducer = inconsistenciesSlice.reducer;
