import { createSlice } from '@reduxjs/toolkit';

import { Risk, RisksResponse } from 'interfaces/risks.interfaces';

import { RISKS_SLICE_NAME } from './action';
import {
  getRisksReducer,
  unsetRiskReducer,
  createRiskReducer,
  deleteRiskReducer,
  getRiskReducer,
  updateRiskReducer,
  proceedRiskReducer,
  completeRiskReducer,
} from './reducers';

export interface RisksSliceState {
  isLoading: boolean;
  risk: Risk | null;
  risks?: RisksResponse | null;
}

export const initialState: RisksSliceState = {
  isLoading: false,
  risk: null,
  risks: null,
};

const risksSlice = createSlice({
  name: RISKS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getRisksReducer(builder);
    unsetRiskReducer(builder);
    createRiskReducer(builder);
    deleteRiskReducer(builder);
    getRiskReducer(builder);
    updateRiskReducer(builder);
    proceedRiskReducer(builder);
    completeRiskReducer(builder);
  },
});

export const risksReducer = risksSlice.reducer;
