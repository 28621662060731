import { useEffect, useState } from 'react';

import { addWeeks } from 'date-fns';
import { UseFormReset } from 'react-hook-form';

import { SelectOption } from 'interfaces/roles.interfaces';
import { TaskInput } from 'interfaces/tasks.interfaces';
import { useAppDispatch } from 'modules/store';
import { asyncGetTask } from 'modules/tasks/action';
import { formatToInputDate, parseDefaultDate, parseInputDate } from 'utils/date.utils';
import { toUpperCaseFirstLater } from 'utils/role.utils';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useTaskDefaultOptions = (resetForm: UseFormReset<TaskInput>, taskId?: string) => {
  const dispatch = useAppDispatch();

  const [defaultOptions, setDefaultOptions] = useState<{
    deadline: Date;
    priority?: SelectOption;
    responsible?: SelectOption[];
  }>({
    deadline: addWeeks(new Date(), 1),
  });

  useEffect(() => {
    if (taskId) {
      dispatch(asyncGetTask(taskId))
        .unwrap()
        .then(({ priority, content, deadlineAt, performers }) => {
          const prioritySelect = { value: priority, label: toUpperCaseFirstLater(priority) };

          const parsedDeadline = parseDefaultDate(deadlineAt);
          const allResponsible = convertObjectsToSelectOption(performers);

          setDefaultOptions({
            deadline: parseInputDate(formatToInputDate(parsedDeadline)),
            priority: prioritySelect,
            responsible: allResponsible,
          });

          resetForm({
            prioritySelect,
            content,
            deadline: formatToInputDate(parsedDeadline),
            allResponsibleSelect: allResponsible,
          });
        });
    }
  }, [dispatch, taskId, resetForm]);

  return defaultOptions;
};
