import { useEffect, useState } from 'react';

import { AnyAction } from '@reduxjs/toolkit';
import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { TASKS_COLUMNS } from 'constants/tasks.constants';
import { useOpen } from 'hooks/useOpen';
import { TaskSelectOption } from 'interfaces/roles.interfaces';
import { TaskResponsible } from 'interfaces/tasks.interfaces';
import { asyncGetInconsistency } from 'modules/inconsistencies/action';
import { selectInconsistency, selectIsLoading } from 'modules/inconsistencies/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncConfirmByOwner, asyncConfirmByPerformer, asyncDeleteTask } from 'modules/tasks/action';
import { selectUser } from 'modules/users/selectors';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

import { TasksTableRow } from '../tasks/tasks-table/TasksTableRow';

export const CorrectionsTable = () => {
  const { id } = useParams();

  const [correctionId, setCorrectionId] = useState<number>();

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const inconsistency = useAppSelector(selectInconsistency);
  const isLoading = useAppSelector(selectIsLoading);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const onModalOpen = (id: number) => () => {
    setCorrectionId(id);
    handleOpenModal();
  };

  const onCorrectionDelete = async () => {
    if (correctionId && id) {
      await dispatch(asyncDeleteTask(correctionId));
      await dispatch(asyncGetInconsistency(id));
      handleCloseModal();
    }
  };

  const methods = useForm({ mode: 'onChange' });

  const { handleSubmit, watch, reset } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const performerStatus = data.performer?.find((dataItem: TaskSelectOption) => dataItem?.value)?.value;
    if (!performerStatus) return;

    const isAuthor = user?.id === performerStatus?.owner?.id;
    const isTaskCompleted = performerStatus?.performers?.every(({ completed }: TaskResponsible) => completed);

    await dispatch(
      isAuthor && isTaskCompleted
        ? asyncConfirmByOwner(performerStatus)
        : (asyncConfirmByPerformer(performerStatus) as unknown as AnyAction),
    );
    await dispatch(asyncGetInconsistency(`${id}`));
    const fieldName = `performer.${performerStatus.id}`;
    reset({ [fieldName]: '' });
  };

  useEffect(() => {
    const subscription = watch(() => handleSubmit(onSubmit)());
    return () => subscription.unsubscribe();
    // eslint-disable-next-line
  }, [handleSubmit, watch]);

  return (
    <>
      <FormProvider {...methods}>
        <CommonLayoutTable
          title='corrections_management'
          columns={TASKS_COLUMNS}
          creationPath={`${AppRoutes.correctionCreate}&inconsistency=${id}`}
          isLoading={isLoading}
        >
          {inconsistency?.tasks?.length ? (
            inconsistency.tasks.map((task) => (
              <TasksTableRow key={task.id} data={task} handleOpenDeleteModal={onModalOpen(task.id)} />
            ))
          ) : (
            <TableNoData colSpan={TASKS_COLUMNS.length} />
          )}
        </CommonLayoutTable>

        <ConfirmationModal
          isOpened={isModalOpened}
          isLoading={isLoading}
          handleCloseModal={handleCloseModal}
          handleApply={onCorrectionDelete}
          title='delete_correction'
        />
      </FormProvider>
    </>
  );
};
