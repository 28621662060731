import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customerRequestSourceApi } from 'apis/customer-request-sources.apis';
import { CustomerRequestSource, CustomerRequestSourceInput } from 'interfaces/customer-request-sources.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const CUSTOMER_REQUEST_SOURCES_SLICE_NAME = 'customerRequestSources';

export const asyncGetCustomerRequestSources = createAsyncThunk(
  `${CUSTOMER_REQUEST_SOURCES_SLICE_NAME}/getCustomerRequestSources`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await customerRequestSourceApi.getCustomerRequestSources(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetCustomerRequestSource = createAsyncThunk(
  `${CUSTOMER_REQUEST_SOURCES_SLICE_NAME}/getCustomerRequestSource`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await customerRequestSourceApi.getCustomerRequestSourceById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateCustomerRequestSource = createAsyncThunk(
  `${CUSTOMER_REQUEST_SOURCES_SLICE_NAME}/updateCustomerRequestSource`,
  async (payload: CustomerRequestSource, { rejectWithValue }) => {
    try {
      await customerRequestSourceApi.updateCustomerRequestSource(payload);
      toast.success(intl.formatMessage({ id: 'customer_request_source_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateCustomerRequestSource = createAsyncThunk(
  `${CUSTOMER_REQUEST_SOURCES_SLICE_NAME}/createCustomerRequestSource`,
  async (payload: CustomerRequestSourceInput, { rejectWithValue }) => {
    try {
      await customerRequestSourceApi.createCustomerRequestSource(payload);
      toast.success(intl.formatMessage({ id: 'country_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteCustomerRequestSource = createAsyncThunk(
  `${CUSTOMER_REQUEST_SOURCES_SLICE_NAME}/deleteCustomerRequestSource`,
  async (id: number, { rejectWithValue }) => {
    try {
      await customerRequestSourceApi.deleteCustomerRequestSource(id);
      toast.success(intl.formatMessage({ id: 'customer_request_source_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetCustomerRequestSource = createAction('UNSET_CUSTOMER_REQUEST_SOURCE');
