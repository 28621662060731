import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Department } from 'interfaces/departments.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Department;
}

export const DepartmentsTableRow: FC<Props> = ({ handleDelete, data: { id, name } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.departments, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='departments.update'
          deletePermission='departments.delete'
          viewPermission='departments.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{name}</TableData>
    </TableRow>
  );
};
