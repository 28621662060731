import { useEffect, useState } from 'react';

import { Priorities } from 'utils/priority.utils';

export const usePriorityColor = (priority: string) => {
  const [priorityColor, setPriorityColor] = useState<string>();

  useEffect(() => {
    switch (priority) {
      case Priorities.low:
        setPriorityColor('bg-green-bright');
        break;
      case Priorities.medium:
        setPriorityColor('bg-blue-bright');
        break;
      case Priorities.high:
        setPriorityColor('bg-red-bright');
        break;
      default:
        break;
    }
  }, [priority]);

  return priorityColor;
};
