import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { customerRequestApi } from 'apis/customer-request.apis';
import { ManagerActionPayload, UpdateManagerActionPayload } from 'interfaces/manager-actions.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const MANAGER_ACTIONS_SLICE_NAME = 'manager-actions';

export const asyncGetManagerAction = createAsyncThunk(
  `${MANAGER_ACTIONS_SLICE_NAME}/getManagerAction`,
  async (payload: { requestId: string; commentId: string }, { rejectWithValue }) => {
    try {
      const response = await customerRequestApi.getManagerAction(payload.requestId, payload.commentId);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateManagerAction = createAsyncThunk(
  `${MANAGER_ACTIONS_SLICE_NAME}/updateManagerAction`,
  async (payload: UpdateManagerActionPayload, { rejectWithValue }) => {
    try {
      await customerRequestApi.updateManagerAction(payload);
      toast.success(intl.formatMessage({ id: 'supervisor_action_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateManagerAction = createAsyncThunk(
  `${MANAGER_ACTIONS_SLICE_NAME}/createManagerAction`,
  async (payload: ManagerActionPayload, { rejectWithValue }) => {
    try {
      await customerRequestApi.createManagerAction(payload);
      toast.success(intl.formatMessage({ id: 'supervisor_action_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteManagerAction = createAsyncThunk(
  `${MANAGER_ACTIONS_SLICE_NAME}/deleteManagerAction`,
  async (payload: { requestId: string; commentId: string }, { rejectWithValue }) => {
    try {
      await customerRequestApi.deleteManagerAction(payload.requestId, payload.commentId);
      toast.success(intl.formatMessage({ id: 'supervisor_action_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetManagerAction = createAction('UNSET_MANAGER_ACTION');
