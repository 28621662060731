import { PageLayout } from 'page-components/page-layout/PageLayout';
import { RolesTable } from 'page-components/roles/RolesTable';

export const Roles = () => {
  return (
    <PageLayout title='roles'>
      <RolesTable />
    </PageLayout>
  );
};
