import { useEffect, useState } from 'react';

import { AUDITS_COLUMNS } from 'constants/audits.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteAudit, asyncGetAudits } from 'modules/audits/action';
import { selectAuditCount, selectAudits, selectIsLoading } from 'modules/audits/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { AuditsTableRow } from 'page-components/audits/AuditsTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Audits = () => {
  const [auditId, setAuditId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const audits = useAppSelector(selectAudits);
  const auditsCount = useAppSelector(selectAuditCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setAuditId(id);
    handleOpenModal();
  };

  const onAuditDelete = async () => {
    if (auditId) {
      await dispatch(asyncDeleteAudit(auditId));
      await dispatch(asyncGetAudits({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetAudits({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='audits' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='audits_management'
        columns={AUDITS_COLUMNS}
        creationPath={AppRoutes.auditCreate}
        isLoading={isLoading}
      >
        {audits?.length ? (
          audits.map((audit) => <AuditsTableRow key={audit.id} data={audit} handleDelete={onModalOpen(audit.id)} />)
        ) : (
          <TableNoData colSpan={AUDITS_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={auditsCount ?? 0} onChange={handlePageChange} />
      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onAuditDelete}
        title='delete_audit'
      />
    </PageLayout>
  );
};
