import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.customerRequestSourceReducer;

export const selectCustomerRequestSource = createSelector(
  selectState,
  ({ customerRequestSource }) => customerRequestSource,
);

export const selectCustomerRequestSources = createSelector(
  selectState,
  ({ customerRequestSources }) => customerRequestSources,
);

export const selectCustomerRequestSourcesCount = createSelector(
  selectState,
  ({ customerRequestSourcesCount }) => customerRequestSourcesCount,
);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
