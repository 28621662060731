import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { CustomerRequestSelects } from 'interfaces/customer-requests.interfaces';
import { asyncGetCustomerRequest } from 'modules/customer-requests/action';
import { useAppDispatch } from 'modules/store';

export const useCustomerRequestDefaultOptions = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<CustomerRequestSelects>({});

  useEffect(() => {
    if (!id) return;
    dispatch(asyncGetCustomerRequest(id))
      .unwrap()
      .then((response) => {
        const defaultStatus = { value: response.requestStatus.id, label: response.requestStatus.name };
        const defaultResponsible = { value: response.responsible.id, label: response.responsible.name };
        const defaultProducts = response.productGroups.map(({ id, name }) => ({ value: id, label: name }));
        const defaultSource = { value: response.requestSource?.id ?? '', label: response.requestSource?.name ?? '' };
        const defaultCustomer = { value: response.customer.id, label: response.customer.name };

        setFilterSelects({ defaultStatus, defaultProducts, defaultResponsible, defaultSource, defaultCustomer });
      });
  }, [dispatch, id]);

  return filterSelects;
};
