import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ProductGroup, ProductGroupsResponse } from 'interfaces/product-groups.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncGetProductGroup,
  asyncGetProductGroups,
  asyncCreateProductGroup,
  asyncDeleteProductGroup,
  asyncUpdateProductGroup,
  unsetProductGroup,
} from './action';
import { ProductGroupsSliceState } from './slice';

export function getProductGroupsReducer(builder: ActionReducerMapBuilder<ProductGroupsSliceState>) {
  builder.addCase(asyncGetProductGroups.fulfilled, (state, { payload }: PayloadAction<ProductGroupsResponse>) => {
    state.productGroups = payload.data;
    state.isLoading = false;
  });

  builder.addCase(asyncGetProductGroups.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getProductGroupReducer(builder: ActionReducerMapBuilder<ProductGroupsSliceState>) {
  builder.addCase(asyncGetProductGroup.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetProductGroup.fulfilled, (state, { payload }: PayloadAction<ProductGroup>) => {
    state.productGroup = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetProductGroup.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateProductGroupReducer = withLoadingReducer(asyncUpdateProductGroup);

export const createProductGroupReducer = withLoadingReducer(asyncCreateProductGroup);

export function deleteProductGroupReducer(builder: ActionReducerMapBuilder<ProductGroupsSliceState>) {
  builder.addCase(asyncDeleteProductGroup.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteProductGroup.fulfilled, (state) => {
    state.productGroup = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteProductGroup.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetProductGroupReducer(builder: ActionReducerMapBuilder<ProductGroupsSliceState>) {
  builder.addCase(unsetProductGroup, (state) => {
    state.productGroup = null;
  });
}
