import { FC } from 'react';

import { Control, Controller } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Range } from 'react-range';
import { useLocation } from 'react-router-dom';

import { ProviderInput } from 'interfaces/providers.interfaces';
import { FormLabel } from 'shared-components/FormLabel';

interface Props {
  name: string;
  label?: string;
  control: Control<ProviderInput>;
  max: number;
  min: number;
}

export const ProviderRateRange: FC<Props> = ({ name, label, control, max, min }) => {
  const { pathname } = useLocation();

  const isView = pathname.includes('/view/');

  return (
    <div key={name} className='flex flex-col gap-6'>
      <FormLabel htmlFor={name} className='relative -left-2'>
        <FormattedMessage id={label} />:
      </FormLabel>
      <Controller
        control={control}
        name={name as never}
        render={({ field }) => (
          <Range
            disabled={isView}
            max={max as number}
            min={min as number}
            values={field.value}
            onChange={(values) => field.onChange(values)}
            renderTrack={({ props, children }) => (
              <div className='h-2 bg-gray2 rounded-md' {...props}>
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div className='bg-extraLightBlue text-white p-2 rounded-md' {...props}>
                {field.value[0]}
              </div>
            )}
          />
        )}
      />
    </div>
  );
};
