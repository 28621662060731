import { FC } from 'react';

import { Control, Controller, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { SelectOption } from 'interfaces/roles.interfaces';
import { Select } from 'shared-components/Select';

interface Props {
  label: string;
  name: string;
  control: Control<FieldValues>;
  options?: SelectOption[];
  defaultOption?: SelectOption;
  placeholder: string;
  isMulti?: boolean;
}

export const SelectFilter: FC<Props> = ({ label, name, control, options, defaultOption, placeholder }) => {
  const { formatMessage } = useIntl();
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultOption}
      render={({ field }) => (
        <Select
          {...field}
          label={label}
          defaultValue={defaultOption}
          options={options}
          placeholder={formatMessage({ id: placeholder })}
          className='flex-1 h-full'
        />
      )}
    />
  );
};
