import { useEffect, useState } from 'react';

import DatePicker from 'react-datepicker';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import { DateFormates } from 'constants/date.constants';
import { INCONSISTENCIES_FILTERS } from 'constants/inconsistencies.constants';
import { useInconsistenciesDefaultOptions } from 'hooks/useInconsistenciesDefaultOptions';
import { useQueryParams } from 'hooks/useQueryParams';
import { useStartEndQuery } from 'hooks/useStartEndQuery';
import { InconsistencyFiltersInput } from 'interfaces/inconsistencies.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { FilterSelects } from 'shared-components/FilterSelects';
import { FormLabel } from 'shared-components/FormLabel';
import { Input } from 'shared-components/Input';
import { formatToDefaultDate } from 'utils/date.utils';

export const InconsistenciesFilters = () => {
  const { handleSubmit, register, control, reset } = useForm<InconsistencyFiltersInput>();

  const [searchParams, setSearchParams] = useSearchParams();

  const { formatMessage } = useIntl();

  const filterSelects = useInconsistenciesDefaultOptions();

  const { startDate, endDate, setStartDate, setEndDate } = useStartEndQuery();

  const [queryParams, setQueryParams] = useState<QueryParams>({});
  const onSubmit: SubmitHandler<InconsistencyFiltersInput> = (data) => {
    const department = data.departmentSelect?.value as number;
    const inconsistencyType = data.instabilityTypeSelect?.value as number;

    const [startDate, endDate] = data?.dateRange
      ? (data.dateRange as unknown as [Date | undefined, Date | undefined])
      : [];

    const queryParams = {
      department,
      inconsistencyType,
      startDate: startDate ? `${formatToDefaultDate(startDate)}` : undefined,
      endDate: endDate ? `${formatToDefaultDate(endDate)}` : undefined,
      search: data?.search,
    };

    setQueryParams(queryParams);
  };

  const handleDateChange = (dates: [Date | null, Date | null]) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleResetFilters = () => {
    setSearchParams({});

    reset({
      search: '',
      departmentSelect: { value: '', label: '' },
      instabilityTypeSelect: { value: '', label: '' },
      dateRange: '',
    });
  };

  useEffect(() => {
    reset({
      search: searchParams.get('search') ?? '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSelects]);

  useQueryParams(queryParams);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card className='flex flex-col gap-4'>
        <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 items-end'>
          {filterSelects && (
            <FilterSelects filterSelects={filterSelects} data={INCONSISTENCIES_FILTERS} control={control} />
          )}
          <div className='w-full flex-1'>
            <FormLabel htmlFor='dateRange'>
              <FormattedMessage id='date_range' />
            </FormLabel>
            <div className='mt-2'>
              <Controller
                control={control}
                name='dateRange'
                render={({ field: { onChange, ...field } }) => (
                  <DatePicker
                    {...field}
                    showIcon
                    onChange={(dates) => {
                      handleDateChange(dates);
                      onChange(dates);
                    }}
                    startDate={startDate}
                    autoComplete='off'
                    endDate={endDate}
                    dateFormat={DateFormates.defaultDate}
                    wrapperClassName='w-full'
                    className='customer flex-1 h-full disabled:border-0'
                    selectsRange
                  />
                )}
              />
            </div>
          </div>
          <Input
            {...register('search')}
            label='search'
            className='flex-1'
            autoComplete='off'
            placeholder={formatMessage({ id: 'search' })}
          />
        </div>
        <div className='flex gap-4'>
          <Button variant={ButtonVariants.CANCEL_OUTLINED} onClick={handleResetFilters}>
            <FormattedMessage id='reset_filters' />
          </Button>
          <Button type='submit'>
            <FormattedMessage id='filter' />
          </Button>
        </div>
      </Card>
    </form>
  );
};
