import { useEffect, useState } from 'react';

import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { asyncGetInconsistencyTypes } from 'modules/inconsistency-types/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useInconsistencyDefaultOptions = (defaultDepartment?: number) => {
  const dispatch = useAppDispatch();

  const [departmentsOptions, setDepartmentsOptions] = useState<SelectOption[]>();
  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<SelectOption>();

  const [inconsistencyTypeOptions, setInconsistencyTypeOptions] = useState<SelectOption[]>();
  const [defaultInconsistencyTypeOption, setInconsistencyTypeDepartmentOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDepartmentsOptions(options);
        setDefaultDepartmentOption(options.find(({ value }) => value === defaultDepartment));
      });

    dispatch(asyncGetInconsistencyTypes({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setInconsistencyTypeOptions(options);
        setInconsistencyTypeDepartmentOption(options.find(({ value }) => value === defaultDepartment));
      });
  }, [dispatch, defaultDepartment]);

  return { departmentsOptions, inconsistencyTypeOptions, defaultDepartmentOption, defaultInconsistencyTypeOption };
};
