import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { ButtonVariants } from 'interfaces/shared/button.interfaces';

import { Button } from './Button';
import { Modal } from './Modal';

interface Props {
  isOpened: boolean;
  isLoading?: boolean;
  handleCloseModal: VoidFunction;
  handleApply: VoidFunction;
  title: string;
}

export const ConfirmationModal: FC<Props> = ({ isOpened, isLoading, handleCloseModal, title, handleApply }) => {
  return (
    <Modal isOpened={isOpened} handleClose={handleCloseModal}>
      <div className='flex flex-col gap-10'>
        <p className='text-xl text-center'>
          <FormattedMessage id={title} defaultMessage={title} />
        </p>
        <div className='flex gap-2'>
          <Button onClick={handleCloseModal} className='flex-1'>
            <FormattedMessage id='no' />
          </Button>
          <Button
            variant={ButtonVariants.CANCEL_OUTLINED}
            isLoading={isLoading}
            className='flex-1'
            onClick={handleApply}
          >
            <FormattedMessage id='yes' />
          </Button>
        </div>
      </div>
    </Modal>
  );
};
