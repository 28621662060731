import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  DocumentPayload,
  DocumentResponse,
  DocumentsResponse,
  UpdateDocumentPayload,
} from 'interfaces/documents.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class DocumentApi {
  constructor(private url: string) {}

  getDocuments(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<DocumentsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getDocumentById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<DocumentResponse>(`${this.url}/${payload}`, config);
  }

  updateDocument({ id, ...data }: UpdateDocumentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<DocumentResponse>(`${this.url}/${id}`, data, config);
  }

  createDocument(data: DocumentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<DocumentResponse>(`${this.url}`, data, config);
  }

  deleteDocument(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const documentsApi = new DocumentApi('/documents');
