import { useEffect, useState } from 'react';

import { CUSTOMER_REQUEST_SOURCES_COLUMNS } from 'constants/customer-request-statuses.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import {
  asyncDeleteCustomerRequestSource,
  asyncGetCustomerRequestSources,
} from 'modules/customer-request-sources/action';
import {
  selectCustomerRequestSources,
  selectCustomerRequestSourcesCount,
  selectIsLoading,
} from 'modules/customer-request-sources/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CustomerRequestSourceTableRow } from 'page-components/customer-request-source/CustomerRequestSourceTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const CustomerRequestSources = () => {
  const [requestSourceId, setRequestSourceId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const customerRequestSources = useAppSelector(selectCustomerRequestSources);
  const customerRequestSourcesCount = useAppSelector(selectCustomerRequestSourcesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setRequestSourceId(id);
    handleOpenModal();
  };

  const onCustomerRequestDelete = async () => {
    if (requestSourceId) {
      await dispatch(asyncDeleteCustomerRequestSource(requestSourceId));
      await dispatch(asyncGetCustomerRequestSources({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetCustomerRequestSources({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='customer_request_sources' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='customer_request_sources_management'
        columns={CUSTOMER_REQUEST_SOURCES_COLUMNS}
        creationPath={AppRoutes.customerRequestSourceCreate}
        isLoading={isLoading}
      >
        {customerRequestSources?.length ? (
          customerRequestSources.map((customerRequestSource) => (
            <CustomerRequestSourceTableRow
              key={customerRequestSource.id}
              data={customerRequestSource}
              handleDelete={onModalOpen(customerRequestSource.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={CUSTOMER_REQUEST_SOURCES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={customerRequestSourcesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCustomerRequestDelete}
        title='delete_customer_request_source'
      />
    </PageLayout>
  );
};
