import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.auditReducer;

export const selectAudit = createSelector(selectState, ({ audit }) => audit);

export const selectAudits = createSelector(selectState, ({ audits }) => audits);

export const selectAuditCount = createSelector(selectState, ({ auditsCount }) => auditsCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
