import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { DepartmentForm } from 'page-components/departments/DepartmentForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Department = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.departmentCreate ? 'create_department' : 'edit_department';
  const titleText = pathname.includes('view') ? 'view_department' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <DepartmentForm />
    </PageLayout>
  );
};
