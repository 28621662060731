import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { CustomerInput } from 'interfaces/customers.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncCreateCustomer, asyncGetCustomer, asyncUpdateCustomer, unsetCustomer } from 'modules/customers/action';
import { selectCustomer } from 'modules/customers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { ItemNavigation } from 'shared-components/ItemNavigation';

import { CustomerContactFields } from './CustomerContactFields';
import { CustomerGeneralFields } from './CustomerGeneralFields';
import { CustomerStatus } from './CustomerStatus';

interface Props {
  handleOnUserCreate?: VoidFunction;
  setCreatedUser?: Dispatch<SetStateAction<SelectOption | undefined>>;
}

export const CustomersForm: FC<Props> = ({ handleOnUserCreate, setCreatedUser }) => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const customer = useAppSelector(selectCustomer);

  const methods = useForm<CustomerInput>();

  const { reset, handleSubmit } = methods;

  const onSubmit: SubmitHandler<CustomerInput> = ({
    country: countryOption,
    city: cityOption,
    customerStatus: statusOption,
    ...data
  }) => {
    const country = countryOption.value as number;
    const city = cityOption.value as number;
    const customerStatus = statusOption?.value as number;

    const formattedData = { country, city, customerStatus, ...data };

    dispatch(id ? asyncUpdateCustomer({ id, ...formattedData }) : asyncCreateCustomer(formattedData))
      .unwrap()
      .then((response) => {
        if (handleOnUserCreate && setCreatedUser) {
          handleOnUserCreate();
          setCreatedUser({ value: response.data.id, label: response.data.name });
          return;
        }
        navigate(AppRoutes.customers);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetCustomer()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetCustomer(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && customer) {
      reset({
        name: customer.name,
        country: { label: customer.country.name, value: customer.country.id },
        city: { label: customer.city.name, value: customer.city.id },
        company: customer.company,
        language: customer.language,
        email: customer.email,
        phone: customer.phone,
        customerStatus: { label: customer.customerStatus.name, value: customer.customerStatus.id },
        contactPerson: customer.contactPerson,
        skype: customer.skype,
        additionalContacts: customer?.additionalContacts,
      });
    }
  }, [id, reset, customer]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormProvider {...methods}>
        <div className='flex flex-col gap-4'>
          <div className='flex gap-4 flex-col md:flex-row'>
            <div className='flex gap-4 flex-col flex-1'>
              <CustomerGeneralFields customer={customer} />
              <CustomerStatus />
            </div>
            <CustomerContactFields />
          </div>
          <ItemNavigation navigateBack={AppRoutes.customers} />
        </div>
      </FormProvider>
    </form>
  );
};
