import { ElementType, FC } from 'react';

import cn from 'classnames';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Range } from 'react-range';
import { useLocation } from 'react-router-dom';

import { FormLabel } from 'shared-components/FormLabel';

import { TitleWithTooltip } from './TitleWithTooltip';

interface Props<T extends FieldValues> {
  name: string;
  label?: string;
  control: Control<T>;
  max: number;
  min: number;
  className?: string;
  icon?: ElementType;
  tooltipChildren?: FC;
}

export const RateRange = <T extends FieldValues>({
  name,
  label,
  control,
  max,
  min,
  className,
  icon,
  tooltipChildren: TooltipItem,
}: Props<T>) => {
  const { pathname } = useLocation();

  const isView = pathname.includes('/view/');

  return (
    <div key={name} className={cn('flex flex-col gap-6', className)}>
      <FormLabel htmlFor={name} className='relative -left-2 flex gap-1 items-center'>
        <FormattedMessage id={label} />
        {TooltipItem && (
          <TitleWithTooltip className='justify-center' id={name} icon={icon} tooltipChildren={<TooltipItem />} />
        )}
        :
      </FormLabel>
      <Controller
        control={control}
        name={name as never}
        render={({ field }) => (
          <Range
            disabled={isView}
            max={max as number}
            min={min as number}
            values={field.value}
            onChange={(values) => field.onChange(values)}
            renderTrack={({ props, children }) => (
              <div className='h-2 bg-gray2 rounded-md' {...props}>
                {children}
              </div>
            )}
            renderThumb={({ props }) => (
              <div className='bg-extraLightBlue text-white p-2 rounded-md' {...props}>
                {field.value[0]}
              </div>
            )}
          />
        )}
      />
    </div>
  );
};
