import { createAsyncThunk } from '@reduxjs/toolkit';

import { permissionsApi } from 'apis/permissions.apis';
import { showServerError } from 'utils/modules.utils';

export const PERMISSIONS_SLICE_NAME = 'permissions';

export const asyncGetPermissions = createAsyncThunk(
  `${PERMISSIONS_SLICE_NAME}/getPermissions`,
  async (_, { rejectWithValue }) => {
    try {
      const response = await permissionsApi.getPermissions();
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);
