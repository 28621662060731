import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.risksReducer;

export const selectRisks = createSelector(selectState, ({ risks, isLoading }) => ({
  risks: risks?.data,
  pagination: risks?.pagination,
  isLoading,
}));

export const selectRisk = createSelector(selectState, ({ risk }) => risk);
