import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { RolesData, RolesResponse } from 'interfaces/roles.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import { asyncCreateRole, asyncDeleteRole, asyncGetRole, asyncGetRoles, asyncUpdateRole, unsetRole } from './action';
import { RolesSliceState } from './slice';

export function getRolesReducer(builder: ActionReducerMapBuilder<RolesSliceState>) {
  builder.addCase(asyncGetRoles.fulfilled, (state, { payload }: PayloadAction<RolesResponse>) => {
    state.roles = payload.data;
    state.rolesCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetRoles.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getRoleReducer(builder: ActionReducerMapBuilder<RolesSliceState>) {
  builder.addCase(asyncGetRole.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetRole.fulfilled, (state, { payload }: PayloadAction<RolesData>) => {
    state.role = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetRole.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateRoleReducer = withLoadingReducer(asyncUpdateRole);

export const createRoleReducer = withLoadingReducer(asyncCreateRole);

export function deleteRoleReducer(builder: ActionReducerMapBuilder<RolesSliceState>) {
  builder.addCase(asyncDeleteRole.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteRole.fulfilled, (state) => {
    state.role = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteRole.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetRoleReducer(builder: ActionReducerMapBuilder<RolesSliceState>) {
  builder.addCase(unsetRole, (state) => {
    state.role = null;
  });
}
