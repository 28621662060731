import { createSlice } from '@reduxjs/toolkit';

import { CustomerStatus } from 'interfaces/customer-statuses.interfaces';

import { CUSTOMER_STATUSES_SLICE_NAME } from './action';
import {
  createCustomerStatusReducer,
  deleteCustomerStatusReducer,
  unsetCustomerStatusReducer,
  getCustomerStatusReducer,
  getCustomerStatusesReducer,
  updateCustomerStatusReducer,
} from './reducers';

export interface CustomerStatusesSliceState {
  isLoading: boolean;
  customerStatus: CustomerStatus | null;
  customerStatuses: CustomerStatus[] | null;
  customerStatusCount: number | null;
}

export const initialState: CustomerStatusesSliceState = {
  customerStatus: null,
  customerStatuses: null,
  customerStatusCount: null,
  isLoading: false,
};

const customerStatusesSlice = createSlice({
  name: CUSTOMER_STATUSES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomerStatusReducer(builder);
    deleteCustomerStatusReducer(builder);
    unsetCustomerStatusReducer(builder);
    getCustomerStatusReducer(builder);
    getCustomerStatusesReducer(builder);
    updateCustomerStatusReducer(builder);
  },
});

export const customerStatusesReducer = customerStatusesSlice.reducer;
