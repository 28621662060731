import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import {
  CustomerRequestStatus,
  CustomerRequestStatusesResponse,
} from 'interfaces/customer-request-statuses.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCustomerRequestStatus,
  asyncDeleteCustomerRequestStatus,
  asyncGetCustomerRequestStatus,
  asyncGetCustomerRequestStatuses,
  asyncUpdateCustomerRequestStatus,
  unsetCustomerRequestStatus,
} from './action';
import { CustomerRequestStatusesSliceState } from './slice';

export function getCustomerRequestStatusesReducer(builder: ActionReducerMapBuilder<CustomerRequestStatusesSliceState>) {
  builder.addCase(asyncGetCustomerRequestStatuses.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomerRequestStatuses.fulfilled,
    (state, { payload }: PayloadAction<CustomerRequestStatusesResponse>) => {
      state.customerRequestStatuses = payload.data;
      state.customerRequestStatusesCount = payload.pagination.total;
      state.isLoading = false;
    },
  );

  builder.addCase(asyncGetCustomerRequestStatuses.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCustomerRequestStatusReducer(builder: ActionReducerMapBuilder<CustomerRequestStatusesSliceState>) {
  builder.addCase(asyncGetCustomerRequestStatus.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomerRequestStatus.fulfilled,
    (state, { payload }: PayloadAction<CustomerRequestStatus>) => {
      state.customerRequestStatus = payload;
      state.isLoading = false;
    },
  );

  builder.addCase(asyncGetCustomerRequestStatus.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCustomerRequestStatusReducer = withLoadingReducer(asyncUpdateCustomerRequestStatus);

export const createCustomerRequestStatusReducer = withLoadingReducer(asyncCreateCustomerRequestStatus);

export function deleteCustomerRequestStatusReducer(
  builder: ActionReducerMapBuilder<CustomerRequestStatusesSliceState>,
) {
  builder.addCase(asyncDeleteCustomerRequestStatus.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCustomerRequestStatus.fulfilled, (state) => {
    state.customerRequestStatus = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCustomerRequestStatus.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCustomerRequestStatusReducer(builder: ActionReducerMapBuilder<CustomerRequestStatusesSliceState>) {
  builder.addCase(unsetCustomerRequestStatus, (state) => {
    state.customerRequestStatus = null;
  });
}
