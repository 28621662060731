import { useEffect, useState } from 'react';

import { ManagerAction } from 'interfaces/manager-actions.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomerRequestStatuses } from 'modules/customer-request-statuses/action';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useManagerActionOptions = (id?: number, managerAction?: ManagerAction | null) => {
  const dispatch = useAppDispatch();

  const [requestStatusOptions, setRequestStatusOptions] = useState<SelectOption[]>();
  const [defaultRequestStatus, setRequestDefaultStatus] = useState<SelectOption>();

  const [customerStatusOptions, setCustomerStatusOptions] = useState<SelectOption[]>();
  const [defaultCustomerStatus, setDefaultCustomerStatus] = useState<SelectOption>();

  useEffect(() => {
    dispatch(asyncGetCustomerRequestStatuses({}))
      .unwrap()
      .then(({ data }) => {
        const options = convertObjectsToSelectOption(data);
        setRequestStatusOptions(options);
      });

    dispatch(asyncGetCustomerStatuses({}))
      .unwrap()
      .then(({ data }) => {
        const options = convertObjectsToSelectOption(data);
        setCustomerStatusOptions(options);
      });

    if (id && managerAction) {
      setRequestDefaultStatus({ value: managerAction.requestStatus.id, label: managerAction.requestStatus.name });
      setDefaultCustomerStatus({
        value: managerAction?.customerStatus?.id,
        label: managerAction?.customerStatus?.name,
      });
    }
  }, [dispatch, id, managerAction]);

  return { requestStatusOptions, defaultRequestStatus, customerStatusOptions, defaultCustomerStatus };
};
