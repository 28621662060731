import { FC } from 'react';

import { FormattedMessage } from 'react-intl';

import { Task } from 'interfaces/tasks.interfaces';
import { formatToDefaultDate, parseDateWithHours } from 'utils/date.utils';

interface Props {
  task: Task | null;
}

export const TaskDates: FC<Props> = ({ task }) => {
  if (!task) return null;

  return (
    <span className='flex mb-2'>
      <FormattedMessage id='creation_date' />: {formatToDefaultDate(parseDateWithHours(task.createdAt))}
    </span>
  );
};
