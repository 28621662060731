import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { CountryInput } from 'interfaces/countries.interfaces';
import { CustomerStatusInput } from 'interfaces/customer-statuses.interfaces';
import {
  asyncCreateCustomerStatus,
  asyncGetCustomerStatus,
  asyncUpdateCustomerStatus,
  unsetCustomerStatus,
} from 'modules/customer-statuses/action';
import { selectCustomerStatus } from 'modules/customer-statuses/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const CustomerStatusForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const customerStatus = useAppSelector(selectCustomerStatus);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CustomerStatusInput>();

  const onSubmit: SubmitHandler<CountryInput> = (data) => {
    dispatch(id ? asyncUpdateCustomerStatus({ id: +id, ...data }) : asyncCreateCustomerStatus(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.customerStatuses);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetCustomerStatus()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetCustomerStatus(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: customerStatus?.name ?? '' });
    }
  }, [id, reset, customerStatus]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='customer-status-name'
          />
          <ItemNavigation navigateBack={AppRoutes.customerStatuses} />
        </div>
      </Card>
    </form>
  );
};
