import { useEffect, useRef, useState } from 'react';

import ChartJS, { ChartConfiguration } from 'chart.js/auto';
import zoomPlugin from 'chartjs-plugin-zoom';
import 'chartjs-adapter-date-fns';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { Button } from './Button';

ChartJS.register(zoomPlugin);

interface Props {
  config: ChartConfiguration;
}

export const Chart = ({ config }: Props) => {
  const chartContainer = useRef(null);
  const [chartInstance, setChartInstance] = useState<ChartJS | null>(null);

  const isZoomable = config.options?.plugins?.zoom;

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (chartContainer?.current) {
      const newChartInstance = new ChartJS(chartContainer.current, config);
      setChartInstance(newChartInstance);

      return () => {
        newChartInstance.destroy();
      };
    }
  }, [config, chartContainer]);

  const onZoomIn = () => {
    chartInstance?.zoom(1.1);
  };
  const onZoomOut = () => {
    chartInstance?.zoom(0.9);
  };
  const onReset = () => {
    chartInstance?.resetZoom();
  };

  return (
    <div className='flex flex-col items-center gap-14 relative overflow-scroll'>
      <div className='relative self-center mx-auto h-[60vh] w-full'>
        <canvas className={cn('overflow-scroll', { 'cursor-grab': isZoomable })} ref={chartContainer} />
        {isZoomable && (
          <div className='md:absolute top-4 right-0 grid grid-cols-2 items-center m-auto md:m-0 gap-2 w-1/2 md:w-auto'>
            <Button className='w-full' onClick={onZoomIn}>
              +
            </Button>
            <Button className='w-full' onClick={onZoomOut}>
              -
            </Button>
          </div>
        )}
      </div>
      <div className='flex gap-2'>
        {isZoomable && (
          <Button onClick={onReset}>
            <FormattedMessage id='reset_zoom' />
          </Button>
        )}
      </div>
    </div>
  );
};
