import { forwardRef } from 'react';

import { FormattedMessage } from 'react-intl';
import { useLocation, useSearchParams } from 'react-router-dom';
import ReactSelect, { GroupBase, Props as SelectProps, SelectInstance } from 'react-select';

import { selectStyles } from 'constants/shared/select.constants';
import { ErrorMessage } from 'shared-components/ErrorMessage';
import { FormLabel } from 'shared-components/FormLabel';

export interface CustomSelectProps extends SelectProps {
  error?: string;
  label?: string;
  isRequired?: boolean;
}

export const Select = forwardRef<SelectInstance<unknown, boolean, GroupBase<unknown>>, CustomSelectProps>(
  (
    {
      label,
      error,
      isRequired,
      styles = selectStyles,
      menuPortalTarget = document.body,
      name,
      isMulti,
      isDisabled,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const multiSelectComponents = { IndicatorSeparator: () => null };

    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();

    const isView = pathname.includes('/view/') || searchParams.has('mode');
    const formattedPlaceholder = `${placeholder}`.length > 20 ? `${placeholder}`.slice(0, 20) + '...' : placeholder;

    return (
      <div className='flex flex-col gap-2 flex-1'>
        {label && (
          <FormLabel htmlFor={name} className='flex'>
            <FormattedMessage id={label} defaultMessage={label} />
            {isRequired && !isView && '*'}
          </FormLabel>
        )}
        <div>
          <ReactSelect
            ref={ref}
            name={name}
            styles={styles}
            isMulti={isMulti}
            placeholder={isView ? '' : formattedPlaceholder}
            isDisabled={isDisabled || isView}
            menuPortalTarget={menuPortalTarget}
            components={isMulti ? multiSelectComponents : undefined}
            {...props}
          />
          {error && (
            <ErrorMessage>
              <FormattedMessage id={error} defaultMessage={error} />
            </ErrorMessage>
          )}
        </div>
      </div>
    );
  },
);

Select.displayName = 'Select';
