import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { auditsApi } from 'apis/audits.apis';
import {
  AuditCarryingPayload,
  AuditFinishPayload,
  AuditPayload,
  UpdateAuditPayload,
} from 'interfaces/audits.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const AUDIT_SLICE_NAME = 'audits';

export const asyncGetAudits = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/getAudits`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await auditsApi.getAudits(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/getAudit`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await auditsApi.getAuditById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/updateAudit`,
  async (payload: UpdateAuditPayload, { rejectWithValue }) => {
    try {
      await auditsApi.updateAudit(payload);
      toast.success(intl.formatMessage({ id: 'audit_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/createAudit`,
  async (payload: AuditPayload, { rejectWithValue }) => {
    try {
      await auditsApi.createAudit(payload);
      toast.success(intl.formatMessage({ id: 'audit_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/deleteAudit`,
  async (id: number, { rejectWithValue }) => {
    try {
      await auditsApi.deleteAudit(id);
      toast.success(intl.formatMessage({ id: 'audit_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncProceedAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/proceedAudit`,
  async (payload: AuditCarryingPayload, { rejectWithValue }) => {
    try {
      await auditsApi.proceedAudit(payload);
      toast.success(intl.formatMessage({ id: 'audit_proceeded' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncFinishAudit = createAsyncThunk(
  `${AUDIT_SLICE_NAME}/finishAudit`,
  async (payload: AuditFinishPayload, { rejectWithValue }) => {
    try {
      await auditsApi.finishAudit(payload);
      toast.success(intl.formatMessage({ id: 'audit_finished' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetAudit = createAction('UNSET_AUDIT');
