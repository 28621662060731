import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CorrectionForm } from 'page-components/corrections/CorrectionForm';
import { CorrectionInconsistencyInfo } from 'page-components/corrections/CorrectionInconsistencyInfo';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const Correction = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.correctionCreate ? 'create_correction' : 'edit_correction';
  const titleText = pathname.includes('view') ? 'view_correction' : editTitle;

  return (
    <PageLayout title={titleText} wrapperClassName='flex flex-col gap-4'>
      <CorrectionInconsistencyInfo />
      <CorrectionForm />
    </PageLayout>
  );
};
