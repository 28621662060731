import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useCorrectionOptions } from 'hooks/useCorrectionOptions';
import { CorrectionInput, MetaData } from 'interfaces/corrections.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  performer?: MetaData;
  responsible?: MetaData;
}

export const CorrectionPerformersFields: FC<Props> = ({ performer, responsible }) => {
  const { formatMessage } = useIntl();

  const { usersOptions, defaultPerformerOption, defaultResponsibleOption } = useCorrectionOptions(
    performer?.id,
    responsible?.id,
  );

  const {
    control,
    formState: { errors },
  } = useFormContext<CorrectionInput>();

  return (
    <Card title='responsible_and_executor' wrapperClassName='flex-1' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='performer'
        defaultValue={defaultPerformerOption}
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            label='performer'
            error={errors['performer']?.message}
            defaultValue={defaultPerformerOption}
            options={usersOptions}
            className='performer flex-1 h-full'
            placeholder={formatMessage({ id: 'search_performer' })}
          />
        )}
      />
      <Controller
        control={control}
        name='responsible'
        defaultValue={defaultResponsibleOption}
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            label='responsible'
            error={errors['responsible']?.message}
            defaultValue={defaultResponsibleOption}
            options={usersOptions}
            className='performer flex-1 h-full'
            placeholder={formatMessage({ id: 'search_responsible' })}
          />
        )}
      />
    </Card>
  );
};
