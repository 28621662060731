import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { InconsistencyTypesResponse, InconsistencyResponse } from 'interfaces/inconsistency-types.interfaces';
import { ProductGroupData, ProductGroupPayload } from 'interfaces/product-groups.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class InconsistencyTypesApi {
  constructor(private url: string) {}

  getInconsistencyTypes(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<InconsistencyTypesResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getInconsistencyTypeById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<InconsistencyResponse>(`${this.url}/${payload}`, config);
  }

  updateInconsistencyType({ id, ...data }: ProductGroupPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<InconsistencyResponse>(`${this.url}/${id}`, data, config);
  }

  createInconsistencyType(data: ProductGroupData, config?: AxiosRequestConfig) {
    return httpApiClient.post<InconsistencyResponse>(`${this.url}`, data, config);
  }

  deleteInconsistencyType(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }
}

export const inconsistencyTypesApi = new InconsistencyTypesApi('/inconsistency-types');
