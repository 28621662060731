import { useEffect } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useRiskOptions } from 'hooks/useRiskOptions';
import { RiskInput } from 'interfaces/risks.interfaces';
import { asyncCreateRisk, asyncGetRisk, asyncUpdateRisk, unsetRisk } from 'modules/risks/action';
import { selectRisk } from 'modules/risks/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Select } from 'shared-components/Select';

export const RiskForm = () => {
  const { id } = useParams();
  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const risk = useAppSelector(selectRisk);

  const { responsibleOptions, departmentsOptions, defaultResponsibleOption, defaultDepartmentOption } =
    useRiskOptions(risk);

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RiskInput>();

  const onSubmit: SubmitHandler<RiskInput> = ({
    responsible: responsibleOption,
    department: departmentOption,
    ...data
  }) => {
    const responsible = responsibleOption.value as number;
    const department = departmentOption.value as number;

    const formattedData = { responsible, department, ...data };

    dispatch(id ? asyncUpdateRisk({ id: +id, ...formattedData }) : asyncCreateRisk(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.risks);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetRisk()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetRisk(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id && risk) {
      reset({
        name: risk.name,
        department: { value: risk.department.id, label: risk.department.name },
        responsible: { value: risk.responsible.id, label: risk.responsible.name },
      });
    }
  }, [id, reset, risk]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-8'>
      <Card title='risk_assessment' wrapperClassName='flex flex-col w-1/2' className='flex flex-col gap-6'>
        <Input
          {...register('name', REQUIRED_VALIDATION)}
          isRequired
          error={errors['name']?.message as string}
          label='subject'
          placeholder={formatMessage({ id: 'subject' })}
          data-test='subject'
        />
        <Controller
          control={control}
          name='department'
          rules={REQUIRED_VALIDATION}
          defaultValue={defaultDepartmentOption}
          render={({ field }) => (
            <Select
              {...field}
              error={errors['department']?.message}
              defaultValue={defaultDepartmentOption}
              isRequired
              label='department'
              options={departmentsOptions}
              className='flex-1 h-full'
              placeholder={formatMessage({ id: 'search' })}
            />
          )}
        />
        <Controller
          control={control}
          name='responsible'
          rules={REQUIRED_VALIDATION}
          defaultValue={defaultResponsibleOption}
          render={({ field }) => (
            <Select
              {...field}
              error={errors['responsible']?.message}
              defaultValue={defaultResponsibleOption}
              isRequired
              label='responsible'
              options={responsibleOptions}
              className='flex-1 h-full'
              placeholder={formatMessage({ id: 'search' })}
            />
          )}
        />
      </Card>
      <ItemNavigation navigateBack={AppRoutes.risks} />
    </form>
  );
};
