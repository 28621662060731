import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import {
  NotificationPayload,
  NotificationResponse,
  NotificationsResponse,
  UpdateNotificationPayload,
} from 'interfaces/notifications.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class NotificationsApi {
  constructor(private url: string) {}

  getNotifications(query: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<NotificationsResponse>(`${this.url}?${generateQueryString(query)}`, config);
  }

  getNotificationById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<NotificationResponse>(`${this.url}/${payload}`, config);
  }

  updateNotification({ id, ...data }: UpdateNotificationPayload, config?: AxiosRequestConfig) {
    return httpApiClient.put<NotificationResponse>(`${this.url}/${id}`, data, config);
  }

  createNotification(data: NotificationPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<NotificationResponse>(`${this.url}`, data, config);
  }

  deleteNotification(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }

  confirmNotification(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.put(`${this.url}/${id}/confirm`, config);
  }
}

export const notificationsApi = new NotificationsApi('/notifications');
