import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Education } from 'interfaces/educations.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { formatToDefaultDate, parseDefaultDate } from 'utils/date.utils';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Education;
}

export const EducationsTableRow: FC<Props> = ({
  handleDelete,
  data: { id, plannedAt, finishedAt, theme, responsible },
}) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.educations, id);

  const startDate = parseDefaultDate(plannedAt);
  const realStartDate = parseDefaultDate(finishedAt);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          editPermission='education.update'
          viewRoute={viewRoute}
          deletePermission='education.delete'
          viewPermission='education.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{formatToDefaultDate(startDate)}</TableData>
      <TableData>{formatToDefaultDate(realStartDate)}</TableData>
      <TableData>{theme}</TableData>
      <TableData className='last:text-left'>{responsible.name}</TableData>
    </TableRow>
  );
};
