import { createSlice } from '@reduxjs/toolkit';

import { Customer } from 'interfaces/customers.interfaces';

import { CUSTOMERS_SLICE_NAME } from './action';
import {
  createCustomerReducer,
  deleteCustomerReducer,
  getCustomerReducer,
  getCustomersReducer,
  unsetCustomerReducer,
  updateCustomerReducer,
} from './reducers';

export interface CustomersSliceState {
  isLoading: boolean;
  customer: Customer | null;
  customers: Customer[] | null;
  customersCount: number | null;
}

export const initialState: CustomersSliceState = {
  customer: null,
  customers: null,
  customersCount: null,
  isLoading: false,
};

const customersSlice = createSlice({
  name: CUSTOMERS_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomerReducer(builder);
    deleteCustomerReducer(builder);
    unsetCustomerReducer(builder);
    getCustomersReducer(builder);
    getCustomerReducer(builder);
    updateCustomerReducer(builder);
  },
});

export const customersReducer = customersSlice.reducer;
