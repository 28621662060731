import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Audit, AuditsResponse } from 'interfaces/audits.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateAudit,
  asyncDeleteAudit,
  asyncFinishAudit,
  asyncGetAudit,
  asyncGetAudits,
  asyncProceedAudit,
  asyncUpdateAudit,
  unsetAudit,
} from './action';
import { AuditSliceState } from './slice';

export function getAuditsReducer(builder: ActionReducerMapBuilder<AuditSliceState>) {
  builder.addCase(asyncGetAudits.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetAudits.fulfilled, (state, { payload }: PayloadAction<AuditsResponse>) => {
    state.audits = payload.data;
    state.auditsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetAudits.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getAuditReducer(builder: ActionReducerMapBuilder<AuditSliceState>) {
  builder.addCase(asyncGetAudit.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetAudit.fulfilled, (state, { payload }: PayloadAction<Audit>) => {
    state.audit = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetAudit.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateAuditReducer = withLoadingReducer(asyncUpdateAudit);
export const createAuditReducer = withLoadingReducer(asyncCreateAudit);
export const proceedAuditReducer = withLoadingReducer(asyncProceedAudit);
export const finishAuditReducer = withLoadingReducer(asyncFinishAudit);

export function deleteAuditReducer(builder: ActionReducerMapBuilder<AuditSliceState>) {
  builder.addCase(asyncDeleteAudit.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteAudit.fulfilled, (state) => {
    state.audit = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteAudit.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetAuditReducer(builder: ActionReducerMapBuilder<AuditSliceState>) {
  builder.addCase(unsetAudit, (state) => {
    state.audit = null;
  });
}
