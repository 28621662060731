import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { QueryParams } from 'interfaces/query.interfaces';
import {
  CommentPayload,
  TaskConfirmPayload,
  TaskInputPayload,
  TaskPerformerPayload,
  TaskResponse,
  TasksResponse,
  UpdateTask,
} from 'interfaces/tasks.interfaces';
import { generateQueryString } from 'utils/apis.utils';

export class TasksApi {
  constructor(private url: string) {}

  getTasks(query?: QueryParams, config?: AxiosRequestConfig) {
    return httpApiClient.get<TasksResponse>(`${this.url}?${generateQueryString(query ?? {})}`, config);
  }

  getTaskById(payload: string, config?: AxiosRequestConfig) {
    return httpApiClient.get<TaskResponse>(`${this.url}/${payload}`, config);
  }

  updateTask({ id, ...data }: UpdateTask, config?: AxiosRequestConfig) {
    return httpApiClient.put<TaskResponse>(`${this.url}/${id}`, data, config);
  }

  createTask(data: TaskInputPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<TaskResponse>(`${this.url}`, data, config);
  }

  deleteTask(id: number, config?: AxiosRequestConfig) {
    return httpApiClient.delete(`${this.url}/${id}`, config);
  }

  addComment({ id, ...data }: CommentPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<TaskResponse>(`${this.url}/${id}/comment`, data, config);
  }

  changePerformer({ taskId, ...data }: TaskPerformerPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<TaskResponse>(`${this.url}/${taskId}/performer`, data, config);
  }

  confirmByPerformer({ id, ...data }: TaskConfirmPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<TaskResponse>(`${this.url}/${id}/confirm`, data, config);
  }

  confirmByOwner({ id, ...data }: TaskConfirmPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post(`${this.url}/${id}/complete`, data, config);
  }
}

export const tasksApi = new TasksApi('/tasks');
