import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { DepartmentInput } from 'interfaces/departments.interfaces';
import { asyncCreateDepartment, asyncGetDepartment, asyncUpdateDepartment } from 'modules/departments/action';
import { selectDepartment } from 'modules/departments/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const DepartmentForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const departments = useAppSelector(selectDepartment);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DepartmentInput>();

  const onSubmit: SubmitHandler<DepartmentInput> = (data) => {
    dispatch(id ? asyncUpdateDepartment({ id: +id, ...data }) : asyncCreateDepartment(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.departments);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetDepartment(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: departments?.name });
    }
  }, [id, reset, departments]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2 overflow-visible' titleClassName='rounded-t-lg'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            isRequired
            error={errors['name']?.message}
            label='title'
            placeholder={formatMessage({ id: 'title' })}
            data-test='department-name'
          />
          <ItemNavigation navigateBack={AppRoutes.departments} />
        </div>
      </Card>
    </form>
  );
};
