import { FC } from 'react';

import { ReactComponent as CautionSvg } from 'assets/svg/caution.svg';
import { AppRoutes } from 'constants/routes-paths.constants';
import { Self } from 'interfaces/self.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { TitleWithTooltip } from 'shared-components/TitleWithTooltip';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleOpenDeleteModal: VoidFunction;
  data: Self;
}

export const UsersTableRow: FC<Props> = ({ handleOpenDeleteModal, data: { email, name, roles, permissions, id } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.users, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleOpenDeleteModal}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='users.update'
          deletePermission='users.delete'
          viewPermission='users.view'
        />
      </TableData>
      <TableData>{name}</TableData>
      <TableData>{email}</TableData>
      <TableData>
        {Object.values(roles).map((role) => (
          <p key={role.id}>{role.name}</p>
        ))}
      </TableData>
      <TableData>
        <TitleWithTooltip
          className='justify-end'
          id={`${id}`}
          text='permissions'
          icon={CautionSvg}
          tooltipChildren={permissions.map((permission) => (
            <p key={permission}>{permission}</p>
          ))}
        />
      </TableData>
    </TableRow>
  );
};
