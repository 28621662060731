import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Complect, ComplectsResponse } from 'interfaces/complects.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateComplect,
  asyncDeleteComplect,
  asyncGetComplect,
  asyncGetComplects,
  asyncUpdateComplect,
  unsetComplect,
} from './action';
import { ComplectsSliceState } from './slice';

export function getComplectsReducer(builder: ActionReducerMapBuilder<ComplectsSliceState>) {
  builder.addCase(asyncGetComplects.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetComplects.fulfilled, (state, { payload }: PayloadAction<ComplectsResponse>) => {
    state.complects = payload.data;
    state.complectsCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetComplects.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getComplectReducer(builder: ActionReducerMapBuilder<ComplectsSliceState>) {
  builder.addCase(asyncGetComplect.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetComplect.fulfilled, (state, { payload }: PayloadAction<Complect>) => {
    state.complect = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetComplect.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateComplectReducer = withLoadingReducer(asyncUpdateComplect);
export const createComplectReducer = withLoadingReducer(asyncCreateComplect);

export function deleteComplectReducer(builder: ActionReducerMapBuilder<ComplectsSliceState>) {
  builder.addCase(asyncDeleteComplect.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteComplect.fulfilled, (state) => {
    state.complect = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteComplect.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetComplectReducer(builder: ActionReducerMapBuilder<ComplectsSliceState>) {
  builder.addCase(unsetComplect, (state) => {
    state.complect = null;
  });
}
