import { useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerInput } from 'interfaces/customers.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomerStatuses } from 'modules/customer-statuses/action';
import { selectCustomer } from 'modules/customers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const CustomerStatus = () => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const customer = useAppSelector(selectCustomer);

  const {
    control,
    formState: { errors },
  } = useFormContext<CustomerInput>();

  const [statusOptions, setStatusOptions] = useState<SelectOption[]>();
  const [statusOption, setStatusOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(asyncGetCustomerStatuses({}))
      .unwrap()
      .then((response) => {
        const statuses = response.data;
        const customerStatus = statuses.find(({ id }) => id === customer?.customerStatus.id);

        if (customerStatus) {
          setStatusOption({ value: customerStatus.id, label: customerStatus.name });
        }
        setStatusOptions(convertObjectsToSelectOption(statuses));
      });
  }, [customer, dispatch]);

  return (
    <Card title='other'>
      <Controller
        control={control}
        name='customerStatus'
        defaultValue={statusOption}
        rules={REQUIRED_VALIDATION}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            label='status'
            error={errors['customerStatus']?.message}
            defaultValue={statusOption}
            options={statusOptions}
            className='performer flex-1 h-full'
            placeholder={formatMessage({ id: 'search_customer_status' })}
          />
        )}
      />
    </Card>
  );
};
