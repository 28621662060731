import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { InconsistencyTypeInput } from 'interfaces/inconsistency-types.interfaces';
import {
  asyncCreateInconsistencyType,
  asyncGetInconsistencyType,
  asyncUpdateInconsistencyType,
  unsetInconsistencyType,
} from 'modules/inconsistency-types/action';
import { selectInconsistencyTypes, selectInconsistencyType } from 'modules/inconsistency-types/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const InconsistencyTypeForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const inconsistency = useAppSelector(selectInconsistencyType);
  const inconsistencies = useAppSelector(selectInconsistencyTypes);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<InconsistencyTypeInput>();

  const onSubmit: SubmitHandler<InconsistencyTypeInput> = (data) => {
    dispatch(id ? asyncUpdateInconsistencyType({ id: +id, ...data }) : asyncCreateInconsistencyType(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.inconsistencyTypes);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (id) {
      dispatch(asyncGetInconsistencyType(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({
        name: inconsistency?.name ?? '',
      });
    }
  }, [id, reset, inconsistency, inconsistencies]);

  useBeforeRouteChange(() => {
    dispatch(unsetInconsistencyType());
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2 overflow-visible' titleClassName='rounded-t-lg'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            isRequired
            error={errors['name']?.message}
            label='title'
            placeholder={formatMessage({ id: 'title' })}
            data-test='inconsistency-type-name'
          />
          <ItemNavigation navigateBack={AppRoutes.inconsistencyTypes} />
        </div>
      </Card>
    </form>
  );
};
