import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Document } from 'interfaces/documents.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Document;
}

export const DocumentsTableRow: FC<Props> = ({
  handleDelete,
  data: { id, departments, name, documentType, description },
}) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.documents, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          editPermission='education.update'
          viewRoute={viewRoute}
          deletePermission='education.delete'
          viewPermission='education.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{name}</TableData>
      <TableData>{description}</TableData>
      <TableData>{documentType?.name}</TableData>
      <TableData className='last:text-left'>{departments.map(({ name }) => name).join(', ')}</TableData>
    </TableRow>
  );
};
