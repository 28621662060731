import { useEffect } from 'react';

import { useLocation, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { asyncGetRoles } from 'modules/roles/action';
import { selectIsLoading as selectRolesIsLoading } from 'modules/roles/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getUser, getUsers, unsetUser } from 'modules/users/action';
import { selectIsLoading as selectUserIsLoading, selectUser } from 'modules/users/selectors';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { UserForm } from 'page-components/user/UserForm';

export const User = () => {
  const { pathname } = useLocation();

  const editTitle = pathname === AppRoutes.userCreate ? 'create_user' : 'edit_user';
  const titleText = pathname.includes('view') ? 'view_user' : editTitle;

  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);
  const userIsLoading = useAppSelector(selectUserIsLoading);
  const rolesIsLoading = useAppSelector(selectRolesIsLoading);

  const { id } = useParams();

  useEffect(() => {
    dispatch(getUsers());
    dispatch(id ? getUser(id) : unsetUser());
    dispatch(asyncGetRoles({ page: 0 }));
  }, [dispatch, id]);

  if (userIsLoading || rolesIsLoading) return null;
  return (
    <PageLayout title={titleText}>
      <UserForm user={user} />
    </PageLayout>
  );
};
