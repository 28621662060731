import { SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { PROFILE_CHANGE_PASSWORD_FIELDS } from 'constants/profile.constants';
import { ChangePasswordInput } from 'interfaces/reset-password.interfaces';
import { Button } from 'shared-components/Button';
import { FormFields } from 'shared-components/FormFields';

export const ProfileChangePasswordForm = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ChangePasswordInput>();

  const onSubmit: SubmitHandler<ChangePasswordInput> = () => 'onSubmit';

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-4'>
      <FormFields fields={PROFILE_CHANGE_PASSWORD_FIELDS} register={register} errors={errors} />
      <Button className='self-center' type='submit' data-test='change-password'>
        <FormattedMessage id='change_password' />
      </Button>
    </form>
  );
};
