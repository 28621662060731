import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { tasksApi } from 'apis/tasks.apis';
import { QueryParams } from 'interfaces/query.interfaces';
import {
  CommentPayload,
  TaskConfirmPayload,
  TaskInputPayload,
  TaskPerformerPayload,
  UpdateTask,
} from 'interfaces/tasks.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const TASKS_SLICE_NAME = 'tasks';

export const asyncGetTasks = createAsyncThunk(
  `${TASKS_SLICE_NAME}/getTasks`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await tasksApi.getTasks(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetTask = createAsyncThunk(
  `${TASKS_SLICE_NAME}/getTask`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await tasksApi.getTaskById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateTask = createAsyncThunk(
  `${TASKS_SLICE_NAME}/updateTask`,
  async (payload: UpdateTask, { rejectWithValue }) => {
    try {
      await tasksApi.updateTask(payload);
      toast.success(intl.formatMessage({ id: 'task_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateTask = createAsyncThunk(
  `${TASKS_SLICE_NAME}/createTask`,
  async (payload: TaskInputPayload, { rejectWithValue }) => {
    try {
      await tasksApi.createTask(payload);
      toast.success(intl.formatMessage({ id: 'task_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteTask = createAsyncThunk(
  `${TASKS_SLICE_NAME}/deleteTask`,
  async (id: number, { rejectWithValue }) => {
    try {
      await tasksApi.deleteTask(id);
      toast.success(intl.formatMessage({ id: 'task_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncAddCommentTask = createAsyncThunk(
  `${TASKS_SLICE_NAME}/addComment`,
  async (payload: CommentPayload, { rejectWithValue }) => {
    try {
      const response = await tasksApi.addComment(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncChangePerformer = createAsyncThunk(
  `${TASKS_SLICE_NAME}/changePerformer`,
  async (payload: TaskPerformerPayload, { rejectWithValue }) => {
    try {
      const response = await tasksApi.changePerformer(payload);
      toast.success(intl.formatMessage({ id: 'added_delegate_successful' }));
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncConfirmByPerformer = createAsyncThunk(
  `${TASKS_SLICE_NAME}/confirmByPerformer`,
  async (payload: TaskConfirmPayload, { rejectWithValue }) => {
    try {
      const response = await tasksApi.confirmByPerformer(payload);
      toast.success(intl.formatMessage({ id: 'confirmed_execution_successful' }));
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncConfirmByOwner = createAsyncThunk(
  `${TASKS_SLICE_NAME}/confirmByOwner`,
  async (payload: TaskConfirmPayload, { rejectWithValue }) => {
    try {
      await tasksApi.confirmByOwner(payload);
      toast.success(intl.formatMessage({ id: 'confirmed_execution_successful' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetTask = createAction('UNSET_TASK');
export const unsetTasks = createAction('UNSET_TASKS');
