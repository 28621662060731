import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { ReportDatesResponse, ReportsResponse } from 'interfaces/reports.interfaces';

import {
  asyncGetCustomerReports,
  asyncGetCustomerRequestsDynamicReports,
  asyncGetCustomerRequestsReports,
  asyncGetCustomersDynamicReports,
  asyncGetInconsistenciesDynamicReports,
  asyncGetInconsistenciesReports,
  unsetReport,
} from './action';
import { ReportsSliceState } from './slice';

export function getCustomerReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetCustomerReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerReports.fulfilled, (state, { payload }: PayloadAction<ReportsResponse>) => {
    state.reports = payload.data;
    state.isLoading = false;
  });
}

export function getCustomerRequestsReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetCustomerRequestsReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomerRequestsReports.fulfilled, (state, { payload }: PayloadAction<ReportsResponse>) => {
    state.reports = payload.data;
    state.isLoading = false;
  });
}

export function getCustomerRequestsDynamicReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetCustomerRequestsDynamicReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomerRequestsDynamicReports.fulfilled,
    (state, { payload }: PayloadAction<ReportsResponse>) => {
      state.reports = payload.data;
      state.isLoading = false;
    },
  );
}

export function getInconsistenciesReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetInconsistenciesReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetInconsistenciesReports.fulfilled, (state, { payload }: PayloadAction<ReportsResponse>) => {
    state.reports = payload.data;
    state.isLoading = false;
  });
}

export function getInconsistenciesDynamicReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetInconsistenciesDynamicReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetInconsistenciesDynamicReports.fulfilled,
    (state, { payload }: PayloadAction<ReportsResponse>) => {
      state.reports = payload.data;
      state.isLoading = false;
    },
  );
}

export function getCustomersDynamicReportsReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(asyncGetCustomersDynamicReports.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(
    asyncGetCustomersDynamicReports.fulfilled,
    (state, { payload }: PayloadAction<ReportDatesResponse>) => {
      state.reports = payload.data;
      state.isLoading = false;
    },
  );
}

export function unsetReportReducer(builder: ActionReducerMapBuilder<ReportsSliceState>) {
  builder.addCase(unsetReport, (state) => {
    state.reports = null;
  });
}
