import { useEffect, useState } from 'react';

import { Notification } from 'interfaces/notifications.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useNotificationOptions = (notification?: Notification | null) => {
  const dispatch = useAppDispatch();

  const [departmentsOptions, setDepartmentsOptions] = useState<SelectOption[]>();
  const [subordinatesOptions, setSubordinatesOptions] = useState<SelectOption[]>();

  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<SelectOption[]>();
  const [defaultSubordinatesOption, setDefaultSubordinatesOption] = useState<SelectOption[]>();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDepartmentsOptions(options);
        setDefaultDepartmentOption(
          options.filter(({ value }) => notification?.departments?.some(({ id }) => value === id)),
        );
      });

    dispatch(getUsers({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDefaultSubordinatesOption(
          options.filter(({ value }) => notification?.employees?.some(({ id }) => value === id)),
        );
        setSubordinatesOptions(options);
      });
  }, [dispatch, notification]);

  return {
    departmentsOptions,
    subordinatesOptions,
    defaultDepartmentOption,
    defaultSubordinatesOption,
  };
};
