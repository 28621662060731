import { FC, useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { ComplaintInput } from 'interfaces/complaints.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { useAppDispatch } from 'modules/store';
import { getUsers } from 'modules/users/action';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

interface Props {
  defaultResponsible?: number;
}

export const ComplaintResponsible: FC<Props> = ({ defaultResponsible }) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [responsibleOptions, setResponsibleOptions] = useState<SelectOption[]>();
  const [defaultResponsibleOption, setDefaultResponsibleOption] = useState<SelectOption>();

  const {
    control,
    formState: { errors },
  } = useFormContext<ComplaintInput>();

  useEffect(() => {
    dispatch(getUsers({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setResponsibleOptions(options);
        setDefaultResponsibleOption(options.find(({ value }) => value === defaultResponsible));
      });
  }, [dispatch, defaultResponsible]);

  return (
    <Card title='responsible' wrapperClassName='flex-1 overflow-visible' className='flex flex-col gap-4'>
      <Controller
        control={control}
        name='responsible'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultResponsibleOption}
        render={({ field: { onChange, value } }) => (
          <Select
            placeholder={formatMessage({ id: 'search_responsible' })}
            isRequired
            label='responsible'
            error={errors['responsible']?.message}
            defaultValue={defaultResponsibleOption}
            options={responsibleOptions ?? []}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Card>
  );
};
