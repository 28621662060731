import { useEffect } from 'react';

import { useParams, useSearchParams } from 'react-router-dom';

import { RiskStatuses } from 'constants/risks.constsnts';
import { asyncGetRisk } from 'modules/risks/action';
import { selectRisk } from 'modules/risks/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { RiskAssessmentTable } from 'page-components/risks/RiskAssessmentTable';
import { RiskPreventTable } from 'page-components/risks/RiskPreventTable';
import { RiskProceedForm } from 'page-components/risks/RiskProceedForm';
import { RiskProceedInfo } from 'page-components/risks/RiskProceedInfo';
import { RiskProceedNavigation } from 'page-components/risks/RiskProceedNavigation';

export const RiskProceed = () => {
  const { id } = useParams();
  const [searchParams] = useSearchParams();

  const dispatch = useAppDispatch();
  const risk = useAppSelector(selectRisk);

  const isUpdatable = risk?.status !== RiskStatuses.completed && !searchParams.has('isView');

  useEffect(() => {
    if (!id) return;
    dispatch(asyncGetRisk(id));
  }, [dispatch, id]);

  return (
    <PageLayout title='risk_assessments_proceed' wrapperClassName='flex flex-col gap-4'>
      <RiskProceedInfo />
      <RiskAssessmentTable />
      <RiskPreventTable />
      {isUpdatable && <RiskProceedForm />}
      <RiskProceedNavigation />
    </PageLayout>
  );
};
