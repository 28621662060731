import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { RiskItem, RiskLevel } from 'interfaces/risks.interfaces';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';

interface Props {
  data: RiskItem;
}

export const RiskAssessmentTableRow = ({ data: { name, probability, seriousness } }: Props) => {
  const riskLevel = probability * seriousness;
  const getMark = (): RiskLevel => {
    let metaData: RiskLevel;

    switch (true) {
      case riskLevel <= 3:
        metaData = { title: 'risk_not_critical', className: 'text-green' };
        break;
      case riskLevel <= 6:
        metaData = { title: 'risk_medium', className: 'text-yellow-600' };
        break;
      case riskLevel >= 6:
        metaData = { title: 'risk_high', className: 'text-red1' };
        break;
      default:
        metaData = { title: '', className: '' };
    }

    return metaData;
  };

  const { title, className } = getMark();

  return (
    <TableRow className='text-center'>
      <TableData>{name}</TableData>
      <TableData>{probability}</TableData>
      <TableData>{seriousness}</TableData>
      <TableData>{riskLevel}</TableData>
      <TableData className={cn('last:text-left', className)}>
        <FormattedMessage id={title} />
      </TableData>
    </TableRow>
  );
};
