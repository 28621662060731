import { RISKS_PROBABILITIES, RISKS_PROBABILITIES_COLUMNS } from 'constants/risks.constsnts';
import { Table } from 'shared-components/table/Table';

import { RiskProbabilityTableRow } from './RiskProbabilityTableRow';

export const RiskProbabilityTable = () => {
  return (
    <Table columns={RISKS_PROBABILITIES_COLUMNS}>
      {RISKS_PROBABILITIES.map((risk) => (
        <RiskProbabilityTableRow key={risk.mark} data={risk} />
      ))}
    </Table>
  );
};
