import { StylesConfig } from 'react-select';

export const selectStyles: StylesConfig = {
  control: (styles, { isDisabled }) => ({
    ...styles,
    overflow: 'hidden',
    borderRadius: '12px',
    border: isDisabled ? '0px' : '1px solid #3d6472',
    backgroundColor: isDisabled ? 'none' : '#ffffff',
  }),
  multiValue: (styles, { isDisabled }) => ({
    ...styles,
    borderRadius: '10px',
    paddingRight: isDisabled ? '3px' : '0px',
  }),
  multiValueRemove: (styles, { isDisabled }) => ({
    ...styles,
    borderRadius: '10px',
    display: isDisabled ? 'none' : 'flex',
  }),
  option: (styles, { isSelected, isFocused }) => {
    let backgroundColor = '#ffffff';
    let color = '#000000';

    if (isFocused) {
      backgroundColor = '#e5e5e5';
      color = '#000000';
    }

    if (isSelected) {
      backgroundColor = '#5f8fa1';
      color = '#ffffff';
    }

    return {
      ...styles,
      backgroundColor,
      color,
    };
  },
  menuPortal: (styles) => ({
    ...styles,
    zIndex: 9999,
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: '8px 10px',
    color: '#000',
    fontSize: '14px',
  }),
  singleValue: (styles) => ({
    ...styles,
    color: '#000',
  }),
  valueContainer: (styles, { isDisabled }) => ({
    ...styles,
    paddingLeft: isDisabled ? '0px' : '8px',
  }),
  indicatorsContainer: (styles, { isDisabled }) => ({
    ...styles,
    display: isDisabled ? 'none' : 'flex',
  }),
};
