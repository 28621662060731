import { createSlice } from '@reduxjs/toolkit';

import { CustomerRequest } from 'interfaces/customer-requests.interfaces';

import { CUSTOMERS_REQUEST_SLICE_NAME } from './action';
import {
  createCustomerRequestReducer,
  deleteCustomerRequestReducer,
  getCustomerRequestReducer,
  updateCustomerRequestReducer,
  unsetCustomerRequestReducer,
  getCustomerRequestsReducer,
} from './reducers';

export interface CustomerRequestSliceState {
  isLoading: boolean;
  customerRequest: CustomerRequest | null;
  customerRequests: CustomerRequest[] | null;
  customersRequestsCount: number | null;
}

export const initialState: CustomerRequestSliceState = {
  customerRequest: null,
  customerRequests: null,
  customersRequestsCount: null,
  isLoading: false,
};

const customerRequestSlice = createSlice({
  name: CUSTOMERS_REQUEST_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomerRequestReducer(builder);
    deleteCustomerRequestReducer(builder);
    getCustomerRequestReducer(builder);
    updateCustomerRequestReducer(builder);
    unsetCustomerRequestReducer(builder);
    getCustomerRequestsReducer(builder);
  },
});

export const customerRequestReducer = customerRequestSlice.reducer;
