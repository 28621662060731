import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { Subordinate } from 'interfaces/subordinates.interfaces';
import { UserInput } from 'interfaces/users.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';

interface Props {
  userSubordinates: Subordinate[];
  defaultSubordinates: Subordinate[];
}

export const UserSubordinates: FC<Props> = ({ userSubordinates, defaultSubordinates }) => {
  const subordinatesOptions = userSubordinates.map(({ id, name }) => ({ label: name, value: id }));
  const defaultOptions = defaultSubordinates.map(({ id, name }) => ({ label: name, value: id }));

  const { formatMessage } = useIntl();

  const {
    control,
    formState: { errors },
  } = useFormContext<UserInput>();

  return (
    <Card title='subordinates' wrapperClassName='overflow-visible flex-1'>
      <Controller
        control={control}
        name='subordinatesSelect'
        defaultValue={defaultOptions}
        render={({ field }) => (
          <Select
            {...field}
            error={errors['subordinatesSelect']?.message}
            defaultValue={defaultOptions}
            isMulti
            options={subordinatesOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_subordinates' })}
          />
        )}
      />
    </Card>
  );
};
