import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.countriesReducer;

export const selectCountry = createSelector(selectState, ({ country }) => country);

export const selectCountries = createSelector(selectState, ({ countries }) => countries);

export const selectCountriesCount = createSelector(selectState, ({ countriesCount }) => countriesCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
