import { ChartConfiguration } from 'chart.js';
import { addYears } from 'date-fns';

import { ChartData, Report, ReportDate } from 'interfaces/reports.interfaces';

import { generateRandomRGBA } from './formatters.utils';

const defaultBarChartConfig = {
  type: 'bar',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          font: {
            size: 14,
            weight: 'italic',
            padding: 10,
          },
        },
      },
    },
  },
};

const defaultLineChartConfig = {
  type: 'line',
  options: {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        position: 'bottom',
        labels: {
          font: {
            size: 14,
            weight: 'italic',
            padding: 10,
          },
        },
      },
    },
  },
};

export const getBarChart = (data: Report): ChartConfiguration => {
  const values = Object.values(data);
  const labels = Object.keys(data);
  const colors = values.map(() => generateRandomRGBA());

  const max = Math.max(...values);
  const step = max / 5;

  return {
    ...defaultBarChartConfig,
    data: {
      labels,
      datasets: [
        {
          data: values,
          backgroundColor: colors,
          borderColor: colors.map((color) => color.replace('0.2', '1')),
          spanGaps: false,
          borderWidth: 1,
        },
      ],
    },
    options: {
      ...defaultBarChartConfig.options,
      scales: {
        x: {
          font: {
            size: 8,
          },
        },
        y: {
          title: {
            display: true,
            text: 'Value',
          },
          min: 0,
          max,
          ticks: {
            stepSize: step,
          },
        },
      },
      plugins: {
        ...defaultBarChartConfig.options.plugins,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: 'x',
            speed: 10,
          },
          pan: {
            enabled: true,
            mode: 'x',
            speed: 1,
          },
        },
      },
    },
  } as unknown as ChartConfiguration;
};

export const getDatesBarChart = (data: ReportDate): ChartConfiguration => {
  const datasets = Object.entries(data).flatMap(([date, report]) =>
    Object.entries(report).map(([name, amount]) => ({
      label: name,
      data: [
        {
          x: date,
          y: amount,
        },
      ],
    })),
  );

  const max = datasets.reduce((acc, data) => (acc += data.data.reduce((sum, item) => (sum += item.y), 0)), 0);
  const step = max / 5;

  const currentDate = new Date();
  const oneYearAgo = addYears(currentDate, -1);
  const oneYearLater = addYears(currentDate, 1);

  return {
    ...defaultBarChartConfig,
    data: {
      datasets,
    },
    options: {
      ...defaultBarChartConfig.options,
      scales: {
        x: {
          stacked: true,
          display: true,
          bounds: 'ticks',
          type: 'time',
          font: {
            size: 8,
          },
          title: {
            display: true,
            text: 'Date',
          },
          time: {
            parser: 'yyyy-MM-dd',
            unit: 'day',
          },
          ticks: {
            display: true,
          },
        },
        y: {
          stacked: true,
          title: {
            display: true,
            text: 'Value',
          },
          min: 0,
          max,
          ticks: {
            stepSize: step,
          },
        },
      },
      plugins: {
        ...defaultBarChartConfig.options.plugins,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: 'x',
            speed: 10,
            rangeMin: {
              x: oneYearAgo.getTime(),
            },
            rangeMax: {
              x: oneYearLater.getTime(),
            },
          },
          pan: {
            enabled: true,
            mode: 'x',
            speed: 1,
            rangeMin: {
              x: oneYearAgo.getTime(),
            },
            rangeMax: {
              x: oneYearLater.getTime(),
            },
          },
        },
      },
    },
  } as unknown as ChartConfiguration;
};

export const getLineChart = (data: Report): ChartConfiguration => {
  const values = Object.values(data);
  const labels = Object.keys(data);

  const max = Math.max(...values);
  const step = max / 5;

  return {
    ...defaultLineChartConfig,
    data: {
      labels,
      datasets: [
        {
          data: values,
        },
      ],
    },
    options: {
      ...defaultLineChartConfig.options,
      scales: {
        x: {
          font: {
            size: 8,
          },
        },
        y: {
          title: {
            display: true,
            text: 'Value',
          },
          min: 0,
          max,
          ticks: {
            stepSize: step,
          },
        },
      },
      plugins: {
        ...defaultLineChartConfig.options.plugins,
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: 'x',
            speed: 10,
          },
          pan: {
            enabled: true,
            mode: 'x',
            speed: 1,
          },
        },
      },
    },
  } as unknown as ChartConfiguration;
};

export const getDatesLineChart = (data: ReportDate): ChartConfiguration => {
  const currentDate = new Date();
  const oneYearAgo = addYears(currentDate, -1);
  const oneYearLater = addYears(currentDate, 1);

  const dates = Object.keys(data).sort();
  const colors = dates.map(() => generateRandomRGBA());

  const sums = dates.reduce((result, date, currentIndex): ChartData[] => {
    const valuesObject = data[date];
    const values = Object.keys(valuesObject);

    let formattedData: ChartData[] = [];

    values.forEach((value) => {
      const dataSet = result?.find((chartData) => value === chartData?.label);

      const backgroundColor = colors[currentIndex];
      const borderColor = backgroundColor.replace('0.2', '1');

      const dataItem = dataSet
        ? {
            ...dataSet,
            data: [
              ...dataSet.data,
              ...Object.entries(valuesObject).map(([key, value]) => (key === dataSet?.label ? value : 0)),
            ],
          }
        : {
            label: value,
            backgroundColor,
            borderColor,
            tension: 0.4,
            data: [...Array(currentIndex).fill(0), valuesObject[value]],
          };

      formattedData = result.some(({ label }) => values.includes(label))
        ? result.map((resultItem) => (resultItem.label === value ? dataItem : resultItem))
        : [...result, dataItem];
    });

    return formattedData;
  }, [] as ChartData[]);

  const datasets = sums.map(({ data, ...rest }) =>
    dates.length <= data.length
      ? { data, ...rest }
      : { ...rest, data: [...data, ...Array(dates.length - data.length).fill(0)] },
  );

  return {
    ...defaultLineChartConfig,
    data: {
      labels: dates,
      datasets,
    },
    options: {
      ...defaultLineChartConfig.options,
      legend: {
        display: true,
      },
      scales: {
        x: {
          display: true,
          bounds: 'ticks',
          type: 'time',
          font: {
            size: 8,
          },
          title: {
            display: true,
            text: 'Date',
          },
          time: {
            parser: 'yyyy-MM-dd',
            unit: 'day',
          },
          ticks: {
            display: true,
          },
        },
        y: {
          title: {
            display: true,
            text: 'Value',
          },
          min: 0,
        },
      },
      plugins: {
        ...defaultLineChartConfig.options.plugins,
        tooltip: {
          enabled: false,
        },
        legend: {
          display: true,
          position: 'bottom',
        },
        zoom: {
          zoom: {
            wheel: {
              enabled: true,
            },
            mode: 'x',
            speed: 10,
            rangeMin: {
              x: oneYearAgo.getTime(),
            },
            rangeMax: {
              x: oneYearLater.getTime(),
            },
          },
          pan: {
            enabled: true,
            mode: 'x',
            speed: 1,
            rangeMin: {
              x: oneYearAgo.getTime(),
            },
            rangeMax: {
              x: oneYearLater.getTime(),
            },
          },
        },
      },
    },
  } as unknown as ChartConfiguration;
};
