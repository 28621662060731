import { useEffect, useState } from 'react';

import { DOCUMENT_TYPES_COLUMNS } from 'constants/document-types.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteDocumentType, asyncGetDocumentTypes } from 'modules/document-types/action';
import { selectDocumentTypes, selectDocumentTypesCount, selectIsLoading } from 'modules/document-types/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { DocumentTypeTableRow } from 'page-components/document-types/DocumentTypeTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const DocumentTypes = () => {
  const [documentTypesId, setDocumentTypesId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const documentTypes = useAppSelector(selectDocumentTypes);
  const documentTypesCount = useAppSelector(selectDocumentTypesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setDocumentTypesId(id);
    handleOpenModal();
  };

  const onDocumentTypeDelete = async () => {
    if (documentTypesId) {
      await dispatch(asyncDeleteDocumentType(documentTypesId));
      await dispatch(asyncGetDocumentTypes({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetDocumentTypes({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='document_types' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='document_types_management'
        columns={DOCUMENT_TYPES_COLUMNS}
        creationPath={AppRoutes.documentTypeCreate}
        isLoading={isLoading}
      >
        {documentTypes?.length ? (
          documentTypes.map((documentType) => (
            <DocumentTypeTableRow
              key={documentType.id}
              data={documentType}
              handleDelete={onModalOpen(documentType.id)}
            />
          ))
        ) : (
          <TableNoData colSpan={DOCUMENT_TYPES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={documentTypesCount ?? 0} onChange={handlePageChange} />
      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onDocumentTypeDelete}
        title='delete_document_type'
      />
    </PageLayout>
  );
};
