import { useEffect, useState } from 'react';

import { CITIES_COLUMNS } from 'constants/cities.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteCity, asyncGetCities } from 'modules/cities/action';
import { selectCities, selectCitiesCount, selectIsLoading } from 'modules/cities/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CitiesTableRow } from 'page-components/cities/CitiesTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Cities = () => {
  const [cityId, setCityId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const cities = useAppSelector(selectCities);
  const citiesCount = useAppSelector(selectCitiesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setCityId(id);
    handleOpenModal();
  };

  const onCityDelete = async () => {
    if (cityId) {
      await dispatch(asyncDeleteCity(cityId));
      await dispatch(asyncGetCities({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetCities({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='cities' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='cities_management'
        columns={CITIES_COLUMNS}
        creationPath={AppRoutes.cityCreate}
        isLoading={isLoading}
      >
        {cities?.length ? (
          cities.map((city) => <CitiesTableRow key={city.id} data={city} handleDelete={onModalOpen(city.id)} />)
        ) : (
          <TableNoData colSpan={CITIES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={citiesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCityDelete}
        title='delete_city'
      />
    </PageLayout>
  );
};
