import { useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import Select, { StylesConfig } from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import { AppRoutes } from 'constants/routes-paths.constants';
import { selectStyles } from 'constants/shared/select.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncDeleteTask, asyncGetTasks, unsetTasks } from 'modules/tasks/action';
import { selectTasks, selectTasksCount, selectIsLoading } from 'modules/tasks/selectors';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { TasksTable } from 'page-components/tasks/tasks-table/TasksTable';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { Pagination } from 'shared-components/Pagination';

export const Tasks = () => {
  const { formatMessage } = useIntl();
  const [taskId, setTaskId] = useState('');

  const dispatch = useAppDispatch();

  const tasks = useAppSelector(selectTasks);
  const tasksCount = useAppSelector(selectTasksCount);
  const isLoading = useAppSelector(selectIsLoading);

  const { page, handlePageChange, perPage, handlePerPageChange } = usePagination();

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const perPageOptions = [
    { value: 5, label: 5 },
    { value: 10, label: 10 },
    { value: 15, label: 15 },
  ];

  const selectStylesTasks: StylesConfig = {
    ...selectStyles,
    input: (styles) => ({
      ...styles,
      padding: '0',
    }),
  };

  const handleOnModalOpen = (id: string) => () => {
    setTaskId(id);
    handleOpenModal();
  };

  const onUserDelete = async () => {
    await dispatch(asyncDeleteTask(+taskId)).then(() => {
      handleCloseModal();
    });
    await dispatch(asyncGetTasks({ page, perPage }));
  };

  useEffect(() => {
    dispatch(asyncGetTasks({ page, perPage }));
  }, [dispatch, page, perPage]);

  useQueryParams({ page, perPage });

  useBeforeRouteChange(() => dispatch(unsetTasks()));

  if (!tasks?.length) return null;

  return (
    <PageLayout title='tasks' wrapperClassName='flex flex-col gap-4'>
      <Card className='p-0 sm:p-5' wrapperClassName='border-0 sm:border'>
        <Tabs>
          <TabList className='flex justify-end items-center space-x-4 cursor-pointer text-mediumBlue font-medium'>
            <Tab data-test='table-view' className='group p-2 rounded outline-none transition-all hover:bg-gray/30'>
              <p>
                <FormattedMessage id='my_tasks' />
              </p>
            </Tab>
            <Tab data-test='list-view' className='group p-2 rounded outline-none transition-all hover:bg-gray/30'>
              <p>
                <FormattedMessage id='my_completed_tasks' />
              </p>
            </Tab>
          </TabList>
          <TabPanel>
            <TasksTable tasks={tasks} handleOpenDeleteModal={handleOnModalOpen} isEditable />
          </TabPanel>
          <TabPanel>
            <TasksTable tasks={tasks} handleOpenDeleteModal={handleOnModalOpen} isEditable={false} />
          </TabPanel>
        </Tabs>
        <Link to={AppRoutes.taskCreate} className='mt-4' data-test='add-task'>
          <FormattedMessage id='add' />
        </Link>
      </Card>
      <div className='flex'>
        <div className='flex flex-1 items-center gap-2'>
          <FormattedMessage id='per_page' />
          <Select
            placeholder={formatMessage({ id: 'search' })}
            className='perPage'
            menuPlacement='top'
            options={perPageOptions}
            defaultValue={perPageOptions[0]}
            value={{ label: perPage, value: perPage }}
            onChange={(value) => handlePerPageChange(value?.value ?? 5)}
            styles={selectStylesTasks as object}
          />
        </div>
        <Pagination
          className='flex-1'
          current={page}
          total={tasksCount ?? 0}
          onChange={handlePageChange}
          pageSize={perPage}
        />
        <div className='flex-1' />
      </div>
      <ConfirmationModal
        isOpened={isModalOpened}
        handleCloseModal={handleCloseModal}
        isLoading={isLoading}
        handleApply={onUserDelete}
        title='delete_task'
      />
    </PageLayout>
  );
};
