import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Customer, CustomersResponse } from 'interfaces/customers.interfaces';
import { withLoadingReducer } from 'utils/reducers.utils';

import {
  asyncCreateCustomer,
  asyncDeleteCustomer,
  asyncGetCustomer,
  asyncGetCustomers,
  asyncUpdateCustomer,
  unsetCustomer,
} from './action';
import { CustomersSliceState } from './slice';

export function getCustomersReducer(builder: ActionReducerMapBuilder<CustomersSliceState>) {
  builder.addCase(asyncGetCustomers.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomers.fulfilled, (state, { payload }: PayloadAction<CustomersResponse>) => {
    state.customers = payload.data;
    state.customersCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomers.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getCustomerReducer(builder: ActionReducerMapBuilder<CustomersSliceState>) {
  builder.addCase(asyncGetCustomer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncGetCustomer.fulfilled, (state, { payload }: PayloadAction<Customer>) => {
    state.customer = payload;
    state.isLoading = false;
  });

  builder.addCase(asyncGetCustomer.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateCustomerReducer = withLoadingReducer(asyncUpdateCustomer);

export const createCustomerReducer = withLoadingReducer(asyncCreateCustomer);

export function deleteCustomerReducer(builder: ActionReducerMapBuilder<CustomersSliceState>) {
  builder.addCase(asyncDeleteCustomer.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(asyncDeleteCustomer.fulfilled, (state) => {
    state.customer = null;
    state.isLoading = false;
  });

  builder.addCase(asyncDeleteCustomer.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetCustomerReducer(builder: ActionReducerMapBuilder<CustomersSliceState>) {
  builder.addCase(unsetCustomer, (state) => {
    state.customer = null;
  });
}
