import { useLocation } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { selectIsLoading } from 'modules/product-groups/selectors';
import { useAppSelector } from 'modules/store';
import { InconsistencyTypeForm } from 'page-components/inconsistency-types/InconsistencyTypeForm';
import { PageLayout } from 'page-components/page-layout/PageLayout';

export const InconsistencyType = () => {
  const { pathname } = useLocation();

  const isLoading = useAppSelector(selectIsLoading);

  const editTitle =
    pathname === AppRoutes.inconsistencyTypeCreate ? 'create_inconsistency_type' : 'edit_inconsistency_type';
  const titleText = pathname.includes('view') ? 'view_inconsistency_type' : editTitle;

  return (
    <PageLayout isLoading={isLoading} title={titleText} wrapperClassName='flex flex-col gap-4'>
      <InconsistencyTypeForm />
    </PageLayout>
  );
};
