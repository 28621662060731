import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { authApi } from 'apis/auth.apis';
import { userApi } from 'apis/user.apis';
import { LocalStorageKeys } from 'constants/local-storage.constants';
import { Locales } from 'i18n/locales';
import { SignInInput } from 'interfaces/auth/sign-in.interfaces';
import { EmailResetPasswordInput, ResetPasswordPayload } from 'interfaces/reset-password.interfaces';
import { Self } from 'interfaces/self.interfaces';
import { resetAccessToken } from 'utils/auth.utils';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const USER_SLICE_NAME = 'user';

export const asyncSignIn = createAsyncThunk(
  `${USER_SLICE_NAME}/signIn`,
  async (payload: SignInInput, { dispatch, rejectWithValue }) => {
    try {
      const response = await authApi.signIn(payload);

      const {
        data: { user, authorization },
      } = response.data;

      await dispatch(setUser(user));
      localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, authorization.accessToken);

      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncLogOut = createAsyncThunk(`${USER_SLICE_NAME}/logOut`, async (_, { rejectWithValue }) => {
  try {
    await authApi.logOut();

    resetAccessToken();

    return null;
  } catch (e) {
    showServerError(e);
    return rejectWithValue(e);
  }
});

export const asyncSendResetPasswordEmail = createAsyncThunk(
  `${USER_SLICE_NAME}/sentResetPasswordEmail`,
  async (data: EmailResetPasswordInput, { rejectWithValue }) => {
    try {
      await authApi.resetPasswordEmail(data);
      toast.success(intl.formatMessage({ id: 'restoration_email_sent' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncSendResetPassword = createAsyncThunk(
  `${USER_SLICE_NAME}/sentResetPassword`,
  async (data: ResetPasswordPayload, { rejectWithValue }) => {
    try {
      await authApi.resetPassword(data);
      toast.success(intl.formatMessage({ id: 'restoration_success' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const getSelf = createAsyncThunk(`${USER_SLICE_NAME}/getSelf`, async () => {
  const response = await userApi.getSelf();
  return response.data.data;
});

export const setUser = createAction<Self>('SET_USER');

export const unsetIsAuthorized = createAction('UNSET_IS_AUTHORIZED');

export const unsetUser = createAction('UNSET_CURRENT_USER');

export const toggleLocale = createAction<Locales>('CHANGE_LANGUAGE');
