import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { InconsistencyInput } from 'interfaces/inconsistencies.interfaces';
import { Card } from 'shared-components/Card';
import { Textarea } from 'shared-components/Textarea';

export const InconsistencyAnalysisFields = () => {
  const { formatMessage } = useIntl();

  const {
    register,
    formState: { errors },
  } = useFormContext<InconsistencyInput>();

  return (
    <Card title='analysis' wrapperClassName='flex-1 overflow-visible' className='flex flex-col gap-6'>
      <Textarea
        {...register('reasons', REQUIRED_VALIDATION)}
        isRequired
        label='reasons'
        error={errors['reasons']?.message}
        placeholder={formatMessage({ id: 'reasons' })}
        data-test='reasons-description'
        rows={4}
      />
      <Textarea
        {...register('consequences', REQUIRED_VALIDATION)}
        isRequired
        label='consequences'
        error={errors['consequences']?.message}
        placeholder={formatMessage({ id: 'consequences' })}
        data-test='consequences-description'
        rows={4}
      />
    </Card>
  );
};
