import { FC } from 'react';

import { Link } from 'react-router-dom';

import { ReactComponent as LogoSvg } from 'assets/svg/logo.svg';
import { AppRoutes } from 'constants/routes-paths.constants';

interface Props {
  onlyIcon?: boolean;
}

export const Logo: FC<Props> = ({ onlyIcon }) => {
  return (
    <Link to={AppRoutes.dashboard} className='flex items-center text-[34px] text-gray font-light'>
      <LogoSvg />
      {!onlyIcon && (
        <p>
          <b className='ml-1 font-normal'>ASSIST</b>ISO
        </p>
      )}
    </Link>
  );
};
