import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { CustomerRequestStatus } from 'interfaces/customer-request-statuses.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: CustomerRequestStatus;
}

export const CustomerRequestStatusesTableRow: FC<Props> = ({ handleDelete, data: { id, name } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.customerRequestStatuses, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='customerRequestStatuses.update'
          deletePermission='customerRequestStatuses.delete'
          viewPermission='customerRequestStatuses.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{name}</TableData>
    </TableRow>
  );
};
