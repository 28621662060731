import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { complaintsApi } from 'apis/complaints.apis';
import { ComplaintPayload, UpdateComplaintPayload } from 'interfaces/complaints.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const COMPLAINTS_SLICE_NAME = 'complaints';

export const asyncGetComplaints = createAsyncThunk(
  `${COMPLAINTS_SLICE_NAME}/getComplaints`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await complaintsApi.getComplaints(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetComplaint = createAsyncThunk(
  `${COMPLAINTS_SLICE_NAME}/getComplaint`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await complaintsApi.getComplaint(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateComplaint = createAsyncThunk(
  `${COMPLAINTS_SLICE_NAME}/updateComplaint`,
  async (payload: UpdateComplaintPayload, { rejectWithValue }) => {
    try {
      await complaintsApi.updateComplaint(payload);
      toast.success(intl.formatMessage({ id: 'complaint_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateComplaint = createAsyncThunk(
  `${COMPLAINTS_SLICE_NAME}/createComplaint`,
  async (payload: ComplaintPayload, { rejectWithValue }) => {
    try {
      await complaintsApi.createComplaint(payload);
      toast.success(intl.formatMessage({ id: 'complaint_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteComplaint = createAsyncThunk(
  `${COMPLAINTS_SLICE_NAME}/deleteComplaint`,
  async (id: number, { rejectWithValue }) => {
    try {
      await complaintsApi.deleteComplaint(id);
      toast.success(intl.formatMessage({ id: 'complaint_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetComplaint = createAction('UNSET_COMPLAINT');
