import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { inconsistencyTypesApi } from 'apis/inconsistency-types.apis';
import { InconsistencyData, InconsistencyPayload } from 'interfaces/inconsistency-types.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const INCONSISTENCY_TYPE_SLICE_NAME = 'inconsistency-types';

export const asyncGetInconsistencyTypes = createAsyncThunk(
  `${INCONSISTENCY_TYPE_SLICE_NAME}/getInconsistencyTypes`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await inconsistencyTypesApi.getInconsistencyTypes(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetInconsistencyType = createAsyncThunk(
  `${INCONSISTENCY_TYPE_SLICE_NAME}/getInconsistencyType`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await inconsistencyTypesApi.getInconsistencyTypeById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateInconsistencyType = createAsyncThunk(
  `${INCONSISTENCY_TYPE_SLICE_NAME}/updateInconsistencyType`,
  async (payload: InconsistencyPayload, { rejectWithValue }) => {
    try {
      await inconsistencyTypesApi.updateInconsistencyType(payload);
      toast.success(intl.formatMessage({ id: 'inconsistency_type_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateInconsistencyType = createAsyncThunk(
  `${INCONSISTENCY_TYPE_SLICE_NAME}/createInconsistencyType`,
  async (payload: InconsistencyData, { rejectWithValue }) => {
    try {
      await inconsistencyTypesApi.createInconsistencyType(payload);
      toast.success(intl.formatMessage({ id: 'inconsistency_type_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteInconsistencyType = createAsyncThunk(
  `${INCONSISTENCY_TYPE_SLICE_NAME}/deleteInconsistencyType`,
  async (id: number, { rejectWithValue }) => {
    try {
      await inconsistencyTypesApi.deleteInconsistencyType(id);
      toast.success(intl.formatMessage({ id: 'inconsistency_type_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetInconsistencyType = createAction('UNSET_INCONSISTENCY_TYPE');
