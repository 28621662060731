import { useEffect } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { DocumentTypeInput } from 'interfaces/document-types.interfaces';
import {
  asyncCreateDocumentType,
  asyncGetDocumentType,
  asyncUpdateDocumentType,
  unsetDocumentType,
} from 'modules/document-types/action';
import { selectDocumentType } from 'modules/document-types/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { Input } from 'shared-components/Input';
import { ItemNavigation } from 'shared-components/ItemNavigation';

export const DocumentTypeForm = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const documentType = useAppSelector(selectDocumentType);

  const { formatMessage } = useIntl();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<DocumentTypeInput>();

  const onSubmit: SubmitHandler<DocumentTypeInput> = (data) => {
    dispatch(id ? asyncUpdateDocumentType({ id: +id, ...data }) : asyncCreateDocumentType(data))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.documentTypes);
      })
      .catch(() => 'error');
  };

  useBeforeRouteChange(() => dispatch(unsetDocumentType()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetDocumentType(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({ name: documentType?.name ?? '' });
    }
  }, [id, reset, documentType]);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card title='general_info' wrapperClassName='w-full md:w-1/2'>
        <div className='flex flex-col gap-4'>
          <Input
            {...register('name', REQUIRED_VALIDATION)}
            error={errors['name']?.message}
            label='title'
            isRequired
            placeholder={formatMessage({ id: 'title' })}
            data-test='document-type-name'
          />
          <ItemNavigation navigateBack={AppRoutes.documentTypes} />
        </div>
      </Card>
    </form>
  );
};
