import { useEffect } from 'react';

import cn from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

import { AuditStatuses } from 'constants/audits.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { AuditFinishInput } from 'interfaces/audits.interfaces';
import { asyncFinishAudit, asyncGetAudit } from 'modules/audits/action';
import { selectAudit } from 'modules/audits/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Textarea } from 'shared-components/Textarea';

export const AuditFinishForm = () => {
  const { id } = useParams();

  const { pathname } = useLocation();
  const isView = pathname.includes('/view/');

  const audit = useAppSelector(selectAudit);

  const { formatMessage } = useIntl();

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<AuditFinishInput>();

  const onSubmit: SubmitHandler<AuditFinishInput> = (data) => {
    if (!id) return;

    dispatch(asyncFinishAudit({ id: +id, ...data }))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.audits);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    if (!isView && audit && audit?.status !== AuditStatuses.proceed) {
      navigate(AppRoutes.audits);
    }
  }, [audit, navigate, isView]);

  useEffect(() => {
    if (id && audit) {
      reset({
        conclusions: audit.conclusions,
        effectiveness: audit.effectiveness,
        recommendations: audit.recommendations,
      });
    }
  }, [id, reset, audit]);

  useEffect(() => {
    if (id) {
      dispatch(asyncGetAudit(id));
    }
  }, [dispatch, id]);

  return (
    <Card>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
        <div className='flex flex-col md:flex-row gap-6'>
          <div className={twMerge(cn('flex-1 flex flex-col gap-8 mt-4 md:self-start', { 'gap-0': isView }))}>
            <Textarea
              {...register('conclusions', REQUIRED_VALIDATION)}
              isRequired
              rows={4}
              error={errors['conclusions']?.message as string}
              label='conclusions'
              placeholder={formatMessage({ id: 'conclusions' })}
              data-test='conclusions'
            />
            <Textarea
              {...register('effectiveness', REQUIRED_VALIDATION)}
              isRequired
              rows={4}
              error={errors['effectiveness']?.message as string}
              label='performance'
              placeholder={formatMessage({ id: 'performance' })}
              data-test='performance'
            />
            <Textarea
              {...register('recommendations', REQUIRED_VALIDATION)}
              isRequired
              rows={4}
              error={errors['recommendations']?.message as string}
              label='recommendations'
              placeholder={formatMessage({ id: 'recommendations' })}
              data-test='recommendations'
            />
          </div>
        </div>
        {!isView && <ItemNavigation navigateBack={AppRoutes.audits} />}
      </form>
    </Card>
  );
};
