import { FC, useEffect, useState } from 'react';

import { Control, Controller } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { CustomerRequestInput } from 'interfaces/customer-requests.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetCustomerRequestStatuses } from 'modules/customer-request-statuses/action';
import { useAppDispatch } from 'modules/store';
import { Select } from 'shared-components/Select';

interface Props {
  control: Control<CustomerRequestInput>;
  defaultStatus?: SelectOption;
  error?: string;
  isView?: boolean;
  id?: string;
}

export const CustomerRequestStatus: FC<Props> = ({ defaultStatus, control, error, isView, id }) => {
  const dispatch = useAppDispatch();

  const { formatMessage } = useIntl();

  const [requestStatusesOptions, setRequestStatusesOptions] = useState<SelectOption[]>([]);

  useEffect(() => {
    dispatch(asyncGetCustomerRequestStatuses({}))
      .unwrap()
      .then(({ data }) => {
        const formattedProducts = data.map(({ id, name }) => ({ value: id, label: name }));
        setRequestStatusesOptions(formattedProducts);
      });
  }, [dispatch, defaultStatus]);

  if (!requestStatusesOptions?.length || (!defaultStatus && id)) return null;

  return (
    <Controller
      control={control}
      name='customerRequestStatusSelect'
      rules={REQUIRED_VALIDATION}
      defaultValue={defaultStatus}
      render={({ field }) => (
        <Select
          {...field}
          isDisabled={isView}
          isRequired
          label='customer_request_status'
          defaultValue={defaultStatus}
          options={requestStatusesOptions}
          className='flex-1 h-full'
          error={error}
          placeholder={formatMessage({ id: 'search_customer_request_status' })}
        />
      )}
    />
  );
};
