import { createSlice } from '@reduxjs/toolkit';

import { RolesData } from 'interfaces/roles.interfaces';

import { ROLES_SLICE_NAME } from './action';
import {
  createRoleReducer,
  deleteRoleReducer,
  getRoleReducer,
  getRolesReducer,
  unsetRoleReducer,
  updateRoleReducer,
} from './reducers';

export interface RolesSliceState {
  isLoading: boolean;
  role: RolesData | null;
  roles: RolesData[] | null;
  rolesCount: number | null;
}

export const initialState: RolesSliceState = {
  role: null,
  roles: null,
  rolesCount: null,
  isLoading: false,
};

const rolesSlice = createSlice({
  name: ROLES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    getRolesReducer(builder);
    getRoleReducer(builder);
    updateRoleReducer(builder);
    createRoleReducer(builder);
    deleteRoleReducer(builder);
    unsetRoleReducer(builder);
  },
});

export const rolesReducer = rolesSlice.reducer;
