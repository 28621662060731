import { FC } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useInconsistencyDefaultOptions } from 'hooks/useInconsistencyDefaultOptions';
import { InconsistencyInput } from 'interfaces/inconsistencies.interfaces';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { Textarea } from 'shared-components/Textarea';

interface Props {
  defaultDepartment?: number;
}

export const InconsistencyGeneralFields: FC<Props> = ({ defaultDepartment }) => {
  const { id } = useParams();

  const { formatMessage } = useIntl();

  const { departmentsOptions, inconsistencyTypeOptions, defaultDepartmentOption, defaultInconsistencyTypeOption } =
    useInconsistencyDefaultOptions(defaultDepartment);

  const {
    register,
    control,
    formState: { errors },
  } = useFormContext<InconsistencyInput>();

  if (id && !defaultDepartmentOption && !defaultInconsistencyTypeOption) return null;

  return (
    <Card title='general_info' wrapperClassName='flex-1' className='flex flex-col gap-6'>
      <Textarea
        {...register('notes', REQUIRED_VALIDATION)}
        isRequired
        label='notes'
        error={errors['notes']?.message}
        placeholder={formatMessage({ id: 'notes' })}
        data-test='notes-description'
        rows={4}
      />
      <Controller
        control={control}
        name='department'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultDepartmentOption}
        render={({ field: { onChange, value } }) => (
          <Select
            isRequired
            label='department'
            error={errors['department']?.message}
            defaultValue={defaultDepartmentOption}
            options={departmentsOptions ?? []}
            value={value}
            onChange={onChange}
            placeholder={formatMessage({ id: 'search_departments' })}
          />
        )}
      />
      <Controller
        control={control}
        name='inconsistencyType'
        rules={REQUIRED_VALIDATION}
        defaultValue={defaultInconsistencyTypeOption}
        render={({ field: { onChange, value } }) => (
          <Select
            isRequired
            label='inconsistency_types'
            error={errors['inconsistencyType']?.message}
            placeholder={formatMessage({ id: 'search_inconsistency' })}
            defaultValue={defaultInconsistencyTypeOption}
            options={inconsistencyTypeOptions ?? []}
            value={value}
            onChange={onChange}
          />
        )}
      />
    </Card>
  );
};
