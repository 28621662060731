import { Field } from 'interfaces/shared/field.interfaces';

import { TableColumn } from './shared/table.constants';
import {
  EMAIL_VALIDATION,
  PASSWORD_VALIDATION,
  PHONE_VALIDATION,
  REQUIRED_VALIDATION,
} from './validation-rules.constants';

export const CUSTOMERS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'name', title: 'name' },
  { id: 'country', title: 'country' },
  { id: 'city', title: 'city' },
  { id: 'email', title: 'email' },
  { id: 'phone', title: 'phone' },
  { id: 'status', title: 'status' },
  { id: 'creationDate', title: 'creation_date', className: 'last:text-left' },
];

export const CUSTOMER_FILTERS: Record<string, { label: string; name: string; placeholder: string }> = {
  status: {
    label: 'status',
    name: 'statusSelect',
    placeholder: 'search_status',
  },
  country: {
    label: 'country',
    name: 'countrySelect',
    placeholder: 'search_country',
  },
  city: {
    label: 'city',
    name: 'citySelect',
    placeholder: 'search_city',
  },
};

export const AUTH_FIELDS: Field[] = [
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'login-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'password',
    options: PASSWORD_VALIDATION,
    'data-test': 'login-password',
    label: 'password',
    placeholder: 'password',
    type: 'password',
  },
];

export const CUSTOMER_GENERAL_FIELDS: Field[] = [
  {
    name: 'name',
    options: REQUIRED_VALIDATION,
    'data-test': 'customer-name',
    label: 'name',
    placeholder: 'name',
  },
  {
    name: 'company',
    options: REQUIRED_VALIDATION,
    'data-test': 'customer-company',
    label: 'company',
    placeholder: 'company',
  },
  {
    name: 'language',
    options: REQUIRED_VALIDATION,
    'data-test': 'customer-language',
    label: 'language',
    placeholder: 'language',
  },
];

export const CUSTOMER_CONTACTS_FIELDS: Field[] = [
  {
    name: 'contactPerson',
    options: REQUIRED_VALIDATION,
    'data-test': 'customer-contact',
    label: 'contact',
    placeholder: 'contact',
  },
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'customer-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'phone',
    options: PHONE_VALIDATION,
    'data-test': 'customer-phone',
    label: 'phone',
    placeholder: 'phone',
  },
  {
    name: 'skype',
    options: {},
    'data-test': 'customer-skype',
    label: 'skype',
    placeholder: 'skype',
  },
];
