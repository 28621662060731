import { FC } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Notification } from 'interfaces/notifications.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Notification;
}

export const NotificationsTableRow: FC<Props> = ({ handleDelete, data: { id, departments, name, description } }) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.notifications, id);

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          editPermission='notifications.update'
          viewRoute={viewRoute}
          deletePermission='notifications.delete'
          viewPermission='notifications.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData>{name}</TableData>
      <TableData>{description}</TableData>
      <TableData className='last:text-left'>{departments.map(({ name }) => name).join(', ')}</TableData>
    </TableRow>
  );
};
