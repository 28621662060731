import { Audit } from 'pages/Audit';
import { AuditCarrying } from 'pages/AuditCarrying';
import { AuditFinish } from 'pages/AuditFinish';
import { Audits } from 'pages/Audits';
import { Cities } from 'pages/Cities';
import { City } from 'pages/City';
import { Complaint } from 'pages/Complaint';
import { Complaints } from 'pages/Complaints';
import { Complect } from 'pages/Complect';
import { Complects } from 'pages/Complects';
import { Correction } from 'pages/Correction';
import { Countries } from 'pages/Countries';
import { Country } from 'pages/Country';
import { Customer } from 'pages/Customer';
import { CustomerReports } from 'pages/CustomerReports';
import { CustomerRequest } from 'pages/CustomerRequest';
import { CustomerRequests } from 'pages/CustomerRequests';
import { CustomerRequestSource } from 'pages/CustomerRequestSource';
import { CustomerRequestSources } from 'pages/CustomerRequestSources';
import { CustomerRequestsReports } from 'pages/CustomerRequestsReports';
import { CustomerRequestStatus } from 'pages/CustomerRequestStatus';
import { CustomerRequestStatuses } from 'pages/CustomerRequestStatuses';
import { Customers } from 'pages/Customers';
import { CustomerStatus } from 'pages/CustomerStatus';
import { CustomerStatuses } from 'pages/CustomerStatuses';
import { Dashboard } from 'pages/Dashboard';
import { Department } from 'pages/Department';
import { Departments } from 'pages/Departments';
import { Document } from 'pages/Document';
import { Documents } from 'pages/Documents';
import { DocumentType } from 'pages/DocumentType';
import { DocumentTypes } from 'pages/DocumentTypes';
import { Education } from 'pages/Education';
import { Educations } from 'pages/Educations';
import { Inconsistencies } from 'pages/Inconsistencies';
import { InconsistencyReports } from 'pages/InconsistenciesReports';
import { Inconsistency } from 'pages/Inconsistency';
import { InconsistencyType } from 'pages/InconsistencyType';
import { InconsistencyTypes } from 'pages/InconsistencyTypes';
import { ManagerAction } from 'pages/ManagerAction';
import { Notification } from 'pages/Notification';
import { Notifications } from 'pages/Notifications';
import { NotificationView } from 'pages/NotificationView';
import { ProductGroup } from 'pages/ProductGroup';
import { ProductGroups } from 'pages/ProductGroups';
import { Profile } from 'pages/Profile';
import { Provider } from 'pages/Provider';
import { Providers } from 'pages/Providers';
import { ResetPassword } from 'pages/ResetPassword';
import { Risk } from 'pages/Risk';
import { RiskProceed } from 'pages/RiskProceed';
import { Risks } from 'pages/Risks';
import { Role } from 'pages/Role';
import { Roles } from 'pages/Roles';
import { SignIn } from 'pages/SignIn';
import { Task } from 'pages/Task';
import { Tasks } from 'pages/Tasks';
import { User } from 'pages/User';
import { Users } from 'pages/Users';

import { AppRoutes } from './routes-paths.constants';

export const UNPROTECTED_ROUTES = [
  {
    path: AppRoutes.signIn,
    element: SignIn,
  },
  {
    path: AppRoutes.resetPassword,
    element: ResetPassword,
  },
  {
    path: AppRoutes.resetPasswordToken,
    element: ResetPassword,
  },
];

export const PROTECTED_ROUTES = [
  {
    path: AppRoutes.dashboard,
    element: Dashboard,
  },
  {
    path: AppRoutes.profile,
    element: Profile,
  },
  {
    path: AppRoutes.users,
    element: Users,
    permissions: ['users.viewAny'],
  },
  {
    path: AppRoutes.user,
    element: User,
    permissions: ['users.update'],
  },
  {
    path: AppRoutes.userCreate,
    element: User,
    permissions: ['users.create'],
  },
  {
    path: AppRoutes.userView,
    element: User,
    permissions: ['users.view'],
  },
  {
    path: AppRoutes.roles,
    element: Roles,
    permissions: ['roles.viewAny'],
  },
  {
    path: AppRoutes.role,
    element: Role,
    permissions: ['roles.update'],
  },
  {
    path: AppRoutes.roleCreate,
    element: Role,
    permissions: ['roles.create'],
  },
  {
    path: AppRoutes.roleView,
    element: Role,
    permissions: ['roles.view'],
  },
  {
    path: AppRoutes.countries,
    element: Countries,
    permissions: ['countries.viewAny'],
  },
  {
    path: AppRoutes.country,
    element: Country,
    permissions: ['countries.update'],
  },
  {
    path: AppRoutes.countryCreate,
    element: Country,
    permissions: ['countries.create'],
  },
  {
    path: AppRoutes.countryView,
    element: Country,
    permissions: ['countries.view'],
  },
  {
    path: AppRoutes.cities,
    element: Cities,
    permissions: ['cities.viewAny'],
  },
  {
    path: AppRoutes.city,
    element: City,
    permissions: ['cities.update'],
  },
  {
    path: AppRoutes.cityCreate,
    element: City,
    permissions: ['cities.create'],
  },
  {
    path: AppRoutes.cityView,
    element: City,
    permissions: ['cities.view'],
  },
  {
    path: AppRoutes.departments,
    element: Departments,
    permissions: ['departments.viewAny'],
  },
  {
    path: AppRoutes.department,
    element: Department,
    permissions: ['departments.update'],
  },
  {
    path: AppRoutes.departmentCreate,
    element: Department,
    permissions: ['departments.create'],
  },
  {
    path: AppRoutes.departmentView,
    element: Department,
    permissions: ['departments.view'],
  },
  {
    path: AppRoutes.tasks,
    element: Tasks,
  },
  {
    path: AppRoutes.task,
    element: Task,
  },
  {
    path: AppRoutes.taskCreate,
    element: Task,
  },
  {
    path: AppRoutes.providers,
    element: Providers,
    permissions: ['providers.view'],
  },
  {
    path: AppRoutes.provider,
    element: Provider,
    permissions: ['providers.update'],
  },
  {
    path: AppRoutes.providerCreate,
    element: Provider,
    permissions: ['providers.create'],
  },
  {
    path: AppRoutes.providerView,
    element: Provider,
    permissions: ['providers.viewAny'],
  },
  {
    path: AppRoutes.customerRequests,
    element: CustomerRequests,
    permissions: ['customerRequests.viewAny'],
  },
  {
    path: AppRoutes.customerRequest,
    element: CustomerRequest,
    permissions: ['customerRequests.update'],
  },
  {
    path: AppRoutes.customerRequestCreate,
    element: CustomerRequest,
    permissions: ['customerRequests.create'],
  },
  {
    path: AppRoutes.customerRequestView,
    element: CustomerRequest,
    permissions: ['customerRequests.view'],
  },
  {
    path: AppRoutes.customerRequestSources,
    element: CustomerRequestSources,
    permissions: ['customerRequestSources.viewAny'],
  },
  {
    path: AppRoutes.customerRequestSource,
    element: CustomerRequestSource,
    permissions: ['customerRequestSources.update'],
  },
  {
    path: AppRoutes.customerRequestSourceCreate,
    element: CustomerRequestSource,
    permissions: ['customerRequestSources.create'],
  },
  {
    path: AppRoutes.customerRequestSourceView,
    element: CustomerRequestSource,
    permissions: ['customerRequestSources.view'],
  },
  {
    path: AppRoutes.customerRequestStatuses,
    element: CustomerRequestStatuses,
    permissions: ['customerRequestStatuses.viewAny'],
  },
  {
    path: AppRoutes.customerRequestStatus,
    element: CustomerRequestStatus,
    permissions: ['customerRequestStatuses.update'],
  },
  {
    path: AppRoutes.customerRequestStatusCreate,
    element: CustomerRequestStatus,
    permissions: ['customerRequestStatuses.create'],
  },
  {
    path: AppRoutes.customerRequestStatusView,
    element: CustomerRequestStatus,
    permissions: ['customerRequestStatuses.view'],
  },
  {
    path: AppRoutes.customerStatuses,
    element: CustomerStatuses,
    permissions: ['customerStatuses.viewAny'],
  },
  {
    path: AppRoutes.customerStatus,
    element: CustomerStatus,
    permissions: ['customerStatuses.update'],
  },
  {
    path: AppRoutes.customerStatusCreate,
    element: CustomerStatus,
    permissions: ['customerStatuses.create'],
  },
  {
    path: AppRoutes.customerStatusView,
    element: CustomerStatus,
    permissions: ['customerStatuses.view'],
  },
  {
    path: AppRoutes.customers,
    element: Customers,
    permissions: ['customers.viewAny'],
  },
  {
    path: AppRoutes.customer,
    element: Customer,
    permissions: ['customers.update'],
  },
  {
    path: AppRoutes.customerCreate,
    element: Customer,
    permissions: ['customers.create'],
  },
  {
    path: AppRoutes.customerView,
    element: Customer,
    permissions: ['customers.view'],
  },
  {
    path: AppRoutes.productGroups,
    element: ProductGroups,
    permissions: ['productGroups.viewAny'],
  },
  {
    path: AppRoutes.productGroup,
    element: ProductGroup,
    permissions: ['productGroups.update'],
  },
  {
    path: AppRoutes.productGroupCreate,
    element: ProductGroup,
    permissions: ['productGroups.create'],
  },
  {
    path: AppRoutes.productGroupView,
    element: ProductGroup,
    permissions: ['productGroups.view'],
  },
  {
    path: AppRoutes.complects,
    element: Complects,
    permissions: ['complects.viewAny'],
  },
  {
    path: AppRoutes.complect,
    element: Complect,
    permissions: ['complects.update'],
  },
  {
    path: AppRoutes.complectCreate,
    element: Complect,
    permissions: ['complects.create'],
  },
  {
    path: AppRoutes.complectView,
    element: Complect,
    permissions: ['complects.view'],
  },
  {
    path: AppRoutes.inconsistencyTypes,
    element: InconsistencyTypes,
    permissions: ['inconsistencyTypes.viewAny'],
  },
  {
    path: AppRoutes.inconsistencyType,
    element: InconsistencyType,
    permissions: ['inconsistencyTypes.update'],
  },
  {
    path: AppRoutes.inconsistencyTypeCreate,
    element: InconsistencyType,
    permissions: ['inconsistencyTypes.create'],
  },
  {
    path: AppRoutes.inconsistencyTypeView,
    element: InconsistencyType,
    permissions: ['inconsistencyTypes.view'],
  },
  {
    path: AppRoutes.inconsistencies,
    element: Inconsistencies,
    permissions: ['inconsistencies.viewAny'],
  },
  {
    path: AppRoutes.inconsistency,
    element: Inconsistency,
    permissions: ['inconsistencies.update'],
  },
  {
    path: AppRoutes.inconsistencyCreate,
    element: Inconsistency,
    permissions: ['inconsistencies.create'],
  },
  {
    path: AppRoutes.inconsistencyView,
    element: Inconsistency,
    permissions: ['inconsistencies.view'],
  },
  {
    path: AppRoutes.complaints,
    element: Complaints,
    permissions: ['complaints.viewAny'],
  },
  {
    path: AppRoutes.complaint,
    element: Complaint,
    permissions: ['complaints.update'],
  },
  {
    path: AppRoutes.complaintCreate,
    element: Complaint,
    permissions: ['complaints.create'],
  },
  {
    path: AppRoutes.complaintView,
    element: Complaint,
    permissions: ['complaints.view'],
  },
  {
    path: AppRoutes.educations,
    element: Educations,
    permissions: ['complaints.viewAny'],
  },
  {
    path: AppRoutes.education,
    element: Education,
    permissions: ['complaints.update'],
  },
  {
    path: AppRoutes.educationCreate,
    element: Education,
    permissions: ['complaints.create'],
  },
  {
    path: AppRoutes.educationView,
    element: Education,
    permissions: ['complaints.view'],
  },
  {
    path: AppRoutes.managerAction,
    element: ManagerAction,
    permissions: ['managerActions.update'],
  },
  {
    path: AppRoutes.managerActionCreate,
    element: ManagerAction,
    permissions: ['managerActions.create'],
  },
  {
    path: AppRoutes.managerActionView,
    element: ManagerAction,
    permissions: ['managerActions.view'],
  },
  {
    path: AppRoutes.correction,
    element: Correction,
    permissions: ['corrections.update'],
  },
  {
    path: AppRoutes.correctionCreate,
    element: Correction,
    permissions: ['corrections.create'],
  },
  {
    path: AppRoutes.correctionView,
    element: Correction,
    permissions: ['corrections.view'],
  },
  {
    path: AppRoutes.documentTypes,
    element: DocumentTypes,
    permissions: ['documentTypes.viewAny'],
  },
  {
    path: AppRoutes.documentType,
    element: DocumentType,
    permissions: ['documentTypes.update'],
  },
  {
    path: AppRoutes.documentTypeCreate,
    element: DocumentType,
    permissions: ['documentTypes.create'],
  },
  {
    path: AppRoutes.documentTypeView,
    element: DocumentType,
    permissions: ['documentTypes.view'],
  },
  {
    path: AppRoutes.documents,
    element: Documents,
    permissions: ['documents.viewAny'],
  },
  {
    path: AppRoutes.document,
    element: Document,
    permissions: ['documents.update'],
  },
  {
    path: AppRoutes.documentsCreate,
    element: Document,
    permissions: ['documents.create'],
  },
  {
    path: AppRoutes.documentsView,
    element: Document,
    permissions: ['documents.view'],
  },
  {
    path: AppRoutes.notifications,
    element: Notifications,
    permissions: ['notifications.viewAny'],
  },
  {
    path: AppRoutes.notification,
    element: Notification,
    permissions: ['notifications.update'],
  },
  {
    path: AppRoutes.notificationsCreate,
    element: Notification,
    permissions: ['notifications.create'],
  },
  {
    path: AppRoutes.notificationView,
    element: NotificationView,
    permissions: ['notifications.view'],
  },
  {
    path: AppRoutes.audits,
    element: Audits,
    permissions: ['audits.viewAny'],
  },
  {
    path: AppRoutes.audit,
    element: Audit,
    permissions: ['audits.update'],
  },
  {
    path: AppRoutes.auditCreate,
    element: Audit,
    permissions: ['audits.create'],
  },
  {
    path: AppRoutes.auditView,
    element: Audit,
    permissions: ['audits.view'],
  },
  {
    path: AppRoutes.auditProceed,
    element: AuditCarrying,
    permissions: ['audits.proceed'],
  },
  {
    path: AppRoutes.auditFinish,
    element: AuditFinish,
    permissions: ['audits.complete'],
  },
  {
    path: AppRoutes.risks,
    element: Risks,
    permissions: ['riskAssessments.viewAny'],
  },
  {
    path: AppRoutes.risk,
    element: Risk,
    permissions: ['riskAssessments.update'],
  },
  {
    path: AppRoutes.riskCreate,
    element: Risk,
    permissions: ['riskAssessments.create'],
  },
  {
    path: AppRoutes.riskView,
    element: Risk,
    permissions: ['riskAssessments.view'],
  },
  {
    path: AppRoutes.riskProceed,
    element: RiskProceed,
    permissions: ['riskAssessments.proceed'],
  },
  {
    path: AppRoutes.reportsCustomers,
    element: CustomerReports,
  },
  {
    path: AppRoutes.reportsCustomerRequests,
    element: CustomerRequestsReports,
  },
  {
    path: AppRoutes.reportsInconsistencies,
    element: InconsistencyReports,
  },
];
