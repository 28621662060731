import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.providersReducer;

export const selectProvider = createSelector(selectState, ({ provider }) => provider);

export const selectProviders = createSelector(selectState, ({ providers }) => providers);

export const selectProvidersCount = createSelector(selectState, ({ providersCount }) => providersCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
