import { createSlice } from '@reduxjs/toolkit';

import { Audit } from 'interfaces/audits.interfaces';

import { AUDIT_SLICE_NAME } from './action';
import {
  createAuditReducer,
  deleteAuditReducer,
  finishAuditReducer,
  getAuditReducer,
  getAuditsReducer,
  proceedAuditReducer,
  unsetAuditReducer,
  updateAuditReducer,
} from './reducers';

export interface AuditSliceState {
  isLoading: boolean;
  audit: Audit | null;
  audits: Audit[] | null;
  auditsCount: number | null;
}

export const initialState: AuditSliceState = {
  audit: null,
  audits: null,
  auditsCount: null,
  isLoading: false,
};

const auditsSlice = createSlice({
  name: AUDIT_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createAuditReducer(builder);
    deleteAuditReducer(builder);
    updateAuditReducer(builder);
    getAuditsReducer(builder);
    getAuditReducer(builder);
    unsetAuditReducer(builder);
    proceedAuditReducer(builder);
    finishAuditReducer(builder);
  },
});

export const auditReducer = auditsSlice.reducer;
