import { TableColumn } from './shared/table.constants';

export const AUDITS_COLUMNS: TableColumn[] = [
  { id: 'actions', title: '', className: 'w-1/12' },
  { id: 'id', title: 'id' },
  { id: 'plannedAt', title: 'planned_date' },
  { id: 'description', title: 'description' },
  { id: 'departments', title: 'departments', className: 'text-center' },
  { id: 'users', title: 'users', className: 'text-center' },
  { id: 'responsible', title: 'responsible', className: 'last:text-left' },
];

export enum AuditStatuses {
  created = 'created',
  proceed = 'proceed',
}
