import { AxiosRequestConfig } from 'axios';

import { httpApiClient } from 'configs/http-client.configs';
import { ReportPayload, ReportDatesResponse, ReportsResponse } from 'interfaces/reports.interfaces';

export class ReportsApi {
  constructor(private url: string) {}

  getCustomerReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportsResponse>(`${this.url}/customers/${reportType}`, body, config);
  }

  getCustomersDynamicReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportDatesResponse>(`${this.url}/customers-dynamic/${reportType}`, body, config);
  }

  getCustomerRequestsReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportsResponse>(`${this.url}/customer-requests/${reportType}`, body, config);
  }

  getCustomerRequestsDynamicReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportsResponse>(`${this.url}/customer-requests-dynamic/${reportType}`, body, config);
  }

  getInconsistenciesReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportsResponse>(`${this.url}/inconsistencies/${reportType}`, body, config);
  }

  getInconsistenciesDynamicReports(reportType: string, body: ReportPayload, config?: AxiosRequestConfig) {
    return httpApiClient.post<ReportsResponse>(`${this.url}/inconsistencies-dynamic/${reportType}`, body, config);
  }
}

export const reportsApi = new ReportsApi('/reports');
