import { FC, ReactNode } from 'react';

import { twMerge } from 'tailwind-merge';

interface Props {
  children?: ReactNode;
  className?: string;
}

export const ErrorMessage: FC<Props> = ({ children, className }) => {
  if (!children) return null;

  return (
    <p data-test='error-text' className={twMerge('text-red', className)}>
      {children}
    </p>
  );
};
