import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { departmentsApi } from 'apis/departments.apis';
import { Department, DepartmentInput } from 'interfaces/departments.interfaces';
import { QueryParams } from 'interfaces/query.interfaces';
import { showServerError } from 'utils/modules.utils';
import { intl } from 'utils/translation.utils';

export const DEPARTMENTS_SLICE_NAME = 'departments';

export const asyncGetDepartments = createAsyncThunk(
  `${DEPARTMENTS_SLICE_NAME}/getDepartments`,
  async (query: QueryParams, { rejectWithValue }) => {
    try {
      const response = await departmentsApi.getDepartments(query);
      return response.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncGetDepartment = createAsyncThunk(
  `${DEPARTMENTS_SLICE_NAME}/getDepartment`,
  async (payload: string, { rejectWithValue }) => {
    try {
      const response = await departmentsApi.getDepartmentById(payload);
      return response.data.data;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncUpdateDepartment = createAsyncThunk(
  `${DEPARTMENTS_SLICE_NAME}/updateDepartment`,
  async (payload: Department, { rejectWithValue }) => {
    try {
      await departmentsApi.updateDepartment(payload);
      toast.success(intl.formatMessage({ id: 'department_updated' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncCreateDepartment = createAsyncThunk(
  `${DEPARTMENTS_SLICE_NAME}/createDepartment`,
  async (payload: DepartmentInput, { rejectWithValue }) => {
    try {
      await departmentsApi.createDepartment(payload);
      toast.success(intl.formatMessage({ id: 'department_created' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const asyncDeleteDepartment = createAsyncThunk(
  `${DEPARTMENTS_SLICE_NAME}/deleteDepartment`,
  async (id: number, { rejectWithValue }) => {
    try {
      await departmentsApi.deleteDepartment(id);
      toast.success(intl.formatMessage({ id: 'department_deleted' }));
      return null;
    } catch (e) {
      showServerError(e);
      return rejectWithValue(e);
    }
  },
);

export const unsetDepartment = createAction('UNSET_DEPARTMENT');
