import { useEffect } from 'react';

import { Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppRoutes } from 'constants/routes-paths.constants';
import { PROTECTED_ROUTES, UNPROTECTED_ROUTES } from 'constants/routes.contants';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { getSelf, unsetIsAuthorized } from 'modules/user/action';
import { selectCurrentUser, selectIsAuth } from 'modules/user/selectors';
import { PagesCommonLayout } from 'page-components/page-layout/PagesCommonLayout';
import { PermissionRoute } from 'shared-components/PermissionRoute';
import { ProtectedRoute } from 'shared-components/ProtectedRoute';
import { getAccessToken } from 'utils/auth.utils';
import { hasPermission } from 'utils/users.utils';

import 'react-toastify/dist/ReactToastify.css';

export const PagesWrapper = () => {
  const dispatch = useAppDispatch();

  const isAuth = useAppSelector(selectIsAuth);
  const user = useAppSelector(selectCurrentUser);

  useEffect(() => {
    const accessToken = getAccessToken();

    if (isAuth === null) {
      dispatch(accessToken ? getSelf() : unsetIsAuthorized());
    }
  }, [dispatch, isAuth]);

  if (isAuth === null) return null;

  return (
    <>
      <Routes>
        <Route element={<ProtectedRoute isAuth={!isAuth} to={AppRoutes.dashboard} />}>
          {UNPROTECTED_ROUTES.map(({ path, element: Element }) => (
            <Route key={path} path={path} element={<Element />} />
          ))}
        </Route>
        <Route element={<PagesCommonLayout />}>
          <Route element={<ProtectedRoute isAuth={isAuth} to={AppRoutes.signIn} />}>
            {PROTECTED_ROUTES.map(({ path, element: Element, permissions }) => (
              <Route
                key={path}
                element={
                  <PermissionRoute
                    hasPermission={permissions?.length ? hasPermission(user?.permissions, permissions) : true}
                    to={AppRoutes.dashboard}
                  />
                }
              >
                <Route path={path} element={<Element />} />
              </Route>
            ))}
          </Route>
        </Route>
      </Routes>
      <ToastContainer theme='colored' />
    </>
  );
};
