import { useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';

import {
  PROVIDER_CONTACTS_FIELDS,
  PROVIDER_GENERAL_FIELDS,
  PROVIDER_MARKS_FIELDS,
} from 'constants/providers.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { ProviderInput, ProviderInputPayload } from 'interfaces/providers.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetComplects } from 'modules/complects/action';
import { asyncCreateProvider, asyncGetProvider, asyncUpdateProvider, unsetProvider } from 'modules/providers/action';
import { selectProvider } from 'modules/providers/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Card } from 'shared-components/Card';
import { FormFields } from 'shared-components/FormFields';
import { ItemNavigation } from 'shared-components/ItemNavigation';
import { Select } from 'shared-components/Select';
import { Textarea } from 'shared-components/Textarea';

import { ProviderRateRange } from './ProviderRateRange';

export const ProviderForm = () => {
  const [componentsOptions, setComponentsOptions] = useState<SelectOption[]>();
  const [defaultOptions, setDefaultOptions] = useState<SelectOption[]>();

  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const provider = useAppSelector(selectProvider);

  const { formatMessage } = useIntl();

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ProviderInput>({
    defaultValues: {
      costRate: [0],
      conditionRate: [0],
      qualityRate: [0],
      termRate: [0],
    },
  });

  const onSubmit: SubmitHandler<ProviderInput> = ({
    componentsSelect,
    qualityRate,
    termRate,
    conditionRate,
    costRate,
    ...data
  }) => {
    const complects = componentsSelect?.map(({ value }) => +value);
    const formattedData: ProviderInputPayload = {
      complects,
      qualityRate: qualityRate[0],
      termRate: termRate[0],
      conditionRate: conditionRate[0],
      costRate: costRate[0],
      ...data,
    };

    dispatch(id ? asyncUpdateProvider({ id, ...formattedData }) : asyncCreateProvider(formattedData))
      .unwrap()
      .then(() => {
        navigate(AppRoutes.providers);
      })
      .catch(() => 'error');
  };

  useEffect(() => {
    dispatch(asyncGetComplects({}))
      .unwrap()
      .then(({ data }) => {
        setComponentsOptions(data.map(({ id, name }) => ({ value: id, label: name })));
      });
  }, [dispatch, provider]);

  useEffect(() => {
    if (id) {
      dispatch(asyncGetProvider(id))
        .unwrap()
        .then((response) => {
          setDefaultOptions(response.complects.map((component) => ({ value: component.id, label: component.name })));
        });
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (id) {
      reset({
        name: provider?.name ?? '',
        taxNumber: provider?.taxNumber,
        phone: provider?.phone,
        email: provider?.email,
        address1: provider?.address1,
        address2: provider?.address2,
        contactPerson: provider?.contactPerson,
        costRate: [provider?.costRate],
        termRate: [provider?.termRate],
        conditionRate: [provider?.conditionRate],
        qualityRate: [provider?.qualityRate],
      });
    }
  }, [id, reset, provider]);

  useBeforeRouteChange(() => dispatch(unsetProvider()));

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <div className='flex gap-6'>
        <div className='flex flex-col gap-6 flex-1'>
          <Card title='general_info' className='flex flex-col gap-4'>
            <FormFields errors={errors} register={register} fields={PROVIDER_GENERAL_FIELDS} />
          </Card>
          <Card title='contact_info' className='flex flex-col gap-4'>
            <FormFields errors={errors} register={register} fields={PROVIDER_CONTACTS_FIELDS} />
          </Card>
          <Card title='components'>
            {componentsOptions?.length && (
              <Controller
                control={control}
                name='componentsSelect'
                defaultValue={defaultOptions}
                render={({ field }) => (
                  <Select
                    {...field}
                    error={errors['componentsSelect']?.message}
                    defaultValue={defaultOptions}
                    isMulti
                    options={componentsOptions}
                    className='flex-1 h-full'
                    placeholder={formatMessage({ id: 'search_components' })}
                  />
                )}
              />
            )}
          </Card>
        </div>
        <div className='flex flex-col gap-6 flex-1'>
          <Card title='marks' className='flex flex-col gap-8' wrapperClassName='px-4 pb-4'>
            {PROVIDER_MARKS_FIELDS.map(({ name, max, min, label }) => (
              <ProviderRateRange
                control={control}
                key={name}
                name={name}
                max={max as number}
                min={min as number}
                label={label}
              />
            ))}
          </Card>
          <Card title='addresses' className='flex flex-col gap-6'>
            <Textarea
              {...register('address1')}
              label='address'
              placeholder={formatMessage({ id: 'address' })}
              rows={4}
            />
            <Textarea
              {...register('address2')}
              label='legal_address'
              placeholder={formatMessage({ id: 'legal_address' })}
              rows={4}
            />
          </Card>
        </div>
      </div>
      <Card>
        <ItemNavigation navigateBack={AppRoutes.providers} />
      </Card>
    </form>
  );
};
