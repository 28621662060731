import { RISKS_CONSEQUENCES, RISKS_CONSEQUENCES_COLUMNS } from 'constants/risks.constsnts';
import { Table } from 'shared-components/table/Table';

import { RiskConsequencesTableRow } from './RiskConsequencesTableRow';

export const RiskConsequencesTable = () => {
  return (
    <Table columns={RISKS_CONSEQUENCES_COLUMNS}>
      {RISKS_CONSEQUENCES.map((risk) => (
        <RiskConsequencesTableRow key={risk.mark} data={risk} />
      ))}
    </Table>
  );
};
