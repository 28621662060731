import { DetailedHTMLProps, FC, InputHTMLAttributes } from 'react';

interface Props extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  checked: boolean;
  ariaLabel?: string;
}

export const Toggle: FC<Props> = ({ checked, ariaLabel, ...rest }) => {
  return (
    <label className='inline-flex items-center cursor-pointer'>
      <input type='checkbox' className='sr-only peer' checked={checked} aria-label={ariaLabel} {...rest} />

      <div className="relative w-11 h-6 bg-gray2 rounded-full peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[5px] after:bg-white after:border-gray2 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-lightBlue" />
    </label>
  );
};
