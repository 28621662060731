import { Field } from 'interfaces/shared/field.interfaces';

import { EMAIL_VALIDATION, PASSWORD_VALIDATION } from './validation-rules.constants';

export const AUTH_FIELDS: Field[] = [
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'login-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'password',
    options: PASSWORD_VALIDATION,
    'data-test': 'login-password',
    label: 'password',
    placeholder: 'password',
    type: 'password',
  },
];

export const RESET_PASSWORD_FIELDS: Field[] = [
  {
    name: 'email',
    options: EMAIL_VALIDATION,
    'data-test': 'reset-email',
    label: 'email',
    placeholder: 'email',
  },
  {
    name: 'password',
    options: PASSWORD_VALIDATION,
    'data-test': 'reset-password',
    label: 'password',
    placeholder: 'password',
    type: 'password',
  },
  {
    name: 'password_confirmation',
    options: PASSWORD_VALIDATION,
    'data-test': 'reset-repeat-password',
    label: 'confirm_new_password',
    placeholder: 'confirm_new_password',
    type: 'password',
  },
];
