import { ReactComponent as ProceedSvg } from 'assets/svg/forward.svg';
import { ReactComponent as ViewSvg } from 'assets/svg/view.svg';
import { RiskStatuses } from 'constants/risks.constsnts';
import { AppRoutes } from 'constants/routes-paths.constants';
import { LinkVariants } from 'interfaces/shared/link.interfaces';
import { Link } from 'shared-components/Link';
import { getProceedRoute } from 'utils/routes.utils';

interface Props {
  riskId: number;
  status: string;
}

export const RiskTableRowAction = ({ status, riskId }: Props) => {
  const proceedRoute = getProceedRoute(AppRoutes.risks, riskId);

  return (
    <>
      {status !== RiskStatuses.completed && (
        <Link
          variant={LinkVariants.TRANSPARENT}
          className='px-2 flex items-center group'
          to={proceedRoute}
          data-test='proceed-item'
        >
          <ProceedSvg className='w-4 h-4 transition-all fill-mediumBlue group-hover:fill-extraLightBlue/50' />
        </Link>
      )}
      {status !== RiskStatuses.created && (
        <Link
          variant={LinkVariants.TRANSPARENT}
          className='px-2 flex items-center group'
          to={`${proceedRoute}&isView=1`}
          data-test='proceed-view-item'
        >
          <ViewSvg className='transition-all stroke-mediumBlue group-hover:stroke-extraLightBlue/50' />
        </Link>
      )}
    </>
  );
};
