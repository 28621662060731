import { FC, SVGProps, useState } from 'react';

import cn from 'classnames';
import { Outlet } from 'react-router-dom';

import { NAVIGATION_LIST } from 'constants/navigation-list.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useLocalStorage } from 'hooks/useLocalStorage';
import { useOpen } from 'hooks/useOpen';
import { useAppSelector } from 'modules/store';
import { selectCurrentUser } from 'modules/user/selectors';
import { Header } from 'page-components/page-layout/Header';
import { Navbar } from 'page-components/page-layout/Navbar';
import { Sidebar } from 'page-components/page-layout/Sidebar';
import { Overlay } from 'shared-components/Overlay';

export interface Navigation {
  icon: FC<SVGProps<SVGSVGElement>>;
  title: string;
  path: AppRoutes;
  permissions?: string[];
}

export interface RoutesItem {
  id: string;
  icon: FC<SVGProps<SVGSVGElement>>;
  navigation: { [key: string]: Navigation[] };
}

export const PagesCommonLayout = () => {
  const user = useAppSelector(selectCurrentUser);

  const [isSidebarOpen, handleOpenSidebar, handleCloseSidebar] = useOpen(false);

  const [activePermission, setActivePermission] = useState(sessionStorage.getItem('sidebar'));

  const navigationItem = NAVIGATION_LIST.find((i) => i.id === activePermission);

  const onStorageChange = () => {
    setActivePermission(sessionStorage.getItem('sidebar'));
  };

  useLocalStorage(onStorageChange);

  return (
    <div className='flex h-screen'>
      <Sidebar
        isOpen={isSidebarOpen}
        handleCloseSidebar={handleCloseSidebar}
        activeId={activePermission as string}
        navigationList={NAVIGATION_LIST}
      />
      <div className='flex-1 flex flex-col overflow-x-hidden'>
        <Header userName={user?.name} isSidebarOpen={isSidebarOpen} handleOpenSidebar={handleOpenSidebar} />

        <div>
          <Navbar
            isSidebarOpen={isSidebarOpen}
            handleCloseSidebar={handleCloseSidebar}
            navigationItem={navigationItem}
          />

          <main
            className={cn(
              'flex-1 overflow-x-hidden overflow-y-auto transition-all duration-300 p-4 lg:pl-20 pb-20 h-[calc(100vh_-_60px)',
              {
                'lg:pl-96': navigationItem,
              },
            )}
          >
            <Outlet />
          </main>
        </div>
      </div>
      <Overlay
        className={cn('hidden lg:hidden', { 'block lg:hidden': isSidebarOpen })}
        onClick={handleCloseSidebar}
        isShown
      />
    </div>
  );
};
