import { useEffect, useState } from 'react';

import { InconsistencySelects } from 'interfaces/inconsistencies.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { asyncGetInconsistencyTypes } from 'modules/inconsistency-types/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useInconsistenciesDefaultOptions = () => {
  const dispatch = useAppDispatch();

  const [filterSelects, setFilterSelects] = useState<InconsistencySelects>();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const department = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, department }));
      });

    dispatch(asyncGetInconsistencyTypes({}))
      .unwrap()
      .then((response) => {
        const inconsistencyType = convertObjectsToSelectOption(response.data);
        setFilterSelects((filter) => ({ ...filter, inconsistencyType }));
      });
  }, [dispatch]);

  return filterSelects;
};
