import { FC, useEffect } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams, useSearchParams } from 'react-router-dom';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { useBeforeRouteChange } from 'hooks/useBeforeRouteChange';
import { useManagerActionOptions } from 'hooks/useManagerActionOptions';
import { ManagerActionInput } from 'interfaces/manager-actions.interfaces';
import { ButtonVariants } from 'interfaces/shared/button.interfaces';
import { asyncGetCustomerRequest } from 'modules/customer-requests/action';
import {
  asyncCreateManagerAction,
  asyncGetManagerAction,
  asyncUpdateManagerAction,
  unsetManagerAction,
} from 'modules/manager-actions/action';
import { selectManagerAction } from 'modules/manager-actions/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { Textarea } from 'shared-components/Textarea';

interface Props {
  managerActionId?: number;
  handleCloseModal?: VoidFunction;
}

export const ManagerActionForm: FC<Props> = ({ managerActionId: id, handleCloseModal }) => {
  const { id: requestId } = useParams();
  const { formatMessage } = useIntl();

  const [searchParams] = useSearchParams();

  const isView = searchParams.has('view');

  const managerAction = useAppSelector(selectManagerAction);

  const { requestStatusOptions, defaultRequestStatus, customerStatusOptions, defaultCustomerStatus } =
    useManagerActionOptions(id, managerAction);

  const dispatch = useAppDispatch();

  const {
    control,
    reset,
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ManagerActionInput>();

  const onSubmit: SubmitHandler<ManagerActionInput> = async ({
    requestStatus: requestStatusOption,
    customerStatus: customerRequestOption,
    ...data
  }) => {
    const requestStatus = requestStatusOption.value as number;
    const customerStatus = customerRequestOption.value as number;

    const formattedData = { requestId: +(requestId ?? 0), requestStatus, customerStatus, ...data };

    await dispatch(
      id ? asyncUpdateManagerAction({ commentId: +id, ...formattedData }) : asyncCreateManagerAction(formattedData),
    )
      .unwrap()
      .then(() => {
        handleCloseModal && handleCloseModal();
      })
      .catch(() => 'error');
    await dispatch(asyncGetCustomerRequest(requestId ?? ''));
  };

  useBeforeRouteChange(() => dispatch(unsetManagerAction()));

  useEffect(() => {
    if (id) {
      dispatch(asyncGetManagerAction({ requestId: requestId ?? '', commentId: `${id}` }));
    }
  }, [dispatch, id, requestId]);

  useEffect(() => {
    if (id && managerAction) {
      reset({
        content: managerAction.content,
        requestStatus: { value: managerAction.requestStatus.id, label: managerAction.requestStatus.name },
        customerStatus: { value: managerAction?.customerStatus?.id, label: managerAction?.customerStatus?.name },
      });
    }
  }, [id, reset, managerAction]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-6'>
      <div className='flex-1 flex flex-col 2xl:flex-row gap-6'>
        <Card title='customer_request_status' wrapperClassName='flex-1' className='flex flex-col gap-4'>
          <Controller
            control={control}
            name='requestStatus'
            rules={REQUIRED_VALIDATION}
            defaultValue={defaultRequestStatus}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={isView}
                isRequired
                label='customer_request_status'
                defaultValue={defaultRequestStatus}
                options={requestStatusOptions}
                className='flex-1 h-full'
                error={errors['requestStatus']?.message}
                placeholder={formatMessage({ id: 'search_customer_request_status' })}
              />
            )}
          />
          <Controller
            control={control}
            name='customerStatus'
            rules={REQUIRED_VALIDATION}
            defaultValue={defaultCustomerStatus}
            render={({ field }) => (
              <Select
                {...field}
                isDisabled={isView}
                isRequired
                label='customer_status'
                defaultValue={defaultCustomerStatus}
                options={customerStatusOptions}
                className='flex-1 h-full'
                error={errors['customerStatus']?.message}
                placeholder={formatMessage({ id: 'search_customer_status' })}
              />
            )}
          />
        </Card>
        <Card title='description' className='flex flex-col gap-6' wrapperClassName='flex-1'>
          <Textarea
            {...register('content', REQUIRED_VALIDATION)}
            isRequired
            isView={isView}
            label='description'
            error={errors['content']?.message}
            placeholder={formatMessage({ id: 'description' })}
            data-test='manager-action-description'
            rows={4}
          />
        </Card>
      </div>
      <div className='flex gap-2'>
        <Button
          onClick={handleCloseModal}
          variant={ButtonVariants.CANCEL_OUTLINED}
          data-test='delete-changes'
          className='flex-1 w-full whitespace-nowrap'
        >
          <FormattedMessage id='go_back' />
        </Button>
        <Button disabled={isView} type='submit' data-test='save-changes' className='flex-1 w-full whitespace-nowrap'>
          <FormattedMessage id='save_changes' />
        </Button>
      </div>
    </form>
  );
};
