import { createSlice } from '@reduxjs/toolkit';

import { CustomerRequestStatus } from 'interfaces/customer-request-statuses.interfaces';

import { CUSTOMER_REQUEST_STATUSES_SLICE_NAME } from './action';
import {
  createCustomerRequestStatusReducer,
  deleteCustomerRequestStatusReducer,
  unsetCustomerRequestStatusReducer,
  getCustomerRequestStatusReducer,
  getCustomerRequestStatusesReducer,
  updateCustomerRequestStatusReducer,
} from './reducers';

export interface CustomerRequestStatusesSliceState {
  isLoading: boolean;
  customerRequestStatus: CustomerRequestStatus | null;
  customerRequestStatuses: CustomerRequestStatus[] | null;
  customerRequestStatusesCount: number | null;
}

export const initialState: CustomerRequestStatusesSliceState = {
  customerRequestStatus: null,
  customerRequestStatuses: null,
  customerRequestStatusesCount: null,
  isLoading: false,
};

const customerRequestStatusesSlice = createSlice({
  name: CUSTOMER_REQUEST_STATUSES_SLICE_NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    createCustomerRequestStatusReducer(builder);
    deleteCustomerRequestStatusReducer(builder);
    unsetCustomerRequestStatusReducer(builder);
    getCustomerRequestStatusReducer(builder);
    getCustomerRequestStatusesReducer(builder);
    updateCustomerRequestStatusReducer(builder);
  },
});

export const customerRequestStatusesReducer = customerRequestStatusesSlice.reducer;
