import { useEffect, useState } from 'react';

import { Document } from 'interfaces/documents.interfaces';
import { SelectOption } from 'interfaces/roles.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { asyncGetDocumentTypes } from 'modules/document-types/action';
import { useAppDispatch } from 'modules/store';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const useDocumentOptions = (document?: Document | null) => {
  const dispatch = useAppDispatch();

  const [departmentsOptions, setDepartmentsOptions] = useState<SelectOption[]>();
  const [documentTypesOptions, setDocumentTypesOptions] = useState<SelectOption[]>();

  const [defaultDepartmentOption, setDefaultDepartmentOption] = useState<SelectOption[]>();
  const [defaultDocumentTypeOption, setDefaultDocumentTypeOption] = useState<SelectOption>();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDepartmentsOptions(options);
        setDefaultDepartmentOption(
          options.filter(({ value }) => document?.departments?.some(({ id }) => value === id)),
        );
      });

    dispatch(asyncGetDocumentTypes({}))
      .unwrap()
      .then((response) => {
        const options = convertObjectsToSelectOption(response.data);
        setDocumentTypesOptions(options);
        setDefaultDocumentTypeOption(options.find(({ value }) => value === document?.documentType?.id));
      });
  }, [dispatch, document]);

  return { departmentsOptions, documentTypesOptions, defaultDepartmentOption, defaultDocumentTypeOption };
};
