import { useEffect, useState } from 'react';

import { COUNTRIES_COLUMNS } from 'constants/countries.constants';
import { AppRoutes } from 'constants/routes-paths.constants';
import { useOpen } from 'hooks/useOpen';
import { usePagination } from 'hooks/usePagination';
import { useQueryParams } from 'hooks/useQueryParams';
import { asyncDeleteCountry, asyncGetCountries } from 'modules/countries/action';
import { selectCountries, selectCountriesCount, selectIsLoading } from 'modules/countries/selectors';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { CountriesTableRow } from 'page-components/countries/CountriesTableRow';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ConfirmationModal } from 'shared-components/CancelModal';
import { Pagination } from 'shared-components/Pagination';
import { CommonLayoutTable } from 'shared-components/table/CommonLayoutTable';
import { TableNoData } from 'shared-components/table/TableNoData';

export const Countries = () => {
  const [countryId, setCountryId] = useState<number>();

  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectIsLoading);
  const counties = useAppSelector(selectCountries);
  const countiesCount = useAppSelector(selectCountriesCount);

  const [isModalOpened, handleOpenModal, handleCloseModal] = useOpen();

  const { page, handlePageChange } = usePagination();

  const onModalOpen = (id: number) => () => {
    setCountryId(id);
    handleOpenModal();
  };

  const onCountryDelete = async () => {
    if (countryId) {
      await dispatch(asyncDeleteCountry(countryId));
      await dispatch(asyncGetCountries({ page, perPage: 10 }));
      handleCloseModal();
    }
  };

  useEffect(() => {
    dispatch(asyncGetCountries({ page, perPage: 10 }));
  }, [dispatch, page]);

  useQueryParams({ page });

  return (
    <PageLayout title='countries' wrapperClassName='flex flex-col gap-4'>
      <CommonLayoutTable
        title='countries_management'
        columns={COUNTRIES_COLUMNS}
        creationPath={AppRoutes.countryCreate}
        isLoading={isLoading}
      >
        {counties?.length ? (
          counties.map((country) => (
            <CountriesTableRow key={country.id} data={country} handleDelete={onModalOpen(country.id)} />
          ))
        ) : (
          <TableNoData colSpan={COUNTRIES_COLUMNS.length} />
        )}
      </CommonLayoutTable>
      <Pagination pageSize={10} current={page} total={countiesCount ?? 0} onChange={handlePageChange} />

      <ConfirmationModal
        isOpened={isModalOpened}
        isLoading={isLoading}
        handleCloseModal={handleCloseModal}
        handleApply={onCountryDelete}
        title='delete_country'
      />
    </PageLayout>
  );
};
