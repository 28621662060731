import { useEffect, useState } from 'react';

import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import { ReactComponent as TimeSvg } from 'assets/svg/time.svg';
import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { SelectOption } from 'interfaces/roles.interfaces';
import { TaskDelegateInput } from 'interfaces/tasks.interfaces';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { asyncChangePerformer } from 'modules/tasks/action';
import { selectTask } from 'modules/tasks/selectors';
import { selectCurrentUser } from 'modules/user/selectors';
import { Button } from 'shared-components/Button';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

import { TaskDelegatesList } from './TaskDelegatesList';

export const TaskDelegate = () => {
  const [usersOptions, setUsersOptions] = useState<SelectOption[]>([]);
  const [defaultOption, setDefaultOption] = useState<SelectOption>();

  const { id } = useParams();

  const { formatMessage } = useIntl();

  const dispatch = useAppDispatch();

  const task = useAppSelector(selectTask);
  const user = useAppSelector(selectCurrentUser);

  const isPerformer = task?.performer.id === user?.id;
  const isAuthor = task?.owner.id === user?.id;
  const userHasSubordinates = !!user?.subordinates?.length;

  const isDisabled = !((isPerformer || isAuthor) && userHasSubordinates);

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<TaskDelegateInput>();

  const onSubmit: SubmitHandler<TaskDelegateInput> = async (data) => {
    if (!id) return;

    await dispatch(asyncChangePerformer({ performer: data.delegate?.value as number, taskId: +id }));
  };

  useEffect(() => {
    if (user?.subordinates) {
      const formattedDelegates = convertObjectsToSelectOption(user.subordinates);
      setUsersOptions(formattedDelegates);
      setDefaultOption(formattedDelegates.find(({ value }) => task?.performer.id === value));
    }
  }, [user, task]);

  return (
    <Card
      title='added_delegates'
      className='flex flex-col gap-4'
      wrapperClassName='flex-1 self-stretch md:self-start overflow-visible'
    >
      <div className='flex flex-col overflow-hidden'>
        <TaskDelegatesList
          delegates={task?.performers}
          isPerformer={isPerformer}
          currentUserId={user?.id}
          taskId={id}
        />
        <div className='self-start p-1 rounded-xl bg-white relative'>
          <TimeSvg className='shrink-0 stroke-mediumBlue w-6 h-6' />
          <div className='absolute bg-lightBlue w-2 left-1/2 -z-10 bottom-0 h-screen -translate-x-1/2' />
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col gap-2'>
        <Controller
          control={control}
          name='delegate'
          rules={REQUIRED_VALIDATION}
          defaultValue={defaultOption ?? usersOptions[0]}
          render={({ field }) => (
            <Select
              error={errors['delegate']?.message}
              isRequired
              menuPlacement='top'
              {...field}
              defaultValue={defaultOption}
              isDisabled={isDisabled}
              options={usersOptions}
              className='flex-1 h-full'
              placeholder={formatMessage({ id: 'search_performer' })}
            />
          )}
        />
        <Button type='submit' disabled={isDisabled}>
          <FormattedMessage id='add_delegate' />
        </Button>
      </form>
    </Card>
  );
};
