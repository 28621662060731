import { FC, ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

import { TableColumn } from 'constants/shared/table.constants';
import { Card } from 'shared-components/Card';
import { Link } from 'shared-components/Link';
import { Table } from 'shared-components/table/Table';

interface Props {
  columns: TableColumn[];
  creationPath?: string;
  isLoading?: boolean;
  children: ReactNode;
  title: string;
}

export const CommonLayoutTable: FC<Props> = ({ columns, isLoading, creationPath, children, title }) => {
  return (
    <>
      <Card title={title} className='flex flex-col gap-5'>
        <Table isLoading={isLoading} columns={columns}>
          {children}
        </Table>
        {creationPath && (
          <Link to={creationPath} className='self-start' data-test='add-item'>
            <FormattedMessage id='add' />
          </Link>
        )}
      </Card>
    </>
  );
};
