import { useEffect, useState } from 'react';

import { Controller, useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';

import { REQUIRED_VALIDATION } from 'constants/validation-rules.constants';
import { SelectOption } from 'interfaces/roles.interfaces';
import { UserInput } from 'interfaces/users.interfaces';
import { asyncGetDepartments } from 'modules/departments/action';
import { useAppDispatch, useAppSelector } from 'modules/store';
import { selectUser } from 'modules/users/selectors';
import { Card } from 'shared-components/Card';
import { Select } from 'shared-components/Select';
import { convertObjectsToSelectOption } from 'utils/select.utils';

export const UserDepartments = () => {
  const dispatch = useAppDispatch();

  const user = useAppSelector(selectUser);

  const [departmentOptions, setDepartmentOptions] = useState<SelectOption[]>([]);

  const userOption = departmentOptions.find((option) => option.value === user?.department.id) ?? departmentOptions[0];

  const {
    control,
    setValue,
    formState: { errors },
  } = useFormContext<UserInput>();

  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(asyncGetDepartments({}))
      .unwrap()
      .then(({ data }) => {
        const options = convertObjectsToSelectOption(data);
        setDepartmentOptions(options);
      });
  }, [dispatch, setValue, user]);

  useEffect(() => {
    if (!user) return;

    setValue('department', userOption);
  }, [departmentOptions, setValue, user, userOption]);

  return (
    <Card title='department' wrapperClassName='overflow-visible'>
      <Controller
        control={control}
        name='department'
        rules={REQUIRED_VALIDATION}
        defaultValue={userOption}
        render={({ field }) => (
          <Select
            {...field}
            isRequired
            error={errors['department']?.message}
            isClearable
            options={departmentOptions}
            className='flex-1 h-full'
            placeholder={formatMessage({ id: 'search_departments' })}
          />
        )}
      />
    </Card>
  );
};
