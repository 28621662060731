import { useAppSelector } from 'modules/store';
import { selectIsLoading } from 'modules/user/selectors';
import { PageLayout } from 'page-components/page-layout/PageLayout';
import { ProfileAbout } from 'page-components/profile/ProfileAbout';
import { ProfileAvatar } from 'page-components/profile/ProfileAvatar';
import { ProfileSettings } from 'page-components/profile/ProfileSettings';
import { Card } from 'shared-components/Card';

export const Profile = () => {
  const isLoading = useAppSelector(selectIsLoading);
  return (
    <PageLayout title='profile' isLoading={isLoading}>
      <div className='flex flex-col md:flex-row gap-6'>
        <div className='flex flex-col gap-6 basis-full md:basis-1/3'>
          <Card className='flex flex-col items-center gap-4'>
            <ProfileAvatar />
          </Card>
          <ProfileAbout />
        </div>
        <ProfileSettings />
      </div>
    </PageLayout>
  );
};
