import { ActionReducerMapBuilder, PayloadAction } from '@reduxjs/toolkit';

import { Self } from 'interfaces/self.interfaces';
import { UsersResponse } from 'interfaces/users.interfaces';
import { createUser, deleteUser, getUser, getUsers, unsetUser, unsetUsers, updateUser } from 'modules/users/action';
import { UsersSliceState } from 'modules/users/slice';
import { withLoadingReducer } from 'utils/reducers.utils';

export function getUserReducer(builder: ActionReducerMapBuilder<UsersSliceState>) {
  builder.addCase(getUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getUser.fulfilled, (state, { payload }: PayloadAction<Self>) => {
    state.user = payload;
    state.isLoading = false;
  });

  builder.addCase(getUser.rejected, (state) => {
    state.isLoading = false;
  });
}

export function getUsersReducer(builder: ActionReducerMapBuilder<UsersSliceState>) {
  builder.addCase(getUsers.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(getUsers.fulfilled, (state, { payload }: PayloadAction<UsersResponse>) => {
    state.users = payload.data;
    state.usersCount = payload.pagination.total;
    state.isLoading = false;
  });

  builder.addCase(getUsers.rejected, (state) => {
    state.isLoading = false;
  });
}

export const updateUserReducer = withLoadingReducer(updateUser);

export const createUserReducer = withLoadingReducer(createUser);

export function deleteUserReducer(builder: ActionReducerMapBuilder<UsersSliceState>) {
  builder.addCase(deleteUser.pending, (state) => {
    state.isLoading = true;
  });

  builder.addCase(deleteUser.fulfilled, (state) => {
    state.isLoading = false;
  });

  builder.addCase(deleteUser.rejected, (state) => {
    state.isLoading = false;
  });
}

export function unsetUserReducer(builder: ActionReducerMapBuilder<UsersSliceState>) {
  builder.addCase(unsetUser, (state) => {
    state.user = null;
    state.isLoading = false;
  });
}

export function unsetUsersReducer(builder: ActionReducerMapBuilder<UsersSliceState>) {
  builder.addCase(unsetUsers, (state) => {
    state.users = null;
    state.isLoading = false;
  });
}
