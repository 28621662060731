import { FC, Fragment } from 'react';

import { AppRoutes } from 'constants/routes-paths.constants';
import { Provider } from 'interfaces/providers.interfaces';
import { ColumnDefaultControls } from 'shared-components/table/ColumnDefaultControls';
import { TableData } from 'shared-components/table/TableData';
import { TableRow } from 'shared-components/table/TableRow';
import { getCommonRoutes } from 'utils/routes.utils';

interface Props {
  handleDelete: VoidFunction;
  data: Provider;
}

export const ProviderTableRow: FC<Props> = ({
  handleDelete,
  data: { id, name, email, phone, taxNumber, complects, qualityRate, termRate, conditionRate, costRate },
}) => {
  const { editRoute, viewRoute } = getCommonRoutes(AppRoutes.providers, id);

  const marks = [
    {
      id: 'quality',
      value: qualityRate,
    },
    {
      id: 'deadline',
      value: termRate,
    },
    {
      id: 'condition',
      value: conditionRate,
    },
    {
      id: 'cost',
      value: costRate,
    },
  ];

  return (
    <TableRow>
      <TableData>
        <ColumnDefaultControls
          handleDelete={handleDelete}
          editRoute={editRoute}
          viewRoute={viewRoute}
          editPermission='providers.update'
          deletePermission='providers.delete'
          viewPermission='providers.view'
        />
      </TableData>
      <TableData>{id}</TableData>
      <TableData className='last:text-left'>{name}</TableData>
      <TableData className='last:text-left'>{taxNumber}</TableData>
      <TableData className='last:text-left'>{phone}</TableData>
      <TableData className='last:text-left'>{email}</TableData>
      <TableData className='last:text-left'>
        <div className='flex gap-2 text-white'>
          {complects.map((component) => (
            <span key={component.id} className='flex p-2 text-sm rounded-md bg-blue'>
              {component.name}
            </span>
          ))}
        </div>
      </TableData>
      <TableData className='last:text-left'>
        <div className='flex gap-2 items-center'>
          {marks.map(({ id, value }, index) => (
            <Fragment key={id}>
              <span className='flex py-1 px-2.5 rounded-md bg-green text-white'>{value}</span>
              {index !== marks.length - 1 && '+'}
            </Fragment>
          ))}
          <span>=</span>
          <span>{marks.reduce((summary, { value }) => (summary += value), 0)}</span>
        </div>
      </TableData>
    </TableRow>
  );
};
