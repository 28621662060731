import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'modules/store';

const selectState = (x: RootState) => x.usersReducer;

export const selectUser = createSelector(selectState, ({ user }) => user);

export const selectUsers = createSelector(selectState, ({ users }) => users);

export const selectUsersCount = createSelector(selectState, ({ usersCount }) => usersCount);

export const selectIsLoading = createSelector(selectState, ({ isLoading }) => isLoading);
